import styled from 'styled-components'
import theme from 'theme_'

export const StyledLoaderOverlay = styled.div<{
  isFullScreen?: boolean
  backgroundColor?: string
}>`
  position: ${(p) => (p.isFullScreen ? 'fixed' : 'absolute')};
  z-index: ${theme.zIndex.loaderOverlay};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(p) =>
    p.backgroundColor || theme.colors.newBackgroundGrey};
`

export interface SpinnerContainerProps {
  mt?: string
}

export const CenteredContainer = styled.div<SpinnerContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${(p) =>
    p.mt &&
    `
    margin-top: ${p.mt};
  `};
`

export const CommonSpinnedWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

export const StyledCompanyImage = styled.img`
  animation: ${theme.animations.flicker} 2s infinite;
`
