import { defineMessages } from 'react-intl'

export const usersMessages = defineMessages({
  title: {
    id: 'users.title',
    description: 'Users page title',
    defaultMessage: 'Users',
  },
  filterAll: {
    id: 'users.filterAll',
    description: 'Users filter - All',
    defaultMessage: 'All',
  },
  filterAdmins: {
    id: 'users.filterAdmins',
    description: 'Users filter - Admins',
    defaultMessage: 'Admins',
  },
  filterHouseholds: {
    id: 'users.filterHouseholds',
    description: 'Users filter - Households',
    defaultMessage: 'Households',
  },
  filterGuests: {
    id: 'users.filterGuests',
    description: 'Users filter - Guests',
    defaultMessage: 'Guests',
  },
  inviteUserBtn: {
    id: 'users.inviteUserBtn',
    description: 'Invite user button',
    defaultMessage: 'Invite User',
  },
  invitationSent: {
    id: 'myAccount.successInviteMessage',
    description: 'Success invitation sent',
    defaultMessage: 'Invitation sent',
  },
  modalSuccess: {
    id: 'users.modalSuccess',
    description: 'Success button text after sending user invite',
    defaultMessage: 'Done',
  },
})
