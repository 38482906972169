import styled from 'styled-components'
import { H2, Regular } from 'components/Typography'
import Icon from '@mdi/react'
import theme from 'theme_'

export const StyledNoHomesWarn = styled(Icon)`
  margin-bottom: ${theme.spacing.sm};

  @media ${theme.devices.tablet} {
    margin-bottom: ${theme.spacing.md};
  }
`

enum TextMaxWidth {
  mobile = '330px',
  tablet = '450px',
  desktop = '750px',
}

export const StyledNoHomesTitle = styled(H2)`
  margin-bottom: ${theme.spacing.sm};
  text-align: center;
  max-width: ${TextMaxWidth.mobile};

  @media ${theme.devices.tablet} {
    max-width: ${TextMaxWidth.tablet};
    margin-bottom: ${theme.spacing.md};
  }

  @media ${theme.devices.desktop} {
    max-width: ${TextMaxWidth.desktop};;
    margin-bottom: ${theme.spacing.lg};
  }
`

export const StyledNoHomesText = styled(Regular)`
  margin-bottom: ${theme.spacing.lg};
  text-align: center;
  max-width: ${TextMaxWidth.mobile};

  @media ${theme.devices.tablet} {
    max-width: ${TextMaxWidth.tablet};
  }

  @media ${theme.devices.desktop} {
    max-width: ${TextMaxWidth.desktop};;
  }
`
