import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { MySubscriptionItemSubTitle, MySubscriptionItemTitle } from '../styles'
import { StyledSubsExpires } from './styles'
import { commonMessages } from 'app/i18n/messages'
import IntlFormattedDate from 'components/i18n/IntlFormattedDate'

interface SubscrExpiresProps {
  expiresAt: number | undefined
  oneLine?: boolean
  hasWarn?: boolean
}

const SubscrExpires: React.FC<SubscrExpiresProps> = ({
  expiresAt,
  oneLine,
  hasWarn,
}) => {
  const intl = useIntl()
  const { formatMessage } = intl

  return (
    <StyledSubsExpires $hasWarn={!!hasWarn}>
      {!oneLine && (
        <MySubscriptionItemTitle>
          {formatMessage(commonMessages.expires)}
        </MySubscriptionItemTitle>
      )}
      <MySubscriptionItemSubTitle>
        {oneLine && <FormattedMessage {...commonMessages.expires} />}{' '}
        <IntlFormattedDate date={expiresAt} />
      </MySubscriptionItemSubTitle>
    </StyledSubsExpires>
  )
}

export default SubscrExpires
