import { FC } from 'react'

import { StyledSelect } from './styles'

interface PropsType {
  [x: string]: any
  name: string
  placeholder?: string
}

export interface Option {
  label: string
  value: string
}

const DropDown: FC<PropsType> = ({
  name,
  value,
  onChange,
  options,
  placeholder,
}) => (
  <StyledSelect
    placeholder={placeholder}
    classNamePrefix="select"
    isSearchable={false}
    name={name}
    value={value}
    onChange={onChange}
    options={options}
  />
)

export default DropDown
