import styled from 'styled-components'

import theme from 'theme_'
import { H4 } from 'components/Typography'

export const StyledBackButtonContainer = styled.button`
  border: 0;
  background-color: transparent;
  padding: 0;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  color: ${theme.colors.beachGrey};
  transition: color 0.2s ease;

  &:hover {
    color: #fff;
  }
`

export const StyledBackButtonLabel = styled(H4).attrs({ as: 'label' })`
  margin-left: 8px;
  color: inherit;
  cursor: pointer;
`
