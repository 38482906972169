import styled from 'styled-components'
import theme from 'theme_'

export const StyledMobileMenuBtn = styled.button`
  appearance: none;
  display: flex;
  width: ${theme.spacing.md};
  height: ${theme.spacing.md};
  justify-content: center;
  align-items: center;
  padding: 0;
  border: none;
  background: transparent;

  @media ${theme.devices.desktop} {
    display: none;
  }
`

export const StyledDefaultScreenWrapper = styled.div`
  animation: ${theme.animations.fadeIn} 0.3s linear;
  padding-bottom: 60px;
`

export const StyledScreenWrapper = styled.div`
  animation: ${theme.animations.slideLeft} 0.2s ease;
`

export const StyledLanguageMobileWrapper = styled.div`
  border-top: 1px solid ${theme.colors.separatorColor};
  border-bottom: 1px solid ${theme.colors.separatorColor};
  padding-top: 15px;
  padding-bottom: 15px;
`
