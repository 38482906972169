import React from 'react'
import { useIntl } from 'react-intl'
import {
  mdiCreditCardOutline,
  mdiAccountCircleOutline,
  mdiPlaylistCheck,
} from '@mdi/js'

import routes from 'app/routes'

import AsideMenuLink from 'components/AsideMenu/AsideMenuLink'
import { accountMenuMessages } from '../messages'
import { isSubscriptionHidden } from 'app/utils/isSubscriptionHidden'

const AsideAccountMenuLinks: React.FC = () => {
  const { formatMessage } = useIntl()

  return (
    <>
      <AsideMenuLink
        to={routes.account}
        message={formatMessage(accountMenuMessages.myAccount)}
        path={mdiAccountCircleOutline}
      />

      {/* TODO temporary disabling */}
      {!isSubscriptionHidden && false && (
        <>
          <AsideMenuLink
            message={formatMessage(accountMenuMessages.paymentMethods)}
            path={mdiCreditCardOutline}
            to={routes.paymentMethods}
          />
          <AsideMenuLink
            message={formatMessage(accountMenuMessages.billingHistory)}
            path={mdiPlaylistCheck}
            to={routes.billingHistory}
          />
        </>
      )}
    </>
  )
}

export default AsideAccountMenuLinks
