import { FC } from 'react'
import { useIntl } from 'react-intl'

import routes from 'app/routes'
import { DealerInfo } from '../types'
import DealerBlock from '../DealerBlock'
import DealerCard from '../DealerCard'
import { findDealerMessages } from '../FindDealer/messages'
import { StyledHeaderAside } from './styles'
import IconLink from 'components/Button/IconLink'

interface CurrentDealerProps {
  dealerInfo: DealerInfo
  fullWidth?: boolean
}

const CurrentDealer: FC<CurrentDealerProps> = ({ dealerInfo, fullWidth }) => {
  const { formatMessage } = useIntl()

  return (
    <DealerBlock
      fullWidth={fullWidth}
      title={formatMessage(findDealerMessages.title)}
      headerAside={
        <StyledHeaderAside>
          {!fullWidth && <IconLink to={routes.myDealer} />}
        </StyledHeaderAside>
      }
    >
      <DealerCard dealerInfo={dealerInfo} fullWidth={fullWidth} />
    </DealerBlock>
  )
}

export default CurrentDealer
