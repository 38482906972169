import React, { useEffect, useState, ChangeEvent } from 'react'
import { Formik, FormikHelpers } from 'formik'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'

import {
  loginMessages,
  loginTermsAndPolicyMessages,
} from '../../login/messages'
import { loginSelectors } from '../../login/selectors'
import {
  ForgotPasswordLink,
  StyledLoginForm,
  StyledTermsAndPolicyLink,
} from './styles'
import {
  ForgotPasswordWrapper,
  StyledTermsAndPolicyText,
  SubmitLoginWrapper,
} from './styles'
import { ILoginFormValues } from '../../login/types'
import CommonButton from 'components/Button/CommonButton'
import { EmailField, PasswordField } from 'components/Fields'
import { useAppSelector } from 'app/hooks'
import { StyledInputError } from 'components/Input/styles'
import LoaderOverlay from 'components/Loader/LoaderOverlay'
import routes from 'app/routes'
import { TERMS_LINKS } from 'app/consts'

const WRONG_CREDENTIALS = 110

const initialLoginValues: ILoginFormValues = {
  email: '',
  password: '',
  clientId: '',
  twoFactor: [],
}

interface ILoginFormProps {
  onSubmit: (values: ILoginFormValues) => void
}

const LoginForm: React.FC<ILoginFormProps> = ({ onSubmit }) => {
  const { formatMessage } = useIntl()
  const history = useHistory()

  const loginError = useAppSelector(loginSelectors.error)

  const [loginFormError, setLoginFormError] = useState<string | null>(null)
  const [submitted, setSubmitted] = useState(false)

  const handleSubmit = async (
    values: ILoginFormValues,
    formikHelpers: FormikHelpers<ILoginFormValues>
  ) => {
    setSubmitted(false)
    await onSubmit(values)
    setSubmitted(true)
  }

  useEffect(() => {
    if (loginError?.code === WRONG_CREDENTIALS) {
      setLoginFormError(formatMessage(loginMessages.wrongCredentials))
    }
  }, [formatMessage, loginError?.code, history, submitted])

  type IChangeEventHandler = (e: ChangeEvent<any>) => void

  const handleLoginInputsChange = (
    e: ChangeEvent<any>,
    fieldHandleChange: IChangeEventHandler
  ) => {
    fieldHandleChange(e)
    setLoginFormError(null)
  }

  return (
    <Formik initialValues={initialLoginValues} onSubmit={handleSubmit}>
      {(formikProps) => (
        <StyledLoginForm>
          {formikProps.isSubmitting && <LoaderOverlay isFullScreen />}
          <EmailField
            name="email"
            autoComplete="email"
            onChange={(e: ChangeEvent<any>) =>
              handleLoginInputsChange(e, formikProps.handleChange)
            }
            invalid={formikProps.errors.email}
          />

          <PasswordField
            name="password"
            error={loginFormError}
            autoComplete=""
            onChange={(e: ChangeEvent<any>) =>
              handleLoginInputsChange(e, formikProps.handleChange)
            }
            invalid={formikProps.errors.password}
          />
          <StyledInputError>{loginFormError}</StyledInputError>

          <ForgotPasswordWrapper>
            <ForgotPasswordLink to={routes.auth.forgotPassword}>
              {formatMessage(loginMessages.forgotPassword)}
            </ForgotPasswordLink>
          </ForgotPasswordWrapper>

          <SubmitLoginWrapper>
            <CommonButton
              variant="goldenFilled"
              type="submit"
              disabled={
                formikProps.isSubmitting ||
                !formikProps.isValid ||
                !formikProps.values.email ||
                !formikProps.values.password
              }
            >
              {formatMessage(loginMessages.loginButton)}
            </CommonButton>
          </SubmitLoginWrapper>
          <StyledTermsAndPolicyText>
            {formatMessage(
              { ...loginTermsAndPolicyMessages.copy },
              {
                TermsLink: (
                  <StyledTermsAndPolicyLink
                    as="a"
                    href={TERMS_LINKS.termsAndConditions}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {formatMessage(loginTermsAndPolicyMessages.termsText)}
                  </StyledTermsAndPolicyLink>
                ),
                PrivacyLink: (
                  <StyledTermsAndPolicyLink
                    as="a"
                    href={TERMS_LINKS.privacyPolicy}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {formatMessage(loginTermsAndPolicyMessages.policyText)}
                  </StyledTermsAndPolicyLink>
                ),
              }
            )}
          </StyledTermsAndPolicyText>
        </StyledLoginForm>
      )}
    </Formik>
  )
}

export default LoginForm
