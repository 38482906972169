import styled from 'styled-components'
import { BluredBlock } from 'components/Layout'
import theme from 'theme_'

export const ClipContainer = styled(BluredBlock).attrs({ as: 'li' })`
  padding: 0;
  margin: 0;
  overflow: hidden;
  color: ${theme.colors.beachGrey};
`

export const ClipHeader = styled.div`
  padding: 8px;
  height: 52px;
  overflow: hidden;
  background-color: transparent;
`

export const ClipBody = styled.div`
  overflow: hidden;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 100px;
  @media ${theme.devices.tablet} {
    height: 211px;
  }
`

export const ClipThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
