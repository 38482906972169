import { FC, CSSProperties } from 'react'
import Icon from '@mdi/react'

import theme from 'theme_'

export { ReactComponent as ArrowLeft } from './keyboard-arrow-left.svg'
export { ReactComponent as CheckCircleOutline } from './check-circle-outline.svg'
export { ReactComponent as Home } from './home.svg'
export { ReactComponent as VpnKey } from './vpn-key.svg'
export { ReactComponent as Avatar } from './avatar.svg'
export { ReactComponent as WarningRounded } from './warning-rounded.svg'

export const MdiIcon: FC<{
  path: string
  color?: string | null
  style?: CSSProperties
  size?: number | string | null
}> = (props) => <Icon size={1} color={theme.colors.dolphinGrey} {...props} />
