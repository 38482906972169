import { authorizedBillingFetch } from 'app/axios/billing'
import { authorizedEdgeFetch } from 'app/axios/edge'

// "New" endpoint for info about home that includes subscriptionInfo
export const getHomeInfoById = (homeId: string) =>
  authorizedEdgeFetch.get(`/homes/${homeId}?hostInfo=true`)

export const getUber = (homeId: string) =>
  authorizedEdgeFetch.get(`homes/${homeId}/users/uber`)

export const getHomeAdmin = (homeId: string, userId: string) =>
  authorizedBillingFetch.get(`/home/${homeId}/admin/${userId}`)

export const getHomesByUserId = (userId: string) =>
  authorizedEdgeFetch.get(`/users/${userId}/homes`)

export const getHomeConfig = (homeId: string) =>
  authorizedEdgeFetch.get(`/homes/${homeId}/config`)
export interface ChangeDealerRequestShape {
  domainId: string
  homeId: string
  requestId: string
}

export const acceptDealerRequest = ({
  domainId,
  homeId,
  requestId,
}: ChangeDealerRequestShape) =>
  authorizedEdgeFetch.put(
    `/rms/domains/${domainId}/homes/${homeId}/requests/${requestId}`
  )

export const declineDealerRequest = ({
  domainId,
  homeId,
  requestId,
}: ChangeDealerRequestShape) =>
  authorizedEdgeFetch.delete(
    `/rms/domains/${domainId}/homes/${homeId}/requests/${requestId}`
  )

export const getClaimCode = (homeId: string) =>
  authorizedEdgeFetch.post(`/homes/${homeId}/claimcode`)
