import styled from 'styled-components'

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px -12px 0 -12px;

  > button {
    margin: 0 12px;
  }
`
