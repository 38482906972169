import React from 'react'
import { FormattedMessage } from 'react-intl'

import { H1 } from 'components/Typography'
import MyAccountForm from './components/MyAccountForm'
import ChangePassword from './components/ChangePassword'
import TwoFactor from './components/TwoFactor'
import ReactHelmet from 'features/analytics/RenderHelmet'

import { myAccountMessages } from './messages'
import { StyledAccountContainer, StyledMobileBackBtnWrap } from './styles'
import AccountBackBtn from './components/AccountBackBtn'
import { DeleteAccount } from './components/DeleteAccount'

const Account: React.FC = () => {
  return (
    <>
      <ReactHelmet title={myAccountMessages.myAccount.defaultMessage} />
      <StyledMobileBackBtnWrap>
        <AccountBackBtn />
      </StyledMobileBackBtnWrap>
      <H1>
        <FormattedMessage {...myAccountMessages.myAccount} />
      </H1>
      <StyledAccountContainer>
        <MyAccountForm />
        <ChangePassword />
        <TwoFactor />
        <DeleteAccount />
      </StyledAccountContainer>
    </>
  )
}

export default Account
