import { mdiCheckCircleOutline } from '@mdi/js'
import Icon from '@mdi/react'

import theme from 'theme_'

const DisabledCheckIcon = () => {
  return (
    <Icon
      path={mdiCheckCircleOutline}
      size={1}
      color={theme.colors.accentGold}
      style={{ opacity: 0.5, cursor: 'not-allowed' }}
    />
  )
}

export default DisabledCheckIcon
