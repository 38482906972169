import { FC, ReactElement } from 'react'

import DetailsBlock from '../../DetailsBlock'

import { InnerContainer } from './styles'

interface PermissionsBlockProps {
  children: ReactElement
  title: ReactElement
  isNarrow?: boolean
}

const PermissionsBlock: FC<PermissionsBlockProps> = ({
  children,
  title,
  isNarrow,
}) => {
  return (
    <DetailsBlock title={title}>
      <InnerContainer isNarrow={isNarrow}>{children}</InnerContainer>
    </DetailsBlock>
  )
}

export default PermissionsBlock
