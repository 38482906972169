import { createSelector } from '@reduxjs/toolkit'
import { path } from 'lodash/fp'

import {
  AUTH_REDUCER_NAME,
  LOGIN2FA_REDUCER_NAME,
  LOGIN_REDUCER_NAME,
} from '../../consts'
import { makeFetchSelectors } from 'app/redux-fetch/selectors'

export const twoFactorSelectors = makeFetchSelectors(
  AUTH_REDUCER_NAME,
  LOGIN2FA_REDUCER_NAME
)

export const loginSelectors = makeFetchSelectors(
  AUTH_REDUCER_NAME,
  LOGIN_REDUCER_NAME
)

export const loginUserEmailSelector = createSelector(
  loginSelectors.data,
  path(['payload', 'email'])
)
