import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  title: {
    id: 'userDetails.title',
    description: 'User details page title',
    defaultMessage: 'User Details',
  },
  info: {
    id: 'userDetails.infoBlock',
    description: 'User details info block',
    defaultMessage: 'User Info',
  },
  joinHomeDate: {
    id: 'userDetails.joinHomeDate',
    description: 'Joined home date label',
    defaultMessage: 'Joined Home',
  },
  lastConnectedDate: {
    id: 'userDetails.lastConnectedDate',
    description: 'Last connected date label',
    defaultMessage: 'Last Connected',
  },
  backBtn: {
    id: 'userDetails.backBtn',
    description: 'Back button',
    defaultMessage: 'Back to Users',
  },
  homePermissions: {
    id: 'userDetails.homePermissions',
    description: 'home permissions',
    defaultMessage: 'Home Permissions',
  },
  homeManagement: {
    id: 'userDetails.homeManagement',
    description: 'Home Management',
    defaultMessage: 'Permission for Home Management',
  },
  accessRemotely: {
    id: 'userDetails.accessRemotely',
    description: 'Access Home Remotely',
    defaultMessage: 'Permission for Accessing Home Remotely',
  },
  notifications: {
    id: 'userDetails.notifications',
    description: 'Notifications',
    defaultMessage: 'Permission for Notifications',
  },
  rooms: {
    id: 'userDetails.rooms',
    description: 'ROOMS',
    defaultMessage: 'Rooms',
  },
  allRooms: {
    id: 'userDetails.allRooms',
    description: 'All',
    defaultMessage: 'All',
  },
})
