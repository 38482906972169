import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  title: {
    id: 'myDealer.permissions.title',
    description: 'MyDealer Permission page title',
    defaultMessage: 'Dealer permissions',
  },
  monitoring: {
    id: 'myDealer.monitoring.title',
    description: 'MyDealer Monitoring permission title',
    defaultMessage: 'Monitoring',
  },
  systemAccess: {
    id: 'myDealer.systemAccess.title',
    description: 'MyDealer System Access permission title',
    defaultMessage: 'System Access',
  },
  systemAccessInfo: {
    id: 'myDealer.systemAccess.info',
    description: 'MyDealer System Access permission info',
    defaultMessage:
      'Monitoring will allow your Savant Dealer to monitor your Savant System and remotely access it for troubleshooting.',
  },
  systemAccessExpireInfo: {
    id: 'myDealer.systemAccessExpire.info',
    description: 'MyDealer System Access permission title',
    defaultMessage:
      'System Access will allow your Savant Dealer to make changes and update your Savant Home. You may set this to expire after a period of time.',
  },
  systemAccessExpire: {
    id: 'myDealer.systemAccessExpire.title',
    description: 'MyDealer System Access Expire permission title',
    defaultMessage: 'System Access Expires',
  },
  systemAccessExpireAt: {
    id: 'myDealer.systemAccessExpire.titleAt',
    description: 'MyDealer System Access Expire permission date',
    defaultMessage: 'System Access Expire on',
  },
  never: {
    id: 'myDealer.systemaccess.never',
    description: 'Dealer Access for home - Never',
    defaultMessage: 'Never',
  },
  forever: {
    id: 'myDealer.systemaccess.forever',
    description: 'Dealer Access for home - Forever',
    defaultMessage: 'Forever',
  },
  day1: {
    id: 'myDealer.days1',
    description: 'Dealer Access for home - 1 day',
    defaultMessage: '1 day',
  },
  days2: {
    id: 'myDealer.days2',
    description: 'Dealer Access for home - 2 days',
    defaultMessage: '2 days',
  },
  days3: {
    id: 'myDealer.days3',
    description: 'Dealer Access for home - 3 days',
    defaultMessage: '3 days',
  },
  permissionsTitle: {
    id: 'myDealer.permissionsTitle',
    description: 'Title text describing dealer permissions form',
    defaultMessage: 'Manage access to your home by your Savant Dealer',
  },
  removeButton: {
    id: 'myDealer.removeButton',
    description: 'Button for removing a dealer',
    defaultMessage: 'Remove Dealer',
  },
  removeDealerTitle: {
    id: 'myDealer.removeDealerTitle',
    description: 'Title of Remove Dealer confirmation popup',
    defaultMessage: 'Remove Dealer?',
  },
  removeDealerMsg: {
    id: 'myDealer.removeDealerMsg',
    description: 'Mesage of Remove Dealer confirmation popup',
    defaultMessage:
      'This will permanently revoke your Savant Dealer’s ability to remotely monitor and configure your Savant System and any Smart Networks.',
  },
  removeDealerConfirmBtn: {
    id: 'myDealer.removeDealerConfirmBtn',
    description: 'Title of Remove Dealer confirm button on confirmation popup',
    defaultMessage: 'Yes, Remove Dealer',
  },
})
