import styled from 'styled-components'
import Icon from '@mdi/react'

import theme from 'theme_'
import { BaseText } from 'components/Typography/Base'

export const StyledDescriptionText = styled(BaseText)`
  text-align: center;
  margin: ${theme.spacing.s} 0 ${theme.spacing.xxxl};
`

export const BackToLoginWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const SuccessIcon = styled(Icon)`
  color: ${theme.colors};
  margin: ${theme.spacing.xl} auto 0;
`
