import styled from 'styled-components'
import theme from 'theme_'

export const H1 = styled.h1`
  margin: 0;
  color: ${theme.colors.beachGrey};
  text-transform: uppercase;
  font-size: 32px;
  line-height: 32px;
  opacity: 0.7;
`

export const StyledH2 = styled.h2`
  font-size: 40px;
  font-weight: 700;
  color: ${theme.colors.beachGrey};
  text-align: center;
  text-transform: uppercase;
`

export const H2 = styled.h2`
  margin: 0;
  color: ${theme.colors.beachGrey};
  text-transform: uppercase;
  font-size: 18px;
  line-height: 1.25;

  @media ${theme.devices.tablet} {
    font-size: 24px;
  }

  @media ${theme.devices.desktop} {
    font-size: 32px;
  }
`

export const H3 = styled.h3`
  margin: 0;
  font-size: 18px;
  line-height: 20px;
  text-transform: uppercase;
  color: ${(p) => p.color || theme.colors.accentGold};
`

export const H4 = styled.h4`
  margin: 0;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: ${(p) => p.color || theme.colors.dimmedGold};
`

export const H5 = styled.h5`
  font-size: 20px;
  line-height: 24px;
  text-transform: capitalize;
  color: ${(p) => p.color || theme.colors.mutedGray};
`

export const Header2 = styled.h2`
  margin: 0;
  color: ${theme.colors.beachGrey};
  font-size: 18px;
  text-transform: uppercase;
`

export const SectionTitle = styled(H2)`
  align-self: start;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: ${theme.colors.mutedGray};
  margin: 0;
  margin-bottom: ${theme.spacing.md};
  text-transform: none;
`

export const BigTitle = styled(H4)`
  font-size: 26px;
  line-height: 28px;
  font-weight: 700;
`
