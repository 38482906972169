import styled from 'styled-components'

import { RegularModerate } from 'components/Typography'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    flex: 1;
  }
`

export const Label = styled(RegularModerate).attrs({ as: 'label' })`
  margin-right: 28px;
`
