import React, { useCallback, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { useAppDispatch, useAppSelector } from 'app/hooks'
import Modal from 'components/Modal'

import { forgotPasswordMessages } from 'features/auth/components/ForgotPassword/messages'
import { H2, H4, Regular } from 'components/Typography'
import { CommonButton } from 'components/Button'
import { StyledAccountBlurredBlock } from '../styles'
import { myAccountMessages } from '../../messages'
import { passInitResetThunk } from 'features/auth/components/ForgotPassword/slice'
import { currUserSelector } from 'features/auth/login/selectors'
import {
  ChangePassModalContainer,
  StyledChangePassBtnWrap,
  StyledInstructionsBlock,
} from './styles'
import { ModalBtnsWrap, ModalText, ModalTitle } from 'components/Modal/styles'
import { CheckCircleOutline } from 'components/Icons'
import { LoaderOverlay } from 'components/Loader'
import { passInitResetSelectors } from 'features/auth/components/ForgotPassword/selectors'
import theme from '../../../../../theme_'

const ChangePassword: React.FC = () => {
  const dispatch = useAppDispatch()
  const { formatMessage } = useIntl()

  const [isModalOpen, setIsModalOpen] = useState(false)

  const openModal = useCallback(() => {
    setIsModalOpen(true)
  }, [])

  const closeModal = useCallback(() => {
    setIsModalOpen(false)
  }, [setIsModalOpen])

  const isPassResetLoading = useAppSelector(passInitResetSelectors.isFetching)

  const { email } = useAppSelector(currUserSelector)

  const handleChange = useCallback(() => {
    dispatch(
      passInitResetThunk({
        email,
        onSuccess: () => {
          openModal()
        },
        onError: (err) => {
          console.log(err)
        },
      })
    )
  }, [dispatch, email, openModal])

  return (
    <>
      {isPassResetLoading && <LoaderOverlay />}
      <Modal isOpen={isModalOpen} onRequestClose={closeModal}>
        <ChangePassModalContainer>
          <CheckCircleOutline />
          <ModalTitle>
            <H2>{formatMessage(myAccountMessages.changePassModalHeading)}</H2>
          </ModalTitle>
          <ModalText>
            {formatMessage(myAccountMessages.changePassModalText)}
          </ModalText>
          <ModalBtnsWrap>
            <CommonButton variant="goldenOutlined" onClick={closeModal}>
              <FormattedMessage {...myAccountMessages.changePassModalClose} />
            </CommonButton>
          </ModalBtnsWrap>
        </ChangePassModalContainer>
      </Modal>
      <StyledAccountBlurredBlock>
        <H4 color={theme.colors.mutedGray}>
          <FormattedMessage {...myAccountMessages.changePassword} />
        </H4>
        <StyledInstructionsBlock>
          <Regular>
            {formatMessage(myAccountMessages.changePassInstructions)}
          </Regular>
        </StyledInstructionsBlock>
        <StyledChangePassBtnWrap>
          <CommonButton variant="goldenOutlined" onClick={handleChange}>
            {formatMessage(forgotPasswordMessages.sendEmail)}
          </CommonButton>
        </StyledChangePassBtnWrap>
      </StyledAccountBlurredBlock>
    </>
  )
}

export default ChangePassword
