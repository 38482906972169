import { BluredBlock } from 'components/Layout'
import styled from 'styled-components'
import theme from 'theme_'

export const StyledAccountBlurredBlock = styled(BluredBlock)`
  padding: ${theme.spacing.md} ${theme.spacing.md} ${theme.spacing.lg};
  margin-bottom: ${theme.spacing.s};

  @media ${theme.devices.desktop} {
    margin-bottom: ${theme.spacing.md};
  }
`

export const AccountInputWrapper = styled.div`
  width: 100%;
  margin-top: ${theme.spacing.s};

  > input {
    width: 100%;
  }
`
