import { combineReducers } from '@reduxjs/toolkit'
import {
  cameraClipReducer,
  cameraClipsListReducer,
  camerasListReducer,
  clipsFiltersReducer,
  clipsListReducer,
  clipsToRenderReducer,
} from './slice'
import {
  CAMERA_CLIP_REDUCER_NAME,
  CAMERA_CLIPS_LIST_REDUCER_NAME,
  CAMERAS_LIST_REDUCER_NAME,
  CLIPS_FILTERS_REDUCER_NAME,
  CLIPS_LIST_REDUCER_NAME,
  CLIPS_TO_RENDER_LIST_REDUCER_NAME,
} from './consts'

export default combineReducers({
  [CAMERAS_LIST_REDUCER_NAME]: camerasListReducer,
  [CLIPS_LIST_REDUCER_NAME]: clipsListReducer,
  [CLIPS_TO_RENDER_LIST_REDUCER_NAME]: clipsToRenderReducer,
  [CAMERA_CLIPS_LIST_REDUCER_NAME]: cameraClipsListReducer,
  [CAMERA_CLIP_REDUCER_NAME]: cameraClipReducer,
  [CLIPS_FILTERS_REDUCER_NAME]: clipsFiltersReducer,
})
