import { defineMessages } from 'react-intl'

export const homeSelectorMessages = defineMessages({
  selectHome: {
    id: 'homeSelector.selectHome',
    description: 'Header for admin home selector',
    defaultMessage: 'Select Home',
  },
  inputPlaceholder: {
    id: 'homeSelector.searchHome',
    description: 'Input placeholder for home search',
    defaultMessage: 'Search Home...',
  },
  openHome: {
    id: 'homeSelector.openHome',
    description: 'Text for button to select home',
    defaultMessage: 'Connect',
  },
})
