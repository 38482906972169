import { FC, useEffect, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import _isEmpty from 'lodash/isEmpty'

import { useAppDispatch, useAppSelector } from 'app/hooks'
import { currentHomeIdSelector } from '../homes/selectors'
import { H1 } from 'components/Typography'
import ReactHelmet from '../analytics/RenderHelmet'
import { LoaderOverlay } from '../../components/Loader'
import Spinner from '../../components/Loader/Spinner'
import { cameraRecordingsMessages } from './messages'
import {
  CameraRecordingsContentContainer,
  CameraRecordingsHeader,
  LoadMoreClipsBtnWrap,
  NoCamerasContainer,
  SpinnerContainer,
} from './styles'
import ClipsFilters from './components/ClipsFilters'
import ClipsList from './components/ClipsList'
import { ClipShape } from 'app/types/clip'
import { ClipsListRequestParams } from './api'
import {
  cameraFilterOptionsSelector,
  camerasListSelectors,
  clipsListSelectors,
  clipsToRenderSelector,
  cameraEndDateSelector,
  nextClipsSelector,
  cameraOldestFirstSelector,
  selectedCameraSelector,
  selectedCameraEventSelector,
  cameraStartDateSelector,
} from './selectors'
import {
  camerasListActions,
  clipsFiltersActions,
  clipsListActions,
  getCamerasListThunk,
  getClipsListThunk,
} from './slice'
import { CommonButton } from 'components/Button'
import theme from 'theme_'
import { setEndOfTheDay } from './utils'

const CameraRecordingsPage: FC = () => {
  const dispatch = useAppDispatch()
  const clipsToRender = useAppSelector<ClipShape[]>(clipsToRenderSelector)
  const hasLoaded = useAppSelector<boolean>(clipsListSelectors.isFinished)
  const isLoading = useAppSelector<boolean>(clipsListSelectors.isFetching)
  const currentHomeId = useAppSelector(currentHomeIdSelector)
  const cameraListOptions = useAppSelector(cameraFilterOptionsSelector)
  const cameraListIsLoading = useAppSelector<boolean>(
    camerasListSelectors.isFetching
  )

  const nextClipsId = useAppSelector(nextClipsSelector)

  const selectedCamera = useAppSelector(selectedCameraSelector)
  const oldestFirst = useAppSelector(cameraOldestFirstSelector)
  const startDate = useAppSelector(cameraStartDateSelector)
  const filterEndDate = useAppSelector(cameraEndDateSelector)
  const selectedCameraEvent = useAppSelector(selectedCameraEventSelector)

  const endDate = useMemo(() => setEndOfTheDay(filterEndDate), [filterEndDate])

  useEffect(() => {
    if (selectedCamera) {
      const params: ClipsListRequestParams = {
        cameraId: selectedCamera?.value,
        rangeStart: startDate,
        rangeEnd: endDate,
        eventIds: selectedCameraEvent?.value,
        limit: 12,
        oldestFirst: oldestFirst,
      }

      dispatch(getClipsListThunk(params))
    }

    return () => {
      dispatch(clipsListActions.clear())
    }
  }, [
    dispatch,
    endDate,
    selectedCameraEvent,
    oldestFirst,
    selectedCamera,
    startDate,
  ])

  const handleLoadNextClips = () => {
    const params: ClipsListRequestParams = {
      cameraId: selectedCamera?.value,
      rangeStart: startDate,
      rangeEnd: endDate,
      eventIds: selectedCameraEvent?.value,
      limit: 12,
      oldestFirst: oldestFirst,
      next: nextClipsId,
    }

    dispatch(getClipsListThunk(params))
  }

  useEffect(() => {
    if (currentHomeId) {
      dispatch(getCamerasListThunk(currentHomeId))
    }

    return () => {
      dispatch(clipsFiltersActions.clearSelectedCamera())
      dispatch(clipsFiltersActions.clearEventId())
      dispatch(camerasListActions.clear())
      dispatch(clipsListActions.clear())
    }
  }, [dispatch, currentHomeId])

  return (
    <>
      <ReactHelmet title={cameraRecordingsMessages.title.defaultMessage} />
      <CameraRecordingsHeader>
        <H1>
          <FormattedMessage {...cameraRecordingsMessages.title} />
        </H1>
      </CameraRecordingsHeader>

      <ClipsFilters />

      {_isEmpty(cameraListOptions) && !cameraListIsLoading ? (
        <NoCamerasContainer>
          <FormattedMessage {...cameraRecordingsMessages.noCameras} />
        </NoCamerasContainer>
      ) : (
        <CameraRecordingsContentContainer>
          {isLoading && (
            <LoaderOverlay backgroundColor={`${theme.overlay.darkImage}`} />
          )}
          {hasLoaded ? (
            <>
              <ClipsList clipsList={clipsToRender} />
              {nextClipsId && (
                <LoadMoreClipsBtnWrap>
                  {!isLoading && (
                    <CommonButton
                      variant="goldenFilled"
                      disabled={isLoading}
                      onClick={handleLoadNextClips}
                    >
                      <FormattedMessage
                        {...cameraRecordingsMessages.loadMore}
                      />
                    </CommonButton>
                  )}
                </LoadMoreClipsBtnWrap>
              )}
            </>
          ) : (
            <SpinnerContainer>
              <Spinner />
            </SpinnerContainer>
          )}
        </CameraRecordingsContentContainer>
      )}
    </>
  )
}

export default CameraRecordingsPage
