import styled from 'styled-components'
import theme from 'theme_'
import { FilterContainer } from '../styles'

export const CameraFilterContainer = styled(FilterContainer)`
  margin-top: ${theme.spacing.md};

  @media ${theme.devices.tablet} {
    margin-top: 0;
  }
`
