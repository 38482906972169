import { FC, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { useIntl, FormattedMessage } from 'react-intl'
import _get from 'lodash/get'

import theme from 'theme_'
import { H1, H3, Regular } from 'components/Typography'
import { formatRecurringPrice } from 'app/i18n'
import routes from 'app/routes'
import { useAppSelector } from 'app/hooks'

import { currentHomeIdSelector } from 'features/homes/selectors'
import { subscriptionFeaturesMessages } from 'features/homes/Subscriptions/components/SubscriptionTable/SubscrCategoryRows/messages'
import { purchaseMessages } from './messages'
import { priceByIdSelector } from 'features/homes/Subscriptions/selectors'
import CompleteModal from './CompleteModal'

import OptionsList from './OptionsList'
import PaymentMethods from './PaymentMethods'
import {
  Content,
  PlanDescription,
  Pricing,
  PaymentInfo,
  BackBtnWrapper,
  StyledPaymentSelection,
} from './styles'
import { BackButton } from 'components/Button'
import Spinner from 'components/Loader/Spinner'
import PaymentFailModal from './PaymentFailModal'
import ReactHelmet from 'features/analytics/RenderHelmet'

interface MatchParams {
  priceId: string
}
interface PaymentProps extends RouteComponentProps<MatchParams> {}

const PaymentSelection: FC<PaymentProps> = ({ match: { params }, ...rest }) => {
  const intl = useIntl()
  const history = useHistory()
  const price = useAppSelector(priceByIdSelector(params.priceId))
  const homeId = useAppSelector(currentHomeIdSelector)

  let priceMetadata: any
  if (price?.metadata) {
    const { typeInfo, ...rest } = price.metadata
    priceMetadata = rest
  }

  const [hasSubscribed] = useState(false)
  const [errorOcurred, setErrorOcurred] = useState(false)
  const [errorMsg] = useState<string>()

  const [, setHasCartAddStarted] = useState(false)

  if (!price || !homeId) return <Spinner />

  return (
    <>
      <ReactHelmet
        title={purchaseMessages.purchaseSubscription.defaultMessage}
      />

      <StyledPaymentSelection>
        <CompleteModal
          isOpen={hasSubscribed}
          onClose={() => rest.history.push(routes.subscription)}
        />
        <PaymentFailModal
          isOpen={errorOcurred}
          onClose={() => setErrorOcurred(false)}
          msg={errorMsg}
        />

        <BackBtnWrapper>
          <BackButton onClick={() => history.push(routes.subscription)}>
            <FormattedMessage {...purchaseMessages.backBtn} />
          </BackButton>
        </BackBtnWrapper>

        <H1>
          <FormattedMessage {...purchaseMessages.purchaseSubscription} />
        </H1>
        <Content>
          <PlanDescription>
            <Pricing>
              <H3 color={theme.colors.accentGold}>{price.nickname}</H3>

              <Regular>
                {formatRecurringPrice(
                  price.unit_amount,
                  price.recurring.interval,
                  intl
                )}
              </Regular>
            </Pricing>

            {priceMetadata &&
              Object.keys(priceMetadata).map((category: any) => {
                const categoryFeatures = Object.keys(priceMetadata[category])
                return (
                  <OptionsList
                    key={category}
                    title={category}
                    bullets={categoryFeatures
                      .filter((feature) =>
                        priceMetadata[category][feature] ? feature : undefined
                      )
                      .map((feature: any) => ({
                        label: intl.formatMessage(
                          _get(subscriptionFeaturesMessages, feature)
                        ),
                      }))}
                  />
                )
              })}
          </PlanDescription>

          <PaymentInfo>
            <PaymentMethods onInitAddCard={() => setHasCartAddStarted(true)} />
          </PaymentInfo>
        </Content>
      </StyledPaymentSelection>
    </>
  )
}

export default PaymentSelection
