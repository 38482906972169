import React from 'react'

import { StyledAsideMenu } from 'components/AsideMenu/styles'
import AsideAccountMenuLinks from './AsideAccountMenuLinks'
import { AsideAccountBackBtn } from './styles'
import AccountBackBtn from 'features/auth/account/components/AccountBackBtn'

const AsideMenu: React.FC = () => {
  return (
    <>
      <AsideAccountBackBtn>
        <AccountBackBtn />
      </AsideAccountBackBtn>
      <StyledAsideMenu>
        <AsideAccountMenuLinks />
      </StyledAsideMenu>
    </>
  )
}

export default AsideMenu
