import styled from 'styled-components'

import theme from 'theme_'
import Select from 'react-select'

export const StyledSelect = styled(Select)`
  .select__indicator-separator {
    display: none;
  }

  .select__dropdown-indicator {
    color: ${theme.colors.disabledGrey};
  }

  .select__menu {
    border: none;
    border-radius: 0;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: ${theme.overlay.SBlur};
    position: absolute;
    top: 100%;
    left: 0;
    opacity: 1;
    will-change: tranform;
    transform-origin: top;
    transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      transform 167ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    margin-top: 0;

    .select__menu-list {
      padding: 0;
    }
    .select__option {
      color: ${theme.colors.beachGrey};
      transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

      :hover {
        background-color: rgba(53, 50, 49, 0.5);
      }
    }

    .select__option--is-focused {
      background-color: rgba(53, 50, 49, 0.5) !important;
    }

    .select__option--is-selected,
    .select__option--is-focused {
      background-color: transparent;
    }
  }

  .select__control--is-focused {
    background-color: ${theme.colors.inputFillFocus}!important;
  }

  .select__control {
    height: 40px;
    background: ${theme.colors.inputFill};
    box-shadow: inset 0px -1px 0px #8a847b;
    cursor: pointer;
    border: none;
    border-radius: 0;

    :hover {
      background-color: ${theme.colors.inputFillFocus};
    }

    .select__single-value {
      color: ${theme.colors.beachGrey};
    }
  }
`
export const Label = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  color: ${theme.colors.beachGrey};
`
