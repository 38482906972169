import styled from 'styled-components'

import theme from 'theme_'
import { BluredBlock } from 'components/Layout'

export const StyledPaymentSelection = styled.div`
  position: relative;
  z-index: 1;
`

export const PlanDescription = styled(BluredBlock)`
  padding: 32px 20px;

  .OptionsList--last {
    padding-bottom: 0;
  }
`

export const PaymentInfo = styled.div`
  margin-top: 24px;
  width: 100%;

  @media ${theme.devices.desktop} {
    margin-top: 0;
  }
`

export const Content = styled.div`
  margin-top: ${theme.spacing.md};

  @media ${theme.devices.desktop} {
    display: flex;
    align-items: flex-start;

    ${PlanDescription} {
      width: 300px;
      flex-shrink: 0;
      margin-right: 24px;
    }
  }
`

export const Pricing = styled.div`
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
`

export const BackBtnWrapper = styled.div`
  margin-bottom: 12px;
`
