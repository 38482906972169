import { FormattedMessage } from 'react-intl'

import { makePublicUrl } from 'app/utils/publicUrl'
import { useCachedImage } from 'features/homes/components/HomesBgImage/HomesBgImage'
import React, { memo } from 'react'
import HomeSelectorLinkImg from '../../LinkImg'
import { StyledHomeSelectorLink, HomeWrapper } from './styles'
import { homeSelectorMessages } from '../../messages'
import { StyledCommonButtonDiv } from 'components/Button'

interface IHomeSelectorLinkProps {
  name: string
  imageUrl: string | null
  onClick: () => void
  isActive?: boolean
  className?: string | undefined
  isAdmin?: boolean
}

const HomeSelectorLink: React.FC<IHomeSelectorLinkProps> = (props) => {
  const image = useCachedImage(
    props.imageUrl,
    makePublicUrl('images/small-default-home-image.svg')
  )
  return (
    <StyledHomeSelectorLink
      className={props.className}
      onClick={props.isAdmin ? () => {} : props.onClick}
      isActive={props.isActive}
    >
      <HomeWrapper>
        <HomeSelectorLinkImg src={image} />
        {props.name}
      </HomeWrapper>

      {props.isAdmin && (
        <StyledCommonButtonDiv variant="goldenFilled" onClick={props.onClick}>
          <FormattedMessage {...homeSelectorMessages.openHome} />
        </StyledCommonButtonDiv>
      )}
    </StyledHomeSelectorLink>
  )
}

export default memo(HomeSelectorLink)
