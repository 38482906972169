import React from 'react'
import { useIntl } from 'react-intl'

import TwoFactorAuthForm from './TwoFactorAuthForm'
import LoginCommonLayout from '../LoginCommonLayout'
import { twoFactorAuthMessages } from './messages'
import { StyledMainHeading } from '../MainHeading/MainHeading'
import { useLocation } from 'react-router-dom'
import { ILoginFormValues } from 'features/auth/login/types'
import { LoaderOverlay } from 'components/Loader'

export interface TwoFactorRouteState {
  savCreds?: ILoginFormValues
}

const TwoFactorAuthPage: React.FC = () => {
  const { formatMessage } = useIntl()

  const { state: routeState } = useLocation<TwoFactorRouteState>()

  return routeState?.savCreds ? (
    <LoginCommonLayout>
      <StyledMainHeading>
        {formatMessage(twoFactorAuthMessages.title)}
      </StyledMainHeading>
      <TwoFactorAuthForm userCreds={routeState.savCreds} />
    </LoginCommonLayout>
  ) : (
    <LoaderOverlay />
  )
}

export default TwoFactorAuthPage
