import Icon from '@mdi/react'
import styled from 'styled-components'

import theme from 'theme_'
import { ButtonStyles } from 'components/Button/styles'
import CommonLink from 'components/Button/CommonLink'

export const StyledClipHeader = styled.header<{ isCompactView?: boolean }>`
  display: flex;
  align-items: center;
  height: ${(p) => (p.isCompactView ? '54px' : '76px')};
  padding: 0 ${(p) => (p.isCompactView ? theme.spacing.tiny : theme.spacing.sm)};
  @media ${theme.devices.tablet} {
    padding: 0 ${(p) => (p.isCompactView ? theme.spacing.s : theme.spacing.sm)};
  }
`

export const StyledDetectionIconImg = styled.img`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  margin-right: 4px;

  @media ${theme.devices.tablet} {
    margin-right: ${theme.spacing.s};
    width: 32px;
    height: 32px;
  }
`

export const StyledVideoIcon = styled(Icon)`
  display: inline-block;
  vertical-align: middle;
  margin-right: ${theme.spacing.tiny};
  color: ${theme.colors.beachGrey};
  width: 8px;
  margin-bottom: 1px;
  @media ${theme.devices.tablet} {
    margin-bottom: 2px;
    margin-right: ${theme.spacing.xxs};
    width: 12px;
  }
`

export const StyledHeaderCloseBtn = styled(ButtonStyles)`
  flex-shrink: 0;
  margin-left: auto;
  color: ${theme.colors.mutedGray};

  &:hover {
    color: ${theme.colors.dimmedGold};
  }
`

export const StyledHeaderCloseIcon = styled(Icon)`
  transition: color 0.2s ease-in;
`

export const StyledClipHeaderRow = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
export const StyledClipCameraName = styled(StyledClipHeaderRow)`
  font-size: 16px;
  font-weight: 700;
  color: ${theme.colors.beachGrey};

  .isCompactView & {
    font-size: 10px;
    line-height: 9px;
    font-weight: 400;
    margin-bottom: 4px;
  }

  @media ${theme.devices.tablet} {
    .isCompactView & {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 2px;
    }
  }
`
export const StyledClipDate = styled(StyledClipHeaderRow)`
  font-size: 16px;
  margin-top: 8px;
  color: ${theme.colors.mutedGray};

  .isCompactView & {
    font-size: 10px;
    line-height: 10px;
    margin-top: 0;
  }
  @media ${theme.devices.tablet} {
    .isCompactView & {
      font-size: 10px;
      line-height: 18px;
      margin-top: 2px;
    }
  }
`

export const StyledClipInfoWrapper = styled.div`
  min-width: 0;
  padding-right: 0;
  @media ${theme.devices.tablet} {
    padding-right: 10px;
  }
`

export const StyledExpandableWrapper = styled.div`
  margin-left: auto;
`

export const StyledExpandableBlock = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin-left: auto;
`
export const StyledExpandableBtn = styled(ButtonStyles)`
  color: ${theme.colors.mutedGray};

  &:hover {
    color: ${theme.colors.dimmedGold};
  }

  width: ${theme.spacing.base};
  @media ${theme.devices.tablet} {
    width: ${theme.spacing.md};
  }
`
export const StyledExpandedMenu = styled.div`
  position: absolute;
  z-index: 2;
  top: 100%;
  right: 0;
  padding: ${theme.spacing.tiny} ${theme.spacing.xs};
  border: 1px solid ${theme.colors.dimmedGold};
  border-radius: 8px;
  background-color: ${theme.colors.darkestGrey};
`
export const StyledExpandedItem = styled(CommonLink)`
  text-decoration: none;

  :hover {
    text-decoration: none;
    color: ${theme.colors.accentGold};
  }
`

export const StyledDownloadLink = styled(ButtonStyles)`
  margin-left: auto;
  color: ${theme.colors.beachGrey};

  &:hover {
    color: ${theme.colors.dimmedGold};
  }
`
