import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  pageTitle: {
    id: 'myDealer.page.title',
    description: 'MyDealer page title',
    defaultMessage: 'My Savant Dealer',
  },
  title: {
    id: 'myDealer.page.savant.title',
    description: 'MyDealer savant page title',
    defaultMessage: 'My savant dealer',
  },
})
