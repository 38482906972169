import React from 'react'
import _round from 'lodash/round'
import { IntlShape } from 'react-intl'

import { commonBackendErrorsMessages, commonMessages } from './messages'
import moment from 'moment'
import { subscriptionsErrorsMessages } from 'components/MySubscription/messages'

export const formatPrice = (price: number): string => {
  const formattedPrice = _round(Math.abs(price) / 100, 2).toLocaleString(
    'en-US',
    {
      maximumFractionDigits: 2,
    }
  )
  return price < 0 ? `-$${formattedPrice}` : `$${formattedPrice}`
}

export const getMessage = (formatMessage: any, err: any, msg: any) => {
  return commonBackendErrorsMessages.hasOwnProperty(err.errors[0].code)
    ? formatMessage(
        commonBackendErrorsMessages[
          err.errors[0].code as keyof typeof commonBackendErrorsMessages
        ]
      )
    : msg.hasOwnProperty(err.errors[0].code)
    ? formatMessage(
        msg[err.errors[0].code as keyof typeof subscriptionsErrorsMessages]
      )
    : formatMessage(commonBackendErrorsMessages.e_00001)
}

export const formatRecurringPrice = (
  price: number,
  interval: 'month' | 'year',
  intl: IntlShape
) => {
  const priceFormatted = formatPrice(price)
  if (interval === 'month') {
    return intl.formatMessage(commonMessages.monthlyPrice, {
      price: priceFormatted,
      b: (chunks: string) => <b>{chunks}</b>,
    })
  }
  return intl.formatMessage(commonMessages.yearlyPrice, {
    price: priceFormatted,
    b: (chunks: string) => <b>{chunks}</b>,
  })
}
/**
 *
 * @param timestamp: number
 * @returns string
 *
 * Takes-in timestamp and returns date in locale format (06/14/2021)
 */
export const formatTimestampToMomentL = (timestamp: number | null): string => {
  if (!timestamp) return ''
  return moment.unix(timestamp).format('L')
}
