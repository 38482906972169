import styled from 'styled-components'

import theme from 'theme_'

export const Container = styled.li`
  list-style: none;
  height: 103px;
`

export const InnerContainer = styled.div<{ isSelected: boolean }>`
  height: 103px;
  background: rgba(162, 149, 132, 0.2);
  border: 1px solid transparent;
  border-radius: 8px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    border-color: ${theme.colors.accentGold};
  }

  ${(p) =>
    p.isSelected &&
    `
    border-color: ${theme.colors.accentGold};
  `}
`

export const Title = styled.div`
  margin-top: 8px;
`
