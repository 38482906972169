import { selectClient } from '@racepointenergy/swc/scs/client'
import { deleteUsersUser } from '@racepointenergy/swc/scs/delete-users-user'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { useAppSelector } from 'app/hooks'
import { CommonButton } from 'components/Button'
import Modal from 'components/Modal'
import { H4 } from 'components/Typography'

import { StyledAccountBlurredBlock } from '../styles'
import theme from '../../../../../theme_'

/** prefer triple-state bool to state explosion */
enum ModalState {
  Initial,
  Confirm,
  Error,
}

const verticalSpaceEl = <div style={{ height: '24px' }} />

const DeleteAccountControl = styled.form`
  align-items: center;
  display: flex;
  justify-content: center;
`

const DeleteAccountHeader = styled(H4)`
  text-transform: uppercase;
`

const DeleteAccountInstructions = styled.p`
  margin: 0 auto;
  max-width: 600px;
`

const DeleteAccountModalInstructions = styled.p`
  color: ${theme.colors.dolphinGrey};
  margin: 0 auto;
  text-align: center;
`

export function DeleteAccount() {
  const { clientId, userId } = useAppSelector(selectClient)

  const [modalState, setModalState] = useState<ModalState>(ModalState.Initial)

  const [
    triggerDeleteUsersUser,
    deleteUsersUserResult,
  ] = deleteUsersUser.useMutation()

  // scs errors are not well-defined. IF an SCS error exists, use it
  // sav-wap 'pretends' this backup message is from SCS, so it is not translated
  const errorMessage =
    (deleteUsersUserResult?.error as any)?.data?.error?.message ??
    'Unknown error. Please try again.'

  return (
    <>
      <StyledAccountBlurredBlock>
        <DeleteAccountHeader color={theme.colors.mutedGray}>
          <FormattedMessage
            id="src/features/auth/account/components/DeleteAccount/header"
            defaultMessage="Delete Account"
          />
        </DeleteAccountHeader>
        {verticalSpaceEl}
        <DeleteAccountInstructions>
          <FormattedMessage
            id="src/features/auth/account/components/DeleteAccount/instructions"
            defaultMessage="To begin deleting your account, select the button below. We will send you an email with a link to complete the process."
          />
        </DeleteAccountInstructions>
        {verticalSpaceEl}
        <DeleteAccountControl
          onSubmit={(event) => {
            event.preventDefault()
            const deleteOperationParameters: {
              clientId?: string
              userId: string
            } = { userId }
            if (typeof clientId === 'string' && clientId.length > 0) {
              deleteOperationParameters.clientId = clientId
            }
            triggerDeleteUsersUser(deleteOperationParameters)
              .unwrap()
              .then(() => {
                setModalState(ModalState.Confirm)
              })
              .catch(() => {
                setModalState(ModalState.Error)
              })
          }}
        >
          <CommonButton type="submit" variant="goldenOutlined">
            <FormattedMessage
              id="src/features/auth/account/components/DeleteAccount/initiate"
              defaultMessage="Send Email"
            />
          </CommonButton>
        </DeleteAccountControl>
      </StyledAccountBlurredBlock>
      <Modal
        isOpen={modalState === ModalState.Confirm}
        onRequestClose={() => {
          setModalState(ModalState.Initial)
        }}
        shouldCloseOnEsc
      >
        <DeleteAccountModalInstructions>
          <FormattedMessage
            id="src/features/auth/account/components/DeleteAccount/email"
            defaultMessage="Check your email to continue deleting your account."
          />
        </DeleteAccountModalInstructions>
        {verticalSpaceEl}
        <DeleteAccountControl
          onSubmit={(event) => {
            event.preventDefault()
            setModalState(ModalState.Initial)
          }}
        >
          <CommonButton variant="goldenOutlined" type="submit">
            <FormattedMessage
              defaultMessage="Close"
              id="src/features/auth/account/components/DeleteAccount/dismissError"
            />
          </CommonButton>
        </DeleteAccountControl>
      </Modal>
      <Modal
        isOpen={modalState === ModalState.Error}
        onRequestClose={() => {
          setModalState(ModalState.Initial)
        }}
        shouldCloseOnEsc
      >
        <DeleteAccountModalInstructions>
          {errorMessage}
        </DeleteAccountModalInstructions>
        {verticalSpaceEl}
        <DeleteAccountControl
          onSubmit={(event) => {
            event.preventDefault()
            setModalState(ModalState.Initial)
          }}
        >
          <CommonButton variant="goldenOutlined" type="submit">
            <FormattedMessage
              defaultMessage="Close"
              id="src/features/auth/account/components/DeleteAccount/dismissError"
            />
          </CommonButton>
        </DeleteAccountControl>
      </Modal>
    </>
  )
}
