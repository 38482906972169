import { createSelector } from '@reduxjs/toolkit'
import { makeFetchSelectors } from 'app/redux-fetch'
import _cloneDeep from 'lodash/cloneDeep'

import {
  CONNECTED_APPS_REDUCER_NAME,
  DELETE_PARTNER_REDUCER_NAME,
  ENROLL_PARTNER_REDUCER_NAME,
  PARTNERS_INBOUND_LIST_REDUCER_NAME,
  PARTNERS_LIST_REDUCER_NAME,
} from './reducer'
import { SUPPORTED_APPS } from './consts'
import { createDeepEqualSelector } from '../../app/utils/createDeepEqualSelector'
import { PartnerShape } from 'app/types/partner'

export const partnersSelectors = makeFetchSelectors(
  CONNECTED_APPS_REDUCER_NAME,
  PARTNERS_LIST_REDUCER_NAME
)

export const partnersInboundSelectors = makeFetchSelectors(
  CONNECTED_APPS_REDUCER_NAME,
  PARTNERS_INBOUND_LIST_REDUCER_NAME
)

export const deletePartnerSelectors = makeFetchSelectors(
  CONNECTED_APPS_REDUCER_NAME,
  DELETE_PARTNER_REDUCER_NAME
)

export const enrollPartnerSelectors = makeFetchSelectors(
  CONNECTED_APPS_REDUCER_NAME,
  ENROLL_PARTNER_REDUCER_NAME
)

export const connectedAppsListHasLoaded = createSelector(
  [partnersSelectors.isFinished, partnersInboundSelectors.isFinished],
  (partnersIsFinished, partnersInboundIsFinish) =>
    partnersIsFinished && partnersInboundIsFinish
)

export const connectedAppsListIsLoading = createSelector(
  [partnersSelectors.isFetching, partnersInboundSelectors.isFetching],
  (isPartnersLoading, isPartnersInboundLoading) =>
    isPartnersLoading && isPartnersInboundLoading
)

export const connectedAppsListSelector = createDeepEqualSelector(
  [partnersSelectors.data, partnersInboundSelectors.data],
  (partners, partnersInbound) => {
    partners = partners || []
    partnersInbound = partnersInbound || []
    const allPartners = partners.concat(partnersInbound)
    const supportedApps = _cloneDeep(SUPPORTED_APPS)

    return supportedApps.map((app) => {
      const partner = allPartners.find(
        (partner: PartnerShape) =>
          partner.name === app.name ||
          (app.utilityName && partner.name.includes(app.utilityName))
      )
      if (partner?.active) {
        app.active = !!partner?.active
      }

      return { ...app, friendlyName: partner?.friendlyName }
    })
  }
)
