import styled from 'styled-components'
import theme from 'theme_'

export const StyledAccountContainer = styled.div`
  position: relative;
  z-index: 1;
  margin-top: ${theme.spacing.md};
  padding-bottom: 100px;
`

export const StyledMobileBackBtnWrap = styled.div`
  margin-bottom: ${theme.spacing.s};

  @media ${theme.devices.desktop} {
    display: none;
  }
`
