import React from 'react'

import { Route } from 'react-router-dom'
import routes from 'app/routes'

import Dashboard from 'features/homes/Dashboard/Dashboard'
import Subscription from 'features/homes/Subscriptions'
import Checkout from 'features/checkout'
import UsersPage from 'features/users'
import Payments from 'features/homes/Payments'
import UserDetails from 'features/users/UserDetails'
import BillingHistory from 'features/billingHistory'
import Account from 'features/auth/account'
import MyDealerInfo from 'features/MyDealerInfo'
import NotFoundPage from './components/NotFoundPage'
import NoHomesPage from './components/NoHomesPage'
import ConnectedAppsPage from './features/connectedApps'
import VpEnrollPage from './features/connectedApps/components/VpEnroll'
import CameraRecordingsPage from './features/cameraRecordings'
import GetHelpPage from 'features/getHelp'
import TutorialsPage from 'features/tutorials'
import DiscoverMorePage from 'features/discoverMore'
import { isSubscriptionHidden } from 'app/utils/isSubscriptionHidden'

const hideOnSubscription = (component: React.FC) => {
  return isSubscriptionHidden ? undefined : component
}

const AuthorizedRoutes: React.FC = () => {
  return (
    <>
      <Route path={routes.dashboard} exact component={Dashboard} />
      <Route path={routes.discoverMore} exact component={DiscoverMorePage} />
      <Route path={routes.tutorials} exact component={TutorialsPage} />
      <Route path={routes.users} exact component={UsersPage} />
      <Route path={routes.connectedApps} exact component={ConnectedAppsPage} />
      <Route path={routes.vpEnroll} exact component={VpEnrollPage} />

      <Route
        path={routes.cameraRecordings}
        exact
        component={CameraRecordingsPage}
      />
      <Route path={routes.getHelp} exact component={GetHelpPage} />
      <Route path={routes.userDetails} exact component={UserDetails} />
      <Route path={routes.subscription} exact component={Subscription} />
      <Route path={routes.checkout} component={hideOnSubscription(Checkout)} />
      <Route path={routes.payments} component={hideOnSubscription(Payments)} />
      <Route
        path={routes.billingHistory}
        component={hideOnSubscription(BillingHistory)}
      />
      <Route path={routes.account} exact component={Account} />
      <Route
        path={routes.paymentMethods}
        exact
        component={hideOnSubscription(Payments)}
      />
      <Route path={routes.myDealer} exact component={MyDealerInfo} />
      <Route path={routes.noHomes} exact component={NoHomesPage} />
      <Route path={routes.notFound} exact component={NotFoundPage} />
    </>
  )
}

export default AuthorizedRoutes
