import { createSlice, ReducersMapObject } from '@reduxjs/toolkit'

import { REQUEST_INITIAL_STATE } from './consts'

export const makeFetchSlice = (
  name: string,
  extraReducers?: ReducersMapObject
) =>
  createSlice({
    initialState: REQUEST_INITIAL_STATE,
    name,
    reducers: {
      // isFinished is being set only once on initial load, and then doesn't change
      init: (state) => ({ ...state, isFetching: true }),
      succeed: (_, action) => ({
        ...REQUEST_INITIAL_STATE,
        isFetching: false,
        isFinished: true,
        data: action.payload,
      }),
      failure: (_, action) => ({
        ...REQUEST_INITIAL_STATE,
        isFetching: false,
        isFinished: true,
        error: action.payload,
      }),
      clear: () => ({
        ...REQUEST_INITIAL_STATE,
      }),
    },
    extraReducers,
  })
