import { defineMessages } from 'react-intl'

export const resetSuccessPageMessages = defineMessages({
  title: {
    id: 'resetSuccess.title',
    description: 'Success title',
    defaultMessage: 'Password Reset Successfully',
  },
  description: {
    id: 'resetSuccess.description',
    description:
      'Text with instructions what user should do after Successfully action',
    defaultMessage:
      'Your password was successfully reset. Please sign in again.',
  },
  goToLogin: {
    id: 'resetSuccess.goToLogin',
    description: 'Go to login button',
    defaultMessage: 'Go to Sign In',
  },
})
