import React, { FC, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { useIntl } from 'react-intl'
import { mdiChevronDown, mdiClose } from '@mdi/js'

import DateRangePicker from 'components/DateRangePicker'
import {
  cameraEndDateSelector,
  cameraStartDateSelector,
} from '../../../selectors'
import { clipsFiltersActions } from '../../../slice'
import { dateRangePickerMessages } from './messages'
import {
  DateFilterContainer,
  DateRangePickerInput,
  FilterChevronIcon,
  FilterCloseIcon,
  ClearButton,
} from './styles'
import IntlFormattedDate from 'components/i18n/IntlFormattedDate'

const DateRangeFilter: FC = () => {
  const [calendarIsOpen, setCalendarIsOpen] = useState(false)
  const { formatMessage } = useIntl()
  const dispatch = useAppDispatch()
  const startDate = useAppSelector(cameraStartDateSelector)
  const endDate = useAppSelector(cameraEndDateSelector)

  const handleStartDateChange = (date: String | null) => {
    dispatch(clipsFiltersActions.setStartDate(date))
  }

  const handleEndDateChange = (date: String | null) => {
    setCalendarIsOpen(false)
    dispatch(clipsFiltersActions.setEndDate(date))
  }

  const handleShowDateRangePicker = () => setCalendarIsOpen(!calendarIsOpen)

  const handleClearRange = () => {
    dispatch(clipsFiltersActions.setStartDate(null))
    dispatch(clipsFiltersActions.setEndDate(null))
  }

  const dateRange = useMemo(
    () =>
      startDate &&
      endDate && (
        <>
          <IntlFormattedDate date={startDate} />
          {' - '}
          <IntlFormattedDate date={endDate} />
        </>
      ),
    [startDate, endDate]
  )

  return (
    <DateFilterContainer>
      {calendarIsOpen && (
        <DateRangePicker
          dateRange={[startDate, endDate]}
          handleStartDateChange={handleStartDateChange}
          handleEndDateChange={handleEndDateChange}
          onOutsideClick={handleShowDateRangePicker}
        />
      )}

      <DateRangePickerInput onClick={handleShowDateRangePicker}>
        {dateRange || formatMessage(dateRangePickerMessages.allPeriod)}

        {startDate && endDate && (
          <ClearButton
            onClick={(event) => {
              event.preventDefault()
              event.stopPropagation()
              handleClearRange()
            }}
          >
            <FilterCloseIcon path={mdiClose} />
          </ClearButton>
        )}
        <FilterChevronIcon path={mdiChevronDown} />
      </DateRangePickerInput>
    </DateFilterContainer>
  )
}

export default DateRangeFilter
