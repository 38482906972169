import { defineMessages } from 'react-intl'

export const notFoundPageMessages = defineMessages({
  title: {
    id: 'notFoundPage.title',
    description: 'Title of the page',
    defaultMessage: 'Page not found',
  },
  description: {
    id: 'notFoundPage.description',
    description: 'Description of the page',
    defaultMessage: 'An error has occurred.',
  },
  backToHome: {
    id: 'notFoundPage.backToHome',
    description: 'Text on button to go back to home menu',
    defaultMessage: 'Back to home',
  },
})
