import styled from 'styled-components'

import { Font14 } from 'components/Typography/Base'
import { MySubscriptionItem } from '../styles'

export const MySubscriptionBillingItem = styled(MySubscriptionItem)`
  ${Font14}
  text-align: right;

  & > div {
    justify-content: flex-end;
  }
`
