import styled from 'styled-components'
import theme from 'theme_'

export const Container = styled.div`
  border-top: 1px solid ${theme.colors.separatorColor};
  padding: 16px 0;
`

export const Bullets = styled.ul`
  margin: 8px 0;
  padding: 0;
`

export const Bullet = styled.li`
  margin-left: 18px;
`
