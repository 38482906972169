import { defineMessages } from 'react-intl'

export const subscriptionMessages = defineMessages({
  title: {
    id: 'subscription.title',
    description: 'My subscription title',
    defaultMessage: 'My subscription',
  },
  billingDate: {
    id: 'subscription.billing',
    description: 'Label for next subscription billing date',
    defaultMessage: 'Renewal date',
  },
  managedBy: {
    id: 'subscription.managedBy',
    description: 'Managed by label',
    defaultMessage: 'Managed by',
  },
  paymentBy: {
    id: 'subscription.paymentBy',
    description: 'Payment by label',
    defaultMessage: 'Payment by',
  },
  subscriptionRenew: {
    id: 'subscription.renewText',
    description: 'Label for renew subscription',
    defaultMessage:
      'Renew subscription to have the best experience with Savant {plan} features',
  },
  subscriptionToPlan: {
    id: 'subscription.subscriptionToPlan',
    description: 'Label for new subscription dynamic plan',
    defaultMessage: 'After this date you will have {plan}.',
  },
  renew: {
    id: 'subscription.renew',
    description: 'Renew subscription button text',
    defaultMessage: 'Renew',
  },
  upgrade: {
    id: 'subscription.upgrade',
    description: 'Upgrade subscription button text',
    defaultMessage: 'Upgrade',
  },
  basePlanName: {
    id: 'subscription.basePlanName',
    description: 'Base plan name',
    defaultMessage: 'Base (FREE)',
  },
  premiumPlan: {
    id: 'basePlan.Premium',
    description: 'Name of premium plan',
    defaultMessage: 'Premium',
  },
})

export const subscriptionsErrorsMessages = defineMessages({
  s_00001: {
    id: 'subscriptionsErrorsMessages.notFound',
    description: 'Subscription not found response',
    defaultMessage: 'Subscription not found',
  },
  s_00002: {
    id: 'subscriptionsErrorsMessages.paymentFail',
    description: 'Payment fails during creation subscription',
    defaultMessage:
      'The initial payment attempt fails. Try to change payment method.',
  },
  s_00003: {
    id: 'subscriptionsErrorsMessages.priceRequiredId',
    description: 'Price Id required in the request',
    defaultMessage: 'Price ID is a required field',
  },
  s_00004: {
    id: 'subscriptionsErrorsMessages.priceNotFound',
    description: 'Price not found during creation subscription',
    defaultMessage: 'Price not found',
  },
  s_00005: {
    id: 'subscriptionsErrorsMessages.updateSubscriptionNotFound',
    description:
      'The update subscription attempt fails. Add valid payment method.',
    defaultMessage:
      'The update subscription attempt fails. Add valid payment method.',
  },
  s_00006: {
    id: 'subscriptionsErrorsMessages.updateSubscriptionFail',
    description:
      'The update subscription attempt fails. Try to change payment method.',
    defaultMessage:
      'The update subscription attempt fails. Try to change payment method.',
  },
  s_00007: {
    id: 'subscriptionErrorsMessages.badId',
    description: 'Bad renew subscription Id',
    defaultMessage: 'Bad renew subscription Id',
  },
})
