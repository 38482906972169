import React from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
import { useSelector } from 'react-redux'
import { ReactComponent as WhiteLogoSvg } from 'images/Savant_white_logo.svg'
import { HeaderLogoWrapper } from './styles'
import { adminHomesListSelector } from 'features/homes/selectors'
import ROUTES from 'app/routes'

const HeaderLogo: React.FC = () => {
  const { pathname } = useLocation()
  const adminHomesList = useSelector(adminHomesListSelector)
  const redirectTo = adminHomesList.length ? ROUTES.dashboard : ROUTES.noHomes

  return (
    <HeaderLogoWrapper>
      {pathname === '/' ? (
        <WhiteLogoSvg />
      ) : (
        <Link to={redirectTo}>
          <WhiteLogoSvg />
        </Link>
      )}
    </HeaderLogoWrapper>
  )
}

export default HeaderLogo
