import styled from 'styled-components'
import theme from 'theme_'

export const DateRangePickerWrapper = styled.div`
  position: absolute;
  top: ${theme.spacing.lg};

  .react-datepicker {
    border: 1px solid ${theme.colors.dimmedGold};
    border-radius: ${theme.spacing.xs};
    box-shadow: 0 16px 32px ${theme.colors.newBackgroundGrey};

    .react-datepicker__navigation {
      z-index: 2;
    }

    .react-datepicker__navigation {
      height: 64px;
      width: 64px;
      .react-datepicker__navigation-icon::before {
        height: 16px;
        width: 16px;
        border-color: ${theme.colors.accentGold};
      }
    }

    .react-datepicker__header {
      z-index: 1;
      background-color: ${theme.colors.popupBrownGrey};
      border-color: ${theme.colors.dimmedGold};

      .react-datepicker__current-month,
      .react-datepicker-time__header,
      .react-datepicker-year-header {
        color: ${theme.colors.mutedGray};
        text-transform: uppercase;

        margin-top: 8px;
        margin-bottom: 8px;

        line-height: 20px;
        font-size: 16px;
        font-weight: 400;
      }

      .react-datepicker__day-name {
        color: ${theme.colors.beachGrey};
        margin: 2px;
        width: 32px;
        text-transform: capitalize;
      }

      .react-datepicker__month-select,
      .react-datepicker__year-select {
        border: 1px solid ${theme.colors.dimmedGold};
        border-radius: ${theme.spacing.xs};
        background-color: ${theme.colors.darkestGrey};
        padding: 2px 4px;
        width: 100px;
        color: ${theme.colors.dimmedGold};
        outline: none;
        margin-right: 4px;
        text-align: center;
        text-indent: 20px hanging;
        appearance: none;
        font-size: 16px;
      }
      .react-datepicker__year-select {
        text-indent: 30px hanging;
      }
    }

    .react-datepicker__month-container {
      height: auto;
      width: 300px;
      background-color: ${theme.colors.popupBrownGrey};
      border-radius: ${theme.spacing.xs};
      padding-bottom: 16px;

      .react-datepicker__month {
        margin: 0.4rem 0;
      }

      .react-datepicker__day {
        color: ${theme.colors.beachGrey};
        margin: 2px;
        width: 32px;
        outline: none;
        &:hover {
          background-color: ${theme.colors.dimmedGold};
          color: ${theme.colors.white};
        }
      }

      .react-datepicker__day--keyboard-selected {
        color: ${theme.colors.white};
        background-color: ${theme.colors.accentGold};
        outline: none;
      }

      .react-datepicker__day--in-selecting-range,
      .react-datepicker__day--in-range {
        color: ${theme.colors.white};
        background-color: ${theme.colors.accentGold};
        outline: none;
      }

      .react-datepicker__day--outside-month {
        color: ${theme.colors.dimmedGold};
      }

      .react-datepicker__day--disabled {
        color: ${theme.colors.duneWarmGrey};
      }
    }
  }
`
