import styled from 'styled-components'
import theme from 'theme_'
import Icon from '@mdi/react'
import { H2 } from 'components/Typography'
import { CommonStyledInput } from 'components/Input/styles'
import HomeSelectorLink from './HomeSelectorLink'

export const StyledHomeSelectorMenu = styled.div<{
  $isOpen?: boolean
  $isFirefox?: boolean
}>`
  display: ${(p) => (p.$isOpen ? 'block' : 'none')};
  animation: ${theme.animations.fadeIn} 0.2s ease;

  @media ${theme.devices.desktop} {
    position: absolute;
    z-index: 2;
    left: calc(100% + 4px);
    top: 0;
    width: 340px;
    border-radius: ${theme.spacing.xs};
    background-color: ${(p) =>
      p.$isFirefox
        ? theme.overlay.tinyAlphaBlack
        : theme.overlay.newDropdownBlack};
  }
`

export const SelectorMenuInner = styled.div`
  border-radius: ${theme.spacing.xs};
  backdrop-filter: ${theme.overlay.SBlur};

  @media ${theme.devices.desktop} {
    > button:first-child {
      border-radius: ${theme.spacing.xs} ${theme.spacing.xs} 0 0;
    }

    > button:last-child {
      border-radius: 0 0 ${theme.spacing.xs} ${theme.spacing.xs};
    }

    > button:only-child {
      border-radius: ${theme.spacing.xs};
    }
  }
`

export const HomeSearchHeader = styled(H2)`
  text-align: center;
  margin-bottom: 36px;
`

export const HomeSearchInput = styled(CommonStyledInput)`
  margin-bottom: 32px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  box-shadow: none;
`

export const InputIcon = styled(Icon)`
  position: absolute;
  color: ${theme.colors.beachGrey};
  top: 50%;
  transform: translateY(-50%);
  height: 15px;
  right: 10px;
`

export const InputWrapper = styled.div`
  position: relative;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 62px;
  margin-top: 20px;
`

export const AdminSelectorMenuInner = styled.div`
  padding-right: 5px;
  max-height: 60vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color rgba(0, 0, 0, 0);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: ${theme.colors.mutedGray};
  }
`

export const AdminHomeSelectorLink = styled(HomeSelectorLink)<{
  isActive?: boolean
}>`
  background-color: ${(p) =>
    p.isActive
      ? `${theme.overlay.inputFillFocus}`
      : `${theme.overlay.cardColor2}`};
  border-radius: 8px;
`
