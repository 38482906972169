import React from 'react'
import { Route } from 'react-router-dom'
import Login from 'features/auth/login/Login'

import TwoFactorAuth from 'features/auth/components/TwoFaAuth/TwoFactorAuth'
import TwoFactorAuthFormError from 'features/auth/components/TwoFaAuth/Error'
import TwoFactorAuthResendSuccess from 'features/auth/components/TwoFaAuth/ResendSuccess'
import routes from 'app/routes'

const UnauthorizedRoutes: React.FC = () => {
  return (
    <>
      <Route path="/" exact component={Login} />
      <Route path={routes.auth.twoFa} exact component={TwoFactorAuth} />
      <Route
        path={routes.auth.twoFaError}
        exact
        component={TwoFactorAuthFormError}
      />
      <Route
        path={routes.auth.twoFaSuccess}
        exact
        component={TwoFactorAuthResendSuccess}
      />
    </>
  )
}

export default UnauthorizedRoutes
