import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'
import theme from 'theme_'

import GothamBook from 'images/Gotham-Book.WOFF'
import GothamMedium from 'images/Gotham-Medium.WOFF'

export const GlobalStyle = createGlobalStyle`
  ${normalize}

  @font-face {
    font-family: 'Gotham';
    src: url('${GothamBook}') format('woff');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Gotham';
    src: url('${GothamMedium}') format('woff');
    font-weight: 700;
    font-style: normal;
  }

  html {
    box-sizing: border-box;
    height: -webkit-fill-available;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  body {
    min-height: 100vh;
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;
    font-size: 16px;
    font-family: ${theme.fonts.base};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: ${theme.colors.newBackgroundGrey};
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }

  .ReactModal__Html--open,
  .ReactModal__Body--open {
    overflow: hidden;
  }
`
