import hmacSha256 from 'crypto-js/hmac-sha256'
import { Buffer } from 'buffer'

import { SCS_AGENT } from 'app/consts'
import { AxiosRequestHeaders } from 'axios'

const makeJWT = (token: string) => {
  return JSON.stringify({
    alg: 'SHA256',
    iss: 'SCS',
    typ: 'user',
    sub: token,
    iat: +new Date(),
  })
}

const makeAuthHeader = (token: string, secret: string) => {
  const message = makeJWT(token)
  const hmacDigest = hmacSha256(message, secret).toString()
  return `${Buffer.from(message).toString('base64')}:${hmacDigest}`
}

export const makeBillingHeaders = () => {
  return {
    'Content-Type': 'application/json',
  }
}

export const makeEdgeHeaders = (): AxiosRequestHeaders => {
  return {
    'Content-Type': 'application/json',
    'SCS-Agent': SCS_AGENT,
  }
}

export const makeAuthorizedEdgeHeaders = (token = '', secret = '') => {
  const headers = {
    ...makeEdgeHeaders(),
    'SCS-Authorization': makeAuthHeader(token, secret),
  }

  return headers
}

export const makeAuthorizedBillingHeaders = (token = '', secret = '') => {
  const headers = {
    ...makeBillingHeaders(),
    'SCS-Authorization': makeAuthHeader(token, secret),
  }
  return headers
}
