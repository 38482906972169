import styled from 'styled-components'

import Icon from '@mdi/react'
import { Regular } from 'components/Typography'
import theme from 'theme_'

export const BackToLoginWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const SuccessIconContainer = styled.div`
  text-align: center;
`

export const SuccessIcon = styled(Icon)`
  color: ${(p) => p.theme.colors.success};
  margin-top: ${(p) => p.theme.spacing.xl};
`

export const StyledDescriptionText = styled(Regular)`
  margin-bottom: ${theme.spacing.xxl};
  text-align: center;
`
