import styled from 'styled-components'

import theme from 'theme_'

export const InnerContainer = styled.div`
  text-align: center;
`

export const ConfirmModalTitle = styled.div`
  margin-top: 36px;
`

export const ConfirmModalBody = styled.div`
  margin: 36px 0;
`

export const ConfirmModalFooter = styled.footer`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: ${theme.spacing.lg};
`
