import Icon from '@mdi/react'
import styled from 'styled-components'
import theme from 'theme_'

const HEADER_LEFT_WIDTH = 77

export const StyledAccountMenuHeader = styled.button`
  position: relative;
  z-index: 1;
  appearance: none;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: ${theme.spacing.xs} 0;
  color: ${theme.colors.dolphinGrey};
  text-align: left;
  outline: none;
  border: none;
  border-bottom: 1px solid ${theme.colors.separatorColor};
  background: none;
`

export const AccountMenuHeaderLeft = styled.div`
  flex-shrink: 0;
  width: ${HEADER_LEFT_WIDTH}px;
  padding-left: ${theme.spacing.xxs};

  > svg {
    display: block;
  }
`
export const AccountMenuHeaderRight = styled.div`
  width: 100%;

  @media ${theme.devices.desktop} {
    width: ${340 - HEADER_LEFT_WIDTH}px;
  }
`

export const AccountMenuHeaderName = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 18px;
  line-height: 20px;
  color: ${theme.colors.white};
`

export const AccountMenuHeaderEmail = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 16px;
  line-height: 24px;
`
export const StyledMobileChevron = styled(Icon)`
  position: absolute;
  z-index: 2;
  top: 27px;
  right: ${theme.spacing.base};
`
