import { defineMessages } from 'react-intl'

export const MySupportMessages = defineMessages({
  title: {
    id: 'mySupport.title',
    description: '"My Support" block title',
    defaultMessage: 'My Support',
  },
  titleSimple: {
    id: 'mySupport.titleSimple',
    description: '"My Support" block title when subscriptions are hidden',
    defaultMessage: 'Help & Feedback',
  },
  havingTrouble: {
    id: 'mySupport.havingTrouble',
    description: '"My Support" block text hinting if needs support',
    defaultMessage: 'Having trouble or issues with your Savant Home?',
  },
  solution: {
    id: 'mySupport.solution',
    description: '"My Support" block text describing a solution to get support',
    defaultMessage:
      'Contact our technical support agents to help you with your Savant Home.',
  },
  getSupport: {
    id: 'mySupport.getSupport',
    description:
      '"My Support" block button text that leads to "Get Help" section',
    defaultMessage: 'Get Support',
  },
  findDealer: {
    id: 'mySupport.findDealer',
    description:
      'What user should to get support while home does not have a dealer',
    defaultMessage: 'Find a Dealer to help you with your Savant Home.',
  },
  contactDealer: {
    id: 'mySupport.contactDealer',
    description: 'What user should to get support while home has a dealer',
    defaultMessage:
      'Contact your Savant Authorized Dealer to help you with your Savant Home or share feedback directly to Savant.',
  },
})
