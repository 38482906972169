import { defineMessages } from 'react-intl'

export const cameraFilterMessages = defineMessages({
  noCameras: {
    id: 'cameraFilter.noCamerasPlaceholder',
    description:
      'Сamera filter placeholder for filter-input when no cameras found',
    defaultMessage: 'No cameras found',
  },
})
