import usFlag from './US.svg'
import canadaFlag from './CA.svg'
import russiaFlag from './RU.svg'
import swedenFlag from './SE.svg'
import chinaFlag from './CN.svg'
import brazilFlag from './BR.svg'
import mexicoFlag from './MX.svg'

import {
  isChineseLocale,
  isFrenchLocale,
  isPortugueseLocale,
  isRussianLocale,
  isSpanishLocale,
  isSwedishLocale,
} from 'app/utils/locales/index'

export const flags = (language: string) => {
  if (isFrenchLocale(language)) {
    return canadaFlag
  }
  if (isRussianLocale(language)) {
    return russiaFlag
  }
  if (isSpanishLocale(language)) {
    return mexicoFlag
  }
  if (isSwedishLocale(language)) {
    return swedenFlag
  }
  if (isPortugueseLocale(language)) {
    return brazilFlag
  }
  if (isChineseLocale(language)) {
    return chinaFlag
  }
  return usFlag
}
