import { FC, useCallback, useEffect, useMemo } from 'react'
import _isEmpty from 'lodash/isEmpty'
import { useIntl } from 'react-intl'

import { CameraFilterContainer } from './styles'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  cameraFilterOptionsSelector,
  camerasListSelectors,
  getDefaultCameraSelector,
  selectedCameraSelector,
} from 'features/cameraRecordings/selectors'
import { clipsFiltersActions } from 'features/cameraRecordings/slice'
import { ActionMeta, OptionProps } from 'react-select/src/types'
import { cameraFilterMessages } from 'features/cameraRecordings/components/ClipsFilters/CameraFilter/messages'
import {
  DROPDOWN_PREFIX,
  StyledCommonDropdown,
} from 'components/Dropdown/ReactSelect/styles'

export interface CameraFilterOption {
  value: string
  label: string
}

const CameraFilter: FC = () => {
  const dispatch = useAppDispatch()
  const { formatMessage } = useIntl()
  const selectedCamera = useAppSelector(selectedCameraSelector)
  const cameraListOptions = useAppSelector(cameraFilterOptionsSelector)
  const defaultCamera = useAppSelector(getDefaultCameraSelector)
  const isLoading = useAppSelector<boolean>(camerasListSelectors.isFetching)

  useEffect(() => {
    if (defaultCamera && !selectedCamera) {
      dispatch(clipsFiltersActions.setSelectedCamera(defaultCamera))
    }
  }, [dispatch, defaultCamera, selectedCamera])

  const handleChange = useCallback(
    (newValue: OptionProps, triggeredAction: ActionMeta<OptionProps>) => {
      if (triggeredAction.action === 'clear') {
        dispatch(clipsFiltersActions.clearSelectedCamera())
      }
      dispatch(clipsFiltersActions.setSelectedCamera(newValue))
    },
    [dispatch]
  )

  const placeholder = useMemo(
    () =>
      _isEmpty(cameraListOptions) && !isLoading
        ? formatMessage(cameraFilterMessages.noCameras)
        : '',
    [formatMessage, isLoading, cameraListOptions]
  )

  return (
    <CameraFilterContainer title={placeholder}>
      <StyledCommonDropdown
        isDisabled={isLoading}
        value={selectedCamera}
        classNamePrefix={DROPDOWN_PREFIX}
        isLoading={isLoading}
        placeholder={placeholder}
        isSearchable={false}
        options={cameraListOptions}
        onChange={handleChange}
      />
    </CameraFilterContainer>
  )
}

export default CameraFilter
