import { useIntl } from 'react-intl'

import type { PlansTableElement } from '../consts'
import { subscriptionFeaturesMessages } from '../messages'
import PlansTableItem from '../PlansTableItem'
import {
  SubscriptionTableRowContainer,
  SubscriptionTableRowEssentials,
  SubscriptionTableRowFeatures,
  SubscriptionTableRowIncluded,
  SubscriptionTableRowNote,
} from './styles'

interface SubscriptionTableRowProps {
  features: string
  note?: string
  included: string
  essentials: string
  isBasicHomePlan: boolean
}

function SubscriptionTableRow({
  features,
  note,
  included,
  essentials,
  isBasicHomePlan,
}: SubscriptionTableRowProps) {
  const { formatMessage } = useIntl()

  return (
    <SubscriptionTableRowContainer isBasicHomePlan={isBasicHomePlan}>
      <SubscriptionTableRowFeatures>
        {formatMessage(subscriptionFeaturesMessages[features])}
        {note && (
          <>
            <br />

            <SubscriptionTableRowNote>
              {formatMessage(subscriptionFeaturesMessages[note])}
            </SubscriptionTableRowNote>
          </>
        )}
      </SubscriptionTableRowFeatures>
      <SubscriptionTableRowIncluded
        isBasicHomePlan={isBasicHomePlan}
        className={isBasicHomePlan ? 'active_cell' : 'inactive_cell'}
      >
        <PlansTableItem
          item={included as PlansTableElement}
          colorful={isBasicHomePlan}
        />
      </SubscriptionTableRowIncluded>
      <SubscriptionTableRowEssentials
        isBasicHomePlan={isBasicHomePlan}
        className={isBasicHomePlan ? 'inactive_cell' : 'active_cell'}
      >
        <PlansTableItem
          item={essentials as PlansTableElement}
          colorful={!isBasicHomePlan}
        />
      </SubscriptionTableRowEssentials>
    </SubscriptionTableRowContainer>
  )
}

export default SubscriptionTableRow
