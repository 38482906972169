import styled from 'styled-components'

import theme from 'theme_'

export const CodeContainer = styled.div`
  margin-top: ${theme.spacing.s};

  & > div {
    width: 100%;
    max-width: 100%;
  }
`

export const ShareCodeBtnContainer = styled.div`
  margin-top: ${theme.spacing.s};
`
