import styled, { css } from 'styled-components'

import theme from 'theme_'

export interface TxtShape {
  bold?: boolean
  uppercase?: boolean
  color?: string
  lh?: string
}

export const BaseText = styled.div<TxtShape>`
  font-family: ${theme.fonts.base};
  ${(p) =>
    p.bold &&
    `
    font-weight: 700;
  `}

  ${(p) =>
    p.uppercase &&
    `
    text-transform: uppercase;
  `}
`

export const Font14 = css`
  font-size: 14px;
  line-height: 16px;
`

export const Font16 = css`
  font-size: 16px;
  line-height: 18px;
`
