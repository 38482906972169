import { PartnerShape } from 'app/types/partner'
import { makePublicUrl } from 'app/utils/publicUrl'

export const SUPPORTED_APPS: PartnerShape[] = [
  {
    name: 'alexa',
    label: 'Amazon Alexa',
    authUrl:
      'https://www.amazon.com/Savant-Systems-LLC/dp/B073KWYYC8/ref=nodl_',
    active: false,
    iconUrl: makePublicUrl('images/connectedAppsIcons/alexa.svg'),
  },
  {
    name: 'google',
    label: 'Google Home',
    authUrl: 'https://www.savant.com/google-home',
    active: false,
    iconUrl: makePublicUrl('images/connectedAppsIcons/googleHome.svg'),
  },
  {
    name: 'ring',
    label: 'Ring',
    authUrl: undefined,
    active: false,
    iconUrl: makePublicUrl('images/connectedAppsIcons/ring.svg'),
  },
  // {
  //   name: 'joshai',
  //   label: 'JOSH.AI',
  //   authUrl: undefined,
  //   active: false,
  //   iconUrl: makePublicUrl('images/connectedAppsIcons/josh-ai.svg'),
  // },
  // {
  //   name: 'zoom',
  //   label: 'Zoom',
  //   authUrl: undefined,
  //   active: false,
  //   iconUrl: makePublicUrl('images/connectedAppsIcons/zoom.svg'),
  // },
  // {
  //   name: 'lutron',
  //   label: 'Lutron',
  //   authUrl: undefined,
  //   active: false,
  //   iconUrl: makePublicUrl('images/connectedAppsIcons/lutron.svg'),
  // },
  {
    name: 'august',
    label: 'August',
    authUrl: undefined,
    active: false,
    iconUrl: makePublicUrl('images/connectedAppsIcons/august.svg'),
  },
  {
    name: 'honeywell',
    label: 'Honeywell',
    authUrl: undefined,
    active: false,
    iconUrl: makePublicUrl('images/connectedAppsIcons/honeywell.svg'),
  },
  {
    name: 'gravityconnect-programDemo',
    utilityName: 'gravityconnect',
    label: 'Virtual Peaker',
    authUrl: '/connected-apps/vp-enroll',
    active: false,
    iconUrl: makePublicUrl('images/connectedAppsIcons/virtualPeaker.svg'),
    newPage: true,
  },
]
