import React, { FC } from 'react'
import { mdiEmailOutline, mdiCellphone, mdiAppleSafari } from '@mdi/js'

import theme from 'theme_'
import { DealerInfo } from '../types'
import {
  StyledDealerImageWrap,
  StyledDealerContactsLink,
  StyledIcon,
  StyledDealerName,
  StyledDealerImg,
  StyledDealerCardContacts,
} from './styles'

interface DealerCardProps {
  dealerInfo: DealerInfo
  fullWidth?: boolean
}

const DealerCard: FC<DealerCardProps> = ({ dealerInfo, fullWidth }) => {
  const pureCompanyLink = ''.replace(/(^\w+:|^)\/\//, '') // TODO

  return (
    <>
      <StyledDealerImageWrap>
        {dealerInfo.imageUrl && (
          <StyledDealerImg alt="Dealer logo" src={dealerInfo.imageUrl} />
        )}

        <StyledDealerName>{dealerInfo.name}</StyledDealerName>
      </StyledDealerImageWrap>

      <StyledDealerCardContacts fullWidth={fullWidth}>
        {!!pureCompanyLink && (
          <>
            <StyledDealerContactsLink href={'TODO'} target="_blank">
              <StyledIcon
                path={mdiAppleSafari}
                color={theme.colors.dolphinGrey}
                size={1}
              />
              <div>{pureCompanyLink}</div>
            </StyledDealerContactsLink>
            <br />
          </>
        )}

        <StyledDealerContactsLink href={`mailto:${dealerInfo.email}?subject=`}>
          <StyledIcon
            path={mdiEmailOutline}
            color={theme.colors.dolphinGrey}
            size={1}
          />
          <div>{dealerInfo.email}</div>
        </StyledDealerContactsLink>
        <br />

        <StyledDealerContactsLink href={`tel:${dealerInfo.phone}`}>
          <StyledIcon
            path={mdiCellphone}
            color={theme.colors.dolphinGrey}
            size={1}
          />
          <div>{dealerInfo.phone}</div>
        </StyledDealerContactsLink>
      </StyledDealerCardContacts>
    </>
  )
}

export default DealerCard
