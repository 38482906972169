import styled from 'styled-components'
import theme from 'theme_'

export const FilterModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

export const CloseBtn = styled.button`
  appearance: none;
  width: ${theme.spacing.md};
  height: ${theme.spacing.md};
  padding: 0;
  border: none;
  background: transparent;
  color: ${theme.colors.beachGrey};
  position: absolute;
  top: -${theme.spacing.md};
  right: 0;
`

export const OpenFilterModalIcon = styled.div`
  width: ${theme.spacing.lg};
  height: ${theme.spacing.lg};
  margin-left: ${theme.spacing.s};
  border-radius: ${theme.spacing.xs};
  background-color: transparent;
  border: none;
  padding: ${theme.spacing.tiny};
  transition: all 0.2s ease;
  color: ${theme.colors.beachGrey};
  :hover {
    background-color: ${theme.colors.inputFillFocus};
  }
`
