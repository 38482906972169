import { fetchEdgeDataThunk, makeFetchSlice } from 'app/redux-fetch'
import { AppDispatch } from 'app/store'
import {
  deletePartner,
  DeletePartnerParams,
  getPartners,
  getPartnersInbound,
  enrollPartner,
  EnrollPartnerParams,
} from './api'

const partnersSlice = makeFetchSlice('partners')
export const partnersReducer = partnersSlice.reducer
export const partnersActions = partnersSlice.actions

export const getPartnersThunk = (homeId: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      await fetchEdgeDataThunk({
        dispatch,
        actions: partnersActions,
        asyncFunc: () => getPartners(homeId),
      })
    } catch (error) {
      console.log('Error fetching partners for home', homeId, error)
    }
  }
}

const partnersInboundSlice = makeFetchSlice('partnersInbound')
export const partnersInboundReducer = partnersInboundSlice.reducer
export const partnersInboundActions = partnersInboundSlice.actions

export const getPartnersInboundThunk = (homeId: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      await fetchEdgeDataThunk({
        dispatch,
        actions: partnersInboundActions,
        asyncFunc: () => getPartnersInbound(homeId),
      })
    } catch (error) {
      console.log('Error fetching partnersInbound for home', homeId, error)
    }
  }
}

const deletePartnerSlice = makeFetchSlice('deletePartner')
export const deletePartnerReducer = deletePartnerSlice.reducer
export const deletePartnerActions = deletePartnerSlice.actions

export const deletePartnerThunk = (params: DeletePartnerParams) => {
  return async (dispatch: AppDispatch): Promise<void> => {
    try {
      await fetchEdgeDataThunk({
        dispatch,
        actions: deletePartnerActions,
        asyncFunc: () => deletePartner(params),
      })

      dispatch(getPartnersThunk(params.homeId))
      dispatch(getPartnersInboundThunk(params.homeId))
    } catch (error) {
      console.log('Error deleting partner', params.partner, error)
    }
  }
}

const enrollPartnerSlice = makeFetchSlice('enrollPartner')
export const enrollPartnerReducer = enrollPartnerSlice.reducer
export const enrollPartnerActions = enrollPartnerSlice.actions

export const enrollPartnerThunk = (params: EnrollPartnerParams) => {
  return async (dispatch: AppDispatch): Promise<void> => {
    try {
      await fetchEdgeDataThunk({
        dispatch,
        actions: enrollPartnerActions,
        asyncFunc: () => enrollPartner(params),
      })

      dispatch(getPartnersThunk(params.homeId))
      dispatch(getPartnersInboundThunk(params.homeId))
    } catch (error) {
      console.log('Error enrolling partner', params.partner, error)
    }
  }
}
