import React, { memo } from 'react'
import { useIntl } from 'react-intl'
import { ErrorMessage, Field } from 'formik'

import { fieldsMessages } from '../messages'
import { IFieldProps } from '../types'
import { emailFieldMessages } from './messages'
import { CommonStyledInput, StyledInputError } from 'components/Input/styles'

const EmailField: React.FC<IFieldProps> = ({ label, ...props }) => {
  const { formatMessage } = useIntl()

  // TODO Think about validation mechanism
  const validateEmail = (value: string) => {
    let error
    if (!value) {
      error = formatMessage(fieldsMessages.requiredField)
    }
    return error
  }

  return (
    <>
      {label && <label htmlFor={props.name}>{label}</label>}
      <CommonStyledInput
        as={Field}
        type="email"
        id={props.name}
        placeholder={
          props.placeholder ??
          `${formatMessage(emailFieldMessages.placeholder)}`
        }
        validate={validateEmail}
        {...props}
      />
      <StyledInputError>
        <ErrorMessage name={props.name} />
      </StyledInputError>
    </>
  )
}

export default memo(EmailField)
