import { combineReducers, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { fetchDataThunk, makeFetchSlice } from 'app/redux-fetch'
import { AppDispatch } from 'app/store'
import { getSubscriptionPrices } from './api'
import {
  SUBSCRIPTIONS_CONFIG_REDUCER_NAME,
  SUBSCRIPTIONS_PAYMENT_FAIL,
} from './consts'
import { SubscriptionPeriodType } from './types'

export interface ISubscriptionsState {
  uiPeriodPlan: SubscriptionPeriodType
  prices: any[] | null
}
export interface ISubscriptionPaymentFailState {
  error: string | undefined
}
const initialState: ISubscriptionsState = {
  uiPeriodPlan: 'monthly',
  prices: null,
}

const subscriptionsConfigSlice = createSlice({
  initialState,
  name: SUBSCRIPTIONS_CONFIG_REDUCER_NAME,
  reducers: {
    setUiPeriodPlan: (state, action: PayloadAction<SubscriptionPeriodType>) => {
      state.uiPeriodPlan = action.payload
    },
    setPrices: (state, action) => {
      state.prices = action.payload
    },
  },
})

export const subscriptionsConfigReducer = subscriptionsConfigSlice.reducer
export const subscriptionsConfigActions = subscriptionsConfigSlice.actions

const initialSubscriptionPaymentFailState: ISubscriptionPaymentFailState = {
  error: undefined,
}

const subscriptionPaymentFailSlice = createSlice({
  initialState: initialSubscriptionPaymentFailState,
  name: SUBSCRIPTIONS_PAYMENT_FAIL,
  reducers: {
    set: (state, action: PayloadAction<string>) => {
      state.error = action.payload
    },
    clear: (state) => {
      state.error = undefined
    },
  },
})

export const subscriptionPaymentFailReducer =
  subscriptionPaymentFailSlice.reducer
export const subscriptionPaymentFailActions =
  subscriptionPaymentFailSlice.actions

const subscrPricesSlice = makeFetchSlice('subscrPrices')
export const subscrPricesReducer = subscrPricesSlice.reducer
export const subscrPricesActions = subscrPricesSlice.actions

export const subscriptionsPricesThunk = (homeId: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const { data } = await fetchDataThunk({
        dispatch,
        actions: subscrPricesActions,
        asyncFunc: () => getSubscriptionPrices(homeId),
      })
      dispatch(subscriptionsConfigActions.setPrices(data))
    } catch (err) {
      dispatch(subscrPricesActions.failure(err))
      console.log(err)
    }
  }
}

export const subscriptionReducer = combineReducers({
  [SUBSCRIPTIONS_CONFIG_REDUCER_NAME]: subscriptionsConfigReducer,
  [SUBSCRIPTIONS_PAYMENT_FAIL]: subscriptionPaymentFailReducer,
})
