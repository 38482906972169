import React from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'

import ForgotPasswordForm from './ForgotPasswordForm'
import { passResetConfirmConfirmSelectors } from './selectors'
import { passInitResetThunk } from './slice'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { forgotPasswordMessages } from './messages'
import routes from 'app/routes'
import LoginCommonLayout from '../LoginCommonLayout'
import { StyledMainHeading } from '../MainHeading/MainHeading'

const ForgotPasswordPage: React.FC = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { formatMessage } = useIntl()

  const isPassResetting = useAppSelector(
    passResetConfirmConfirmSelectors.isFetching
  )

  return (
    <LoginCommonLayout>
      <StyledMainHeading>
        {formatMessage(forgotPasswordMessages.title)}
      </StyledMainHeading>
      <ForgotPasswordForm
        isLoading={isPassResetting}
        onSubmit={({ email }) =>
          dispatch(
            passInitResetThunk({
              email,
              onSuccess: () => {
                history.push(routes.auth.forgotPasswordInitSuccess)
              },
              onError: (err) => {
                console.log(err)
              },
            })
          )
        }
      />
    </LoginCommonLayout>
  )
}

export default ForgotPasswordPage
