import styled from 'styled-components'
import Icon from '@mdi/react'

import { Regular } from 'components/Typography'
import theme from 'theme_'

export const StyledDescriptionText = styled(Regular)`
  text-align: center;
  margin-bottom: ${theme.spacing.md};
`

export const BackToLoginWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const ErrorIcon = styled(Icon)`
  color: ${theme.colors.error};
  margin: ${theme.spacing.xl} auto 0;
  transform: rotate(180deg);
`
