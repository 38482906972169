import { defineMessages } from 'react-intl'

export const contactDealerMessages = defineMessages({
  title: {
    id: 'contactDealer.title',
    description: 'Text about specific dealer',
    defaultMessage: 'Contact Your Dealer',
  },
  description: {
    id: 'contactDealer.description',
    description: 'Text about specific dealer',
    defaultMessage:
      'Contact your local Savant Authorized Dealer for any issues regarding your Savant Home.',
  },
})
