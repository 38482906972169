import styled from 'styled-components'
import theme from 'theme_'

import { CommonStyledFooter } from '../styles'

export const LoginFooterWrapper = styled(CommonStyledFooter)`
  flex-direction: column;
  align-items: flex-end;
  padding-top: ${theme.spacing.lg};
  padding-bottom: ${theme.spacing.lg};

  div:first-child {
    margin-bottom: ${theme.spacing.lg};
  }
`

export const LoginFooterLinksContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  text-align: right;

  & > a:not(:first-child) {
    margin-left: ${theme.spacing.lg};
  }
`
