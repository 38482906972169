import { fetchEdgeDataThunk, makeFetchSlice } from 'app/redux-fetch'
import { AppDispatch } from 'app/store'
import { getDealerAccess, updateDealerAccess } from './api'
import { UpdateDealerRequest } from './types'

const dealerAccessSlice = makeFetchSlice('dealerAccess')

export const dealerAccessReducer = dealerAccessSlice.reducer
export const dealerAccessActions = dealerAccessSlice.actions

export const getDealerAccessThunk = (homeId: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      await fetchEdgeDataThunk({
        dispatch,
        actions: dealerAccessActions,
        asyncFunc: () => getDealerAccess(homeId),
      })
    } catch (error) {
      console.log('Error fetching dealer access for home', homeId, error)
    }
  }
}

const dealerUpdateAccessSlice = makeFetchSlice('dealerUpdateAccess')

export const dealerUpdateAccessReducer = dealerUpdateAccessSlice.reducer
export const dealerUpdateAccessActions = dealerUpdateAccessSlice.actions

export const updateDealerAccessThunk = (payload: UpdateDealerRequest) => {
  return async (dispatch: AppDispatch) => {
    try {
      await fetchEdgeDataThunk({
        dispatch,
        actions: dealerUpdateAccessActions,
        asyncFunc: () => updateDealerAccess(payload),
      })
    } catch (error) {
      console.log(
        'Error updating dealer access for home',
        payload.homeId,
        error
      )
    }
  }
}
