import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import axios, { AxiosInstance } from 'axios'

import Spinner from 'components/Loader/Spinner'

import { H1 } from 'components/Typography'
import { tutorialsMessages } from './messages'
// TODO extract common layout into reusable component
import {
  GuidesList,
  GuidesListItem,
  GuidesImgWrap,
  GuidesItemTitle,
  GuidesImg,
} from 'features/discoverMore/styles'

import ReactHelmet from '../analytics/RenderHelmet'

import { SpinnerContainer } from './styles'

// TODO move to vimeo service
export const authorizedVimeo: AxiosInstance = axios.create({
  baseURL: 'https://api.vimeo.com/',
  headers: {
    Authorization: 'bearer 35754a43cba5a706236ab2b94c051948',
  },
})

function TutorialsPage() {
  const [videos, setVideos] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    // TODO move to thunks
    authorizedVimeo
      .get('/albums/9832082/videos')
      .then((res) => res.data.data)
      .then(setVideos)
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false))
  }, [])

  return (
    <>
      <ReactHelmet title={tutorialsMessages.title.defaultMessage} />
      <H1>
        <FormattedMessage {...tutorialsMessages.title} />
      </H1>
      {isLoading ? (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      ) : (
        <GuidesList>
          {videos.map((video: any) => (
            <GuidesListItem
              as="a"
              href={video.link}
              target="_blank"
              key={video.link}
            >
              <GuidesImgWrap style={{ height: 208 }}>
                <GuidesImg src={video.pictures.base_link} alt={video.name} />
              </GuidesImgWrap>
              <GuidesItemTitle>{video.name}</GuidesItemTitle>
            </GuidesListItem>
          ))}
        </GuidesList>
      )}
    </>
  )
}

export default TutorialsPage
