import { keyframes } from 'styled-components'

// Adapted for min-width
const breakpoint = {
  mobileLarge: '415px',
  tablet: '721px',
  desktop: '1025px',
  mediumDesktop: '1280px',
}

const devices = {
  mobileLarge: `screen and (min-width: ${breakpoint.mobileLarge})`,
  tablet: `screen and (min-width: ${breakpoint.tablet})`,
  desktop: `screen and (min-width: ${breakpoint.desktop})`,
  mediumDesktop: `screen and (min-width: ${breakpoint.mediumDesktop})`,
}

const animations = {
  fadeIn: keyframes`
    from {
      opacity: 0;
      visibility: hidden
  }
    to {
      opacity: 1;
      visibility: visible
  }
  `,

  slideLeft: keyframes`
    from {
      transform: translateX(100%);
      opacity: 0;
      visibility: hidden
  }
    to {
      transform: translateX(0);
      opacity: 1;
      visibility: visible
  }
  `,
  // should have transform-origin: top
  dropDown: keyframes`
  from {
    transform: scaleY(0.5);
    opacity: 0.5;
  }
  to {
    transform: scaleY(1);
    opacity: 1;
  }
  `,
  flicker: keyframes`
    0%   { opacity: 1; }
    50%  { opacity: 0; }
    100% { opacity: 1; }
  `,
}

const theme = {
  colors: {
    black: '#000000',
    darkestGrey: '#3F3F3F',
    anotherDarkGrey: '#5F5F5F',
    newDarkGrey: '#747271',
    darkGrey: '#A5A5A5',
    horizonOrange: '#FF5F00',
    otterWarmBlack: '#382E2B',
    duneWarmGrey: '#696057',
    dolphinGrey: '#AEA69B',
    beachGrey: '#D2CCC1',
    pelicanGrey: '#DBD9D6',
    lightGrey: '#F2F2F2',
    white: '#FFFFFF',
    error: '#C5292A',
    success: '#23C514',
    newLightGrey: '#EBEBEB',
    disabledGrey: '#747271',
    dimmedGold: '#BBA88C',
    accentGold: '#E8B97A',
    accentOrange: '#FF5F00',

    cstBackgroundGrey: '#201E1D',
    newBackgroundGrey: '#121111',
    popupBrownGrey: '#272523',
    nonTransparentHighlight: '#3D3834',

    separatorColor: 'rgba(162, 149, 132, 0.2)',
    mutedGray: '#A39689',
    inputFill: 'rgba(105, 96, 87, 0.15)',
    inputFillFocus: 'rgba(105, 96, 87, 0.4)',
  },

  fonts: {
    base: 'Gotham, Roboto, sans-serif',
  },

  spacing: {
    tiny: '4px',
    xxs: '6px',
    xs: '8px',
    s: '12px',
    base: '16px',
    sm: '20px',
    md: '24px',
    lg: '32px',
    xl: '36px',
    xxl: '40px',
    xxxl: '48px',
    large: '64px',
    xlarge: '80px',
  },

  sizes: {
    maxContentWidth: '1440px',
    headerHeight: '75px',
    footerHeight: '90px',
  },

  breakpoint,

  devices,

  animations,

  zIndex: {
    bgImage: '-200',
    bgOverlay: '-199',
    loaderOverlay: '100',
    fixedHeader: '98',
    modal: 99,
    alertBanner: '97',
  },

  /* For darkening overlays, gradients, etc */
  overlay: {
    dropdownBlack: 'rgba(0, 0, 0, 0.3)',
    darkImage: 'rgba(0, 0, 0, 0.5)',
    newDropdownBlack: 'rgba(0, 0, 0, 0.7)',
    cardColor2: 'rgba(60, 56, 51, 0.5)',
    SBlur: 'blur(20px)',
    tinyAlphaBlack: 'rgba(0, 0, 0, 0.95)',
    newMenuHover: 'rgba(105, 96, 87, 0.3)',
    inputFillFocus: 'rgba(105, 96, 87, 0.4)',
    menuHover: 'rgba(53, 50, 49, 0.5)',
    slightLight: 'rgba(165, 165, 165, 0.1);',
    bgBeachOpac: 'rgba(187, 168, 140, 0.2)',
  },
}

export default theme
