import React, { FunctionComponent } from 'react'
import {
  StyledAsideMenuIcon,
  StyledAsideMenuImg,
  StyledAsideMenuLink,
} from './styles'

interface IAsideMenuProps {
  to: string
  path: string | FunctionComponent
  message: string
}

const AsideMenuLink: React.FC<IAsideMenuProps> = (props) => {
  return (
    <StyledAsideMenuLink exact to={props.to}>
      {typeof props.path === 'string' ? (
        <StyledAsideMenuIcon path={props.path} size={1} />
      ) : (
        <StyledAsideMenuImg>
          <props.path />
        </StyledAsideMenuImg>
      )}
      {props.message}
    </StyledAsideMenuLink>
  )
}

export default AsideMenuLink
