import { combineReducers, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { fetchDataThunk, makeFetchSlice } from 'app/redux-fetch'
import { AppDispatch } from 'app/store'

import {
  PAYMENT_METHODS_REDUCER_NAME,
  SELECTED_PAYMENT_METHOD_REDUCER_NAME,
  DETACH_PAYMENT_METHODS_REDUCER_NAME,
  CHANGED_DEFAULT_PAYMENT_METHODS_REDUCER_NAME,
  INVOICE_REDUCER_NAME,
  SETUP_INTENT_REDUCER_NAME,
} from './consts'
import {
  getPaymentMethods,
  setDefaultPaymentMethod,
  detachPaymentMethod,
  getInvoice,
  InvoiceParams,
  createSetupIntents,
} from './api'

const detachPaymentMethodsSlice = makeFetchSlice('detachPaymentMethods')
export const detachPaymentMethodsReducer = detachPaymentMethodsSlice.reducer
export const detachPaymentMethodsActions = detachPaymentMethodsSlice.actions

export const detachPaymentMethodThunk = (id: string, userId: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      await fetchDataThunk({
        dispatch,
        actions: detachPaymentMethodsActions,
        asyncFunc: () => detachPaymentMethod(id, userId),
      })
      dispatch(getPaymentMethodsThunk(userId))
    } catch (error) {
      console.log('Error detaching default payment method', error)
    }
  }
}

const defaultPaymentMethodsSlice = makeFetchSlice('defaultPaymentMethods')
export const defaultPaymentMethodsReducer = defaultPaymentMethodsSlice.reducer
export const defaultPaymentMethodsActions = defaultPaymentMethodsSlice.actions

export const setDefaultPaymentMethodThunk = (id: string, userId: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      await fetchDataThunk({
        dispatch,
        actions: defaultPaymentMethodsActions,
        asyncFunc: () => setDefaultPaymentMethod(id, userId),
      })
      dispatch(getPaymentMethodsThunk(userId))
    } catch (error) {
      console.log('Error setting default payment method', error)
    }
  }
}

const paymentMethodsSlice = makeFetchSlice('getPaymentMethods')
export const paymentMethodsReducer = paymentMethodsSlice.reducer
export const getPaymentMethodsActions = paymentMethodsSlice.actions

export const getPaymentMethodsThunk = (userId: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(getPaymentMethodsActions.clear())
      const res = await fetchDataThunk({
        dispatch,
        actions: getPaymentMethodsActions,
        asyncFunc: () => getPaymentMethods(userId),
      })
      const defaultPaymentMethod = res.data.find((e: any) => e.isDefault)?.id

      if (defaultPaymentMethod) {
        dispatch(
          selectedPaymentMethodActions.selectPaymentMethod(
            res.data.find((e: any) => e.isDefault).id
          )
        )
      } else {
        dispatch(selectedPaymentMethodActions.clear())
      }
    } catch (error) {
      console.log('Error getting payment methods', error)
    }
  }
}

const setupIntentSlice = makeFetchSlice('setupIntent')
export const setupIntentReducer = setupIntentSlice.reducer
export const setupIntentActions = setupIntentSlice.actions

export const createSetupIntentThunk = (userId: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const res = await fetchDataThunk({
        dispatch,
        actions: setupIntentActions,
        asyncFunc: () => createSetupIntents(userId),
      })

      return res
    } catch (error) {
      console.log(`Error while creating Stripe's SetupIntent`, error)
    }
  }
}

interface SelectedPaymentMethodState {
  id: string | null
}

const SELECT_PAYMENT_METHOD_INIT_STATE = {
  id: null,
} as SelectedPaymentMethodState

const selectedPaymentMethodSlice = createSlice({
  name: 'selectedPaymentMethod',
  initialState: SELECT_PAYMENT_METHOD_INIT_STATE,
  reducers: {
    selectPaymentMethod(state, action: PayloadAction<string>) {
      state.id = action.payload
    },
    clear: () => ({
      ...SELECT_PAYMENT_METHOD_INIT_STATE,
    }),
  },
})

export const selectedPaymentMethodActions = selectedPaymentMethodSlice.actions

const invoiceSlice = makeFetchSlice('getInvoice')
export const invoiceReducer = invoiceSlice.reducer
export const invoiceActions = invoiceSlice.actions

export const getInvoiceThunk = (
  params: InvoiceParams,
  homeId: string,
  userId: string
) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await fetchDataThunk({
        dispatch,
        actions: invoiceActions,
        asyncFunc: () => getInvoice(params, homeId, userId),
      })
      return response
    } catch (error) {
      console.log('Error getting invoice', error)
    }
  }
}

export const paymentReducer = combineReducers({
  [PAYMENT_METHODS_REDUCER_NAME]: paymentMethodsReducer,
  [SETUP_INTENT_REDUCER_NAME]: setupIntentReducer,
  [SELECTED_PAYMENT_METHOD_REDUCER_NAME]: selectedPaymentMethodSlice.reducer,
  [CHANGED_DEFAULT_PAYMENT_METHODS_REDUCER_NAME]: defaultPaymentMethodsReducer,
  [DETACH_PAYMENT_METHODS_REDUCER_NAME]: detachPaymentMethodsReducer,
  [INVOICE_REDUCER_NAME]: invoiceReducer,
})
