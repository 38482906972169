import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { mdiAlertCircleOutline } from '@mdi/js'
import ROUTES from 'app/routes'
import { adminHomesListSelector } from 'features/homes/selectors'
import { noHomesPageMessages } from './messages'
import {
  StyledNoHomesWarn,
  StyledNoHomesText,
  StyledNoHomesTitle
} from './styles'
import { StubPageWrapper } from '../Layout'

const NoHomesPage: React.FC = () => {
  const history = useHistory()
  const adminHomesList = useSelector(adminHomesListSelector)

  useEffect(() => {
    if (adminHomesList.length) {
      history.replace(ROUTES.dashboard)
    }
  }, [adminHomesList, history])

  return (
    <StubPageWrapper>
      <StyledNoHomesWarn path={mdiAlertCircleOutline} size={2.5} color="#A39689"/>
      <StyledNoHomesTitle><FormattedMessage {...noHomesPageMessages.title} /></StyledNoHomesTitle>
      <StyledNoHomesText><FormattedMessage {...noHomesPageMessages.description} /></StyledNoHomesText>
    </StubPageWrapper>
  )
}

export default NoHomesPage
