import React from 'react'
import { useIntl } from 'react-intl'

import AccountMenuNav from 'components/AccountMenu/AccountMenuNav/AccountMenuNav'
import MobileBackBtn from '../MobileBackBtn'
import { StyledScreenWrapper } from '../styles'
import { mobileMenuMessages } from '../messages'

interface IScreenAccountProps {
  handleScreenDefault: () => void
  handleCloseMenu?: () => void
}

const ScreenAccount: React.FC<IScreenAccountProps> = (props) => {
  const { formatMessage } = useIntl()
  return (
    <StyledScreenWrapper>
      <MobileBackBtn
        handleClick={props.handleScreenDefault}
        text={formatMessage(mobileMenuMessages.settings)}
      />
      <AccountMenuNav onLinkClick={props.handleCloseMenu} />
    </StyledScreenWrapper>
  )
}

export default ScreenAccount
