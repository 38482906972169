import { makeFetchSelectors } from 'app/redux-fetch'

import {
  USERS_REDUCER_NAME,
  USER_LIST_REDUCER_NAME,
  USER_DETAILS_REDUCER_NAME,
  UPDATE_PERMISSIONS_REDUCER_NAME,
  DELETE_USER_REDUCER_NAME,
  INVITE_USER_REDUCER_NAME,
} from './reducer'

export const userListSelectors = makeFetchSelectors(
  USERS_REDUCER_NAME,
  USER_LIST_REDUCER_NAME
)

export const userDetailsSelectors = makeFetchSelectors(
  USERS_REDUCER_NAME,
  USER_DETAILS_REDUCER_NAME
)

export const updatePermissionsSelectors = makeFetchSelectors(
  USERS_REDUCER_NAME,
  UPDATE_PERMISSIONS_REDUCER_NAME
)

export const deleteUserSelectors = makeFetchSelectors(
  USERS_REDUCER_NAME,
  DELETE_USER_REDUCER_NAME
)

export const inviteUserSelectors = makeFetchSelectors(
  USERS_REDUCER_NAME,
  INVITE_USER_REDUCER_NAME
)
