import { loginEvent } from '../consts'

export const getGtag = () => {
  return global.window.gtag
}

export const gaLoginSuccess = (failedAttempts: number) => {
  const gtag = getGtag()
  gtag('event', loginEvent, {
    failed_attempts: failedAttempts,
    success: true,
  })
}

export const gaLoginFail = () => {
  const gtag = getGtag()
  gtag('event', loginEvent, {
    success: false,
  })
}
