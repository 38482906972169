import React, { useState, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import qs from 'qs'

import { commonMessages } from 'app/i18n'
import { CommonButton } from 'components/Button'
import { HelpFormMessages } from './messages'
import {
  HelpButton,
  HelpContainer,
  FormWrapper,
  HelpText,
  ModalWrapper,
  ButtonsFooter,
  FieldContainer,
  Label,
  HomeImage,
} from './styles'
import { H2, SectionTitle } from 'components/Typography'
import { Form, Formik, FormikHelpers } from 'formik'
import Modal from 'components/Modal'
import { fieldsMessages } from 'components/Fields/messages'
import { Prompt } from 'react-router-dom'
import { DropdownField, TextAreaField, TextField } from 'components/Fields'
import { LoaderOverlay } from 'components/Loader'
import theme from 'theme_'
import { ModalTitle } from 'components/Modal/styles'
import axios from 'axios'
import { SALESFORCE_SUPPORT } from 'app/consts'
import { currUserSelector } from 'features/auth/login/selectors'
import { useAppSelector } from 'app/hooks'
import { AuthorizedUser } from 'app/types'
import {
  CATEGORY_OPTIONS,
  CONTACT_OPTIONS,
  COUNTRY_OPTIONS,
  HELP_OPTIONS,
} from './selectOptions'
import { currentHomeSelector } from 'features/homes/selectors'
import SuccessModal from 'components/Modal/SuccessModal'
import { CheckCircleOutline } from 'components/Icons'

const {
  url: salesforceURL,
  recordType,
  oid,
  contactMethod,
  category,
  helpWith,
} = SALESFORCE_SUPPORT

interface HelpFormProps {
  dealerId?: string
  homeId?: string
}

interface GetHelpFormValues {
  [key: string]: string
}

const ifv = {
  [helpWith]: '',
  [category]: '',
  first_name: '',
  last_name: '',
  description: '',
  country: 'United States',
  zip: '',
  [contactMethod]: 'Email',
  phone: '',
  email: '',
  oid,
  recordType: recordType,
  homeId: '',
  dealerId: '',
  lead_source: `Home Manager | ${window.location.href}`,
}

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const HelpForm: React.FC<HelpFormProps> = (props) => {
  const { formatMessage } = useIntl()

  const [modalOpen, setModalOpen] = useState(false)
  const [showPhone, setShowPhone] = useState(false)
  const [initialFormValues, setInitialFormValues] = useState(ifv)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const user: AuthorizedUser = useAppSelector(currUserSelector)
  const currentHome = useSelector(currentHomeSelector)

  useEffect(() => {
    if (user && currentHome) {
      setInitialFormValues({
        ...ifv,
        email: user.email,
        first_name: user.firstName,
        last_name: user.lastName,
        homeId: currentHome.id,
        dealerId: props.dealerId || '',
        zip: currentHome.postalCode,
      })
    }
  }, [user, currentHome, setInitialFormValues, props.dealerId])

  const handleSubmit = async (
    values: GetHelpFormValues,
    formikHelpers: FormikHelpers<GetHelpFormValues>
  ) => {
    try {
      // @ts-ignore
      await axios.post(salesforceURL, qs.stringify(values), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
    } catch (err) {
      console.log('Error')
      console.log(err)
      formikHelpers.resetForm()
    }
    formikHelpers.resetForm({ values })
    setModalOpen(false)
    setIsSubmitted(true)
  }

  return (
    <>
      <HelpContainer>
        <SectionTitle>
          <FormattedMessage {...HelpFormMessages.helpTitle} />
        </SectionTitle>

        <HelpText>
          <FormattedMessage {...HelpFormMessages.helpText} />
        </HelpText>

        <HelpButton
          variant={'goldenOutlined'}
          onClick={() => setModalOpen(true)}
        >
          <FormattedMessage {...HelpFormMessages.helpButton} />
        </HelpButton>
      </HelpContainer>

      <SuccessModal
        isOpen={isSubmitted}
        confirmBtnTitle={formatMessage(HelpFormMessages.done)}
        content={formatMessage(HelpFormMessages.savantInTouch)}
        title={formatMessage(HelpFormMessages.modalSuccess)}
        icon={<CheckCircleOutline />}
        onConfirm={() => setIsSubmitted(false)}
      />

      <Modal isOpen={modalOpen} onRequestClose={() => setModalOpen(false)}>
        <ModalTitle>
          <H2>{<FormattedMessage {...HelpFormMessages.modalTitle} />}</H2>
        </ModalTitle>

        <Formik
          initialValues={initialFormValues}
          enableReinitialize
          onSubmit={handleSubmit}
          validationSchema={Yup.object().shape({
            description: Yup.string().required(
              formatMessage(fieldsMessages.requiredField)
            ),
            zip: Yup.string().required(
              formatMessage(fieldsMessages.requiredField)
            ),
            [helpWith]: Yup.string().required(
              formatMessage(fieldsMessages.requiredField)
            ),
            [category]: Yup.string().required(
              formatMessage(fieldsMessages.requiredField)
            ),
            phone: Yup.string().matches(
              phoneRegExp,
              'Please enter a valid phone number'
            ),
          })}
        >
          {(formikProps) => {
            return (
              <>
                <Prompt
                  when={formikProps.dirty}
                  message={formatMessage(commonMessages.promptUnsavedForm)}
                />
                <Form>
                  {formikProps.isSubmitting && (
                    <LoaderOverlay backgroundColor={theme.overlay.darkImage} />
                  )}
                  <ModalWrapper>
                    <FormWrapper>
                      <HomeImage image={currentHome.imageUrl}>
                        {currentHome.name}
                      </HomeImage>

                      {!showPhone && (
                        <>
                          <FieldContainer>
                            <Label>
                              <FormattedMessage
                                {...HelpFormMessages.helpWith}
                              />
                            </Label>
                            <div>
                              <DropdownField
                                name={helpWith}
                                options={HELP_OPTIONS(formatMessage)}
                              />
                            </div>
                          </FieldContainer>

                          <FieldContainer>
                            <Label>
                              <FormattedMessage
                                {...HelpFormMessages.selectCategory}
                              />
                            </Label>
                            <div>
                              <DropdownField
                                name={category}
                                options={CATEGORY_OPTIONS(formatMessage)}
                              />
                            </div>
                          </FieldContainer>

                          <TextAreaField
                            name="description"
                            placeholder={formatMessage(
                              HelpFormMessages.message
                            )}
                            maxLength={500}
                          />

                          <FieldContainer>
                            <Label>
                              <FormattedMessage {...HelpFormMessages.country} />
                            </Label>
                            <div>
                              <DropdownField
                                name="country"
                                options={COUNTRY_OPTIONS.map((o) => ({
                                  label: o,
                                  value: o,
                                }))}
                              />
                            </div>
                          </FieldContainer>

                          <FieldContainer>
                            <Label>
                              <FormattedMessage {...HelpFormMessages.zipCode} />
                            </Label>
                            <div>
                              <TextField
                                name="zip"
                                placeholder={formatMessage(
                                  HelpFormMessages.zipCode
                                )}
                              />
                            </div>
                          </FieldContainer>

                          <FieldContainer>
                            <Label>
                              <FormattedMessage
                                {...HelpFormMessages.contactMethod}
                              />
                            </Label>
                            <div>
                              <DropdownField
                                name={contactMethod}
                                options={CONTACT_OPTIONS(formatMessage)}
                              />
                            </div>
                          </FieldContainer>
                        </>
                      )}

                      {showPhone && (
                        <FieldContainer>
                          <Label>
                            <FormattedMessage {...HelpFormMessages.phone} />
                          </Label>
                          <div>
                            <TextField
                              name="phone"
                              placeholder="xxx-xxx-xxxx"
                            />
                          </div>
                        </FieldContainer>
                      )}
                    </FormWrapper>
                  </ModalWrapper>

                  <ButtonsFooter>
                    {showPhone && (
                      <CommonButton
                        type="button"
                        variant="goldenOutlined"
                        onClick={() => {
                          formikProps.setFieldValue('phone', '')
                          setShowPhone(false)
                        }}
                      >
                        <FormattedMessage {...HelpFormMessages.back} />
                      </CommonButton>
                    )}
                    {!showPhone && (
                      <CommonButton
                        type="button"
                        variant="goldenOutlined"
                        onClick={(ev) => {
                          ev.preventDefault()
                          ev.stopPropagation()
                          formikProps.resetForm()
                          setModalOpen(false)
                        }}
                      >
                        <FormattedMessage {...commonMessages.cancel} />
                      </CommonButton>
                    )}
                    {formikProps.values[contactMethod] === 'Phone' &&
                      !showPhone &&
                      !formikProps.isSubmitting && (
                        <CommonButton
                          variant="goldenFilled"
                          type="submit"
                          disabled={!formikProps.isValid || !formikProps.dirty}
                          onClick={() => setShowPhone(true)}
                        >
                          <FormattedMessage {...HelpFormMessages.next} />
                        </CommonButton>
                      )}
                    {(formikProps.values[contactMethod] !== 'Phone' ||
                      showPhone) && (
                      <CommonButton
                        type="submit"
                        variant="goldenFilled"
                        disabled={
                          formikProps.isSubmitting ||
                          !formikProps.isValid ||
                          !formikProps.dirty
                        }
                      >
                        <FormattedMessage {...HelpFormMessages.send} />
                      </CommonButton>
                    )}
                  </ButtonsFooter>
                </Form>
              </>
            )
          }}
        </Formik>
      </Modal>
    </>
  )
}

export default HelpForm
