import React from 'react'
import { StyledCommonButton } from './styles'

export type IButtonVariants =
  | 'whiteOutlined'
  | 'darkOutlined'
  | 'goldenFilled'
  | 'goldenOutlined'
  | 'errorFilled'

export type IButtonSizes = 'small' | 'very-small'

export interface ICommonButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: IButtonVariants
  size?: IButtonSizes
}

export default StyledCommonButton
