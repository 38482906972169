import { FC, ReactElement } from 'react'

import { RegularModerate } from 'components/Typography'

import { Container, InnerContainer, Title } from './styles'

interface RoleProps {
  title: ReactElement
  icon: ReactElement
  onSelect: () => void
  isSelected: boolean
}

const Role: FC<RoleProps> = ({ title, icon, onSelect, isSelected }) => (
  <Container>
    <InnerContainer onClick={onSelect} {...{ isSelected }}>
      {icon}
      <Title>
        <RegularModerate>{title}</RegularModerate>
      </Title>
    </InnerContainer>
  </Container>
)

export default Role
