import { FC, useCallback, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useFormikContext } from 'formik'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { mdiHelpCircleOutline } from '@mdi/js'

import PermissionsBlock from 'features/users/UserDetails/components/Permissions/PermissionsBlock'
import Permission from 'features/users/UserDetails/components/Permissions/Permission'
import ConfirmModal from 'components/Modal/ConfirmModal'
import { removeDealerSelectors } from 'features/homes/selectors'
import { MdiIcon } from 'components/Icons'
import { LoaderOverlay } from 'components/Loader'
import { Regular } from 'components/Typography'
import { removeDealerThunk } from 'features/homes/homesSlice'
import { messages } from '../../messages'
import { commonMessages } from 'app/i18n'
import { PermissionsForm } from '../../types'
import { DropdownField } from 'components/Fields'
import { Label } from 'components/Input/Dropdown/styles'
import { CommonButton } from 'components/Button'
import {
  DealerAccessExpiresContainer,
  DealerPermissionParagraph,
  RemoveDealer,
} from './styles'
import { SecondaryWithBg } from 'components/Typography'

export enum AccessExpires {
  Never = 9999999999000,
}

const [oneDay, twoDays, threeDays] = [86400000, 86400000 * 2, 86400000 * 3]

interface Dealer {
  homeId?: string
  id?: string
  requestedDomainId?: string
}

interface DealerPermissionsProps {
  dealer: Dealer
}

const DealerPermissions: FC<DealerPermissionsProps> = ({ dealer }) => {
  const { values } = useFormikContext<PermissionsForm>()
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const [isRemoveConfirmOpen, setIsRemoveConfirmOpen] = useState(false)
  const isRemoveLoading = useSelector(removeDealerSelectors.isFetching)
  const items = useMemo(() => {
    return [
      {
        value: oneDay,
        label: formatMessage(messages.day1),
      },
      {
        value: twoDays,
        label: formatMessage(messages.days2),
      },
      {
        value: threeDays,
        label: formatMessage(messages.days3),
      },
      {
        value: AccessExpires.Never,
        label: formatMessage(messages.never),
      },
    ]
  }, [formatMessage])

  const isNeverExpiringAccess = useMemo(
    () => values.systemAccessExpires > Date.now() + threeDays,
    [values.systemAccessExpires]
  )

  const getDropDownLabel = useCallback(() => {
    if (values.systemAccessExpires) {
      if (
        values.systemAccessExpires === AccessExpires.Never ||
        isNeverExpiringAccess
      ) {
        return formatMessage(messages.never)
      }
      return moment(values.systemAccessExpires).format('DD/MM/YYYY')
    }
    return ''
  }, [formatMessage, isNeverExpiringAccess, values.systemAccessExpires])

  return (
    <>
      <PermissionsBlock
        title={<FormattedMessage {...messages.title} />}
        isNarrow
      >
        <>
          <SecondaryWithBg>
            <FormattedMessage {...messages.permissionsTitle} />
          </SecondaryWithBg>

          <Permission
            title={<FormattedMessage {...messages.monitoring} />}
            name="monitoring"
            type="toggle"
          />
          <DealerPermissionParagraph>
            <FormattedMessage {...messages.systemAccessInfo} />
          </DealerPermissionParagraph>
          <Permission
            title={<FormattedMessage {...messages.systemAccess} />}
            name="systemAccess"
            type="toggle"
          />
          <DealerPermissionParagraph>
            <FormattedMessage {...messages.systemAccessExpireInfo} />
          </DealerPermissionParagraph>
          {values.systemAccess && (
            <DealerAccessExpiresContainer>
              <Label>
                <FormattedMessage {...messages.systemAccessExpire} />
              </Label>
              <DropdownField
                name="systemAccessExpires"
                options={items}
                placeholder={
                  getDropDownLabel() || formatMessage(messages.never)
                }
              />
            </DealerAccessExpiresContainer>
          )}
          <RemoveDealer>
            <CommonButton
              variant="goldenOutlined"
              onClick={(e) => {
                e.preventDefault()
                setIsRemoveConfirmOpen(true)
              }}
            >
              <FormattedMessage {...messages.removeButton} />
            </CommonButton>
          </RemoveDealer>
        </>
      </PermissionsBlock>

      {isRemoveLoading && <LoaderOverlay isFullScreen />}

      <ConfirmModal
        isOpen={isRemoveConfirmOpen}
        title={<FormattedMessage {...messages.removeDealerTitle} />}
        icon={<MdiIcon path={mdiHelpCircleOutline} size="64px" />}
        discardBtnTitle={<FormattedMessage {...commonMessages.cancel} />}
        confirmBtnTitle={
          <FormattedMessage {...messages.removeDealerConfirmBtn} />
        }
        onDiscard={() => setIsRemoveConfirmOpen(false)}
        onConfirm={async () => {
          setIsRemoveConfirmOpen(false)
          await dispatch(
            removeDealerThunk({
              homeId: dealer.homeId || '',
              requestId: dealer.id || '',
              domainId: dealer.requestedDomainId || '',
            })
          )
        }}
        isRedConfirmBtn
      >
        <Regular>
          <FormattedMessage
            {...messages.removeDealerMsg}
            values={{
              br: (
                <>
                  <br />
                  <br />
                </>
              ),
            }}
          />
        </Regular>
      </ConfirmModal>
    </>
  )
}

export default DealerPermissions
