import { FC } from 'react'
import Icon from '@mdi/react'
import { mdiSortVariant, mdiSortReverseVariant } from '@mdi/js'

import { useAppDispatch, useAppSelector } from 'app/hooks'
import { clipsFiltersActions } from '../../../slice'
import { OldestFirstFilterButton } from './styles'
import { cameraOldestFirstSelector } from '../../../selectors'

const OldestFirstFilter: FC = () => {
  const dispatch = useAppDispatch()
  const oldestFirst = useAppSelector(cameraOldestFirstSelector)
  const handleChange = () =>
    dispatch(clipsFiltersActions.setOldestFirstFilter(!oldestFirst))

  return (
    <OldestFirstFilterButton onClick={handleChange}>
      {oldestFirst ? (
        <Icon path={mdiSortVariant} />
      ) : (
        <Icon path={mdiSortReverseVariant} />
      )}
    </OldestFirstFilterButton>
  )
}

export default OldestFirstFilter
