import styled from 'styled-components'
import Icon from '@mdi/react'

import theme from 'theme_'
import { BluredBlock } from 'components/Layout'
import {
  BigTitle,
  RegularModerate,
  Secondary,
  SectionTitle,
} from 'components/Typography'
import { ButtonStyles } from 'components/Button/styles'

const { colors, spacing, devices, overlay } = theme

export const HomeInfoContainer = styled(BluredBlock)`
  position: relative;
  z-index: 1;
  margin: ${spacing.base} 0 ${spacing.s};
  padding: ${spacing.md} ${spacing.base} ${spacing.xxl};
  background: linear-gradient(
    131.13deg,
    rgba(124, 116, 105, 0.5) 2.94%,
    rgba(60, 56, 51, 0.5) 70.05%
  );

  @media ${devices.tablet} {
    padding: ${spacing.md} ${spacing.md} ${spacing.xxl};
    margin-top: ${spacing.base};
  }

  @media ${devices.desktop} {
    margin: ${spacing.md} 0 ${spacing.base};
  }
`

export const HomeInfoTitle = styled(SectionTitle)`
  margin-bottom: ${spacing.md};

  @media ${devices.tablet} {
    margin-bottom: ${spacing.xs};
  }
`

export const HomeInfoStatusWrapper = styled(Secondary)<{ status: string }>`
  position: absolute;
  top: 16px;
  right: 20px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  height: 40px;
  padding: 0 ${spacing.sm};
  border-radius: ${spacing.xs};
  background: rgba(0, 0, 0, 0.3);
  text-transform: capitalize;

  @media ${devices.tablet} {
    position: static;
  }
`

export const HomeInfoStatusCircle = styled.div<{ status: string }>`
  border-radius: 50%;
  height: ${spacing.s};
  width: ${spacing.s};
  margin-right: ${spacing.s};

  background-color: ${(p) => (p.status ? colors.success : colors.beachGrey)};
`

export const HomeInfoHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const HomeInfoName = styled(BigTitle)`
  font-size: 30px;
  line-height: 32px;
`

export const HomeInfoToggler = styled(ButtonStyles)`
  position: absolute;
  z-index: 2;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${spacing.xxl};
  border-radius: 0 0 ${spacing.xs} ${spacing.xs};

  @media (hover: hover) {
    &:hover {
      background: ${overlay.slightLight};
    }
  }
`

export const HomeInfoTogglerIcon = styled(Icon)`
  color: ${colors.dimmedGold};
`

export const HomeInfoDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${spacing.lg};
`
export const HomeInfoDetail = styled.div`
  flex-basis: 100%;
  margin-bottom: ${spacing.lg};

  @media ${devices.tablet} {
    flex-basis: 50%;
    padding-right: ${spacing.md};
  }
`

export const HomeInfoDetailTitle = styled(RegularModerate)`
  margin-bottom: ${spacing.xxs};
  text-transform: capitalize;
`

export const HomeInfoDetailContent = styled(RegularModerate)`
  font-weight: 700;
`

export const HomeInfoTitleIcon = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-right: ${theme.spacing.xs};
`
