import { defineMessages } from 'react-intl'

export const twoFactorAuthMessages = defineMessages({
  title: {
    id: 'twoFactor.title',
    description: 'Verification code',
    defaultMessage: 'Verification code',
  },
  description: {
    id: 'twoFactor.description',
    description: 'Description text of 2fa page',
    defaultMessage:
      'To help keep your account secure, we’ve sent a verification code to your Email <b>{email}</b>.',
  },
  enterCode: {
    id: 'twoFactor.enterCode',
    description: 'Text pointing that user should enter verification code',
    defaultMessage:
      'Please enter the 6 digit code below. Your code expires in 30 minutes.',
  },
  resendDescription: {
    id: 'twoFactor.resendDescription',
    description: 'Description text for resend link',
    defaultMessage: 'Can’t find our Email? ',
  },
  resendCode: {
    id: 'twoFactor.resendCode',
    description: 'Text of resend 2fa verification Code',
    defaultMessage: 'Resend Verification',
  },
  twoFactor: {
    id: 'twoFactor.twoFactor',
    description: 'Placeholder text of verification Code',
    defaultMessage: 'Verification Code',
  },
  goToLogin: {
    id: 'twoFactor.goToLogin',
    description: 'Button text to cancel verification and back to Log In page',
    defaultMessage: 'Cancel',
  },
  verifyCode: {
    id: 'twoFactor.verifyCode',
    description: 'Text of verification code button',
    defaultMessage: 'Verify',
  },
  codeLengthError: {
    id: 'twoFactor.codeLengthError',
    description: 'Error text of verification code input length',
    defaultMessage: 'Must be 6 characters long',
  },
})
