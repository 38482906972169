import { fetchDataThunk, makeFetchSlice } from 'app/redux-fetch'
import { clearUserCreds, storeAuthorizedUser } from 'app/services/LocalStorage'
import { AppDispatch } from 'app/store'
import { updateAuthStatus, userActions } from '../../login/slice'
import { ILoginFormValues } from '../../login/types'
import { login } from './api'
import history from 'app/history'
import { gaLoginFail } from 'features/analytics/utils'

const twoFaLoginSlice = makeFetchSlice('login2fa')

export const twoFaLoginSliceReducer = twoFaLoginSlice.reducer
export const twoFaLoginSliceActions = twoFaLoginSlice.actions

export const login2FaThunk = (
  params: ILoginFormValues,
  onSuccess: () => void
) => {
  return async (dispatch: AppDispatch) => {
    try {
      const { payload, error } = await fetchDataThunk({
        dispatch,
        actions: twoFaLoginSliceActions,
        asyncFunc: () => login(params),
      })

      if (error) {
        gaLoginFail()
        await dispatch(userActions.incrementLoginAttempts())
        dispatch(twoFaLoginSliceActions.failure(error))
      } else {
        clearUserCreds()
        onSuccess()
        dispatch(userActions.resetLoginAttempts())
        storeAuthorizedUser(payload)
        history.push('/')
      }

      await dispatch(updateAuthStatus())

      return payload
    } catch (err) {
      console.log('Error logging in the user', err)
      gaLoginFail()
      await dispatch(userActions.incrementLoginAttempts())
      dispatch(updateAuthStatus())
    }
  }
}
