import React, { useEffect } from 'react'
import { Route, Router, Switch } from 'react-router-dom'

import routes from 'app/routes'
import {
  isAuthenticatedSelector,
  hasUserStateLoadedSelector,
} from 'features/auth/login/selectors'
import { getActiveUserInfoThunk } from 'features/auth/account/slice'
import { updateAuthStatus } from 'features/auth/login/slice'
import history from 'app/history'
import { useAppSelector, useAppDispatch } from 'app/hooks'

import AuthorizedRoutes from './AuthorizedRoutes'
import UnauthorizedRoutes from './UnauthorizedRoutes'
import LoaderOverlay from 'components/Loader/LoaderOverlay'
import HomesCommonLayout from 'features/homes/components/HomesCommonLayout/HomesCommonLayout'
import InitSuccessPage from 'features/auth/components/ForgotPassword/InitSuccess'
import ResetSuccessPage from 'features/auth/components/ForgotPassword/ResetSuccess'
import ForgotPassword from 'features/auth/components/ForgotPassword'
import ResetPassword from 'features/auth/components/ForgotPassword/ConfirmReset'
import ResetErrorPagePage from 'features/auth/components/ForgotPassword/ResetError'

declare global {
  interface Window {
    gtag: any
    process: any
  }
}

const App: React.FC = () => {
  const dispatch = useAppDispatch()
  const isAuthenticated = useAppSelector(isAuthenticatedSelector)
  const hasUserStateLoaded = useAppSelector(hasUserStateLoadedSelector)

  useEffect(
    () => {
      dispatch(updateAuthStatus())
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(
    () => {
      if (isAuthenticated) {
        dispatch(getActiveUserInfoThunk())
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAuthenticated]
  )

  useEffect(() => {
    // https://stackoverflow.com/a/71331099
    window.process = {
      ...window.process,
    }
  }, [])

  if (!hasUserStateLoaded) {
    return <LoaderOverlay />
  }

  return (
    <Router history={history}>
      <Switch>
        <Route
          path={routes.auth.forgotPassword}
          exact
          component={ForgotPassword}
        />
        <Route
          path={routes.auth.forgotPasswordResetConfirm}
          exact
          component={ResetPassword}
        />
        <Route
          path={routes.auth.forgotPasswordInitSuccess}
          exact
          component={InitSuccessPage}
        />
        <Route
          path={routes.auth.forgotPasswordResetSuccess}
          exact
          component={ResetSuccessPage}
        />
        <Route
          path={routes.auth.forgotPasswordResetError}
          exact
          component={ResetErrorPagePage}
        />
        {isAuthenticated ? (
          <HomesCommonLayout>
            <AuthorizedRoutes />
          </HomesCommonLayout>
        ) : (
          <UnauthorizedRoutes />
        )}
      </Switch>
    </Router>
  )
}

export default App
