import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import ResetPasswordForm from './ResetPasswordForm'
import { passResetConfirmConfirmSelectors } from '../selectors'
import { passResetConfirmThunk } from '../slice'
import { resetPasswordMessages } from './messages'
import routes from 'app/routes'
import LoginCommonLayout from '../../LoginCommonLayout'
import { StyledMainHeading } from './../../MainHeading/MainHeading'

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

const ResetPasswordPage: React.FC = () => {
  const dispatch = useDispatch()
  const queryParams = useQuery()
  const history = useHistory()
  const { formatMessage } = useIntl()

  const passwordResetToken = queryParams.get('t') || ''
  const userId = queryParams.get('u') || ''

  const isPassConfirming = useSelector(
    passResetConfirmConfirmSelectors.isFetching
  )

  const handleSubmit = (newPassword: string) =>
    dispatch(
      passResetConfirmThunk({
        userId,
        newPassword,
        passwordResetToken,
        onSuccess: () => {
          history.push(routes.auth.forgotPasswordResetSuccess)
        },
        onError: (err) => {
          history.push(routes.auth.forgotPasswordResetError)
        },
      })
    )

  return (
    <LoginCommonLayout>
      <StyledMainHeading>
        {formatMessage(resetPasswordMessages.title)}
      </StyledMainHeading>
      <ResetPasswordForm
        isLoading={isPassConfirming}
        onSubmit={({ newPassword }) => handleSubmit(newPassword)}
      />
    </LoginCommonLayout>
  )
}

export default ResetPasswordPage
