import styled from 'styled-components'
import { Form } from 'formik'

import { RegularModerate } from 'components/Typography'
import theme from 'theme_'

export const LoginContainer = styled.div`
  position: relative;
  display: block;
  min-height: 100vh;
  padding-top: 0;

  @media ${theme.devices.tablet} {
    padding-top: 110px;
  }

  @media ${theme.devices.desktop} {
    display: flex;
  }
`

export const StyledAuthForm = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 347px;
  margin: 0 auto;

  @media ${theme.devices.tablet} {
    max-width: 471px;
    padding: 0;
  }
`

export const StyledDescriptionText = styled(RegularModerate)`
  margin: 0 0 ${theme.spacing.sm};
  text-align: center;
`

export const StyledHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 347px;
  margin: 0 auto;

  @media ${theme.devices.tablet} {
    max-width: 471px;
    padding: 0;
  }
`
