import styled from 'styled-components'
import theme from 'theme_'
import Icon from '@mdi/react'
import { FilterContainer } from '../styles'
import { ButtonStyles } from 'components/Button/styles'

export const DateRangePickerInput = styled(FilterContainer)``

export const ClearButton = styled(ButtonStyles)``

export const DateFilterContainer = styled.div`
  position: relative;
  margin-bottom: ${theme.spacing.md};
  margin-top: ${theme.spacing.md};

  @media ${theme.devices.tablet} {
    margin-bottom: 0;
    margin-top: 0;
    width: 225px;
    margin-right: ${theme.spacing.s};
  }
`

export const FilterChevronIcon = styled(Icon)`
  color: ${theme.colors.beachGrey};
  position: absolute;
  z-index: 2;
  width: 22px;
  top: 5px;
  right: 4px;
`

export const FilterCloseIcon = styled(Icon)`
  color: rgba(210, 204, 193, 0.5);
  position: absolute;
  z-index: 2;
  width: ${theme.spacing.base};
  top: ${theme.spacing.xs};
  right: 24px;
  :hover {
    color: ${theme.colors.beachGrey};
  }
`
