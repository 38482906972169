import React, { useCallback, useState } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { mdiChevronRight } from '@mdi/js'
import { makePublicUrl } from 'app/utils/publicUrl'
import HomeSelectorMenu from './HomeSelectorMenu'
import HomeSearch from './HomeSelectorMenu/HomeSearch'
import HomeSelectorLinkImg from './LinkImg'
import {
  StyledHomeSelectorWrapper,
  StyledHomeSelector,
  StyledHomeSelectorArrow,
} from './styles'
import { useSelector } from 'react-redux'
import {
  currentHomeSelector,
  adminHomesListSelector,
} from 'features/homes/selectors'

interface IHomeSelectorProps {
  mobileClickHandler?: () => void
}

const HomeSelector: React.FC<IHomeSelectorProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleIsOpen = useCallback(() => setIsOpen(!isOpen), [isOpen])

  const handleClose = useCallback(() => setIsOpen(false), [])

  const currentHome = useSelector(currentHomeSelector)
  const adminHomesList = useSelector(adminHomesListSelector)

  return currentHome ? (
    <StyledHomeSelectorWrapper>
      <OutsideClickHandler
        onOutsideClick={handleClose}
        disabled={adminHomesList.length >= 8}
      >
        <StyledHomeSelector
          onClick={props.mobileClickHandler || toggleIsOpen}
          isOpen={isOpen}
        >
          <HomeSelectorLinkImg
            src={
              currentHome.imageUrl
                ? currentHome.imageUrl
                : makePublicUrl('images/small-default-home-image.svg')
            }
          />
          {currentHome.name}
          <StyledHomeSelectorArrow path={mdiChevronRight} size={1} />
        </StyledHomeSelector>
        {adminHomesList.length < 8 ? (
          <HomeSelectorMenu isOpen={isOpen} closeMenuHandler={handleClose} />
        ) : (
          <HomeSearch isOpen={isOpen} closeMenuHandler={handleClose} />
        )}
      </OutsideClickHandler>
    </StyledHomeSelectorWrapper>
  ) : null
}

export default HomeSelector
