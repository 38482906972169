import { ESSENTIALS_ICON } from 'app/consts'
import { useIntl } from 'react-intl'
import { subscriptionTableMessages } from '../messages'
import {
  HeaderColumnEnrolledText,
  HeaderColumnEssentials,
  HeaderColumnEssentialsIcon,
  HeaderColumnFeature,
  HeaderColumnIncluded,
  SubscriptionTableHeaderContainer,
} from './styles'

interface SubscriptionTableHeaderProps {
  isBasicHomePlan: boolean
}

function SubscriptionTableHeader({
  isBasicHomePlan,
}: SubscriptionTableHeaderProps) {
  const { formatMessage } = useIntl()

  return (
    <SubscriptionTableHeaderContainer>
      <HeaderColumnFeature>
        {formatMessage(subscriptionTableMessages.headerTitleFeatures)}
      </HeaderColumnFeature>
      <HeaderColumnIncluded isColored={isBasicHomePlan}>
        {formatMessage(subscriptionTableMessages.headerTitleIncluded)}
      </HeaderColumnIncluded>
      <HeaderColumnEssentials isColored={!isBasicHomePlan}>
        <HeaderColumnEssentialsIcon src={ESSENTIALS_ICON} />
        {formatMessage(subscriptionTableMessages.headerTitleEssentials)}
        {!isBasicHomePlan && (
          <HeaderColumnEnrolledText>
            {formatMessage(subscriptionTableMessages.headerTitleEnrolled)}
          </HeaderColumnEnrolledText>
        )}
      </HeaderColumnEssentials>
    </SubscriptionTableHeaderContainer>
  )
}

export default SubscriptionTableHeader
