import { defineMessages } from 'react-intl'

export const myAccountMessages = defineMessages({
  myAccount: {
    id: 'myAccount.myAccount',
    description: 'My Account page title',
    defaultMessage: 'My Account',
  },
  profileInfo: {
    id: 'myAccount.profileInfo',
    description: 'Header of one account section. "Profile info" ',
    defaultMessage: 'Profile Info',
  },
  commManagement: {
    id: 'myAccount.commManagement',
    description: 'Header of one account section. "Communication Management" ',
    defaultMessage: 'Communication Management',
  },
  password: {
    id: 'myAccount.password',
    description: 'Header of one account section. "Password" ',
    defaultMessage: 'Password',
  },
  receiveEmails: {
    id: 'myAccount.receiveEmails',
    description:
      'Label info about user giving permission for receiving emails ',
    defaultMessage: 'Receive news and announcements from Savant',
  },
  changePassword: {
    id: 'myAccount.changePassword',
    description: 'Change Password page title',
    defaultMessage: 'Change password',
  },
  changePassInstructions: {
    id: 'myAccount.changePassInstructions',
    description: 'Text about next steps to change the password',
    defaultMessage:
      'To change your account’s password select the button below. We will send you an email with further instructions on how to reset your Savant Account password. Resetting your password will sign you out of all Savant applications.',
  },
  changePassModalHeading: {
    id: 'myAccount.changePassModalHeading',
    description: 'Modal header when email to change/reset password sent',
    defaultMessage: 'Email sent',
  },
  changePassModalText: {
    id: 'myAccount.changePassModalText',
    description: 'Modal text when email to change/reset password sent',
    defaultMessage:
      'Check your inbox for an email from Savant with further instructions to reset your password.',
  },
  changePassModalClose: {
    id: 'myAccount.changePassModalClose',
    description:
      'Button text to close modal when email to change/reset password sent',
    defaultMessage: 'Close',
  },
  manageService: {
    id: 'myAccount.manageService',
    description:
      'Text describing what user should do to be able to change "service-level" permissions/change photo',
    defaultMessage: 'Use the Savant Pro App to set service level permissions',
  },
  twoFactorHeading: {
    id: 'myAccount.twoFactorHeading',
    description: 'Header for two factor authentiaction enabling',
    defaultMessage: 'Two-factor Authentication',
  },
  twoFactorText: {
    id: 'myAccount.twoFactorText',
    description: 'Text for two factor authentiaction enabling',
    defaultMessage:
      "Increase your account's security by requiring an email verification code to login.",
  },
  twoFactorToggle: {
    id: 'myAccount.twoFactorToggle',
    description: 'Label for two factor authentiaction enabling',
    defaultMessage: 'Two-factor Authentication Enabled',
  },
  twoFactorError: {
    id: 'myAccount.twoFactorError',
    description: 'Error message for two factor authentiaction enabling',
    defaultMessage:
      'There was an error verifying your Two-factor Authentication. Please make sure your code is valid.',
  },
  enabled: {
    id: 'myAccount.enabled',
    description: 'Message for two factor enabled toggle',
    defaultMessage: 'Enabled',
  },
  disabled: {
    id: 'myAccount.disabled',
    description: 'Message for two factor disabled toggle',
    defaultMessage: 'Disabled',
  },
})
