import { FC, useCallback, useContext, useState } from 'react'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { registerLocale } from 'react-datepicker'
import { enUS, frCA, ru, es, sv, ptBR, zhCN } from 'date-fns/locale'

import { DateRangePickerWrapper } from './styles'
import { LocalizationContext } from 'components/i18n/LocalizationWrapper/LocalizationWrapper'
import { LANG } from 'components/LanguageSelector'

const LANG_OPTIONS_MAPPING = [
  { appId: LANG.EN, pickerId: enUS },
  { appId: LANG.FR, pickerId: frCA },
  { appId: LANG.RU, pickerId: ru },
  { appId: LANG.ES, pickerId: es },
  { appId: LANG.SV, pickerId: sv },
  { appId: LANG.PT, pickerId: ptBR },
  { appId: LANG.CN, pickerId: zhCN },
]

LANG_OPTIONS_MAPPING.forEach((lang) =>
  registerLocale(lang.appId, lang.pickerId)
)

type DateRange = [Date | null, Date | null]

interface DateTimeRangePickerProps {
  handleStartDateChange: (date: string | null) => void
  handleEndDateChange: (date: string | null) => void
  dateRange: DateRange
  onOutsideClick: () => void
}

const DateRangePicker: FC<DateTimeRangePickerProps> = ({
  dateRange,
  handleStartDateChange,
  handleEndDateChange,
  onOutsideClick,
}) => {
  const localizationContext = useContext(LocalizationContext)
  const { locale } = localizationContext

  const [range, setRange] = useState(dateRange)
  const [startDate, endDate] = range

  const onChange = useCallback(
    (dateRange: DateRange) => {
      setRange(dateRange)
      const [startDate, endDate] = dateRange
      if ((startDate && endDate) || (startDate === null && endDate === null)) {
        const serializedStartDate = startDate && moment(startDate).format()
        const serializedEndDate = endDate && moment(endDate).format()
        handleStartDateChange(serializedStartDate)
        handleEndDateChange(serializedEndDate)
      }
    },
    [handleEndDateChange, handleStartDateChange]
  )

  return (
    <DateRangePickerWrapper>
      <DatePicker
        inline
        showPopperArrow={false}
        maxDate={new Date()}
        minDate={new Date('2000/01/01')}
        selectsRange={true}
        startDate={startDate && moment(startDate).toDate()}
        endDate={endDate && moment(endDate).toDate()}
        onChange={onChange}
        dateFormat="MM/d/yy"
        locale={locale}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        onClickOutside={onOutsideClick}
      />
    </DateRangePickerWrapper>
  )
}

export default DateRangePicker
