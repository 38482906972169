import { defineMessages } from 'react-intl'

export const FeedbackFormMessages = defineMessages({
  feedbackTitle: {
    id: 'getHelp.feedbackTitle',
    description: 'Get Help page feedback block title',
    defaultMessage: 'Submit Feedback to Savant',
  },
  feedbackText: {
    id: 'getHelp.feedbackText',
    description: 'Get Help page feedback block text',
    defaultMessage: 'Share your Savant experience with our team.',
  },
  feedbackButtonTitle: {
    id: 'getHelp.feedbackButton',
    description: 'Get Help page feedback block button title',
    defaultMessage: 'Submit Feedback',
  },
})
