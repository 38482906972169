import {
  DEALER_ACCESS_REDUCER,
  DEALER_UPDATE_ACCESS_REDUCER,
  DEALER_REDUCER_NAME,
} from './reducer'
import { makeFetchSelectors } from 'app/redux-fetch'

export const dealerAccessSelectors = makeFetchSelectors(
  DEALER_REDUCER_NAME,
  DEALER_ACCESS_REDUCER
)

export const dealerUpdateAccessSelectors = makeFetchSelectors(
  DEALER_REDUCER_NAME,
  DEALER_UPDATE_ACCESS_REDUCER
)
