import styled from 'styled-components'
import ReactModal from 'react-modal'
import theme from 'theme_'

export const StyledModalContent = styled(ReactModal)`
  display: flex;
  height: 100%;
  padding: ${theme.spacing.sm} ${theme.spacing.xs};
  overflow-y: auto;

  @media ${theme.devices.tablet} {
    padding: ${theme.spacing.xxl};
  }
`

export const StyledModalInner = styled.div`
  margin: auto;
  max-width: calc(100% - ${theme.spacing.base});
  width: 880px;
  padding: ${theme.spacing.xxl} ${theme.spacing.xs};
  border: none;
  border-radius: ${theme.spacing.xs};
  background-color: ${theme.colors.popupBrownGrey};

  @media ${theme.devices.tablet} {
    padding: ${theme.spacing.xxl} ${theme.spacing.sm};
  }

  @media ${theme.devices.desktop} {
    padding: ${theme.spacing.xxl} ${theme.spacing.xxl};
  }
`

export const ModalTitle = styled.div`
  margin: ${theme.spacing.xl} 0;
  text-align: center;
`

export const ModalIconWrapper = styled.div`
  text-align: center;
`

export const ModalText = styled.div`
  color: ${theme.colors.beachGrey};
  text-align: center;
  margin-bottom: ${theme.spacing.lg};
`

export const ModalBtnsWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${theme.spacing.xl};
  justify-content: center;
  align-items: center;

  > button:nth-child(2) {
    margin-top: ${theme.spacing.base};
    margin-left: 0;
  }

  @media ${theme.devices.desktop} {
    flex-direction: row;

    > button:nth-child(2) {
      margin-top: 0;
      margin-left: ${theme.spacing.base};
    }
  }
`
