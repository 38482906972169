import React, { FC } from 'react'
import { PartnerShape } from 'app/types/partner'
import { ConnectedAppsContainer } from './styles'
import ConnectedApp from './ConnectedApp'

interface ConnectedAppsListProps {
  connectedApps: PartnerShape[]
}

const ConnectedAppsList: FC<ConnectedAppsListProps> = ({ connectedApps }) => {
  return (
    <>
      <ConnectedAppsContainer>
        {connectedApps.map((app) => (
          <ConnectedApp key={app.name} connectedApp={app} />
        ))}
      </ConnectedAppsContainer>
    </>
  )
}

export default ConnectedAppsList
