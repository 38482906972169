import React from 'react'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { mdiCheckCircleOutline } from '@mdi/js'

import { resetSuccessPageMessages } from './messages'
import {
  BackToLoginWrapper,
  StyledDescriptionText,
  SuccessIcon,
} from './styles'
import CommonButton from 'components/Button/CommonButton'
import LoginCommonLayout from '../../LoginCommonLayout'
import { StyledAuthForm } from 'features/auth/login/styles'
import { StyledMainHeading } from '../../MainHeading/MainHeading'

const ResetSuccessPage: React.FC = () => {
  const { formatMessage } = useIntl()
  const history = useHistory()
  return (
    <LoginCommonLayout>
      <StyledAuthForm as="div">
        <SuccessIcon path={mdiCheckCircleOutline} size={3} />
        <StyledMainHeading>
          {formatMessage(resetSuccessPageMessages.title)}
        </StyledMainHeading>
        <StyledDescriptionText>
          {formatMessage(resetSuccessPageMessages.description)}
        </StyledDescriptionText>
        <BackToLoginWrapper>
          <CommonButton
            type="button"
            variant="goldenOutlined"
            onClick={() => history.push('/')}
          >
            {formatMessage(resetSuccessPageMessages.goToLogin)}
          </CommonButton>
        </BackToLoginWrapper>
      </StyledAuthForm>
    </LoginCommonLayout>
  )
}

export default ResetSuccessPage
