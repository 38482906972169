import styled from 'styled-components'
import theme from 'theme_'

export const CommonStyledFooter = styled.footer`
  display: flex;
  padding-right: ${theme.spacing.lg};
  padding-left: ${theme.spacing.lg};
  color: ${theme.colors.beachGrey};
  background-color: ${theme.overlay.newDropdownBlack};
`

export const FooterWrapper = styled(CommonStyledFooter)`
  flex-direction: column;
  justify-content: center;
  height: ${theme.sizes.footerHeight};

  @media ${theme.devices.tablet} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`

export const FooterLinksContainer = styled.div`
  display: flex;
  margin-top: ${theme.spacing.s};

  & > a:not(:first-child) {
    margin-left: ${theme.spacing.lg};
  }

  @media ${theme.devices.tablet} {
    justify-content: flex-end;
    text-align: right;
    margin-top: 0;
  }
`

export const StyledFooterLink = styled.a`
  color: ${theme.colors.dimmedGold};
`
