import { defineMessages } from 'react-intl'

export const commonBackendErrorsMessages = defineMessages({
  e_00001: {
    id: 'common.ServerError',
    description: 'Server error with 500 status',
    defaultMessage: 'Server error',
  },
  e_00002: {
    id: 'common.badRequest',
    description: 'Bad request with 400 status',
    defaultMessage: 'Bad request',
  },
  e_00003: {
    id: 'common.invalidRequest',
    description: 'Indicate invalid request to server',
    defaultMessage: 'Invalid request',
  },
})

export const commonMessages = defineMessages({
  cancel: {
    id: 'labels.cancel',
    description: 'Cancel button title',
    defaultMessage: 'Cancel',
  },
  save: {
    id: 'labels.save',
    description: 'Save button title',
    defaultMessage: 'Save',
  },
  done: {
    id: 'labels.done',
    description: 'Done button title',
    defaultMessage: 'Done',
  },
  close: {
    id: 'labels.close',
    description: 'Close button title',
    defaultMessage: 'Close',
  },
  yearlyPrice: {
    id: 'labels.yearlyPrice',
    description: 'Yearly price label',
    defaultMessage: '<b>{price}</b>/year',
  },
  monthlyPrice: {
    id: 'labels.monthlyPrice',
    description: 'Monthly price label',
    defaultMessage: '<b>{price}</b>/month',
  },
  expires: {
    id: 'labels.expires',
    description:
      'Indicating that something (eg subscription) expires (by following date)',
    defaultMessage: 'Expires',
  },
  starts: {
    id: 'labels.starts',
    description:
      'Indicating that something (eg subscription) starts (by following date)',
    defaultMessage: 'Starts at',
  },
  you: {
    id: 'labels.you',
    description: 'You label',
    defaultMessage: 'You',
  },
  genericError: {
    id: 'labels.genericError',
    description: 'Generic error message',
    defaultMessage: 'Something went wrong',
  },
  plan: {
    id: 'labels.plan',
    description: 'Message for "plan" word usually as addition',
    defaultMessage: 'plan',
  },
  promptUnsavedForm: {
    id: 'labels.promptUnsavedForm',
    description:
      'Message in a dialog when user tries to leave the page that has unsaved form changes',
    defaultMessage: 'You have unsaved changes. Are you sure?',
  },
  copy: {
    id: 'labels.copy',
    description: 'Message for copy (text) action',
    defaultMessage: 'Copy',
  },
  savantEssentials: {
    id: 'labels.savantEssentials',
    description: 'Common name for "Savant Essentials" subscription plan',
    defaultMessage: 'Savant Essentials',
  },
  essentials: {
    id: 'labels.essentials',
    description: 'Common name for "Essentials" subscription plan',
    defaultMessage: 'Essentials',
  },
  learnMore: {
    id: 'labels.learnMore',
    description: 'Common name for "Learn More" title',
    defaultMessage: 'Learn More',
  },
})
