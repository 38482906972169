import React from 'react'
import { useIntl } from 'react-intl'
import { loginMessages } from '../../login/messages'
import {
  StyledFindDealer,
  StyledNeedAccount,
  StyledStoreIcon,
  StyledStoreIconImg,
  StyledStoreIconsWrapper,
  StyledSystemInstalled,
  StyledLoginInfoContainer,
} from './styles'
import { makePublicUrl } from 'app/utils/publicUrl'

const LoginInfoBlock: React.FC = () => {
  const { formatMessage } = useIntl()

  return (
    <StyledLoginInfoContainer>
      <StyledNeedAccount>
        {formatMessage(loginMessages.getAccount)}
      </StyledNeedAccount>
      <StyledStoreIconsWrapper>
        <StyledStoreIcon
          href="https://play.google.com/store/apps/details?id=com.savantsystems.controlapp.pro"
          target="_blank"
        >
          <StyledStoreIconImg
            src={makePublicUrl('images/Google-Play.svg')}
            alt="Google Play icon"
          />
        </StyledStoreIcon>
        <StyledStoreIcon
          href="https://apps.apple.com/app/savant-pro/id1095325838"
          target="_blank"
        >
          <StyledStoreIconImg
            src={makePublicUrl('images/App-Store.svg')}
            alt="App Store icon"
          />
        </StyledStoreIcon>
      </StyledStoreIconsWrapper>
      <StyledSystemInstalled>
        <>
          {formatMessage(loginMessages.needSavantSystem)}
          <StyledFindDealer
            as="a"
            href="https://www.savant.com/find-a-dealer"
            target="_blank"
          >
            {formatMessage(loginMessages.findDealer)}
          </StyledFindDealer>
        </>
      </StyledSystemInstalled>
    </StyledLoginInfoContainer>
  )
}

export default LoginInfoBlock
