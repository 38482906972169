import React, { memo, useState } from 'react'
import { useIntl } from 'react-intl'
import { mdiEye, mdiEyeOff } from '@mdi/js'
import { ErrorMessage, Field } from 'formik'

import { fieldsMessages } from '../messages'
import { IPasswordFieldProps } from '../types'
import { passwordFieldMessages } from './messages'
import {
  RevealPasswordButton,
  RevealPasswordIcon,
  StyledPasswordWrapper,
} from './styles'
import { CommonStyledInput, StyledInputError } from 'components/Input/styles'

const PasswordField: React.FC<IPasswordFieldProps> = ({
  label,
  wrapperClassName,
  ...props
}) => {
  const { formatMessage } = useIntl()

  const [passwordRevealed, setPasswordRevealed] = useState(false)

  // TODO Think about validation mechanism
  const validatePassword = (value: string) => {
    let error
    if (!value) {
      error = formatMessage(fieldsMessages.requiredField)
    }
    return error
  }

  return (
    <StyledPasswordWrapper className={wrapperClassName}>
      {label && <label htmlFor={props.name}>{label}</label>}
      <CommonStyledInput
        as={Field}
        type={passwordRevealed ? 'text' : 'password'}
        id={props.name}
        placeholder={
          props.placeholder ??
          `${formatMessage(passwordFieldMessages.placeholder)}`
        }
        validate={validatePassword}
        {...props}
      />
      <StyledInputError>
        <ErrorMessage name={props.name} />
      </StyledInputError>
      <RevealPasswordButton
        type="button"
        onClick={() => setPasswordRevealed(!passwordRevealed)}
        title="Reveal"
      >
        <RevealPasswordIcon
          path={passwordRevealed ? mdiEye : mdiEyeOff}
          size={1}
          horizontal
          vertical
        />
      </RevealPasswordButton>
    </StyledPasswordWrapper>
  )
}

export default memo(PasswordField)
