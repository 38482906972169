import { defineMessages } from 'react-intl'

export const accountMenuMessages = defineMessages({
  myAccount: {
    id: 'accountMenu.myAccount',
    description: 'Text on menu link to edit/view Account',
    defaultMessage: 'My Account',
  },
  changePassword: {
    id: 'accountMenu.changePassword',
    description: 'Text on menu link to Change Password',
    defaultMessage: 'Change Password',
  },
  paymentMethods: {
    id: 'accountMenu.paymentMethods',
    description: 'Text on menu link to edit/view Payment Methods',
    defaultMessage: 'Payment Methods',
  },
  billingHistory: {
    id: 'accountMenu.billingHistory',
    description: 'Text on menu link to edit/view Billing History',
    defaultMessage: 'Billing History',
  },
  aiSettings: {
    id: 'accountMenu.aiSettings',
    description: 'Text on menu link to edit/view AI Settings',
    defaultMessage: 'AI Settings',
  },
  logout: {
    id: 'accountMenu.logout',
    description: 'Text on menu link to log out user',
    defaultMessage: 'Sign Out',
  },
  backToHome: {
    id: 'accountMenu.backToHome',
    description: 'Text on button to go back to home menu',
    defaultMessage: 'Back to home',
  },
})
