import { combineReducers } from '@reduxjs/toolkit'
import { dealerAccessReducer, dealerUpdateAccessReducer } from './slice'

export const DEALER_REDUCER_NAME = 'dealer'
export const DEALER_ACCESS_REDUCER = 'access'
export const DEALER_UPDATE_ACCESS_REDUCER = 'updateAccess'

export default combineReducers({
  [DEALER_ACCESS_REDUCER]: dealerAccessReducer,
  [DEALER_UPDATE_ACCESS_REDUCER]: dealerUpdateAccessReducer,
})
