import styled from 'styled-components'

import theme from 'theme_'

export const StyledLoginInner = styled.div`
  padding: 0 ${theme.spacing.md};
  margin-bottom: ${theme.spacing.lg};

  @media ${theme.devices.tablet} {
    padding: 0;
  }
`
