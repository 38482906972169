import { FC, useMemo } from 'react'
import { Field, FieldProps, FieldAttributes, ErrorMessage } from 'formik'
import { OptionsType, ValueType } from 'react-select'

import Dropdown, { Option } from 'components/Input/Dropdown'
import { StyledDropdownError } from './styles'

export interface DropdownFieldProps extends FieldProps {
  options: OptionsType<Option>
  placeholder?: string
}

const DropdownDrawer: FC<DropdownFieldProps> = ({
  field,
  form,
  options,
  placeholder,
}) => {
  const onChange = (option: ValueType<Option | Option[], boolean>) => {
    form.setFieldValue(field.name, (option as Option).value)
  }

  const value = useMemo(
    () =>
      Array.isArray(options)
        ? options.find((option) => option.value === field.value)
        : '',
    [field.value, options]
  )

  return (
    <Dropdown
      name={field.name}
      value={value}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
    />
  )
}

const DropdownField: FC<FieldAttributes<any> & DropdownFieldProps> = (
  props
) => {
  return (
    <>
      <Field component={DropdownDrawer} {...props} />
      <StyledDropdownError>
        <ErrorMessage name={props.name} />
      </StyledDropdownError>
    </>
  )
}

export default DropdownField
