import styled from 'styled-components'

import theme from 'theme_'
import { BluredBlock } from 'components/Layout'
import { Regular } from 'components/Typography'

export const UserContainer = styled(BluredBlock).attrs({ as: 'li' })<{
  isCurrentUser?: boolean
}>`
  margin: 0;
  border: 1px solid transparent;
  overflow: hidden;
  transition: border-color 0.2s;

  :hover {
    border-color: ${(p) =>
      p.isCurrentUser ? 'transparent' : theme.colors.duneWarmGrey};
    cursor: ${(p) => (p.isCurrentUser ? '' : 'pointer')};
  }
`

export const Actions = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${theme.spacing.md};
`

export const Role = styled.div`
  display: flex;
  align-items: center;
`

export const RoleIconContainer = styled.div`
  margin-right: 4px;
`

export const EditActions = styled.div`
  display: flex;
  align-items: center;
`

export const Details = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
`

export const ImgContainer = styled.div`
  width: 64px;
  height: 64px;
  overflow: hidden;
  text-align: center;
  border-radius: 32px;
`

export const ProfileImg = styled.img`
  background-color: ${theme.colors.beachGrey};
  width: 64px;
  height: 64px;
  object-fit: cover;
`

export const FullNameRow = styled.div`
  margin-top: 24px;
`

export const EmailRow = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  max-width: 100%;
  margin-top: 6px;
  padding-left: 30px;
`

export const EmailIconContainer = styled.div`
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
`

export const EmailRowText = styled(Regular)`
  overflow: hidden;
  text-overflow: ellipsis;
`
