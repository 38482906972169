import Icon from '@mdi/react'
import styled from 'styled-components'

import theme from 'theme_'

export const StyledAlertBanner = styled.div<{ onClick?: () => void }>`
  position: absolute;
  top: ${theme.sizes.headerHeight};
  z-index: ${theme.zIndex.alertBanner};
  display: flex;
  align-items: center;
  width: 100%;
  padding: ${theme.spacing.xxs} ${theme.spacing.s};
  background: ${theme.overlay.darkImage};
  cursor: ${(p) => (p.onClick ? 'pointer' : 'default')};

  @media ${theme.devices.tablet} {
    padding: ${theme.spacing.md} ${theme.spacing.xxl};
  }
`

export const AlertBannerInfoIcon = styled(Icon)`
  flex-shrink: 0;
  color: ${theme.colors.error};
  margin-right: 10px;

  @media ${theme.devices.tablet} {
    margin-right: 14px;
  }
`

export const AlertBannerText = styled.div<{ color?: string }>`
  color: ${(p) => p.color || theme.colors.error};
  font-size: 14px;

  @media ${theme.devices.tablet} {
    font-size: 16px;
  }
`
