import {
  isChineseLocale,
  isFrenchLocale,
  isPortugueseLocale,
  isRussianLocale,
  isSpanishLocale,
  isSwedishLocale,
} from 'app/utils/locales/index'

export const displayLangCode = (lang: string): string => {
  if (isFrenchLocale(lang)) {
    return 'FR'
  }
  if (isRussianLocale(lang)) {
    return 'RU'
  }
  if (isSpanishLocale(lang)) {
    return 'ES'
  }
  if (isSwedishLocale(lang)) {
    return 'SV'
  }
  if (isPortugueseLocale(lang)) {
    return 'PT'
  }
  if (isChineseLocale(lang)) {
    return 'CN'
  }
  return 'EN'
}

export const displayFullLanguage = (lang: string): string => {
  if (isFrenchLocale(lang)) {
    return 'French'
  }
  if (isRussianLocale(lang)) {
    return 'Russian'
  }
  if (isSpanishLocale(lang)) {
    return 'Spanish'
  }
  if (isSwedishLocale(lang)) {
    return 'Swedish'
  }
  if (isPortugueseLocale(lang)) {
    return 'Portuguese'
  }
  if (isChineseLocale(lang)) {
    return 'Chinese'
  }
  return 'English'
}
