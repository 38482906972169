import { passInitReset, passResetConfirm } from './api'
import { makeFetchSlice, fetchDataThunk } from 'app/redux-fetch'
import { AppDispatch } from 'app/store'

const passInitResetSlice = makeFetchSlice('passInitReset')
export const passInitResetReducer = passInitResetSlice.reducer
export const passInitResetActions = passInitResetSlice.actions

const passResetConfirmSlice = makeFetchSlice('passResetConfirm')
export const passResetConfirmReducer = passResetConfirmSlice.reducer
export const passResetConfirmActions = passResetConfirmSlice.actions

interface PassInitResetActionParams {
  email: string
  onSuccess: () => void
  onError: (err: any) => void
}

interface PassResetConfirmActionParams {
  userId: string
  newPassword: string
  passwordResetToken: string
  onSuccess: () => void
  onError: (err: any) => void
}

export const passInitResetThunk = ({
  email,
  onSuccess,
  onError,
}: PassInitResetActionParams) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await fetchDataThunk({
        dispatch,
        actions: passInitResetActions,
        asyncFunc: () => passInitReset({ email }),
      })

      onSuccess()

      return response
    } catch (err) {
      console.log('Error initiating password reset', err)
      onError(err)
    }
  }
}

export const passResetConfirmThunk = ({
  userId,
  passwordResetToken,
  newPassword,
  onSuccess,
  onError,
}: PassResetConfirmActionParams) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await fetchDataThunk({
        dispatch,
        actions: passResetConfirmActions,
        asyncFunc: () =>
          passResetConfirm(
            {
              newPassword,
              passwordResetToken,
            },
            userId
          ),
      })

      onSuccess()

      return response
    } catch (err) {
      console.log('Error initiating password confirmation', err)
      onError(err)
    }
  }
}
