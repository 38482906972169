import { combineReducers } from '@reduxjs/toolkit'
import {
  deletePartnerReducer,
  enrollPartnerReducer,
  partnersInboundReducer,
  partnersReducer,
} from './slice'

export const CONNECTED_APPS_REDUCER_NAME = 'connectedApps'
export const PARTNERS_LIST_REDUCER_NAME = 'partners'
export const PARTNERS_INBOUND_LIST_REDUCER_NAME = 'partnersInbound'
export const DELETE_PARTNER_REDUCER_NAME = 'deletePartner'
export const ENROLL_PARTNER_REDUCER_NAME = 'enrollPartner'

export default combineReducers({
  [PARTNERS_LIST_REDUCER_NAME]: partnersReducer,
  [PARTNERS_INBOUND_LIST_REDUCER_NAME]: partnersInboundReducer,
  [DELETE_PARTNER_REDUCER_NAME]: deletePartnerReducer,
  [ENROLL_PARTNER_REDUCER_NAME]: enrollPartnerReducer,
})
