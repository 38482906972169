import React from 'react'
import Icon from '@mdi/react'
import { mdiChevronLeft } from '@mdi/js'
import { StyledMobileBackBtn } from './styles'

interface IMobileBackBtnProps {
  handleClick: () => void
  text: string
}

const MobileBackBtn: React.FC<IMobileBackBtnProps> = (props) => {
  return (
    <StyledMobileBackBtn onClick={props.handleClick}>
      <Icon path={mdiChevronLeft} size={1} color="#fff" />
      {props.text}
    </StyledMobileBackBtn>
  )
}

export default MobileBackBtn
