import { FC, useContext, useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import Card from 'components/Payment/Card'
import { H4 } from 'components/Typography'
import {
  createSetupIntentThunk,
  getPaymentMethodsThunk,
  selectedPaymentMethodActions,
} from 'features/payment/slice'
import {
  paymentMethodsSelector,
  paymentMethodSelectors,
  selectedPaymentMethodSelector,
  changedDefaultPaymentMethodSelectors,
  detachPaymentMethodSelectors,
} from 'features/payment/selectors'
import Spinner from 'components/Loader/Spinner'
import { CommonButton } from 'components/Button'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { STRIPE_PK } from 'app/consts'

import { purchaseMessages } from '../messages'
import {
  Container,
  Header,
  Cards,
  SpinnerContainer,
  AddFirstPaymentMethod,
  AddFirstPaymentMethodText,
} from './styles'
import { AuthorizedUser } from 'app/types'
import { currUserSelector } from 'features/auth/login/selectors'
import Modal from 'components/Modal'
import AddPaymentMethodForm from '../AddPaymentMethodForm'
import { LocalizationContext } from 'components/i18n/LocalizationWrapper/LocalizationWrapper'
import elementsLocaleMapper from 'app/utils/elementsLocaleMapper'

interface PaymentMethodsProps {
  onInitAddCard: () => void
  noHeading?: boolean
}

const PaymentMethods: FC<PaymentMethodsProps> = ({ noHeading }) => {
  const dispatch = useAppDispatch()

  const paymentMethods = useAppSelector(paymentMethodsSelector)
  const user: AuthorizedUser = useAppSelector(currUserSelector)
  const selectedPaymentMethod = useAppSelector(selectedPaymentMethodSelector)
  const hasPaymentMethodsLoaded = useAppSelector(
    paymentMethodSelectors.isFinished
  )
  const hasSetDefaultPaymentMethod = useAppSelector(
    changedDefaultPaymentMethodSelectors.isFetching
  )
  const hasDetachPaymentMethod = useAppSelector(
    detachPaymentMethodSelectors.isFetching
  )

  useEffect(() => {
    async function fetchData() {
      await dispatch(createSetupIntentThunk(user.id))
    }
    fetchData()
  }, [dispatch, user.id])

  const [isAddModalOpen, setIsAddModalOpen] = useState(false)
  const [isAddFirstModalOpen, setIsAddFirstModalOpen] = useState(false)

  const handleAddSuccess = async () => {
    await dispatch(getPaymentMethodsThunk(user.id))
    setIsAddModalOpen(false)
  }

  const handleAddFirstSuccess = async () => {
    await dispatch(getPaymentMethodsThunk(user.id))
    setIsAddFirstModalOpen(false)
  }

  const localizationContext = useContext(LocalizationContext)

  const stripePromise = useMemo(
    () =>
      loadStripe(STRIPE_PK || '', {
        locale: elementsLocaleMapper(localizationContext.locale),
      }),
    [localizationContext.locale]
  )

  return (
    <Elements key={localizationContext.locale} stripe={stripePromise}>
      <Container>
        <Header>
          <H4>
            {!noHeading && (
              <FormattedMessage {...purchaseMessages.paymentMethodsTitle} />
            )}
          </H4>

          <Modal
            isOpen={isAddModalOpen}
            shouldCloseOnEsc={false}
            onRequestClose={() => setIsAddModalOpen(false)}
          >
            {isAddModalOpen && (
              <AddPaymentMethodForm
                onCancel={() => setIsAddModalOpen(false)}
                onSuccess={handleAddSuccess}
              />
            )}
          </Modal>

          <Modal
            isOpen={isAddFirstModalOpen}
            shouldCloseOnEsc={false}
            onRequestClose={() => setIsAddFirstModalOpen(false)}
          >
            {isAddFirstModalOpen && (
              <AddPaymentMethodForm
                isFirstMethod
                onCancel={() => setIsAddFirstModalOpen(false)}
                onSuccess={handleAddFirstSuccess}
              />
            )}
          </Modal>

          <H4>
            {paymentMethods.length > 0 && (
              <>
                <CommonButton
                  variant="goldenOutlined"
                  onClick={() => setIsAddModalOpen(true)}
                  size="small"
                >
                  <FormattedMessage {...purchaseMessages.addNewPaymentBtn} />
                </CommonButton>
              </>
            )}
          </H4>
        </Header>
        <Cards>
          {hasPaymentMethodsLoaded &&
          !hasSetDefaultPaymentMethod &&
          !hasDetachPaymentMethod ? (
            <>
              {paymentMethods.length > 0 ? (
                <>
                  {paymentMethods.map((paymentMethod) => (
                    <Card
                      {...{ paymentMethod }}
                      key={paymentMethod.id}
                      onSelect={(id) =>
                        dispatch(
                          selectedPaymentMethodActions.selectPaymentMethod(id)
                        )
                      }
                      isSelected={selectedPaymentMethod === paymentMethod.id}
                      isSingleMethod={paymentMethods.length === 1}
                    />
                  ))}
                </>
              ) : (
                <AddFirstPaymentMethod>
                  <AddFirstPaymentMethodText>
                    <FormattedMessage
                      {...purchaseMessages.addFirstPaymentMethodText}
                      values={{ br: <br /> }}
                    />
                  </AddFirstPaymentMethodText>
                  <CommonButton
                    variant="goldenFilled"
                    onClick={() => setIsAddFirstModalOpen(true)}
                  >
                    <FormattedMessage {...purchaseMessages.addPaymentBtn} />
                  </CommonButton>
                </AddFirstPaymentMethod>
              )}
            </>
          ) : (
            <SpinnerContainer>
              <Spinner />
            </SpinnerContainer>
          )}
        </Cards>
      </Container>
    </Elements>
  )
}

export default PaymentMethods
