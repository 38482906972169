import styled from 'styled-components'
import theme from 'theme_'

export const StyledBGImageWrapper = styled.div`
  position: absolute;
  z-index: ${theme.zIndex.bgImage};
  top: 0;
  left: 0;
  min-width: 100%;
  height: 100%;
`

export const StyledBGImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const StyledBackgroundOverlay = styled.div`
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${theme.overlay.darkImage};
`
