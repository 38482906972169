import styled from 'styled-components'
import { StyledH2 } from 'components/Typography/Headings'
import theme from 'theme_'

export const StyledMainHeading = styled(StyledH2)`
  margin: ${theme.spacing.xl} 0 ${theme.spacing.md};
  font-size: 18px;
  line-height: 1.3;

  @media ${theme.devices.tablet} {
    margin-top: ${theme.spacing.xlarge};
    font-size: 32px;
  }

  @media ${theme.devices.desktop} {
    margin-top: ${theme.spacing.xl};
  }
`
