import styled from 'styled-components'

export const BackBtnContainer = styled.div`
  margin-bottom: 12px;
`

export const SpinnerContainer = styled.div`
  margin-top: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Container = styled.div`
  position: relative;
  z-index: 1;
  margin-bottom: 86px;
`
