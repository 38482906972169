import styled from 'styled-components'

import { BluredBlock } from 'components/Layout'
import theme from 'theme_'

export const Container = styled(BluredBlock)`
  width: 100%;
  min-height: 180px;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Cards = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 24px auto 0;

  & > div:not(:first-of-type) {
    margin-top: 12px;
  }
`

export const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const AddFirstPaymentMethod = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const AddFirstPaymentMethodText = styled.div`
  padding-bottom: 24px;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.colors.beachGrey};
  text-align: center;
  max-width: 200px;
  white-space: normal;
  @media ${theme.devices.tablet} {
    font-size: 16px;
    line-height: 22px;
    max-width: 400px;
    white-space: nowrap;
  }
`
