export const isEnglishLocale = (locale: string) => {
  return /^en\b/i.test(locale)
}
export const isFrenchLocale = (locale: string) => {
  return /^fr\b/i.test(locale)
}
export const isRussianLocale = (locale: string) => {
  return /^ru\b/i.test(locale)
}
export const isSpanishLocale = (locale: string) => {
  return /^es\b/i.test(locale)
}
export const isSwedishLocale = (locale: string) => {
  return /^sv\b/i.test(locale)
}
export const isPortugueseLocale = (locale: string) => {
  return /^pt\b/i.test(locale)
}
export const isChineseLocale = (locale: string) => {
  return /^zh\b/i.test(locale)
}
