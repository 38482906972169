import styled from 'styled-components'

export const DateRowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const DateRowDate = styled.div`
  display: flex;
  align-items: center;

  > svg {
    margin-right: 8px;
  }
`
