import { StripeElementLocale } from '@stripe/stripe-js'
import {
  isChineseLocale,
  isEnglishLocale,
  isFrenchLocale,
  isPortugueseLocale,
  isRussianLocale,
  isSpanishLocale,
  isSwedishLocale,
} from 'app/utils/locales'

const elementsLocaleMapper = (
  locale: string
): StripeElementLocale | undefined => {
  if (isEnglishLocale(locale)) {
    return 'en'
  }
  if (isFrenchLocale(locale)) {
    return 'fr'
  }
  if (isRussianLocale(locale)) {
    return 'ru'
  }
  if (isSpanishLocale(locale)) {
    return 'es-419'
  }
  if (isSwedishLocale(locale)) {
    return 'sv'
  }
  if (isPortugueseLocale(locale)) {
    return 'pt-BR'
  }
  if (isChineseLocale(locale)) {
    return 'zh'
  }
  return undefined
}

export default elementsLocaleMapper
