import { authorizedEdgeFetch } from '../../app/axios/edge'

export const getHomeCameras = (homeId: string) =>
  authorizedEdgeFetch.get(`video/cameras/${homeId}`)

export interface ClipsListRequestParams {
  cameraId?: string
  rangeStart?: string
  rangeEnd?: string
  eventIds?: string
  limit?: number
  oldestFirst?: boolean
  next?: string
}

export const getHomeClips = (homeId: string, params: ClipsListRequestParams) =>
  authorizedEdgeFetch.get(`video/clips/${homeId}`, { params })

export interface GetHomeCameraClipsData {
  homeId: string
  cameraId: string
}

export const getHomeCameraClips = (
  { homeId, cameraId }: GetHomeCameraClipsData,
  params: ClipsListRequestParams
) => authorizedEdgeFetch.get(`video/clips/${homeId}/${cameraId}`, { params })

export interface GetHomeCameraClipParams {
  homeId: string
  cameraId: string
  clipId: string
}

export const getHomeCameraClip = ({
  homeId,
  cameraId,
  clipId,
}: GetHomeCameraClipParams) =>
  authorizedEdgeFetch.get(`video/clips/${homeId}/${cameraId}/${clipId}`)
