import styled from 'styled-components'

import theme from 'theme_'
import { BigTitle } from 'components/Typography'
import { BluredBlock } from 'components/Layout'
import { CommonButton } from 'components/Button'

export const WhatsNewContainer = styled(BluredBlock)`
  margin-bottom: 16px;
`

export const WhatsNewTitle = styled(BigTitle)`
  margin-bottom: 16px;
  text-transform: none;
`

export const WhatsNewDescription = styled.div`
  margin-bottom: 24px;
`

export const WidgetBlock = styled.div`
  position: relative;
`

export const WidgetImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: inline-block;
  vertical-align: top;
  &::before {
    border-radius: 8px;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0.2) 100%
    );
  }
`

export const WidgetImage = styled.img`
  bottom: 0;
  height: 100%;
  left: 0;
  margin: 0;
  max-width: none;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
`

export const WidgetContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 320px;
  position: relative;
  padding: 40px;
  z-index: 2;
`

export const WidgetTitle = styled(BigTitle)`
  color: ${theme.colors.beachGrey};
  margin-bottom: 24px;
  text-transform: none;
`

export const WidgetSubTitle = styled.div`
  color: ${theme.colors.beachGrey};
  width: 75%;
  margin-bottom: 24px;
  text-transform: none;
`

export const WidgetButton = styled(CommonButton)`
  width: 156px;
  padding 16px;
`

export const WidgetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
