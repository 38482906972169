import styled from 'styled-components'

import theme from 'theme_'
import { Regular } from 'components/Typography'

export const StyledErrorIcon = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${theme.spacing.xxl};
`

export const StyledDescriptionText = styled(Regular)`
  margin-top: ${theme.spacing.sm};
  text-align: center;
`

export const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${theme.spacing.xxl};

  & > button:first-child {
    margin-right: 16px;
  }

  @media ${theme.devices.desktop} {
    & > button:first-child {
      margin-right: 32px;
    }
  }
`
