import { createSelector } from '@reduxjs/toolkit'
import { path } from 'lodash/fp'

import {
  HOMES_REDUCER_NAME,
  HOME_ADMIN_REDUCER_NAME,
  HOME_EDGE_ADMIN_REDUCER_NAME,
} from 'app/consts'
import type { RootState } from 'app/store'
import {
  makeFetchSelectors,
  makeTypedFetchSelectors,
} from 'app/redux-fetch/selectors'

import type { UberShape, DealerRequest } from './components/MyDealer/types'
import type { HomeType } from './types'

import {
  UBER_REDUCER_NAME,
  HOMES_LIST_REDUCER_NAME,
  HOME_CONFIG_REDUCER_NAME,
  CURR_HOME_ID_REDUCER_NAME,
  DEALER_REQUEST_ACCEPT_REDUCER_NAME,
  DEALER_REQUEST_DECLINE_REDUCER_NAME,
  REMOVE_DEALER_REQUEST_REDUCER_NAME,
  CLAIM_HOME_REQUEST_REDUCER_NAME,
  CURR_HOME_INFO_REDUCER_NAME,
} from './consts'
import { getUserRole, isAdmin } from '../auth/utils'
import { currUserSelector } from 'features/auth/login/selectors'

const selectHomesState = (state: RootState) => state[HOMES_REDUCER_NAME]

export const homesRequestSelectors = makeFetchSelectors(
  HOMES_REDUCER_NAME,
  HOMES_LIST_REDUCER_NAME
)

export const homesListSelector = createSelector(
  homesRequestSelectors.data,
  (res) => res || []
)

export const adminHomesListSelector = createSelector(
  homesRequestSelectors.data,
  (homes) =>
    (homes || []).filter((home: any) => isAdmin(getUserRole(home.permissions)))
)

export const currentHomeIdSelector = createSelector(
  selectHomesState,
  (homes) => homes[CURR_HOME_ID_REDUCER_NAME]
)

export const currentHomeSelector = createSelector(
  homesListSelector,
  currentHomeIdSelector,
  (homes, currentHomeId) => homes.find((home: any) => home.id === currentHomeId)
)

export const uberSelectors = makeTypedFetchSelectors<UberShape>(
  UBER_REDUCER_NAME
)

export const dealerSelector = createSelector(
  uberSelectors.data,
  (uber): DealerRequest | undefined => uber?.dealer
)

export const dealerRequestSelector = createSelector(uberSelectors.data, (uber):
  | DealerRequest
  | undefined => path(['dealerRequests', 0], uber))

export const homeConfigSelectors = makeFetchSelectors(
  HOMES_REDUCER_NAME,
  HOME_CONFIG_REDUCER_NAME
)

export const homeLicensesSelector = createSelector(
  currentHomeSelector,
  (home) => home?.licenses || []
)

// Mostly from SCM's HostStatus
export const homeSubscriptionLicenseSelector = createSelector(
  homeLicensesSelector,
  (licenses) => {
    if (!licenses.length) return undefined
    const subscriptionLicense = licenses
      .filter(
        (license: any) =>
          typeof license?.expiresAt === 'number' &&
          license?.expiresAt > +Date.now() &&
          license?.capabilities.some((capability: any) =>
            ['SUB_ESSENTIAL', 'SUB_PRO'].includes(capability?.code)
          )
      )
      .sort(
        // reverse chronologically by expiry
        (licenseA: any, licenseB: any) =>
          licenseB?.expiresAt - licenseA?.expiresAt
      )
    return subscriptionLicense.length ? subscriptionLicense[0] : undefined
  }
)
export interface IAdmin {
  id: string
  edgeId: string
}

export interface IEdgeUser {
  id: string
  type: number
  userId: string
  firstName: string
  lastName: string
  email: string
  phone: string
  created: number
  updated: number
}

export const homeAdminSelector = createSelector(
  selectHomesState,
  (homes): IAdmin | null => homes[HOME_ADMIN_REDUCER_NAME]?.data
)

export const isAdminSelector = createSelector(
  homeAdminSelector,
  currUserSelector,
  (admin, user) => {
    return admin?.edgeId === user.id || admin?.edgeId === undefined
  }
)

export const homeEdgeAdminSelector = createSelector(
  selectHomesState,
  (homes): IEdgeUser | null => homes[HOME_EDGE_ADMIN_REDUCER_NAME]?.data
)

export const dealerRequestAcceptSelectors = makeFetchSelectors(
  HOMES_REDUCER_NAME,
  DEALER_REQUEST_ACCEPT_REDUCER_NAME
)

export const dealerRequestDeclineSelectors = makeFetchSelectors(
  HOMES_REDUCER_NAME,
  DEALER_REQUEST_DECLINE_REDUCER_NAME
)

export const removeDealerSelectors = makeFetchSelectors(
  HOMES_REDUCER_NAME,
  REMOVE_DEALER_REQUEST_REDUCER_NAME
)

export const claimHomeRequestSelectors = makeFetchSelectors(
  HOMES_REDUCER_NAME,
  CLAIM_HOME_REQUEST_REDUCER_NAME
)

export const currentHomeInfoSelectors = makeTypedFetchSelectors<HomeType>(
  CURR_HOME_INFO_REDUCER_NAME
)
