import styled from 'styled-components'

export const SuccessModalContainer = styled.div`
  text-align: center;
  margin: 20px 0;
`

export const SuccessModalFooter = styled.footer`
  display: flex;
  justify-content: center;

  > button {
    width: 100%;
  }

  @media screen and (min-width: 350px) {
    > button {
      width: auto;

      &:first-of-type {
        margin-right: 19px;
      }

      &:last-of-type {
        margin-left: 19px;
      }
    }
  }
`

export const SuccessModalTitle = styled.div`
  margin: 36px 0;
`

export const SuccessModalContent = styled.div`
  margin-bottom: 36px;
`
