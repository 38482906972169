import styled, { css } from 'styled-components'
import theme from 'theme_'

export const SubscriptionTableHeaderContainer = styled.div`
  display: flex;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;
  position: sticky;
  top: 64px;

  @media ${theme.devices.tablet} {
    position: unset;
    align-items: center;
  }
`

const coloredBorderRadius = css`
  border-top-right-radius: ${theme.spacing.xs};
  border-top-left-radius: ${theme.spacing.xs};
`

const HeaderColumns = styled.div<{
  isColored?: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  text-align: center;
  min-height: 120px;
  padding: ${theme.spacing.xs} ${theme.spacing.lg};

  @media ${theme.devices.tablet} {
    font-size: 20px;
    ${(p) => p.isColored && coloredBorderRadius};
    border: ${(p) =>
      p.isColored ? `1px solid ${theme.colors.accentGold}` : 'none'};
    border-bottom: 1px solid ${theme.colors.separatorColor};
  }
`

export const HeaderColumnFeature = styled(HeaderColumns)`
  display: none;

  @media ${theme.devices.tablet} {
    display: flex;
    flex-basis: 56%;
    justify-content: center;
  }
`

export const HeaderColumnIncluded = styled(HeaderColumns)`
  flex-basis: 50%;
  line-height: 1.5;
  color: ${(p) =>
    p.isColored ? theme.colors.accentGold : theme.colors.beachGrey};
  background-color: ${(p) =>
    p.isColored ? theme.colors.nonTransparentHighlight : '#272422'};

  @media ${theme.devices.tablet} {
    flex-basis: 22%;
    line-height: 1.5;
    font-size: 16px;
  }
`

export const HeaderColumnEssentials = styled(HeaderColumns)`
  display: flex;
  flex-direction: column;
  font-weight: bold;
  line-height: 1.5;
  flex-basis: 50%;
  font-size: 20px;
  color: ${theme.colors.beachGrey};
  background-color: ${(p) =>
    p.isColored ? theme.colors.nonTransparentHighlight : '#272422'};

  @media ${theme.devices.tablet} {
    flex-basis: 22%;
    flex-direction: column;
    padding: 0;
  }
`

export const HeaderColumnEssentialsIcon = styled.img`
  height: 34px;
  width: 34px;
  margin-bottom: 10px;
`

export const HeaderColumnEnrolledText = styled.div`
  color: ${theme.colors.accentGold};
  font-weight: 400;
  font-size: 16px;
  margin-top: 10px;
`
