import React, { FC } from 'react'
import { OptionsContainer, Option } from './styles'
import { FlagImage, LangLabel } from '../styles'
import { flags } from '../../../lang/flags'

interface ILanguageItem {
  label: string
  id: string
}

interface OptionsProps {
  items: Array<{ id: string; label: string }>
  isOpen: boolean
  selectedLang: string
  inMobileMenu?: boolean
  onSelectedChange(lang: string): void
}

const Options: FC<OptionsProps> = ({
  items,
  isOpen,
  selectedLang,
  inMobileMenu,
  onSelectedChange,
}) => {
  return (
    <OptionsContainer isOpen={isOpen} inMobileMenu={inMobileMenu}>
      {items.map((lang: ILanguageItem) => (
        <Option
          key={lang.id}
          isSelected={lang.id === selectedLang}
          onClick={() => onSelectedChange(lang.id as string)}
        >
          <FlagImage alt="Flag icon" src={flags(lang.id)} />
          <LangLabel>{lang.label}</LangLabel>
        </Option>
      ))}
    </OptionsContainer>
  )
}

export default Options
