import React, { useCallback, useState, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Formik, FormikHelpers, Form } from 'formik'
import * as Yup from 'yup'

import { useAppDispatch } from 'app/hooks'
import Modal from 'components/Modal'

import {
  clearUserCreds,
  getAuthorizedUser,
  storeAuthorizedUser,
} from 'app/services/LocalStorage'
import { updateAuthStatus } from 'features/auth/login/slice'
import { UserContentContainer } from 'components/UserItems/styles'
import { H2, H4, Regular } from 'components/Typography'
import TextField from 'components/Fields/TextField/TextField'
import { StyledInputError } from 'components/Input/styles'
import { CommonButton } from 'components/Button'
import Toggle from 'components/Input/Toggle'
import { StyledAccountBlurredBlock } from '../styles'
import { myAccountMessages } from '../../messages'
import { fieldsMessages } from 'components/Fields/messages'
import { twoFactorAuthMessages } from '../../../components/TwoFaAuth/messages'
import {
  enableTwoFaThunk,
  confirmTwoFaThunk,
  disableTwoFaThunk,
} from '../../slice'
import { I2FaValues } from '../../types'
import {
  ChangePassModalContainer,
  StyledButtonsWrapper,
  StyledResendVerification,
  StyledDescriptionText,
  StyledDescriptionTextWrapper,
  ResendCodeWrapper,
  StyledResendText,
  ModalContent,
  StyledInstructionsBlock,
  AccountToggleContainer,
  ToggleWrapper,
} from './styles'
import { ModalTitle } from 'components/Modal/styles'
import { CheckCircleOutline } from 'components/Icons'
import { SpinnerCentered, Spinner } from 'components/Loader'
import theme from '../../../../../theme_'

const TwoFactor: React.FC = () => {
  const dispatch = useAppDispatch()
  const { formatMessage } = useIntl()

  const [loading, setIsLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [twoFaEnabled, setTwoFaEnabled] = useState(false)
  const [twoFaFormError, setTwoFaFormError] = useState<string | null>(null)

  const storedUser = getAuthorizedUser()

  useEffect(() => {
    setTwoFaEnabled(
      (storedUser && storedUser.twoFactor[0]?.enabled === 'Enabled') ?? false
    )
  }, [storedUser])

  const openModal = useCallback(() => {
    setIsModalOpen(true)
  }, [])

  const closeModal = useCallback(() => {
    setIsModalOpen(false)
  }, [setIsModalOpen])

  const onError = (err: any) => {
    setIsLoading(false)
    setTwoFaFormError(formatMessage(myAccountMessages.twoFactorError))
  }

  const onEnableSuccess = (payload: any) => {
    setIsLoading(false)
    openModal()
  }

  const onConfirmSuccess = (payload: any) => {
    setIsLoading(false)
    clearUserCreds()
    storeAuthorizedUser(payload)
    dispatch(updateAuthStatus())
    closeModal()
  }

  const handleChange = async (e: any) => {
    setIsLoading(true)
    if (twoFaEnabled) {
      dispatch(disableTwoFaThunk({ onSuccess: onEnableSuccess, onError }))
    } else {
      dispatch(enableTwoFaThunk({ onSuccess: onEnableSuccess, onError }))
    }
  }

  const handleSubmit = async (
    values: I2FaValues,
    formikHelpers: FormikHelpers<I2FaValues>
  ) => {
    setIsLoading(true)
    dispatch(
      confirmTwoFaThunk(values.code, { onSuccess: onConfirmSuccess, onError })
    )
  }

  const handleCodeInputsChange = (e: any, fieldHandleChange: any) => {
    fieldHandleChange(e)
    setTwoFaFormError(null)
  }

  const resendClick = () => {
    setIsLoading(true)
    dispatch(enableTwoFaThunk({ onSuccess: onEnableSuccess, onError }))
  }

  return (
    <>
      <Modal isOpen={isModalOpen} onRequestClose={closeModal}>
        <ChangePassModalContainer>
          <CheckCircleOutline />
          <ModalTitle>
            <H2>{formatMessage(myAccountMessages.changePassModalHeading)}</H2>
          </ModalTitle>
          <Formik
            initialValues={{ code: '' }}
            onSubmit={handleSubmit}
            validationSchema={Yup.object().shape({
              code: Yup.string()
                .required(formatMessage(fieldsMessages.requiredField))
                .length(
                  6,
                  formatMessage(twoFactorAuthMessages.codeLengthError)
                ),
            })}
          >
            {(formikProps) => (
              <Form>
                <ModalContent>
                  <StyledDescriptionTextWrapper>
                    <StyledDescriptionText>
                      <FormattedMessage {...twoFactorAuthMessages.enterCode} />
                    </StyledDescriptionText>
                  </StyledDescriptionTextWrapper>
                  <TextField
                    name="code"
                    error={twoFaFormError}
                    disabled={loading}
                    onChange={(e: any) =>
                      handleCodeInputsChange(e, formikProps.handleChange)
                    }
                    placeholder={formatMessage(twoFactorAuthMessages.twoFactor)}
                    autoComplete="off"
                  />
                  <StyledInputError>{twoFaFormError}</StyledInputError>

                  <StyledButtonsWrapper>
                    <CommonButton
                      type="button"
                      onClick={(ev) => {
                        ev.preventDefault()
                        ev.stopPropagation()
                        closeModal()
                      }}
                      variant="goldenOutlined"
                      disabled={formikProps.isSubmitting || loading}
                    >
                      {formatMessage(myAccountMessages.changePassModalClose)}
                    </CommonButton>
                    <CommonButton
                      type="submit"
                      variant="goldenFilled"
                      disabled={
                        formikProps.isSubmitting ||
                        !formikProps.values.code ||
                        !formikProps.isValid ||
                        loading
                      }
                    >
                      {formatMessage(twoFactorAuthMessages.verifyCode)}
                    </CommonButton>
                  </StyledButtonsWrapper>
                  <ResendCodeWrapper>
                    <StyledResendText>
                      {formatMessage(twoFactorAuthMessages.resendDescription)}{' '}
                    </StyledResendText>
                    <StyledResendVerification to="#" onClick={resendClick}>
                      {formatMessage(twoFactorAuthMessages.resendCode)}
                    </StyledResendVerification>
                  </ResendCodeWrapper>
                </ModalContent>
              </Form>
            )}
          </Formik>
        </ChangePassModalContainer>
      </Modal>
      <StyledAccountBlurredBlock>
        {!storedUser ? (
          <SpinnerCentered />
        ) : (
          <>
            <H4 color={theme.colors.mutedGray}>
              <FormattedMessage {...myAccountMessages.twoFactorHeading} />
            </H4>
            <StyledInstructionsBlock>
              <Regular>
                {formatMessage(myAccountMessages.twoFactorText)}
              </Regular>
            </StyledInstructionsBlock>
            <UserContentContainer>
              <AccountToggleContainer>
                <Regular>
                  <FormattedMessage {...myAccountMessages.twoFactorToggle} />
                </Regular>
                {loading ? (
                  <Spinner />
                ) : (
                  <ToggleWrapper>
                    <span>
                      {twoFaEnabled
                        ? formatMessage(myAccountMessages.enabled)
                        : formatMessage(myAccountMessages.disabled)}
                    </span>
                    <Toggle
                      checked={twoFaEnabled}
                      disabled={loading}
                      onChange={(e: any) => handleChange(e)}
                    />
                  </ToggleWrapper>
                )}
              </AccountToggleContainer>
            </UserContentContainer>
          </>
        )}
      </StyledAccountBlurredBlock>
    </>
  )
}

export default TwoFactor
