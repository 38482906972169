import { defineMessages } from 'react-intl'

export const alertBannerMessages = defineMessages({
  cardExpired: {
    id: 'alertBanner.cardExpired',
    description: "Warning text that user's default card is expired",
    defaultMessage:
      'Your default payment method expired. Please add valid payment method for subscription renewal.',
  },
  cardExpiresSoon: {
    id: 'alertBanner.cardExpiresSoon',
    description: `Warning text that user's default card is going to expire soon`,
    defaultMessage:
      'Your payment method expires in {daysNumber} days. Please add valid payment method for subscription renewal',
  },
})
