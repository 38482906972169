import React from 'react'
import MobileBackBtn from '../MobileBackBtn'
import HomeSelectorMenu from 'components/AsideMenu/HomeSelector/HomeSelectorMenu'
import { StyledScreenWrapper } from '../styles'

interface IScreenHomesProps {
  handleScreenDefault: () => void
  handleCloseMenu: () => void
}

const ScreenHomes: React.FC<IScreenHomesProps> = (props) => {
  return (
    <StyledScreenWrapper>
      <MobileBackBtn
        handleClick={props.handleScreenDefault}
        text={'Select Home'}
      />
      <HomeSelectorMenu isMobileMenu closeMenuHandler={props.handleCloseMenu} />
    </StyledScreenWrapper>
  )
}

export default ScreenHomes
