import React, { memo, useRef } from 'react'

import { HomesBgImageWrapper, StyledHomesBgImage } from './styles'
import { useAppSelector } from 'app/hooks'
import { currentHomeSelector } from 'features/homes/selectors'
import { makePublicUrl } from 'app/utils/publicUrl'

interface HomeBgImageProps {
  noImage?: boolean
}

export const useCachedImage = (
  imgUrl: string | undefined | null,
  defaultImg: string,
  noImageUrl?: string,
  noImage?: boolean
) => {
  const getImgUrlPath = (url?: string) => {
    if (!url) {
      return
    }
    return url.split('?')[0]
  }

  const currFetchedImg = useRef<null | string>(null)

  if (noImage) return noImageUrl || ''

  if (imgUrl) {
    if (currFetchedImg.current) {
      if (getImgUrlPath(imgUrl) === getImgUrlPath(currFetchedImg.current)) {
        return currFetchedImg.current
      }
    }
    currFetchedImg.current = imgUrl
    return imgUrl
  }
  return defaultImg
}

const HomesBgImage: React.FC<HomeBgImageProps> = (props) => {
  const currentHome = useAppSelector(currentHomeSelector)

  const image = useCachedImage(
    currentHome?.imageUrl,
    makePublicUrl('images/loginBgImage.jpg'),
    makePublicUrl('images/no-home-image.jpg'),
    props.noImage
  )

  return (
    <HomesBgImageWrapper>
      <StyledHomesBgImage imageUrl={image} />
    </HomesBgImageWrapper>
  )
}

export default memo(HomesBgImage)
