import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

import Modal from 'components/Modal'
import { CheckCircleOutline } from 'components/Icons'
import { H2 } from 'components/Typography'
import { CommonButton } from 'components/Button'
import { commonMessages } from 'app/i18n'

import { purchaseMessages } from '../messages'
import { InnerContainer } from './styles'
import { ModalTitle } from 'components/Modal/styles'

interface ModalProps {
  isOpen: boolean
  onClose: () => void
}

const CompleteModal: FC<ModalProps> = ({ isOpen, onClose }) => (
  <Modal {...{ isOpen }}>
    <InnerContainer>
      <CheckCircleOutline />
      <ModalTitle>
        <H2>
          <FormattedMessage {...purchaseMessages.successTitle} />
        </H2>
      </ModalTitle>
      <CommonButton variant="goldenOutlined" onClick={onClose}>
        <FormattedMessage {...commonMessages.close} />
      </CommonButton>
    </InnerContainer>
  </Modal>
)

export default CompleteModal
