import { combineReducers } from '@reduxjs/toolkit'

import {
  userListReducer,
  userDetailsReducer,
  updateUserPermissionsReducer,
  deleteUserReducer,
  inviteUserReducer,
} from './slice'

export const USERS_REDUCER_NAME = 'users'
export const USER_LIST_REDUCER_NAME = 'list'
export const USER_DETAILS_REDUCER_NAME = 'details'
export const UPDATE_PERMISSIONS_REDUCER_NAME = 'updatePermissions'
export const DELETE_USER_REDUCER_NAME = 'deleteUser'
export const INVITE_USER_REDUCER_NAME = 'inviteUser'

export default combineReducers({
  [USER_LIST_REDUCER_NAME]: userListReducer,
  [USER_DETAILS_REDUCER_NAME]: userDetailsReducer,
  [UPDATE_PERMISSIONS_REDUCER_NAME]: updateUserPermissionsReducer,
  [DELETE_USER_REDUCER_NAME]: deleteUserReducer,
  [INVITE_USER_REDUCER_NAME]: inviteUserReducer,
})
