import { FC, ButtonHTMLAttributes } from 'react'
import { mdiChevronLeft } from '@mdi/js'

import { StyledBackButtonContainer, StyledBackButtonLabel } from './styles'
import Icon from '@mdi/react'

interface BackButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {}

const BackButton: FC<BackButtonProps> = ({ children, ...rest }) => (
  <StyledBackButtonContainer {...rest}>
    <Icon path={mdiChevronLeft} size={1} />
    <StyledBackButtonLabel>{children}</StyledBackButtonLabel>
  </StyledBackButtonContainer>
)

export default BackButton
