import { useState, useEffect, useMemo, useCallback } from 'react'
import _throttle from 'lodash/throttle'

export function useBgOnScroll() {
  const [shouldHaveBg, setShouldHaveBg] = useState(false)

  const scrollEventListener: EventListener = useCallback(
    (e: Event) => {
      if (window.scrollY > 100) {
        setShouldHaveBg(true)
      } else {
        setShouldHaveBg(false)
      }
    },
    [setShouldHaveBg]
  )
  const throttledScrollListener = useMemo(
    () => _throttle(scrollEventListener, 200),
    [scrollEventListener]
  )

  useEffect(() => {
    window.addEventListener('scroll', throttledScrollListener)
    return () => {
      window.removeEventListener('scroll', throttledScrollListener)
    }
  }, [throttledScrollListener])

  return shouldHaveBg
}
