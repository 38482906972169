import React from 'react'
import { useIntl } from 'react-intl'
import { findDealerHelpMessages } from './messages'
import CommonButton from 'components/Button/CommonButton'

import DealerBlock from '../DealerBlock'
import { StyledDealerRow, StyledButtonWrapper } from './styles'

interface FindDealerHelpProps {
  fullWidth?: boolean
  homeId?: string
}

const FindDealerHelp: React.FC<FindDealerHelpProps> = (props) => {
  const { formatMessage } = useIntl()

  return (
    <DealerBlock
      fullWidth={props.fullWidth}
      title={!props.fullWidth && formatMessage(findDealerHelpMessages.title)}
    >
      <StyledDealerRow fullWidth={props.fullWidth}>
        <p>{formatMessage(findDealerHelpMessages.extendedText)}</p>
      </StyledDealerRow>
      <StyledDealerRow fullWidth={props.fullWidth}>
        <StyledButtonWrapper>
          <CommonButton
            variant="goldenOutlined"
            type="button"
            as="a"
            href={
              'https://www.savant.com/find-a-dealer/?homeID=' + props.homeId
            }
            target="_blank"
          >
            {formatMessage(findDealerHelpMessages.findDealer)}
          </CommonButton>
        </StyledButtonWrapper>
      </StyledDealerRow>
    </DealerBlock>
  )
}
export default FindDealerHelp
