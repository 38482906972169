import styled from 'styled-components'

import { Container as AccessRevocationFieldContainer } from 'components/Fields/AccessRevocationField/styles'
import theme from 'theme_'

export const InnerContainer = styled.div`
  text-align: center;
  width: 100%;
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
`

export const EmailContainer = styled.div`
  margin-top: 32px;
  margin-bottom: 32px;

  & > input {
    width: 100%;
  }
`

export const Message = styled.div`
  padding: 12px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  margin-top: 14px;
`

export const Footer = styled.footer`
  display: flex;
  flex-flow: row;
  justify-content: center;
  margin-top: 32px;

  > button {
    margin: 0 6px;

    @media ${theme.devices.desktop} {
      margin: 0 19px;
    }
  }
`

export const AccessRevocationContainer = styled.div`
  margin-top: 32px;

  ${AccessRevocationFieldContainer} {
    flex-flow: column;

    > label {
      width: 100%;
      text-align: left;
      margin-right: 0;
    }

    > div {
      width: 100%;
      margin-top: 12px;
    }

    @media ${theme.devices.desktop} {
      flex-flow: row;

      > label {
        width: auto;
        margin-right: 28px;
      }

      > div {
        width: auto;
        margin-top: 0;
      }
    }
  }
`
