import { FC, ReactElement, useEffect, useMemo } from 'react'
import { useFormikContext } from 'formik'

import { ToggleField, CheckboxField, DropdownField } from 'components/Fields'
import { IFieldProps } from 'components/Fields/types'
import { Regular } from 'components/Typography'

import { PermissionsForm } from '../types'
import { PermissionContainer, PermissionToggleContainer } from './styles'

interface PermissionProps extends IFieldProps {
  title: ReactElement | string
  type: 'toggle' | 'checkbox' | 'dropdown'
  resetForAdmin?: boolean
}

const Permission: FC<PermissionProps> = ({ title, type, ...rest }) => {
  const FieldComponent = useMemo(() => {
    let fieldComponent
    switch (type) {
      case 'toggle':
        fieldComponent = ToggleField
        break
      case 'checkbox':
        fieldComponent = CheckboxField
        break
      case 'dropdown':
        fieldComponent = DropdownField
        break
    }
    return fieldComponent
  }, [type])

  const {
    values,
    setFieldValue,
    getFieldProps,
  } = useFormikContext<PermissionsForm>()
  const isAdmin = values.admin
  const field = getFieldProps(rest.name)

  useEffect(() => {
    if (isAdmin && !field.value) {
      setFieldValue(field.name, true)
    }
  }, [isAdmin, field.name, field.value, setFieldValue])

  return (
    <PermissionContainer htmlFor={rest.name}>
      <Regular>{title}</Regular>

      <PermissionToggleContainer>
        <FieldComponent {...rest} />
      </PermissionToggleContainer>
    </PermissionContainer>
  )
}

export default Permission
