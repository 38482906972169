import React from 'react'
import { FormattedMessage } from 'react-intl'

import { FeedbackFormMessages } from './messages'
import { FeedbackButton, FeedbackContainer, FeedbackText } from './styles'
import { GET_HELP_LINKS } from 'app/consts'
import { SectionTitle } from 'components/Typography'

interface FeedbackFormProps {
  dealerId?: string
  homeId?: string
}

const FeedbackForm: React.FC<FeedbackFormProps> = (props) => (
  <FeedbackContainer>
    <SectionTitle>
      <FormattedMessage {...FeedbackFormMessages.feedbackTitle} />
    </SectionTitle>

    <FeedbackText>
      <FormattedMessage {...FeedbackFormMessages.feedbackText} />
    </FeedbackText>

    <FeedbackButton
      as="a"
      href={GET_HELP_LINKS.feedbackForm.concat(
        '/?homeID=' + props.homeId + '&d=' + props.dealerId
      )}
      target="_blank"
      variant={'goldenOutlined'}
    >
      <FormattedMessage {...FeedbackFormMessages.feedbackButtonTitle} />
    </FeedbackButton>
  </FeedbackContainer>
)

export default FeedbackForm
