import React from 'react'
import Icon from '@mdi/react'
import { mdiArrowRight } from '@mdi/js'

import { StyledIconLink, StyledIconLinkDiv } from './styles'
import theme from 'theme_'

interface IconLinkProps {
  to?: string
  svgPath?: string
  size?: number
  color?: string
  hoverColor?: string
}

const IconLink: React.FC<IconLinkProps> = ({
  to,
  svgPath,
  size,
  color,
  hoverColor,
  ...restProps
}) => {
  return to ? (
    <StyledIconLink
      to={to}
      color={color ?? theme.colors.mutedGray}
      $hoverColor={hoverColor ?? theme.colors.dimmedGold}
      {...restProps}
    >
      <Icon path={svgPath ?? mdiArrowRight} size={size ?? 1} />
    </StyledIconLink>
  ) : (
    <StyledIconLinkDiv
      color={color ?? theme.colors.mutedGray}
      $hoverColor={hoverColor ?? theme.colors.dimmedGold}
    >
      <Icon path={svgPath ?? mdiArrowRight} size={size ?? 1} />
    </StyledIconLinkDiv>
  )
}

export default IconLink
