import styled from 'styled-components'
import { BluredBlock } from 'components/Layout'
import theme from 'theme_'

export const ClipsFiltersContainer = styled(BluredBlock)`
  position: relative;
  z-index: 2;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: auto;
  width: 100%;
  margin-top: ${theme.spacing.md};
  padding: ${theme.spacing.md};
`

export const LeftSideDesktop = styled.div`
  display: none;

  @media ${theme.devices.tablet} {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
  }
`

export const LeftSideMobile = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${theme.devices.tablet} {
    display: none;
  }
`

export const RightSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
`

export const FilterContainer = styled.div`
  width: 100%;
  height: ${theme.spacing.lg};
  margin-bottom: ${theme.spacing.md};
  padding: ${theme.spacing.xxs} 10px;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.colors.beachGrey};
  background: ${theme.overlay.darkImage};
  border-radius: ${theme.spacing.xs};
  cursor: pointer;
  transition: all 0.2s ease;

  :hover {
    background-color: ${theme.colors.inputFillFocus};
  }

  @media ${theme.devices.tablet} {
    margin-bottom: 0;
    width: 225px;
    margin-right: ${theme.spacing.s};
  }
`
