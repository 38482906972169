import { makePublicUrl } from 'app/utils/publicUrl'

export const CAMERA_RECORDINGS_REDUCER_NAME = 'cameraRecordings'
export const CAMERAS_LIST_REDUCER_NAME = 'camerasList'
export const CLIPS_LIST_REDUCER_NAME = 'clipsList'
export const CLIPS_TO_RENDER_LIST_REDUCER_NAME = 'clipsToRenderList'
export const CAMERA_CLIPS_LIST_REDUCER_NAME = 'cameraClipsList'
export const CAMERA_CLIP_REDUCER_NAME = 'cameraClip'
export const CLIPS_FILTERS_REDUCER_NAME = 'clipsFilters'

export const CAM_MOTION_ICON = makePublicUrl(
  'images/cameraRecordings/cam_motion.svg'
)
export const CAM_NOISE_ICON = makePublicUrl(
  'images/cameraRecordings/cam_noise.svg'
)
export const CAM_DOORBELL_ICON = makePublicUrl(
  'images/cameraRecordings/cam_doorbell.svg'
)
export const CAM_TAMPER_ICON = makePublicUrl(
  'images/cameraRecordings/cam_tamper.svg'
)
export const CAM_LIVE_ICON = makePublicUrl(
  'images/cameraRecordings/cam_live.svg'
)
export const CAM_DEFAULT_ICON = makePublicUrl(
  'images/cameraRecordings/cam_default.svg'
)
