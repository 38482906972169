import React from 'react'
import { Formik } from 'formik'
import { useIntl } from 'react-intl'
import * as Yup from 'yup'

import { StyledAuthForm } from 'features/auth/login/styles'
import { resetPasswordMessages } from './messages'
import { StyledDescriptionText } from './styles'
import { PassResetConfirmParams } from '../types'
import CommonButton from 'components/Button/CommonButton'
import { SubmitLoginWrapper } from '../../LoginForm/styles'
import PasswordField from 'components/Fields/PasswordField/PasswordField'
import { fieldsMessages } from 'components/Fields/messages'
import LoaderOverlay from 'components/Loader/LoaderOverlay'

// At least 1 number, 1 lowercase and 1 uppercase letter
const passwordRegex = /^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*)$/

interface ResetPasswordFormParams {
  onSubmit: (params: PassResetConfirmParams) => void
  isLoading: boolean
}

const initialConfirmPassValues: PassResetConfirmParams = {
  newPassword: '',
  passwordResetToken: '',
}

const ResetPasswordForm: React.FC<ResetPasswordFormParams> = ({
  onSubmit,
  isLoading,
}) => {
  const { formatMessage } = useIntl()
  const handleSubmit = (values: PassResetConfirmParams) => {
    onSubmit(values)
  }

  const Schema = Yup.object().shape({
    password: Yup.string()
      .required(formatMessage(fieldsMessages.requiredField))
      .min(8, formatMessage(resetPasswordMessages.shortPassword))
      .matches(passwordRegex, formatMessage(resetPasswordMessages.validation)),
    newPassword: Yup.string().when('password', {
      is: (val: string) => !!(val && val.length > 0),
      then: Yup.string().oneOf(
        [Yup.ref('password')],
        formatMessage(resetPasswordMessages.passwordsDoNotMatch)
      ),
    }),
  })

  return (
    <Formik
      initialValues={initialConfirmPassValues}
      validationSchema={Schema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <StyledAuthForm>
          <StyledDescriptionText>
            {formatMessage(resetPasswordMessages.description)}
          </StyledDescriptionText>

          <PasswordField name="password" />

          <PasswordField
            placeholder={formatMessage(resetPasswordMessages.confirmPassword)}
            name="newPassword"
          />
          {formikProps.isSubmitting && <LoaderOverlay />}
          <SubmitLoginWrapper>
            <CommonButton
              type="submit"
              variant="goldenFilled"
              disabled={
                !formikProps.isValid ||
                formikProps.isSubmitting ||
                !formikProps.values.password ||
                !formikProps.values.newPassword
              }
            >
              {formatMessage(resetPasswordMessages.submitButton)}
            </CommonButton>
          </SubmitLoginWrapper>
        </StyledAuthForm>
      )}
    </Formik>
  )
}

export default ResetPasswordForm
