import { defineMessages } from 'react-intl'

export const FooterMessages = defineMessages({
  copyright: {
    id: 'footer.copyright',
    description: 'Copyright information in footer',
    defaultMessage: '© {currentYear} Savant Systems, Inc.',
  },
  loginCopyright: {
    id: 'footer.loginCopyright',
    description: 'Copyright information in footer of "login" pages',
    defaultMessage: `1997-{currentYear} © Savant Technologies LLC. Google Play and the Google Play
    logo are trademarks of Google Inc. Apple and the Apple logo are
    trademarks of Apple Inc., registered in the U.S. and other countries.
    App Store is a service mark of Apple Inc. The Bluetooth work mark is a
    registered trademark owned by Bluetooth SIG, Inc., and any use of such
    mark by GE is under license. Reveal ® is a trademark of Savant
    Technologies LLC. The GE monogram and C by GE are trademarks of
    General Electric Company used under license. GE Lighting, A Savant
    Company products are only intended for use within the continental
    United States, Alaska, Hawaii and Canada.`,
  },
  privacyPolicy: {
    id: 'footer.privacyPolicy',
    description: 'Link text to "Privacy Policy" ',
    defaultMessage: 'Privacy Policy',
  },
  termsOfService: {
    id: 'footer.termsOfService',
    description: 'Link text to "Terms of Service" ',
    defaultMessage: 'Terms of Service',
  },
})
