import React from 'react'
import { makePublicUrl } from 'app/utils/publicUrl'
import {
  StyledBackgroundOverlay,
  StyledBGImage,
  StyledBGImageWrapper,
} from './styles'

const LoginBgImage: React.FC = () => {
  return (
    <>
      <StyledBGImageWrapper>
        <StyledBGImage
          src={makePublicUrl('images/loginBgImage.jpg')}
          alt="Login page background image"
        />
        <StyledBackgroundOverlay />
      </StyledBGImageWrapper>
    </>
  )
}

export default LoginBgImage
