import { fetchEdgeDataThunk, makeFetchSlice } from 'app/redux-fetch'
import { AppDispatch } from 'app/store'

import {
  getUserInfo,
  updateUser,
  updateUserPassword,
  enableTwoFa,
  confirmTwoFa,
  disableTwoFa,
} from './api'
import {
  getAuthorizedUser,
  storeAuthorizedUser,
  getClientId,
} from 'app/services/LocalStorage'
import { ChangePasswordFormValues, MyAccountFormValues } from './types'
import { updateAuthStatus } from '../login/slice'

const activeUserInfoSlice = makeFetchSlice('activeUserInfo')
export const activeUserInfoReducer = activeUserInfoSlice.reducer
export const activeUserInfoActions = activeUserInfoSlice.actions

export const getActiveUserInfoThunk = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const storedUser = getAuthorizedUser()

      if (storedUser) {
        const payload = await fetchEdgeDataThunk({
          dispatch,
          actions: activeUserInfoActions,
          asyncFunc: () => getUserInfo(storedUser.userId),
        })
        await storeAuthorizedUser({ ...storedUser, ...payload })
        await dispatch(updateAuthStatus())
        return payload
      }
    } catch (err) {
      console.log('Error while getting active user info attempt:' + err)
      dispatch(activeUserInfoActions.failure(err))
    }
  }
}

const requestUserUpdateSlice = makeFetchSlice('requestUserUpdate')
export const requestUserUpdateReducer = requestUserUpdateSlice.reducer
export const requestUserUpdateActions = requestUserUpdateSlice.actions

export const requestUserUpdateThunk = (params: MyAccountFormValues) => {
  return async (dispatch: AppDispatch) => {
    try {
      const storedUser = getAuthorizedUser()

      if (storedUser) {
        const payload = await fetchEdgeDataThunk({
          dispatch,
          actions: requestUserUpdateActions,
          asyncFunc: () => updateUser(storedUser.userId, params),
        })
        await dispatch(getActiveUserInfoThunk())
        return payload
      }
    } catch (err) {
      console.log('Error while updating user attempt:' + err)
      dispatch(requestUserUpdateActions.failure(err))
    }
  }
}

const changePasswordSlice = makeFetchSlice('changePassword')
export const changePasswordReducer = changePasswordSlice.reducer
export const changePasswordActions = changePasswordSlice.actions

export const changePasswordThunk = (params: ChangePasswordFormValues) => {
  return async (dispatch: AppDispatch) => {
    try {
      const storedUser = getAuthorizedUser()

      if (storedUser) {
        const payload = await fetchEdgeDataThunk({
          dispatch,
          actions: changePasswordActions,
          asyncFunc: () => updateUserPassword(storedUser.userId, params),
        })
        await dispatch(getActiveUserInfoThunk())
        return payload
      }
    } catch (err) {
      console.log('Error while changing user password attempt:' + err)
      dispatch(changePasswordActions.failure(err))
    }
  }
}

const twoFaSlice = makeFetchSlice('twoFa')
export const twoFaReducer = twoFaSlice.reducer
export const twoFaActions = twoFaSlice.actions

interface TwoFaActionParams {
  onSuccess: (payload: any) => void
  onError: (err: any) => void
}

export const enableTwoFaThunk = ({ onSuccess, onError }: TwoFaActionParams) => {
  return async (dispatch: AppDispatch) => {
    try {
      const storedUser = getAuthorizedUser()
      const clientId = getClientId()

      if (storedUser && clientId) {
        const payload = await fetchEdgeDataThunk({
          dispatch,
          actions: activeUserInfoActions,
          asyncFunc: () => enableTwoFa(storedUser.userId, clientId),
        })
        onSuccess(payload)
        return payload
      }
    } catch (err) {
      console.log('Error while enabling 2fa:' + err)
      dispatch(twoFaActions.failure(err))
      onError(err)
    }
  }
}

export const disableTwoFaThunk = ({
  onSuccess,
  onError,
}: TwoFaActionParams) => {
  return async (dispatch: AppDispatch) => {
    try {
      const storedUser = getAuthorizedUser()
      const clientId = getClientId()

      if (storedUser && clientId) {
        const payload = await fetchEdgeDataThunk({
          dispatch,
          actions: activeUserInfoActions,
          asyncFunc: () => disableTwoFa(storedUser.userId, clientId),
        })
        onSuccess(payload)
        return payload
      }
    } catch (err) {
      console.log('Error while disabling 2fa:' + err)
      dispatch(twoFaActions.failure(err))
      onError(err)
    }
  }
}

export const confirmTwoFaThunk = (
  code: string,
  { onSuccess, onError }: TwoFaActionParams
) => {
  return async (dispatch: AppDispatch) => {
    try {
      const storedUser = getAuthorizedUser()
      const clientId = getClientId()

      if (storedUser && clientId) {
        const payload = await fetchEdgeDataThunk({
          dispatch,
          actions: activeUserInfoActions,
          asyncFunc: () => confirmTwoFa(storedUser.userId, clientId, code),
        })
        onSuccess(payload)
        return payload
      }
    } catch (err) {
      console.log('Error while confirming 2fa:' + err)
      dispatch(twoFaActions.failure(err))
      onError(err)
    }
  }
}
