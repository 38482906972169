import React, { memo } from 'react'
import ReactDOM from 'react-dom'
import Spinner from './Spinner'
import { StyledLoaderOverlay, StyledCompanyImage } from './styles'

interface LoaderOverlayProps {
  isFullScreen?: boolean
  backgroundColor?: string
}

const LoaderOverlay: React.FC<LoaderOverlayProps> = (props) => {
  if (props.isFullScreen) {
    const container = document.querySelector('#root')
    if (!container) return null

    return ReactDOM.createPortal(
      <StyledLoaderOverlay
        isFullScreen={props.isFullScreen}
        backgroundColor={props.backgroundColor}
      >
        <StyledCompanyImage src="/images/s-logo.svg" alt="Savant logo" />
      </StyledLoaderOverlay>,
      container
    )
  }

  return (
    <StyledLoaderOverlay
      isFullScreen={props.isFullScreen}
      backgroundColor={props.backgroundColor}
    >
      <Spinner />
    </StyledLoaderOverlay>
  )
}

export default memo(LoaderOverlay)
