import { Action } from '@reduxjs/toolkit'
import { AppDispatch } from 'app/store'
import { prop } from 'lodash/fp'
import axios from 'axios'

export * from './selectors'
export * from './consts'
export * from './slice'
export * from './types'

const dataTransformer = prop('data')
const defaultServerErrors: FetchErrors = [{ detail: 'Internal server error' }]

interface FetchDataParams {
  dispatch: AppDispatch
  actions: FetchActions
  asyncFunc: () => void
  transformResponse?: (res: any) => any
  suppressErrors?: boolean
}

interface FetchActions {
  init: () => Action
  succeed: (res: any) => Action
  failure: (err: any) => Action
}

interface ApiError {
  detail: string
  code?: string
}

type FetchErrors = ApiError[]

export async function fetchDataThunk({
  dispatch,
  actions,
  asyncFunc,
  transformResponse = dataTransformer,
  suppressErrors,
}: FetchDataParams) {
  try {
    dispatch(actions.init())
    const response = await asyncFunc()
    const transformedResponse = transformResponse(response)

    dispatch(actions.succeed(transformedResponse))

    return transformedResponse
  } catch (err: unknown) {
    let errors: FetchErrors = defaultServerErrors

    if (axios.isAxiosError(err)) {
      const validationErrorMessage = err?.response?.data?.errorMessage
      const validationErrors = validationErrorMessage && [
        { detail: validationErrorMessage },
      ]

      const apiErrors = err?.response?.data?.errors
      errors = validationErrors ?? apiErrors
    }

    dispatch(actions.failure(errors))

    if (!suppressErrors) {
      throw err
    }
  }
}

export async function fetchHomePortalDataThunk(params: FetchDataParams) {
  return fetchDataThunk({
    ...params,
    transformResponse: (props) => {
      const { data, error } = prop('data', props)
      if (error) throw error
      return data
    },
  })
}

export async function fetchEdgeDataThunk(params: FetchDataParams) {
  return fetchDataThunk({
    ...params,
    transformResponse: (props) => {
      const { payload, error } = prop('data', props)
      if (error) throw error
      return payload
    },
  })
}
