import { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { mdiAlertCircleOutline } from '@mdi/js'

import Modal from 'components/Modal'
import { H2 } from 'components/Typography'
import { CommonButton } from 'components/Button'

import { purchaseMessages } from '../messages'
import { InnerContainer, StyledFailIcon } from './styles'
import { ModalText, ModalTitle } from 'components/Modal/styles'

interface ModalProps {
  isOpen: boolean
  onClose: () => void
  msg: string | undefined
  btnText?: string | undefined
}

const PaymentFailModal: FC<ModalProps> = ({
  isOpen,
  onClose,
  msg,
  btnText,
}) => (
  <Modal {...{ isOpen }}>
    <InnerContainer>
      <StyledFailIcon path={mdiAlertCircleOutline} size={2.5} />
      <ModalTitle>
        <H2>
          <FormattedMessage {...purchaseMessages.paymentErrorTitle} />
        </H2>
      </ModalTitle>
      <ModalText>
        {msg ? (
          msg
        ) : (
          <FormattedMessage {...purchaseMessages.paymentErrorText} />
        )}
      </ModalText>
      <CommonButton variant="goldenOutlined" onClick={onClose}>
        {btnText ? (
          btnText
        ) : (
          <FormattedMessage {...purchaseMessages.paymentErrorButton} />
        )}
      </CommonButton>
    </InnerContainer>
  </Modal>
)

export default PaymentFailModal
