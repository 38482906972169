import React, { memo } from 'react'
import { StyledLinkImg } from './styles'

interface IHomeSelectorLinkImg {
  src: string
}

const HomeSelectorLinkImg: React.FC<IHomeSelectorLinkImg> = (props) => {
  return <StyledLinkImg src={props.src} alt="Home small image" />
}

export default memo(HomeSelectorLinkImg)
