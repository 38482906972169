import styled from 'styled-components'

import { Regular } from 'components/Typography'
import theme from 'theme_'

export const StyledDescriptionText = styled(Regular)`
  margin-bottom: ${theme.spacing.md};
  text-align: center;
`

export const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${(p) => p.theme.spacing.md};

  & > button:first-child {
    margin-right: 32px;
  }

  @media only screen and (max-width: ${theme.breakpoint.desktop}) {
    & > button:first-child {
      margin-right: 16px;
    }
  }
`
