import styled from 'styled-components'
import theme from 'theme_'

export const StyledHomeSelectorLink = styled.button<{ isActive?: boolean }>`
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 88px;
  padding: ${theme.spacing.base};
  color: ${theme.colors.beachGrey};
  text-align: left;
  border: none;
  background-color: ${(p) =>
    p.isActive ? `${theme.overlay.inputFillFocus}` : 'transparent'};
  cursor: pointer;
  transition: background-color 0.2s ease;

  :hover {
    background-color: ${theme.overlay.menuHover};
  }
`

export const HomeWrapper = styled.div`
  display: flex;
  align-items: center;
`
