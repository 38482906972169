import { FC } from 'react'
import { mdiKeyOutline, mdiHomeOutline, mdiLockOutline } from '@mdi/js'
import { FormattedMessage } from 'react-intl'

import { authMessages } from 'features/auth/messages'
import { MdiIcon } from 'components/Icons'
import { UserRole } from 'features/auth/consts'

import Role from './Role'
import { Container } from './styles'

interface RolesProps {
  value: UserRole
  onChange: (val: UserRole) => void
}

const Roles: FC<RolesProps> = ({ value, onChange }) => (
  <Container>
    <Role
      title={<FormattedMessage {...authMessages.userRoleAdmin} />}
      icon={<MdiIcon size="52px" path={mdiKeyOutline} />}
      onSelect={() => onChange(UserRole.ADMIN)}
      isSelected={value === UserRole.ADMIN}
    />
    <Role
      title={<FormattedMessage {...authMessages.userRoleHousehold} />}
      icon={<MdiIcon size="52px" path={mdiHomeOutline} />}
      onSelect={() => {
        onChange(UserRole.HOUSEHOLD)
      }}
      isSelected={value === UserRole.HOUSEHOLD}
    />
    <Role
      title={<FormattedMessage {...authMessages.userRoleGuest} />}
      icon={<MdiIcon size="52px" path={mdiLockOutline} />}
      onSelect={() => onChange(UserRole.GUEST)}
      isSelected={value === UserRole.GUEST}
    />
  </Container>
)

export default Roles
