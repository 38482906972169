import { FC, MouseEvent, ReactElement, ReactNode } from 'react'
import Modal from '../index'

import { H2, Regular } from 'components/Typography'
import { CommonButton } from 'components/Button'

import {
  SuccessModalContainer,
  SuccessModalTitle,
  SuccessModalFooter,
  SuccessModalContent,
} from './styles'

interface SuccessModalProps {
  isOpen: boolean
  icon: ReactElement
  title: ReactNode
  content?: ReactNode
  confirmBtnTitle: ReactNode
  onConfirm: (ev: MouseEvent<HTMLElement>) => void
}

const SuccessModal: FC<SuccessModalProps> = ({
  isOpen,
  icon,
  title,
  confirmBtnTitle,
  content = null,
  onConfirm,
}) => {
  return (
    <Modal isOpen={isOpen}>
      <SuccessModalContainer>
        {icon}

        <SuccessModalTitle>
          <H2>{title}</H2>
        </SuccessModalTitle>

        <SuccessModalContent>
          <Regular>{content}</Regular>
        </SuccessModalContent>

        <SuccessModalFooter>
          <CommonButton variant="goldenOutlined" onClick={onConfirm}>
            {confirmBtnTitle}
          </CommonButton>
        </SuccessModalFooter>
      </SuccessModalContainer>
    </Modal>
  )
}

export default SuccessModal
