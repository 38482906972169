import styled from 'styled-components'
import theme from 'theme_'
import { BluredBlock } from 'components/Layout'
import Icon from '@mdi/react'
import { CommonButton } from 'components/Button'
import { TextField } from 'components/Fields'

export const EnrollContainer = styled(BluredBlock)`
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const EnrollIcon = styled(Icon)`
  color: ${theme.colors.mutedGray};
  width: ${theme.spacing.xxl};
  margin-bottom: ${theme.spacing.md};
`

export const EnrollText = styled.div`
  line-height: 22px;
  font-size: 16px;
  color: ${theme.colors.beachGrey};
  margin-bottom: ${theme.spacing.lg};
`

export const EnrollButton = styled(CommonButton)`
  width: fit-content;
`

export const ModalWrapper = styled.div`
  display: flex;
  gap: 32px;
  flex-direction: column;
  align-items: center;
`

export const FormWrapper = styled.div`
  width: 90%;

  @media ${theme.devices.tablet} {
    width: 80%;
  }
  @media ${theme.devices.mediumDesktop} {
    width: 70%;
  }

  display: flex;
  gap: 32px;
  flex-direction: column;
`

export const ButtonsFooter = styled.div`
  margin-top: 32px;
  gap: 38px;
  display: flex;
  justify-content: center;
`

export const FieldContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 28px;

  > div {
    flex-grow: 1;
  }

  flex-direction: column;
  @media ${theme.devices.tablet} {
    flex-direction: row;
  }
`

export const Label = styled.label`
  display: flex;
  flex-grow: unset !important;
  align-items: center;
  white-space: nowrap;
  justify-content: space-between;
  color: ${theme.colors.beachGrey};
`

export const TextArea = styled(TextField)`
  line-height: 3em;
`

export const HomeImage = styled.div<{ image: string }>`
  transform: scale(1.1)
  filter: blur(2.5px);
  background-image: linear-gradient(
      to bottom,
      rgba(39, 37, 35, .3) 0%,
      rgba(39, 37, 35, 1) 100%
    ),
    url(${(p) => p.image});
  width: 100%;
  height: 120px;
  background-size: cover;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`
