import styled from 'styled-components'
import theme from 'theme_'

import { BluredBlock } from 'components/Layout'

export const StyledAsideMenu = styled(BluredBlock).attrs({ as: 'nav' })`
  padding: 0;

  > a:last-child {
    border-radius: 0 0 ${theme.spacing.xs} ${theme.spacing.xs};
  }
`
