import React from 'react'
import { useIntl } from 'react-intl'
import { findDealerMessages } from './messages'
import CommonButton from 'components/Button/CommonButton'
import { isSubscriptionHidden } from 'app/utils/isSubscriptionHidden'

import DealerBlock from '../DealerBlock'
import ShareCodeBlock from '../ShareCodeBlock'
import {
  StyledDealerRow,
  StyledButtonWrapper,
  CodeBlockContainer,
} from './styles'

const FindDealer: React.FC<{ fullWidth?: boolean }> = ({ fullWidth }) => {
  const { formatMessage } = useIntl()

  return (
    <DealerBlock
      fullWidth={fullWidth}
      title={!fullWidth && formatMessage(findDealerMessages.title)}
    >
      {!isSubscriptionHidden && (
        <CodeBlockContainer>
          <ShareCodeBlock />
        </CodeBlockContainer>
      )}

      <StyledDealerRow fullWidth={fullWidth}>
        <p>{formatMessage(findDealerMessages.extendedText)}</p>
      </StyledDealerRow>
      <StyledDealerRow fullWidth={fullWidth}>
        <StyledButtonWrapper>
          <CommonButton
            variant="goldenOutlined"
            type="button"
            as="a"
            href="https://www.savant.com/find-a-dealer"
            target="_blank"
          >
            {formatMessage(findDealerMessages.findDealer)}
          </CommonButton>
        </StyledButtonWrapper>
      </StyledDealerRow>
    </DealerBlock>
  )
}
export default FindDealer
