import { authorizedEdgeFetch } from '../../app/axios/edge'

export const getPartners = (homeId: string) =>
  authorizedEdgeFetch.get(`homes/${homeId}/partners`)

export const getPartnersInbound = (homeId: string) =>
  authorizedEdgeFetch.get(`homes/${homeId}/partners/inbound`)

export interface DeletePartnerParams {
  homeId: string
  partner: string
}

export const deletePartner = ({ homeId, partner }: DeletePartnerParams) =>
  authorizedEdgeFetch.delete(`homes/${homeId}/partners/${partner}`)

export interface EnrollPartnerParams {
  homeId: string
  partner: string
  pairingCode: string
}

export const enrollPartner = ({
  homeId,
  partner,
  pairingCode,
}: EnrollPartnerParams) =>
  authorizedEdgeFetch.post(`homes/${homeId}/partners/${partner}/enroll`, {
    pairingcode: pairingCode,
  })
