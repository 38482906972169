import { FC, ReactNode } from 'react'
import { mdiCalendarBlank } from '@mdi/js'

import { Secondary } from 'components/Typography'
import { MdiIcon } from 'components/Icons'

import { DateRowContainer, DateRowDate } from './styles'
import IntlFormattedDate from 'components/i18n/IntlFormattedDate'

interface DateRowProps {
  label: ReactNode
  date: number
}

const DateRow: FC<DateRowProps> = ({ label, date }) => (
  <DateRowContainer>
    <Secondary>{label}</Secondary>
    <DateRowDate>
      <MdiIcon path={mdiCalendarBlank} />
      <Secondary>
        <IntlFormattedDate date={date} />
      </Secondary>
    </DateRowDate>
  </DateRowContainer>
)

export default DateRow
