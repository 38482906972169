import { useSelector } from 'react-redux'

import { LoaderOverlay } from 'components/Loader'
import {
  dealerSelector,
  uberSelectors,
  dealerRequestSelector,
} from 'features/homes/selectors'
import FindDealer from './FindDealer'
import CurrentDealer from './CurrentDealer'
import DealerRequest from './DealerRequest/DealerRequest'

const MyDealer: React.FC<{ fullWidth?: boolean }> = ({ fullWidth }) => {
  const dealer = useSelector(dealerSelector)
  const isDealerLoading = useSelector(uberSelectors.isFetching)
  const dealerRequest = useSelector(dealerRequestSelector)

  if (isDealerLoading) {
    return <LoaderOverlay isFullScreen />
  }

  if (dealerRequest) {
    return <DealerRequest dealerRequest={dealerRequest} fullWidth={fullWidth} />
  }

  return (
    <>
      {dealer ? (
        <CurrentDealer fullWidth={fullWidth} dealerInfo={dealer.dealerInfo} />
      ) : (
        <FindDealer fullWidth={fullWidth} />
      )}
    </>
  )
}

export default MyDealer
