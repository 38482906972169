import React from 'react'
import { useIntl } from 'react-intl'
import { FormattedMessage } from 'react-intl'

import { FooterMessages } from './messages'
import { FooterLinksContainer, FooterWrapper, StyledFooterLink } from './styles'
import { Secondary } from 'components/Typography'
import { TERMS_LINKS } from 'app/consts'

const currentYear = new Date().getFullYear()

const Footer: React.FC = () => {
  const { formatMessage } = useIntl()

  return (
    <FooterWrapper>
      <Secondary>
        <FormattedMessage
          {...FooterMessages.copyright}
          values={{ currentYear }}
        />
      </Secondary>
      <FooterLinksContainer>
        <StyledFooterLink
          as="a"
          target="_blank"
          href={TERMS_LINKS.termsAndConditions}
        >
          {formatMessage(FooterMessages.termsOfService)}
        </StyledFooterLink>
        <StyledFooterLink
          as="a"
          target="_blank"
          href={TERMS_LINKS.privacyPolicy}
        >
          {formatMessage(FooterMessages.privacyPolicy)}
        </StyledFooterLink>
      </FooterLinksContainer>
    </FooterWrapper>
  )
}

export default Footer
