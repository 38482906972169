import { authorizedEdgeFetch } from 'app/axios/edge'
import { ChangePasswordFormValues, MyAccountFormValues } from './types'

export const getUserInfo = (userId: string) =>
  authorizedEdgeFetch.get(`/users/${userId}`)

export const updateUser = (userId: string, accountInfo: MyAccountFormValues) =>
  authorizedEdgeFetch.put(`/users/${userId}`, accountInfo)

export const updateUserPassword = (
  userId: string,
  userPasswords: ChangePasswordFormValues
) => authorizedEdgeFetch.put(`/users/${userId}/password`, userPasswords)

export const enableTwoFa = (userId: string, clientId: string) =>
  authorizedEdgeFetch.put(`/users/${userId}/2fa/enable`, {
    twoFactor: [
      {
        type: 'EMAIL_CODE',
      },
    ],
    clientId,
  })

export const disableTwoFa = (userId: string, clientId: string) =>
  authorizedEdgeFetch.put(`/users/${userId}/2fa/disable`, {
    twoFactor: [
      {
        type: 'EMAIL_CODE',
      },
    ],
    clientId,
  })

export const confirmTwoFa = (userId: string, clientId: string, code: string) =>
  authorizedEdgeFetch.put(`/users/${userId}/2fa/confirm`, {
    twoFactor: [
      {
        type: 'EMAIL_CODE',
        code,
      },
    ],
    clientId,
  })
