import { makePublicUrl } from './utils/publicUrl'

export const LANGUAGE_KEY = 'sav-language'

export enum COUNTRY_CODES {
  USA = 'US',
  Canada = 'CA',
}

export const NODE_ENV = process.env.NODE_ENV
/** One of dev, alpha, beta, prod */
export const CLOUD_ENV = process.env.REACT_APP_CLOUD_CONFIG || 'prod'
export const PUBLIC_URL = process.env.PUBLIC_URL
export const EDGE_API_URL = process.env.REACT_APP_EDGE_API_URL
export const BILLING_API_URL = process.env.REACT_APP_BILLING_API_URL
export const STRIPE_PK = process.env.REACT_APP_STRIPE_PK
export const SCS_AGENT = process.env.REACT_APP_SCS_AGENT || ''
export const HIDE_SUBSCRIPTIONS = process.env.REACT_APP_HIDE_SUBSCRIPTIONS
export const SALESFORCE_SUPPORT = JSON.parse(
  process.env.REACT_APP_SALESFORCE_SUPPORT || '{}'
)

export const LOGIN_HEADER_HEIGHT = 72

export const HOMES_REDUCER_NAME = 'homes'
export const HOME_ADMIN_REDUCER_NAME = 'admin'
export const HOME_EDGE_ADMIN_REDUCER_NAME = 'edgeAdmin'

export const TERMS_LINKS = {
  termsAndConditions: 'https://www.savant.com/terms-and-conditions',
  privacyPolicy: 'https://www.savant.com/privacy-policy',
}

export const GET_HELP_LINKS = {
  getHelp: 'https://www.savant.com/support',
  // TODO: update when PO will provide proper number
  call: 'tel:+1234567890',
  contactUs: 'https://www.savant.com/contact',
  videoTutorials: 'https://www.youtube.com/user/SavantAV/videos',
  searchArticles: 'https://support.savant.com/pro',
  feedbackForm: 'https://www.savant.com/support/savant-pro',
}

export const DEFAULT_PAGE_TITLE = 'Savant Home Manager'

export enum CAMERA_MANUFACTURERS {
  ring = 'ring',
  '2n' = '2n',
}

export const hostTypes = {
  Mac: 'Pro',
  'Mac-Super': 'Super Pro',
  Linux: 'Linux',
  'Linux-ARM-Ace': 'Smart',
  'Linux-ARM-SimplePro': 'Simple',
  'Linux-ARM-Clapton': 'IP Audio Studio Soundbar',
  'Linux-ARM-38Special': 'IP Audio 1',
  'Linux-ARM-Marley': 'Smart Audio Soundbar',
  'Linux-ARM-SHR': 'Smart',
}

export const ESSENTIALS_ICON = makePublicUrl(
  'images/subscriptions/essentials.svg'
)
