import { FC, useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { mdiLock, mdiEmailOutline, mdiHome, mdiKey } from '@mdi/js'
import { useSelector } from 'react-redux'
import { useHistory, generatePath } from 'react-router-dom'

import routes from 'app/routes'
import theme from 'theme_'
import formatUserFullName from 'app/utils/formatUserFullName'
import { Avatar, MdiIcon } from 'components/Icons'
import { BodyXL, Regular } from 'components/Typography'
import { UserShape } from 'app/types/user'
import { getUserRole, getUserRoleLabel } from 'features/auth/utils'
import { UserRole } from 'features/auth/consts'
import { currUserSelector } from 'features/auth/login/selectors'
import { commonMessages } from 'app/i18n/messages'

import {
  UserContainer,
  Actions,
  Role,
  RoleIconContainer,
  Details,
  ImgContainer,
  ProfileImg,
  FullNameRow,
  EmailRow,
  EmailIconContainer,
  EmailRowText,
} from './styles'
import IconLink from 'components/Button/IconLink'

interface UserProps {
  homeId: string
  item: UserShape
  onDelete: () => void
}

const USER_ROLE_ICON = {
  [UserRole.ADMIN]: mdiKey,
  [UserRole.HOUSEHOLD]: mdiHome,
  [UserRole.GUEST]: mdiLock,
  [UserRole.CUSTOM]: mdiLock,
}

const User: FC<UserProps> = ({ homeId, item }) => {
  const role = useMemo(() => getUserRole(item.permissions), [item])
  const roleMsg = useMemo(() => getUserRoleLabel(item), [item])
  const roleIcon = role && USER_ROLE_ICON[role]
  const currentUser = useSelector(currUserSelector)
  const isCurrentUser = currentUser.id === item.id
  const { formatMessage } = useIntl()
  const history = useHistory()

  const handleGoToDetails = useCallback(() => {
    history.push(generatePath(routes.userDetails, { homeId, userId: item.id }))
  }, [history, homeId, item.id])

  return (
    <UserContainer
      onClick={isCurrentUser ? () => void 0 : handleGoToDetails}
      isCurrentUser={isCurrentUser}
    >
      <Actions>
        <Role>
          <RoleIconContainer>
            {roleIcon && (
              <MdiIcon
                path={roleIcon}
                color={
                  isCurrentUser
                    ? theme.colors.mutedGray
                    : theme.colors.beachGrey
                }
              />
            )}
          </RoleIconContainer>
          <Regular
            color={
              isCurrentUser ? theme.colors.mutedGray : theme.colors.beachGrey
            }
          >
            {roleMsg &&
              `${formatMessage(roleMsg)}${
                isCurrentUser ? ` (${formatMessage(commonMessages.you)})` : ''
              }`}
          </Regular>
        </Role>

        {!isCurrentUser && <IconLink />}
      </Actions>
      <Details>
        <ImgContainer>
          {item.profilePicUrl ? (
            <ProfileImg src={item.profilePicUrl} alt="Profile picture" />
          ) : (
            <Avatar />
          )}
        </ImgContainer>

        <FullNameRow>
          <BodyXL>{formatUserFullName(item)}</BodyXL>
        </FullNameRow>

        <EmailRow>
          <EmailIconContainer>
            <MdiIcon path={mdiEmailOutline} />
          </EmailIconContainer>

          <EmailRowText title={item.email}>{item.email}</EmailRowText>
        </EmailRow>
      </Details>
    </UserContainer>
  )
}

export default User
