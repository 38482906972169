import { useSelector } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'

import { LoaderOverlay } from 'components/Loader'
import {
  dealerSelector,
  uberSelectors,
  dealerRequestSelector,
} from 'features/homes/selectors'
import FindDealer from '../FindDealer'
import DealerRequest from '../DealerRequest/DealerRequest'
import DealerBlock from '../DealerBlock'
import DealerCard from '../DealerCard'
import { contactDealerMessages } from './messages'
import { StyledDescription } from './styles'

const MyDealer: React.FC<{ fullWidth?: boolean }> = ({ fullWidth }) => {
  const { formatMessage } = useIntl()
  const dealer = useSelector(dealerSelector)
  const isDealerLoading = useSelector(uberSelectors.isFetching)
  const dealerRequest = useSelector(dealerRequestSelector)

  if (isDealerLoading) {
    return <LoaderOverlay isFullScreen />
  }

  if (dealerRequest) {
    return <DealerRequest dealerRequest={dealerRequest} fullWidth={fullWidth} />
  }

  return (
    <>
      {dealer ? (
        <DealerBlock
          fullWidth={fullWidth}
          title={formatMessage(contactDealerMessages.title)}
        >
          <StyledDescription>
            <FormattedMessage {...contactDealerMessages.description} />
          </StyledDescription>

          <DealerCard dealerInfo={dealer.dealerInfo} fullWidth={fullWidth} />
        </DealerBlock>
      ) : (
        <FindDealer fullWidth={fullWidth} />
      )}
    </>
  )
}

export default MyDealer
