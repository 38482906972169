import { FC, useCallback, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { mdiDelete, mdiHelpCircleOutline } from '@mdi/js'
import Icon from '@mdi/react'

import { Secondary } from 'components/Typography'
import { makePublicUrl } from 'app/utils/publicUrl'
import { PaymentMethod } from 'features/payment/types'
import { MdiIcon } from 'components/Icons'
import ConfirmModal from 'components/Modal/ConfirmModal'
import { useAppDispatch, useAppSelector } from 'app/hooks'

import {
  Container,
  PaymentSystem,
  DeleteBtn,
  Number,
  DateRow,
  OuterContainer,
  CardLabelBase as CardLabel,
} from './styles'
import {
  detachPaymentMethodThunk,
  setDefaultPaymentMethodThunk,
} from 'features/payment/slice'

import { ModalText } from 'components/Modal/styles'
import theme from 'theme_'
import { messages } from 'features/checkout/PaymentSelection/PaymentMethods/messages'
import { AuthorizedUser } from 'app/types'
import { currUserSelector } from 'features/auth/login/selectors'
import { isAdminSelector } from 'features/homes/selectors'
import { isExpiredCard } from 'app/utils/checkExpiration'
import { activeSubscriptionSelector } from 'features/homes/Subscriptions/selectors'

interface CardProps {
  isSelected?: boolean
  isSingleMethod?: boolean
  paymentMethod: PaymentMethod
  onSelect: (id: string) => void
}

const Card: FC<CardProps> = ({
  paymentMethod: { id, card, isDefault },
  onSelect,
  isSelected = false,
  isSingleMethod = false,
}) => {
  const dispatch = useAppDispatch()
  const { formatMessage } = useIntl()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [detachAction, setDetachAction] = useState(false)

  const user: AuthorizedUser = useSelector(currUserSelector)
  const activeSubscription = useSelector(activeSubscriptionSelector)
  const isAdmin = useAppSelector(isAdminSelector)

  const detachPaymentMethod = useCallback(() => {
    dispatch(detachPaymentMethodThunk(id, user.id))
  }, [dispatch, id, user.id])

  const setDefaultPaymentMethod = useCallback(() => {
    dispatch(setDefaultPaymentMethodThunk(id, user.id))
  }, [dispatch, id, user.id])

  const openModal = (detachAction: boolean) => {
    setDetachAction(detachAction)
    setIsModalOpen(true)
  }
  const closeModal = useCallback(() => {
    setIsModalOpen(false)
  }, [setIsModalOpen])

  const performAction = useCallback(() => {
    detachAction ? detachPaymentMethod() : setDefaultPaymentMethod()
    closeModal()
  }, [detachPaymentMethod, closeModal, setDefaultPaymentMethod, detachAction])

  const formatDetachCardModalText = useCallback(() => {
    if (!isDefault) {
      return formatMessage(messages.removeText)
    }

    if (!isSingleMethod) {
      return formatMessage(messages.removeDefaultCardText)
    }

    if (!isAdmin) {
      return formatMessage(messages.removeDefaultCardByNotAdminText)
    }

    return activeSubscription
      ? formatMessage(messages.removeDefaultCardWithSubscriptionText)
      : formatMessage(messages.removeDefaultCardWithoutSubscriptionText)
  }, [activeSubscription, isAdmin, isDefault, isSingleMethod, formatMessage])

  const isExpired = isExpiredCard(card)

  return (
    <OuterContainer onClick={() => onSelect(id)}>
      <Container {...{ isSelected, isExpired }}>
        <Number>
          <PaymentSystem
            src={makePublicUrl(`images/paymentLogos/${card.brand}.svg`)}
            alt={card.brand}
          />
          <Secondary>{`**** ${card.last4}`}</Secondary>
          {isDefault || isSingleMethod ? (
            <CardLabel isDefault={true}>
              <FormattedMessage {...messages.default} />
            </CardLabel>
          ) : (
            !isExpired && (
              <CardLabel onClick={() => openModal(false)}>
                <FormattedMessage {...messages.setAsDefault} />
              </CardLabel>
            )
          )}
        </Number>

        <DateRow>
          {isExpired ? (
            <CardLabel isExpired={isExpired}>
              <FormattedMessage {...messages.expired} />
            </CardLabel>
          ) : (
            <CardLabel isExpired={isExpired}>{`${
              card.exp_month > 9 ? card.exp_month : `0${card.exp_month}`
            }/${card.exp_year}`}</CardLabel>
          )}
          <DeleteBtn onClick={() => openModal(true)}>
            <MdiIcon path={mdiDelete} />
          </DeleteBtn>
        </DateRow>

        <ConfirmModal
          isOpen={isModalOpen}
          onDiscard={closeModal}
          icon={
            <Icon
              path={mdiHelpCircleOutline}
              size={2.5}
              color={theme.colors.mutedGray}
            />
          }
          title={
            detachAction
              ? formatMessage(messages.removePaymentMethodModal)
              : formatMessage(messages.setAsDefaultModal)
          }
          discardBtnTitle={<FormattedMessage {...messages.cancel} />}
          confirmBtnTitle={
            detachAction
              ? formatMessage(messages.yesRemove)
              : formatMessage(messages.yesChange)
          }
          onConfirm={performAction}
        >
          <ModalText>
            {detachAction
              ? formatDetachCardModalText()
              : formatMessage(messages.defaultText)}
          </ModalText>
        </ConfirmModal>
      </Container>
    </OuterContainer>
  )
}

export default Card
