import { FC } from 'react'
import { mdiHelpCircleOutline } from '@mdi/js'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import ConfirmModal from 'components/Modal/ConfirmModal'
import { MdiIcon } from 'components/Icons'
import { Regular } from 'components/Typography'
import { commonMessages } from 'app/i18n/messages'
import { deleteUserThunk } from 'features/users/slice'
import { deleteUserSelectors } from 'features/users/selectors'
import { LoaderOverlay } from 'components/Loader'

import messages from './messages'

interface ConfirmationProps {
  userId: string
  homeId: string
  isOpen: boolean
  onConfirm: () => void
  onDiscard: () => void
}

const RemoveUserConfirmation: FC<ConfirmationProps> = ({
  homeId,
  userId,
  onConfirm,
  isOpen,
  onDiscard,
}) => {
  const dispatch = useDispatch()
  const isRemoveLoading = useSelector(deleteUserSelectors.isFetching)

  return (
    <>
      {isRemoveLoading && <LoaderOverlay isFullScreen />}

      <ConfirmModal
        {...{ isOpen, onDiscard }}
        icon={<MdiIcon path={mdiHelpCircleOutline} size="64px" />}
        title={<FormattedMessage {...messages.title} />}
        discardBtnTitle={<FormattedMessage {...commonMessages.cancel} />}
        confirmBtnTitle={<FormattedMessage {...messages.ctaBtn} />}
        onConfirm={async () => {
          await dispatch(deleteUserThunk({ homeId, userId }))
          onConfirm()
        }}
        isRedConfirmBtn
      >
        <Regular>
          <FormattedMessage {...messages.message} values={{ br: <br /> }} />
        </Regular>
      </ConfirmModal>
    </>
  )
}

export default RemoveUserConfirmation
