import React, { ChangeEvent, useEffect, useState } from 'react'
import { Formik, FormikHelpers } from 'formik'
import { useSelector } from 'react-redux'
import { useIntl, FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'

import CommonButton from 'components/Button/CommonButton'
import { StyledLoginForm } from '../LoginForm/styles'

import {
  ResendCodeWrapper,
  StyledButtonsWrapper,
  StyledDescriptionText,
  StyledDescriptionTextWrapper,
  StyledEmailBold,
  StyledResendVerification,
} from './styles'
import { twoFactorAuthMessages } from './messages'
import TextField from 'components/Fields/TextField/TextField'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { clearUserCreds, getClientId } from 'app/services/LocalStorage'
import { ILoginFormValues } from '../../login/types'
import { login2FaThunk, twoFaLoginSliceActions } from './slice'
import LoaderOverlay from 'components/Loader/LoaderOverlay'
import { twoFactorSelectors } from './selectors'
import { loginThunk } from '../../login/slice'
import {
  failedLoginAttemptsSelector,
  isAuthenticatedSelector,
} from '../../login/selectors'
import { I2FaValues } from './types'
import { StyledInputError } from 'components/Input/styles'
import routes from 'app/routes'
import { loginUserEmailSelector } from './selectors'
import { fieldsMessages } from 'components/Fields/messages'
import { gaLoginSuccess } from 'features/analytics/utils'

const initialLoginValues: ILoginFormValues = {
  email: '',
  password: '',
  clientId: '',
  twoFactor: [],
}

const initial2faValues: I2FaValues = {
  code: '',
}

interface TwoFactorAuthFormProps {
  userCreds: ILoginFormValues
}

const TwoFactorAuthForm: React.FC<TwoFactorAuthFormProps> = ({ userCreds }) => {
  const { formatMessage } = useIntl()
  const [twoFaFormError, set2faFormError] = useState<string | null>(null)
  const dispatch = useAppDispatch()

  const twoFaError = useAppSelector(twoFactorSelectors.error)
  const isAuthenticated = useAppSelector(isAuthenticatedSelector)
  const history = useHistory()
  const loginUserEmail = useSelector(loginUserEmailSelector)
  const failedLoginAttempts = useAppSelector(failedLoginAttemptsSelector)

  type IChangeEventHandler = (e: ChangeEvent<any>) => void

  const handleCodeInputsChange = (
    e: ChangeEvent<any>,
    fieldHandleChange: IChangeEventHandler
  ) => {
    fieldHandleChange(e)
    set2faFormError(null)
  }
  const resendClick = () => {
    dispatch(twoFaLoginSliceActions.failure(null))
    dispatch(
      loginThunk(userCreds ?? initialLoginValues, {
        onSuccess: (payload) => {
          if (
            payload &&
            payload.twoFactor &&
            payload.twoFactor.length > 0 &&
            payload.twoFactor[0].enabled === 'Enabled'
          ) {
            history.push(routes.auth.twoFaSuccess, {
              savCreds: userCreds ?? initialLoginValues,
            })
          }
        },
        onError: () => {
          history.push(routes.auth.twoFaError)
        },
      })
    )
  }

  const goToLogin = () => {
    clearUserCreds()
    dispatch(twoFaLoginSliceActions.failure(null))
    history.push('/')
  }

  const handleSubmit = async (
    values: I2FaValues,
    formikHelpers: FormikHelpers<I2FaValues>
  ) => {
    if (!userCreds) {
      history.push('/')
    } else {
      let params: ILoginFormValues = {
        email: userCreds.email,
        password: userCreds.password,
        clientId: getClientId(),
        twoFactor: [
          {
            type: 'EMAIL_CODE',
            code: values.code,
          },
        ],
      }
      dispatch(login2FaThunk(params, () => gaLoginSuccess(failedLoginAttempts)))
    }
  }

  useEffect(() => {
    if (twoFaError) {
      console.log(twoFaError)
      set2faFormError(twoFaError.message)
    }
  }, [formatMessage, twoFaError, twoFaError?.code, isAuthenticated])

  return (
    <Formik
      initialValues={initial2faValues}
      onSubmit={handleSubmit}
      validationSchema={Yup.object().shape({
        code: Yup.string()
          .required(formatMessage(fieldsMessages.requiredField))
          .length(6, formatMessage(twoFactorAuthMessages.codeLengthError)),
      })}
    >
      {(formikProps) => (
        <StyledLoginForm>
          {formikProps.isSubmitting && <LoaderOverlay />}

          <StyledDescriptionTextWrapper>
            <StyledDescriptionText>
              <FormattedMessage
                {...twoFactorAuthMessages.description}
                values={{
                  email: loginUserEmail,
                  b: (chunks: string) => (
                    <StyledEmailBold>{chunks}</StyledEmailBold>
                  ),
                }}
              />
            </StyledDescriptionText>
            <StyledDescriptionText>
              <FormattedMessage {...twoFactorAuthMessages.enterCode} />
            </StyledDescriptionText>
          </StyledDescriptionTextWrapper>
          <TextField
            name="code"
            error={twoFaFormError}
            onChange={(e: ChangeEvent<any>) =>
              handleCodeInputsChange(e, formikProps.handleChange)
            }
            placeholder={formatMessage(twoFactorAuthMessages.twoFactor)}
            autoComplete="off"
          />
          <StyledInputError>{twoFaFormError}</StyledInputError>

          <StyledButtonsWrapper>
            <CommonButton
              type="button"
              onClick={goToLogin}
              variant="goldenOutlined"
            >
              {formatMessage(twoFactorAuthMessages.goToLogin)}
            </CommonButton>
            <CommonButton
              type="submit"
              variant="goldenFilled"
              disabled={
                formikProps.isSubmitting ||
                !formikProps.values.code ||
                !formikProps.isValid
              }
            >
              {formatMessage(twoFactorAuthMessages.verifyCode)}
            </CommonButton>
          </StyledButtonsWrapper>
          <ResendCodeWrapper>
            {formatMessage(twoFactorAuthMessages.resendDescription)}{' '}
            <StyledResendVerification to="#" onClick={resendClick}>
              {formatMessage(twoFactorAuthMessages.resendCode)}
            </StyledResendVerification>
          </ResendCodeWrapper>
        </StyledLoginForm>
      )}
    </Formik>
  )
}

export default TwoFactorAuthForm
