import styled from 'styled-components'

import { Font14 } from 'components/Typography/Base'
import { MySubscriptionItemSubTitleWithIcon } from '../styles'

export const StyledSubscrManagedBy = styled.div`
  ${Font14}
  word-break: break-word;

  ${MySubscriptionItemSubTitleWithIcon} {
    font-weight: 700;
  }
`

export const SubscrManagedByImg = styled.img`
  width: 18px;
  margin-right: 5px;
`
