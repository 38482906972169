import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    id: 'confirmUserRemoval.title',
    description: 'Title of user removal confirmation dialog',
    defaultMessage: 'Remove user',
  },
  message: {
    id: 'confirmUserRemoval.msg',
    description: 'Message of user removal confirmation dialog',
    defaultMessage:
      'Removing a user revokes their access to this Savant Home.{br}They will no longer be able to connect to and control this home.',
  },
  ctaBtn: {
    id: 'confirmUserRemoval.ctaBtn',
    description: 'Confirm button title of user removal confirmation dialog',
    defaultMessage: 'Remove',
  },
})
