import React, { FC, useCallback, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { ClipsListContainer, ClipsListInfoContainer } from './styles'
import { ClipShape } from 'app/types/clip'
import Clip from './Clip'
import _isEmpty from 'lodash/isEmpty'
import { cameraRecordingsMessages } from '../../messages'
import VideoModal from '../VideoModal'
import { CurrentClipState } from 'features/cameraRecordings/types'

const initialCurrentClipState: CurrentClipState = {
  currentClip: null,
  nextClip: null,
  prevClip: null,
}

interface ClipsListProps {
  clipsList: ClipShape[]
}

const ClipsList: FC<ClipsListProps> = ({ clipsList }) => {
  const [currentClipState, setCurrentClipState] = useState<CurrentClipState>(
    initialCurrentClipState
  )
  const [modalOpen, setModalOpen] = useState(false)

  const handleCurrentClipChange = useCallback(
    async (newClip: ClipShape) => {
      const newClipIndex = clipsList.findIndex((item) => item === newClip)

      const prevIndex = newClipIndex - 1
      const nextIndex = newClipIndex + 1

      await setCurrentClipState({
        currentClip: newClip,
        nextClip: clipsList[nextIndex] || null,
        prevClip: clipsList[prevIndex] || null,
      })
      setModalOpen(true)
    },
    [clipsList]
  )

  const handleModalClose = async () => {
    await setCurrentClipState(initialCurrentClipState)
    setModalOpen(false)
  }

  return (
    <>
      {currentClipState.currentClip && (
        <VideoModal
          isOpen={modalOpen}
          onClose={handleModalClose}
          clipInfo={currentClipState}
          handleClipChange={handleCurrentClipChange}
        />
      )}
      {_isEmpty(clipsList) ? (
        <ClipsListInfoContainer>
          <FormattedMessage {...cameraRecordingsMessages.noClips} />
        </ClipsListInfoContainer>
      ) : (
        <>
          <ClipsListContainer>
            {Array.isArray(clipsList) &&
              clipsList.map((clip) => (
                <Clip
                  clip={clip}
                  key={clip.clipId}
                  handleClick={handleCurrentClipChange}
                />
              ))}
          </ClipsListContainer>
        </>
      )}
    </>
  )
}

export default ClipsList
