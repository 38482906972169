import React, { memo, useCallback, useState } from 'react'
import { mdiChevronDown } from '@mdi/js'
import OutsideClickHandler from 'react-outside-click-handler'

import {
  StyledAccountMenuWrapper,
  StyledAccountMenuBtn,
  StyledArrowIcon,
} from './styles'
import AccountMenuNav from './AccountMenuNav/AccountMenuNav'
import { UserImgContainer } from 'components/UserItems'
import { useAppSelector } from 'app/hooks'
import { currUserSelector } from 'features/auth/login/selectors'

const AccountMenu: React.FC = () => {
  const currentUser = useAppSelector(currUserSelector)

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const handleMenuToggle = useCallback(() => setIsMenuOpen(!isMenuOpen), [
    isMenuOpen,
  ])
  const handleMenuClose = useCallback(() => setIsMenuOpen(false), [])

  return (
    <StyledAccountMenuWrapper>
      <OutsideClickHandler onOutsideClick={handleMenuClose}>
        <StyledAccountMenuBtn
          type="button"
          onClick={handleMenuToggle}
          isMenuOpen={isMenuOpen}
        >
          <UserImgContainer
            size={24}
            profilePicUrl={currentUser.profilePicUrl}
          />
          <StyledArrowIcon
            path={mdiChevronDown}
            size={1}
            color="#fff"
            rotate={isMenuOpen ? 180 : 0}
          />
        </StyledAccountMenuBtn>
        {isMenuOpen && <AccountMenuNav onLinkClick={handleMenuClose} />}
      </OutsideClickHandler>
    </StyledAccountMenuWrapper>
  )
}

export default memo(AccountMenu)
