import React from 'react'

import LoginFooter from 'components/Footer/LoginFooter/LoginFooter'
import { LoginContainer } from 'features/auth/login/styles'
import LoginInfoBlock from '../LoginContainer/LoginInfoBlock'
import { StyledLoginContainer } from '../LoginContainer/styles'
import LoginHeader from '../LoginHeader/LoginHeader'
import LoginBgImage from '../LoginBgImage/LoginBgImage'
import { StyledLoginInner } from './styles'
import ReactHelmet from 'features/analytics/RenderHelmet'

const LoginCommonLayout: React.FC = (props) => {
  return (
    <>
      <ReactHelmet title="Sign In" />

      <div>
        <LoginContainer>
          <LoginBgImage />
          <LoginHeader />
          <StyledLoginContainer>
            <StyledLoginInner>{props.children}</StyledLoginInner>
            <LoginInfoBlock />
          </StyledLoginContainer>
        </LoginContainer>
        <LoginFooter />
      </div>
    </>
  )
}

export default LoginCommonLayout
