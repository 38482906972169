import { defineMessages } from 'react-intl'

export const successPageMessages = defineMessages({
  title: {
    id: 'initSuccess.title',
    description:
      'Title of message users sees after entering his email for password recover',
    defaultMessage: 'Password Reset Link Sent',
  },
  description: {
    id: 'initSuccess.description',
    description:
      'Text of message users sees after entering his email for password recover',
    defaultMessage:
      'If there is a Savant Account associated to the email address provided, a link to reset your password has successfully been sent.',
  },
  goToLogin: {
    id: 'initSuccess.goToLogin',
    description: 'Button text to proceed and go to Log In page',
    defaultMessage: 'Go To Sign In',
  },
})
