import React from 'react'
import { MdiIcon } from 'components/Icons'
import { ProfileImg, StyledUserImgContainer } from './styles'
import { mdiAccountCircle } from '@mdi/js'
import theme from 'theme_'

interface UserImgContainerProps {
  profilePicUrl?: string | null
  /**
   * Width and Height of icon in pixels
   */
  size?: number
}

const UserImgContainer: React.FC<UserImgContainerProps> = (props) => {
  const imgSize = props.size || 120

  return (
    <StyledUserImgContainer $size={imgSize}>
      {props.profilePicUrl ? (
        <ProfileImg
          $size={imgSize}
          src={props.profilePicUrl}
          alt="Profile picture"
        />
      ) : (
        <MdiIcon
          path={mdiAccountCircle}
          color={theme.colors.beachGrey}
          size={imgSize + 'px'}
        />
      )}
    </StyledUserImgContainer>
  )
}

export default UserImgContainer
