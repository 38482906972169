import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useAppSelector } from 'app/hooks'

import { useAppDispatch } from 'app/hooks'
import CommonButton from 'components/Button/CommonButton'
import { Secondary } from 'components/Typography'
import { currentHomeIdSelector } from 'features/homes/selectors'
import { getClaimCodeThunk } from 'features/homes/homesSlice'
import { claimHomeRequestSelectors } from 'features/homes/selectors'
import CopyField from 'features/homes/components/CopyField'

import { shareCodeMessages } from './messages'
import { CodeContainer, ShareCodeBtnContainer } from './styles'

const ShareCodeBlock = () => {
  const dispatch = useAppDispatch()
  const homeId = useAppSelector(currentHomeIdSelector)
  const claimHomeCode = useAppSelector(claimHomeRequestSelectors.data)
  const isFetching = useAppSelector(claimHomeRequestSelectors.isFetching)
  const { formatMessage } = useIntl()
  const [fetchedClaimCode, setFetchedClaimCode] = useState(false)

  useEffect(() => {
    if (homeId && !isFetching && !fetchedClaimCode) {
      dispatch(getClaimCodeThunk(homeId))
      setFetchedClaimCode(true)
    }
  }, [dispatch, homeId, fetchedClaimCode, isFetching])

  return (
    <>
      <Secondary>
        <FormattedMessage {...shareCodeMessages.msg} />
      </Secondary>

      {claimHomeCode && (
        <CodeContainer>
          <CopyField text={claimHomeCode} />
        </CodeContainer>
      )}

      {navigator.share && (
        <ShareCodeBtnContainer>
          <CommonButton
            variant="goldenFilled"
            type="button"
            onClick={() =>
              navigator.share({
                title: formatMessage(shareCodeMessages.shareCodeBtn),
                text: formatMessage(shareCodeMessages.shareCodeTxt, {
                  claimCode: claimHomeCode,
                }),
              })
            }
          >
            <FormattedMessage {...shareCodeMessages.shareCodeBtn} />
          </CommonButton>
        </ShareCodeBtnContainer>
      )}
    </>
  )
}

export default ShareCodeBlock
