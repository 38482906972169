import {
  AUTH_REDUCER_NAME,
  PASS_INIT_RESET_REDUCER_NAME,
  PASS_RESET_CONFIRM_REDUCER_NAME,
} from '../../consts'
import { makeFetchSelectors } from 'app/redux-fetch/selectors'

export const passInitResetSelectors = makeFetchSelectors(
  AUTH_REDUCER_NAME,
  PASS_INIT_RESET_REDUCER_NAME
)

export const passResetConfirmConfirmSelectors = makeFetchSelectors(
  AUTH_REDUCER_NAME,
  PASS_INIT_RESET_REDUCER_NAME,
  PASS_RESET_CONFIRM_REDUCER_NAME
)
