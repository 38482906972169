import { defineMessages } from 'react-intl'

export const subscriptionPageMessages = defineMessages({
  title: {
    id: 'subscriptionPage.title',
    description: 'Main heading on "Subscription" page',
    defaultMessage: 'Subscriptions',
  },
  selectPlan: {
    id: 'subscriptionPage.selectPlan',
    description: 'Text asking users to chose which plan they prefer',
    defaultMessage: 'Select your subscription plan',
  },
  nonAdminTitle: {
    id: 'subscriptionPage.nonAdminTitle',
    description: 'Title for subscription table when user is not admin',
    defaultMessage:
      'If you would like to make changes to your plan, please contact your Savant Home Dealer.',
  },
})
