const forgotPasswordUrl = '/forgot-password'

const accountRoute = '/account'

// Authorization agnostic routes
const neutralRoutes = {
  forgotPassword: `${forgotPasswordUrl}`,
  forgotPasswordInitSuccess: `${forgotPasswordUrl}/init-success`,
  forgotPasswordResetSuccess: `${forgotPasswordUrl}/reset-success`,
  forgotPasswordResetConfirm: `${forgotPasswordUrl}/reset-confirm`,
  forgotPasswordResetError: `${forgotPasswordUrl}/reset-error`,
}

const ROUTES = {
  auth: {
    home: '/',
    ...neutralRoutes,
    twoFa: '/2fa',
    twoFaSuccess: '/2fa/success',
    twoFaError: '/2fa/error',
  },

  dashboard: '/',
  discoverMore: '/discover-more',
  tutorials: '/tutorials',
  subscription: '/subscription',
  users: '/:homeId/users',
  connectedApps: '/connected-apps',
  vpEnroll: '/connected-apps/vp-enroll',
  cameraRecordings: '/camera-recordings',
  getHelp: '/get-help',
  userDetails: '/:homeId/users/:userId',
  checkout: '/checkout',
  payments: '/payments',
  account: accountRoute,
  paymentMethods: `${accountRoute}/payment-methods`,
  billingHistory: `${accountRoute}/billing-history`,
  aiSettings: `${accountRoute}/ai-settings`,
  myDealer: '/my-dealer',
  noHomes: '/no-homes',
  notFound: '/not-found',
}

export default ROUTES
