import { FC, ReactElement } from 'react'

import { SectionTitle } from 'components/Typography'

import { DetailsBlockContainer } from './styles'

const DetailsBlock: FC<{
  title?: ReactElement
  header?: ReactElement
  children: ReactElement | ReactElement[]
}> = ({ title, header, children }) => (
  <DetailsBlockContainer>
    {header || (
      <header>
        <SectionTitle>{title}</SectionTitle>
      </header>
    )}
    {children}
  </DetailsBlockContainer>
)

export default DetailsBlock
