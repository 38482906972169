import styled from 'styled-components'
import theme from 'theme_'

export const StyledTextArea = styled.textarea`
  resize: none;
  margin: 0;
  padding: 0 ${theme.spacing.s};
  height: 90px;
  font-size: 16px;
  font-family: ${theme.fonts.base};
  color: ${theme.colors.beachGrey};
  background-color: ${theme.colors.inputFill};
  border: none;
  box-shadow: inset 0px -1px 0px #8a847b;
  outline: none;

  :hover {
    background-color: ${theme.colors.inputFillFocus};
  }
  :focus {
    background-color: ${theme.colors.inputFillFocus};
    box-shadow: inset 0px -1px 0px #edbb76;
  }
  :disabled {
    color: ${theme.colors.disabledGrey};
    box-shadow: none;
    pointer-events: none;
  }
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    -webkit-text-fill-color: ${theme.colors.accentGold};
    transition: background-color 5000s ease-in-out 0s;
  }
`
