import React from 'react'
import AccountMenuHeader from 'components/AccountMenu/AccountMenuHeader/AccountMenuHeader'
import HomeSelector from 'components/AsideMenu/HomeSelector'
import LanguageSelector from 'components/LanguageSelector'
import AsideMenuLinks from 'components/AsideMenu/AsideMenuLinks'
import {
  StyledLanguageMobileWrapper,
  StyledDefaultScreenWrapper,
} from '../styles'
import { useSelector } from 'react-redux'
import { adminHomesListSelector } from 'features/homes/selectors'

interface IScreenDefaultProps {
  handleScreenAccount: () => void
  handleScreenHomes: () => void
  handleScreenLanguage: () => void
  handleCloseMenu: () => void
}

const ScreenDefault: React.FC<IScreenDefaultProps> = (props) => {
  const adminHomesList = useSelector(adminHomesListSelector)
  return (
    <StyledDefaultScreenWrapper>
      <AccountMenuHeader mobileClickHandler={props.handleScreenAccount} />
      {adminHomesList.length ? (
        <>
          <HomeSelector mobileClickHandler={props.handleScreenHomes} />
          <div onClick={props.handleCloseMenu}>
            <AsideMenuLinks />
          </div>
        </>
      ) : null}
      <StyledLanguageMobileWrapper>
        <LanguageSelector mobileClickHandler={props.handleScreenLanguage} />
      </StyledLanguageMobileWrapper>
    </StyledDefaultScreenWrapper>
  )
}

export default ScreenDefault
