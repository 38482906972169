import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { DateTime } from 'luxon'

import { useAppSelector } from 'app/hooks'
import routes from 'app/routes'
import IconLink from 'components/Button/IconLink'
import { dealerSelector } from 'features/homes/selectors'
import { activeSubscriptionSelector } from 'features/homes/Subscriptions/selectors'
import {
  StyledSubscriptionInfo,
  SubscriptionInfoLeft,
  SubscriptionInfoWrapper,
  SubsInfoBottom,
  SubsInfoButtomBtns,
  SubsInfoEnrolledHelperText,
  SubsInfoExpadedRight,
  SubsInfoHeaderTitle,
  SubsInfoHelperTextRow,
  SubsInfoTitle,
  SubsInfoTitleImg,
} from './styles'
import { CommonButton } from 'components/Button'
import { isExpiringSoon } from 'features/homes/Subscriptions/utils'
import { makePublicUrl } from 'app/utils/publicUrl'
import SubscrManagedBy from 'components/MySubscription/SubscrManagedBy'
import SubscrBillingDate from 'components/MySubscription/SubscrBillingDate'
import SubscrExpires from 'components/MySubscription/SubscrExpires'
import { FormattedMessage, useIntl } from 'react-intl'
import { subscrInfoMessages } from './messages'
import { commonMessages } from 'app/i18n'
import { ESSENTIALS_ICON } from 'app/consts'

const SUBSCRIPTION_STATE = {
  NONE: 'NONE',
  ENROLLED: 'ENROLLED',
  WILL_NOT_RENEW: 'WILL_NOT_RENEW',
  EXPIRES: 'EXPIRES',
}

const warningIcon = makePublicUrl('images/subscriptions/warning.svg')
const homeInfoImage = makePublicUrl('images/home_info.svg')

interface SubscriptionInfoProps {
  customBg?: boolean
  isExpanded?: boolean
}

// WIP, including localization. Extract 'Essentials' to commonMessages
const SubscriptionInfo = (props: SubscriptionInfoProps) => {
  const history = useHistory()
  const { formatMessage } = useIntl()

  const dealer = useAppSelector(dealerSelector)
  const dealerName = dealer?.dealerInfo?.name

  const activeSubscription = useAppSelector(activeSubscriptionSelector)

  const subscriptionState = useMemo(() => {
    if (activeSubscription) {
      if (activeSubscription.autoRenew) return SUBSCRIPTION_STATE.ENROLLED
      return isExpiringSoon(activeSubscription, DateTime.now())
        ? SUBSCRIPTION_STATE.EXPIRES
        : SUBSCRIPTION_STATE.WILL_NOT_RENEW
    }
    return SUBSCRIPTION_STATE.NONE
  }, [activeSubscription])

  const isEnrolled = subscriptionState === SUBSCRIPTION_STATE.ENROLLED

  const isNoDealerExpiring = !!activeSubscription && !dealer

  const isExpiringSub = subscriptionState === SUBSCRIPTION_STATE.WILL_NOT_RENEW
  const isExpiringSoonSub = subscriptionState === SUBSCRIPTION_STATE.EXPIRES

  const shouldRenderFindDealerMsg = !dealer

  const shouldContactDealerToEnable =
    !!dealer && props.isExpanded && (isExpiringSoonSub || !activeSubscription)

  const shouldContactDealerToManage =
    !!dealer && ((props.isExpanded && isEnrolled) || isExpiringSub)

  const shouldRenderFindDealerBtn =
    props.isExpanded && !dealer && !activeSubscription

  const subscriptionPostTitle = useMemo(() => {
    if (isExpiringSoonSub) {
      return formatMessage(subscrInfoMessages.isExpiring)
    }
    if (isEnrolled || isExpiringSub) {
      return formatMessage(subscrInfoMessages.isActive)
    }
    return ''
  }, [formatMessage, isEnrolled, isExpiringSoonSub, isExpiringSub])

  return (
    <SubscriptionInfoWrapper $isExpanded={props.isExpanded}>
      <StyledSubscriptionInfo
        $customBg={props.customBg}
        $isExpanded={props.isExpanded}
      >
        <SubscriptionInfoLeft $isExpanded={props.isExpanded}>
          <SubsInfoTitle>
            {props.isExpanded ? (
              <FormattedMessage {...subscrInfoMessages.title} />
            ) : (
              <FormattedMessage {...subscrInfoMessages.altTitle} />
            )}
            {!props.isExpanded && <IconLink to={routes.subscription} />}
          </SubsInfoTitle>
          <SubsInfoHeaderTitle>
            {(isEnrolled || isExpiringSub) && (
              <SubsInfoTitleImg src={ESSENTIALS_ICON} />
            )}
            {(isExpiringSoonSub || !activeSubscription) && (
              <SubsInfoTitleImg src={warningIcon} />
            )}
            {activeSubscription ? (
              <FormattedMessage {...commonMessages.savantEssentials} />
            ) : (
              <FormattedMessage
                {...subscrInfoMessages.upgradeToPlan}
                values={{
                  planName: formatMessage(commonMessages.savantEssentials),
                }}
              />
            )}{' '}
            {subscriptionPostTitle}
          </SubsInfoHeaderTitle>
          {activeSubscription ? (
            <>
              <SubsInfoEnrolledHelperText>
                <FormattedMessage
                  {...subscrInfoMessages.hasPaidPlan}
                  values={{
                    planName: formatMessage(commonMessages.savantEssentials),
                  }}
                />
              </SubsInfoEnrolledHelperText>
              {/* // TODO: UX
              {(isExpiringSub || isExpiringSoonSub) && (
                <SubsInfoEnrolledHelperText $hasWarn={isExpiringSoonSub}>
                  <FormattedMessage {...subscrInfoMessages.afterExpire} />
                </SubsInfoEnrolledHelperText>
              )} */}
              {shouldContactDealerToManage && (
                <SubsInfoHelperTextRow>
                  <FormattedMessage
                    {...subscrInfoMessages.contactToManage}
                    values={{
                      planName: formatMessage(commonMessages.savantEssentials),
                    }}
                  />
                </SubsInfoHelperTextRow>
              )}
            </>
          ) : (
            <div>
              <FormattedMessage {...subscrInfoMessages.hasFreePlan} />
            </div>
          )}

          {shouldRenderFindDealerMsg && (
            <SubsInfoHelperTextRow>
              <FormattedMessage
                {...subscrInfoMessages.findToUpgrade}
                values={{
                  planName: formatMessage(commonMessages.savantEssentials),
                }}
              />
            </SubsInfoHelperTextRow>
          )}

          {shouldContactDealerToEnable && (
            <SubsInfoHelperTextRow>
              <FormattedMessage
                {...subscrInfoMessages.contactToEnablePlan}
                values={{
                  planName: formatMessage(commonMessages.savantEssentials),
                }}
              />
            </SubsInfoHelperTextRow>
          )}

          <SubsInfoBottom>
            {shouldRenderFindDealerBtn && (
              <CommonButton
                variant="goldenFilled"
                type="button"
                onClick={() => {
                  history.push(routes.myDealer)
                }}
              >
                <FormattedMessage {...subscrInfoMessages.findDealerBtn} />
              </CommonButton>
            )}
            {dealer && !!activeSubscription ? (
              <SubscrManagedBy managedBy={dealerName} />
            ) : (
              <div></div>
            )}
            {isEnrolled && (
              <SubscrBillingDate
                nextBillingDate={activeSubscription?.autoRenew}
              />
            )}
            {(isExpiringSub || isExpiringSoonSub) && (
              <SubscrExpires
                expiresAt={activeSubscription?.expiresAt}
                oneLine={isNoDealerExpiring}
                hasWarn={isExpiringSoonSub}
              />
            )}
            {!activeSubscription && !props.isExpanded && (
              <SubsInfoButtomBtns>
                <CommonButton
                  variant="goldenFilled"
                  type="button"
                  onClick={() => {
                    history.push(routes.subscription)
                  }}
                >
                  <FormattedMessage {...commonMessages.learnMore} />
                </CommonButton>
              </SubsInfoButtomBtns>
            )}
          </SubsInfoBottom>
        </SubscriptionInfoLeft>
        {props.isExpanded && (
          <SubsInfoExpadedRight>
            <img src={homeInfoImage} alt="" />
          </SubsInfoExpadedRight>
        )}
      </StyledSubscriptionInfo>
    </SubscriptionInfoWrapper>
  )
}

export default SubscriptionInfo
