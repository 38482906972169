import Icon from '@mdi/react'
import styled from 'styled-components'

export const StyledPasswordWrapper = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
`

export const RevealPasswordButton = styled.button`
  position: absolute;
  z-index: 2;
  right: 5px;
  top: 7px;
  display: block;
  cursor: pointer;
  border: none;
  background: transparent;
  :hover {
    opacity: 0.8;
  }
  :focus-visible {
    outline: 1px solid ${(p) => p.theme.colors.horizonOrange};
  }
`
export const RevealPasswordIcon = styled(Icon)`
  color: ${(p) => p.theme.colors.newDarkGrey};
`
