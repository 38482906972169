import styled from 'styled-components'
import theme from 'theme_'

export const HomesBgImageWrapper = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  height: 300px;
  width: 100%;
  overflow: hidden;
  animation: ${theme.animations.fadeIn} 0.5s linear;

  @media ${theme.devices.desktop} {
    height: 600px;
  }
`

export const StyledHomesBgImage = styled.div<{ imageUrl: string }>`
  width: 100%;
  height: 100%;
  background-color: ${theme.colors.newBackgroundGrey};
  background-position: center center;
  background-repeat: no-repeat;
  background-image: linear-gradient(
      ${(p) =>
        p.imageUrl ? 'rgba(18, 17, 17, 0.1) 0%' : 'rgba(89, 74, 59, 0.5) 0%'},
      rgba(18, 17, 17, 1) 90%
    ),
    url(${(p) => (p.imageUrl ? p.imageUrl : '')});
  background-size: cover;
  filter: blur(2px);
  transform: scale(1.05);

  @media ${theme.devices.desktop} {
    filter: blur(5px);
  }
`
