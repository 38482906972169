import styled from 'styled-components'
import ReactModal from 'react-modal'

import { StyledModalInner } from 'components/Modal/styles'
import { ButtonStyles } from 'components/Button/styles'
import theme from 'theme_'

export const StyledVideoModal = styled(ReactModal)`
  color: #ffffff;
  display: flex;
  height: 100%;
  overflow-y: auto;
`

export const StyledModalVideo = styled.video`
  display: block;
`

export const StyledVideoModalInner = styled(StyledModalInner)`
  padding: 0;
`
export const StyledVideoModalFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  border-radius: 0 0 8px 8px;
  background-color: #000;
`

export const StyledVideoNavigationWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 100px;
`

export const StyledVideoNavigationBtn = styled(ButtonStyles)`
  color: #fff;

  :hover {
    color: ${theme.colors.accentGold};
  }
`
