import styled from 'styled-components'
import Icon from '@mdi/react'

import theme from 'theme_'

export const LanguageSelectorContainer = styled.div<{ isOpen: boolean }>`
  background-color: ${(p) =>
    p.isOpen ? theme.overlay.newDropdownBlack : 'transparent'};
  cursor: pointer;
  border-radius: ${theme.spacing.xs};

  @media ${theme.devices.desktop} {
    padding: ${theme.spacing.tiny} ${theme.spacing.tiny} ${theme.spacing.tiny} 0;
    display: flex;
    align-items: center;
    position: relative;

    &:hover {
      background-color: ${theme.overlay.newDropdownBlack};
    }
  }
`

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${`${theme.spacing.xs} ${theme.spacing.xxs} ${theme.spacing.xxs} ${theme.spacing.md}`};

  @media ${theme.devices.desktop} {
    padding: ${`${theme.spacing.xs} ${theme.spacing.xxs} ${theme.spacing.xxs} ${theme.spacing.base}`};
  }
`

export const LabelContainerInner = styled.div`
  display: flex;
  align-items: center;
`

export const LangLabel = styled.label`
  font-size: ${theme.spacing.sm};
  color: ${theme.colors.lightGrey};
  text-transform: capitalize;
  cursor: pointer;

  @media ${theme.devices.desktop} {
    font-size: ${theme.spacing.base};
  }
`

export const ChevronContainer = styled.div<{ isOpen: boolean }>`
  padding-right: 2px;
  @media ${theme.devices.desktop} {
    display: flex;
    transition: transform 0.2s ease;
    margin-right: ${(p) => `${p.theme.spacing.xs}`};
    padding-right: 0;
  }
  ${(p) =>
    p.isOpen &&
    `
    transform: rotate(180deg);
  `}
`

export const FlagImage = styled.img`
  object-fit: cover;
  vertical-align: middle;
  width: 32px;
  position: relative;
  margin-right: ${theme.spacing.s};

  @media ${theme.devices.desktop} {
    margin-right: ${theme.spacing.xs};
  }
`

export const SelectedFlagImage = styled(FlagImage)`
  width: 22px;
  height: 14px;

  @media ${theme.devices.desktop} {
    top: 0;
  }
`
export const StyledChevronIcon = styled(Icon)`
  display: block;
  color: ${theme.colors.lightGrey};
`
