import React from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import Icon from '@mdi/react'
import { mdiAlertCircleOutline } from '@mdi/js'

import CommonButton from 'components/Button/CommonButton'
import {
  StyledButtonsWrapper,
  StyledDescriptionText,
  StyledErrorIcon,
} from './styles'
import { twoFactorAuthErrorMessages } from './messages'
import { clearUserCreds } from 'app/services/LocalStorage'
import routes from 'app/routes'
import LoginCommonLayout from '../../LoginCommonLayout'
import theme from 'theme_'
import { StyledAuthForm } from 'features/auth/login/styles'
import { StyledMainHeading } from '../../MainHeading/MainHeading'

const TwoFactorAuthFormError: React.FC = () => {
  const { formatMessage } = useIntl()
  const handleGoToLogin = () => {
    clearUserCreds()
    history.push('/')
  }
  const handleTryAgain = () => {
    clearUserCreds()
    history.push(routes.auth.twoFa)
  }

  const history = useHistory()
  return (
    <LoginCommonLayout>
      <StyledAuthForm as="div">
        <StyledErrorIcon>
          <Icon
            path={mdiAlertCircleOutline}
            size={3}
            color={theme.colors.error}
          />
        </StyledErrorIcon>
        <StyledMainHeading>
          {formatMessage(twoFactorAuthErrorMessages.errorTitle)}
        </StyledMainHeading>
        <StyledDescriptionText>
          {formatMessage(twoFactorAuthErrorMessages.errorDescription)}
        </StyledDescriptionText>
        <StyledButtonsWrapper>
          <CommonButton
            type="button"
            variant="goldenOutlined"
            onClick={handleGoToLogin}
          >
            {formatMessage(twoFactorAuthErrorMessages.goToLogin)}
          </CommonButton>
          <CommonButton
            type="button"
            variant="goldenFilled"
            onClick={handleTryAgain}
          >
            {formatMessage(twoFactorAuthErrorMessages.tryAgain)}
          </CommonButton>
        </StyledButtonsWrapper>
      </StyledAuthForm>
    </LoginCommonLayout>
  )
}

export default TwoFactorAuthFormError
