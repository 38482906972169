import styled from 'styled-components'
import theme from 'theme_'

import { BluredBlock } from 'components/Layout'
import { RegularModerate, SectionTitle } from 'components/Typography'

export const StyledMySupport = styled(BluredBlock)`
  order: 3;
`

export const StyledMySupportTitle = styled(SectionTitle)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${theme.spacing.md};
`

export const StyledMySupportTrouble = styled(RegularModerate)`
  font-weight: 700;
  margin-bottom: ${theme.spacing.lg};

  @media ${theme.devices.desktop} {
    margin-bottom: ${theme.spacing.md};
  }
`

export const StyledMySupportSolution = styled(RegularModerate)``

export const StyledMySupportBtnWrap = styled.div`
  display: flex;
  margin-top: ${theme.spacing.md};
`
