import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'
import {
  mdiAccountCircleOutline,
  mdiCreditCardOutline,
  mdiPlaylistCheck,
  mdiExport,
} from '@mdi/js'

import AccountMenuHeader from '../AccountMenuHeader/AccountMenuHeader'
import {
  StyledAccountMenu,
  StyledAccountMenuIcon,
  StyledAccountMenuLink,
  StyledAccountMenuLogout,
} from './styles'
import { accountMenuMessages } from '../messages'
import { useAppDispatch } from 'app/hooks'
import { logOutThunk } from 'features/auth/login/slice'
import routes from 'app/routes'
import { isSubscriptionHidden } from 'app/utils/isSubscriptionHidden'
import { isFirefox } from 'app/utils/userAgent'

interface AccountMenuProps {
  onLinkClick?: () => void
}

const AccountMenuNav: React.FC<AccountMenuProps> = (props) => {
  const { formatMessage } = useIntl()
  const dispatch = useAppDispatch()

  const handleLogout = useCallback(() => {
    dispatch(logOutThunk())
  }, [dispatch])

  return (
    <StyledAccountMenu $isFirefox={isFirefox()}>
      <AccountMenuHeader />
      <StyledAccountMenuLink
        exact
        to={routes.account}
        onClick={props.onLinkClick}
      >
        <StyledAccountMenuIcon path={mdiAccountCircleOutline} size={1} />
        {formatMessage(accountMenuMessages.myAccount)}
      </StyledAccountMenuLink>
      {!isSubscriptionHidden && false && (
        <>
          <StyledAccountMenuLink
            exact
            to={routes.paymentMethods}
            onClick={props.onLinkClick}
          >
            <StyledAccountMenuIcon path={mdiCreditCardOutline} size={1} />
            {formatMessage(accountMenuMessages.paymentMethods)}
          </StyledAccountMenuLink>
          <StyledAccountMenuLink
            exact
            to={routes.billingHistory}
            onClick={props.onLinkClick}
          >
            <StyledAccountMenuIcon path={mdiPlaylistCheck} size={1} />
            {formatMessage(accountMenuMessages.billingHistory)}
          </StyledAccountMenuLink>
        </>
      )}
      <StyledAccountMenuLogout type="button" onClick={handleLogout}>
        <StyledAccountMenuIcon path={mdiExport} size={1} />
        {formatMessage(accountMenuMessages.logout)}
      </StyledAccountMenuLogout>
    </StyledAccountMenu>
  )
}

export default AccountMenuNav
