import { FC } from 'react'
import { mdiHelpCircleOutline } from '@mdi/js'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import ConfirmModal from 'components/Modal/ConfirmModal'
import { MdiIcon } from 'components/Icons'
import { Regular } from 'components/Typography'
import { commonMessages } from 'app/i18n/messages'
import { LoaderOverlay } from 'components/Loader'
import messages from './messages'
import { deletePartnerThunk } from '../../slice'
import { deletePartnerSelectors } from '../../selectors'

interface ConfirmationProps {
  appName: string
  appLabel: string
  currentHomeId: string
  isOpen: boolean
  handleConfirm: () => void
  handleDiscard: () => void
}

const RevokeAppConfirmation: FC<ConfirmationProps> = ({
  currentHomeId,
  appName,
  appLabel,
  handleConfirm,
  isOpen,
  handleDiscard,
}) => {
  const dispatch = useDispatch()
  const isRemoveLoading = useSelector(deletePartnerSelectors.isFetching)

  return (
    <>
      {isRemoveLoading && <LoaderOverlay isFullScreen />}
      <ConfirmModal
        {...{ isOpen }}
        onDiscard={handleDiscard}
        icon={<MdiIcon path={mdiHelpCircleOutline} size="64px" />}
        title={<FormattedMessage {...messages.title} />}
        discardBtnTitle={<FormattedMessage {...commonMessages.cancel} />}
        confirmBtnTitle={<FormattedMessage {...messages.ctaBtn} />}
        onConfirm={async () => {
          await dispatch(
            deletePartnerThunk({ homeId: currentHomeId, partner: appName })
          )
          handleConfirm()
        }}
        isRedConfirmBtn
      >
        <Regular>
          <FormattedMessage
            {...messages.message}
            values={{ br: <br />, appName: <b>{appLabel}</b> }}
          />
        </Regular>
      </ConfirmModal>
    </>
  )
}

export default RevokeAppConfirmation
