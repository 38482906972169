import styled from 'styled-components'

import theme from 'theme_'
import { BluredBlock } from 'components/Layout'
import { IconButton } from 'components/Button'

export const DeleteBtn = styled(IconButton)`
  height: 48px;
  width: 48px;
  top: -12px;
  right: -12px;
  position: absolute;
`

export const OuterContainer = styled.div`
  overflow: hidden;
`

export const CardLabelBase = styled.div<{
  isDefault?: boolean
  isExpired?: boolean
}>`
  font-family: ${theme.fonts.base};
  font-size: 14px;
  line-height: 20px;
  margin-left: auto;
  color: ${(props) =>
    props.isDefault ? theme.colors.dimmedGold : theme.colors.beachGrey};
  ${(p) =>
    p.isExpired &&
    `
  color: ${theme.colors.error};
  font-weight: 700;
`}
`

export const Container = styled(BluredBlock)<{
  isSelected?: boolean
  isExpired?: boolean
}>`
  width: 100%;
  padding: 20px;
  cursor: pointer;
  background: linear-gradient(
    131.13deg,
    rgba(124, 116, 105, 0.5) 2.94%,
    rgba(60, 56, 51, 0.5) 70.05%
  );

  &:hover {
    box-shadow: inset 0 0 0 1px ${theme.colors.dimmedGold};
  }
  ${(p) =>
    p.isExpired &&
    `
    box-shadow: inset 0 0 0 1px ${theme.colors.error};
  `}
  ${(p) =>
    p.isSelected &&
    !p.isExpired &&
    `
    box-shadow: inset 0 0 0 1px ${theme.colors.dimmedGold};
  `}
`

export const PaymentSystem = styled.img`
  flex-shrink: 0;
  margin-right: 8px;
`

export const Number = styled.div`
  display: flex;
  align-items: center;
`

export const DateRow = styled.div`
  position: relative;
  margin-top: 12px;
`
