import { defineMessages } from 'react-intl'

export const authMessages = defineMessages({
  userRoleAdmin: {
    id: 'userRole.admin',
    description: 'Admin user role',
    defaultMessage: 'Admin',
  },
  userRoleHousehold: {
    id: 'userRole.household',
    description: 'Household user role',
    defaultMessage: 'Household',
  },
  userRoleGuest: {
    id: 'userRole.guest',
    description: 'Guest user role',
    defaultMessage: 'Guest',
  },
  userRoleCustom: {
    id: 'userRole.custom',
    description: 'Custom user role',
    defaultMessage: 'Custom permissions',
  },
})
