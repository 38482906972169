import { defineMessages } from 'react-intl'

export const subscriptionTableMessages = defineMessages({
  title: {
    id: 'subscriptionTableMessages.title',
    description: 'Subscription-features table title',
    defaultMessage: 'Key Features',
  },
  dynamicUnlimitedLabel: {
    id: 'subscriptionTableMessages.dynamicUnlimitedElement',
    description: 'Subscription-features Unlimited label',
    defaultMessage: 'Unlimited',
  },
  videoClipsLabel: {
    id: 'subscriptionTableMessages.videoClipsLabel',
    description: 'Subscription-features Video Recordings label',
    defaultMessage: '3 Months or about 1000 clips',
  },
  headerTitleFeatures: {
    id: 'subscriptionTableMessages.headerTitleFeatures',
    description: 'Subscription-features main heading-cell title',
    defaultMessage: 'Features',
  },
  headerTitleIncluded: {
    id: 'subscriptionTableMessages.headerTitleIncluded',
    description: 'Subscription-features title for basic features column',
    defaultMessage: 'Included in your Savant Home',
  },
  headerTitleEssentials: {
    id: 'subscriptionTableMessages.headerTitleEssentials',
    description: 'Subscription-features title for Essentials column',
    defaultMessage: 'Savant Essentials',
  },
  headerTitleEnrolled: {
    id: 'subscriptionTableMessages.headerTitleEnrolled',
    description:
      'Subscription-features title showing that user is subscribed to paid plan',
    defaultMessage: 'Enrolled',
  },
})

export const subscriptionFeaturesMessages: Record<string, any> = defineMessages(
  {
    backup: {
      id: 'subscriptionFeatures.backup',
      description: 'Subscription-feature about backup and restore',
      defaultMessage: 'Cloud Based Home Backup & Restore',
    },
    appleTv: {
      id: 'subscriptionFeatures.appleTv',
      description: 'Subscription-feature about Apple TV',
      defaultMessage: 'Savant Home App for Apple TV',
    },
    zoom: {
      id: 'subscriptionFeatures.zoom',
      description: 'Subscription-feature about Zoom',
      defaultMessage: 'Conference Room Integration with Zoom Rooms',
    },
    ring: {
      id: 'subscriptionFeatures.ring',
      description: 'Subscription-feature about Ring',
      defaultMessage: 'Ring Doorbell and Camera Integration',
    },
    ringNote: {
      id: 'subscriptionFeatures.ringNote',
      description: 'Subscription-feature notes about Ring',
      defaultMessage:
        'NOTE: Recording Storage is based on the subscibed Ring Protect Plan',
    },
    lutron: {
      id: 'subscriptionFeatures.lutron',
      description: 'Subscription-feature about Lutron LEAP',
      defaultMessage: 'Lutron Lighting Control Integration',
    },
    intercom: {
      id: 'subscriptionFeatures.intercom',
      description: 'Subscription-feature about Intercom',
      defaultMessage: 'Local Intercom and Paging',
    },
    video2n: {
      id: 'subscriptionFeatures.video2n',
      description: 'Subscription-feature about 2N camera recordings',
      defaultMessage: '2N Entry Video Recordings',
    },
    enhancements: {
      id: 'subscriptionTableMessages.enhancements',
      description: 'Subscription-feature about future enhancements',
      defaultMessage: 'Future Features/Enhancements',
    },
    updates: {
      id: 'subscriptionFeatures.updates',
      description: 'Subscription-feature about SavantOS upgrades and releases',
      defaultMessage: 'Savant Software Bug Fixes',
    },
    userExperience: {
      id: 'subscriptionFeatures.userExperience',
      description: 'Subscription-feature about Premium user experience',
      defaultMessage: 'Savant App User Experience',
    },
    avDevices: {
      id: 'subscriptionFeatures.avDevices',
      description: 'Subscription-feature about profile integration',
      defaultMessage: 'Direct Integration with 1000s of AV Devices and more',
    },
    savantMusic: {
      id: 'subscriptionFeatures.savantMusic',
      description: 'Subscription-feature about Savant Music',
      defaultMessage: 'Savant Music (Streaming Music Services)',
    },
    lighting: {
      id: 'subscriptionFeatures.lighting',
      description: 'Subscription-feature about Lighting control',
      defaultMessage: 'Savant Lighting Control',
    },
    notifications: {
      id: 'subscriptionFeatures.notifications',
      description: 'Subscription-feature about notifications',
      defaultMessage: 'App Push and Email Notifications',
    },
    remote: {
      id: 'subscriptionFeatures.remote',
      description: 'Subscription-feature about Remote Access',
      defaultMessage: 'Savant App Remote Access',
    },
    cloud: {
      id: 'subscriptionFeatures.cloud',
      description:
        'Subscription-feature about Alexa/Google Home/Siri TV IP etc.',
      defaultMessage: 'Cloud Voice Control with Alexa and Google',
    },
    proRemote: {
      id: 'subscriptionFeatures.proRemote',
      description: 'Subscription-feature about Pro Remote',
      defaultMessage: 'Pro Remote Enabled Siri via Apple TV',
    },
    yaleAugust: {
      id: 'subscriptionFeatures.yaleAugust',
      description: 'Subscription-feature about Yale/August integration',
      defaultMessage: 'Yale / August Locks Integration',
    },
    siri: {
      id: 'subscriptionFeatures.siri',
      description: 'Subscription-feature about Siri integration',
      defaultMessage: 'Siri & Apple Shortcuts with Natural Language Commands.',
    },
    siriNote: {
      id: 'subscriptionFeatures.siriNote',
      description: 'Subscription-feature notes about Siri',
      defaultMessage: 'NOTE: Requires a Mac Savant Host',
    },
    honeywell: {
      id: 'subscriptionFeatures.honeywell',
      description:
        'Subscription-feature about Honeywell Thermostat integration',
      defaultMessage: 'Honeywell T Series Thermostat Integration',
    },
  }
)
