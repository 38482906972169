import { Card } from 'features/payment/types'
import moment from 'moment'

const getExpireDate = (cardExpireYear: number, cardExpireMonth: number) => {
  const expireDate = new Date()
  expireDate.setFullYear(cardExpireYear, cardExpireMonth, 1)
  expireDate.setHours(0, 0, 0, 0)

  return expireDate
}

export const isExpiredCard = (card?: Card): boolean => {
  if (!card) return false

  const currentDate = new Date()

  const expireDate = getExpireDate(card.exp_year, card.exp_month)

  return expireDate < currentDate
}

export const isExpiringCardInDays = (card?: Card): boolean | number => {
  if (!card) return false

  const currentDate = moment()

  const expireDate = getExpireDate(card.exp_year, card.exp_month)
  const monthBeforeExpireDate = moment(expireDate).subtract(1, 'months')

  if (currentDate > monthBeforeExpireDate) {
    const endMonth = moment().endOf('month')

    // Incrementing by one to prevent "0 days" in messages and "falsy" checks
    return endMonth.diff(currentDate, 'days') + 1
  }

  return false
}
