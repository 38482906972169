import styled from 'styled-components'

import theme from 'theme_'
import { Container } from 'components/Fields/AccessRevocationField/styles'
import { Secondary } from 'components/Typography'

export const DealerPermissionParagraph = styled(Secondary)`
  margin-top: ${theme.spacing.md};
  color: ${theme.colors.mutedGray};
`

export const DealerAccessExpiresContainer = styled(Container)`
  margin-top: ${theme.spacing.s};
`

export const RemoveDealer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${theme.spacing.md};
`
