import { FC, InputHTMLAttributes } from 'react'
import Toggle from 'react-toggle'

import 'react-toggle/style.css'

import { ToggleContainer } from './styles'

export interface ToggleProps extends InputHTMLAttributes<HTMLInputElement> {}

const ToggleDrawer: FC<ToggleProps> = ({
  checked,
  onChange,
  name,
  disabled,
}) => (
  <ToggleContainer>
    <Toggle
      icons={false}
      {...{ name, checked, onChange, disabled, id: name }}
    />
  </ToggleContainer>
)

export default ToggleDrawer
