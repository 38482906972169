import React, { FC, useEffect, useState } from 'react'
import Icon from '@mdi/react'
import { mdiClose, mdiFilterVariant } from '@mdi/js'
import { CloseBtn, OpenFilterModalIcon, FilterModalContainer } from './styles'
import Modal from 'components/Modal'

const FilterModal: FC = ({ children }) => {
  const [isOpen, setModalOpen] = useState(false)

  useEffect(() => {
    window.addEventListener('resize', handleClose)
    return () => {
      window.removeEventListener('resize', handleClose)
    }
  }, [])

  const handleClick = () => {
    setModalOpen(true)
  }

  const handleClose = () => {
    setModalOpen(false)
  }

  return (
    <>
      <Modal isOpen={isOpen} onRequestClose={handleClose}>
        <FilterModalContainer>
          <CloseBtn onClick={handleClose}>
            <Icon path={mdiClose} />
          </CloseBtn>

          {children}
        </FilterModalContainer>
      </Modal>

      <OpenFilterModalIcon onClick={handleClick}>
        <Icon path={mdiFilterVariant} />
      </OpenFilterModalIcon>
    </>
  )
}

export default FilterModal
