import { defineMessages } from 'react-intl'

export const fieldsMessages = defineMessages({
  invalidEmail: {
    id: 'validation.invalidEmail',
    description: 'Validation error text for invalid email format in input',
    defaultMessage:
      'Make sure to enter a valid email address in the correct format.',
  },
  requiredField: {
    id: 'validation.required',
    description: 'Validation error text for required input/field',
    defaultMessage: 'This field is required',
  },
  shouldBeNumber: {
    id: 'validation.shouldBeNumber',
    description:
      'Validation error text if value is NOT a Number while it should be',
    defaultMessage: 'Numbers only',
  },
})
