import React from 'react'
import { useIntl } from 'react-intl'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import { mdiCheckCircleOutline } from '@mdi/js'

import CommonButton from 'components/Button/CommonButton'
import { StyledButtonsWrapper, SuccessIcon } from './styles'
import { twoFactorAuthSuccessMessages } from './messages'
import routes from 'app/routes'
import LoginCommonLayout from '../../LoginCommonLayout'
import { StyledLoginForm } from '../../LoginForm/styles'
import { StyledMainHeading } from '../../MainHeading/MainHeading'
import { ILoginFormValues } from 'features/auth/login/types'
import { TwoFactorRouteState } from '../TwoFactorAuth'

interface TwoFactorAuthResendSuccessProps {
  userCreds?: ILoginFormValues
}

interface TwoFactorAuthResendState extends TwoFactorRouteState {}

const TwoFactorAuthResendSuccess: React.FC<TwoFactorAuthResendSuccessProps> = () => {
  const { formatMessage } = useIntl()
  const history = useHistory()

  const { state: routeState } = useLocation<TwoFactorAuthResendState>()

  const handleProceed = () => {
    history.push(routes.auth.twoFa, {
      savCreds: routeState.savCreds,
    })
  }

  return (
    <LoginCommonLayout>
      {routeState.savCreds ? (
        <StyledLoginForm as="div">
          <SuccessIcon path={mdiCheckCircleOutline} size={3} />
          <StyledMainHeading>
            {formatMessage(twoFactorAuthSuccessMessages.title)}
          </StyledMainHeading>
          <StyledButtonsWrapper>
            <CommonButton
              type="button"
              variant="goldenFilled"
              onClick={handleProceed}
            >
              {formatMessage(twoFactorAuthSuccessMessages.proceed)}
            </CommonButton>
          </StyledButtonsWrapper>
        </StyledLoginForm>
      ) : (
        <Redirect to="/" />
      )}
    </LoginCommonLayout>
  )
}

export default TwoFactorAuthResendSuccess
