import { defineMessages } from 'react-intl'

export const cameraRecordingsMessages = defineMessages({
  title: {
    id: 'cameraRecordings.title',
    description: 'Camera Recordings page title',
    defaultMessage: 'Camera recordings',
  },
  noClips: {
    id: 'cameraRecordings.noClips',
    description:
      'Camera Recordings page - info message when no clips(recordings) found',
    defaultMessage: 'No recordings found',
  },
  noCameras: {
    id: 'cameraRecordings.noCameras',
    description: 'Camera Recordings page - info message when no cameras found',
    defaultMessage: 'No cameras found',
  },
  loadMore: {
    id: 'cameraRecordings.loadMore',
    description: 'Button text to load more video clips',
    defaultMessage: 'Load More',
  },
})
