import React from 'react'
import { FormattedDate } from 'react-intl'

interface IntlFormattedDateProps {
  date: string | number | Date | null | undefined
  withTime?: boolean
  defaultReturn?: string
}

export const IntlFormattedDate: React.FC<IntlFormattedDateProps> = (props) => {
  return (
    <>
      {props.date && (
        <FormattedDate
          value={props.date}
          year="numeric"
          month="2-digit"
          day="2-digit"
          hour={props.withTime ? 'numeric' : undefined}
          minute={props.withTime ? 'numeric' : undefined}
        />
      )}
      {!props.date && !!props.defaultReturn && props.defaultReturn}
    </>
  )
}

export default IntlFormattedDate
