import React from 'react'
import { Formik } from 'formik'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'

import { StyledLoginForm } from '../LoginForm/styles'
import { forgotPasswordMessages } from './messages'
import { StyledButtonsWrapper, StyledDescriptionText } from './styles'
import { PassInitResetParams } from './types'
import CommonButton from 'components/Button/CommonButton'
import { EmailField } from 'components/Fields'
import { fieldsMessages } from 'components/Fields/messages'
import { LoaderOverlay } from 'components/Loader'

interface ForgotPassFormParams {
  onSubmit: (params: PassInitResetParams) => void
  isLoading: boolean
}

const initialForgotPassValues: PassInitResetParams = {
  email: '',
}

const ForgotPasswordForm: React.FC<ForgotPassFormParams> = ({
  onSubmit,
  isLoading,
}) => {
  const history = useHistory()
  const { formatMessage } = useIntl()

  const handleSubmit = (values: PassInitResetParams) => {
    onSubmit(values)
  }

  return (
    <Formik
      initialValues={initialForgotPassValues}
      validationSchema={Yup.object().shape({
        email: Yup.string().required(
          formatMessage(fieldsMessages.requiredField)
        ),
      })}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <StyledLoginForm>
          {formikProps.isSubmitting && <LoaderOverlay />}
          <StyledDescriptionText>
            {formatMessage(forgotPasswordMessages.description)}
          </StyledDescriptionText>
          <StyledDescriptionText>
            {formatMessage(forgotPasswordMessages.relogWarning)}
          </StyledDescriptionText>

          <EmailField name="email" invalid={formikProps.errors.email} />

          <StyledButtonsWrapper>
            <CommonButton type="button" onClick={() => history.push('/')}>
              {formatMessage(forgotPasswordMessages.goToLogin)}
            </CommonButton>
            <CommonButton
              type="submit"
              variant="goldenFilled"
              disabled={
                isLoading || !formikProps.values.email || !formikProps.isValid
              }
            >
              {formatMessage(forgotPasswordMessages.sendEmail)}
            </CommonButton>
          </StyledButtonsWrapper>
        </StyledLoginForm>
      )}
    </Formik>
  )
}

export default ForgotPasswordForm
