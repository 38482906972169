import { Link } from 'react-router-dom'
import styled from 'styled-components'

const CommonLink = styled(Link)`
  color: ${(p) => p.theme.colors.dimmedGold};
  text-decoration: none;
  :hover,
  :focus-visible {
    text-decoration: underline;
    outline: none;
  }
`
export default CommonLink
