import React from 'react'
import { useIntl } from 'react-intl'

import { H1 } from 'components/Typography/Headings'
import { SubscriptionsSummaryWrapper } from './styles'
import { subscriptionPageMessages } from './messages'
import { Spinner } from 'components/Loader'
import { CommonSpinnedWrapper } from 'components/Loader/styles'
import ReactHelmet from 'features/analytics/RenderHelmet'
import SubscriptionInfo from '../components/SubscriptionInfo'
import { useAppSelector } from 'app/hooks'
import { currentHomeInfoSelectors } from '../selectors'
import SubscriptionTable from 'components/SubscriptionTable'

const Subscription: React.FC = () => {
  const { formatMessage } = useIntl()

  const isHomeInfoFetching = useAppSelector(currentHomeInfoSelectors.isFetching)

  return (
    <>
      <ReactHelmet title={subscriptionPageMessages.title.defaultMessage} />

      <H1>{formatMessage(subscriptionPageMessages.title)}</H1>
      {isHomeInfoFetching ? (
        <SubscriptionsSummaryWrapper>
          <CommonSpinnedWrapper>
            <Spinner />
          </CommonSpinnedWrapper>
        </SubscriptionsSummaryWrapper>
      ) : (
        <SubscriptionInfo isExpanded />
      )}
      <SubscriptionTable />
    </>
  )
}

export default Subscription
