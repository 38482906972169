import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  default: {
    id: 'card.default',
    description: 'Text on a card saying that this card is default',
    defaultMessage: 'Default',
  },
  setAsDefault: {
    id: 'card.setDefault',
    description: 'Text on a button to set a payment card as default',
    defaultMessage: 'Make default',
  },
  expired: {
    id: 'card.expired',
    description: 'Text saying that card has expired',
    defaultMessage: 'Expired',
  },
  removePaymentMethodModal: {
    id: 'card.modalRemove',
    description: 'Text on a button to remove payment method',
    defaultMessage: 'REMOVE PAYMENT METHOD',
  },
  setAsDefaultModal: {
    id: 'card.modalSetDefault',
    description: 'Make card is default',
    defaultMessage: 'CHANGE DEFAULT PAYMENT METHOD',
  },
  cancel: {
    id: 'card.btnCancel',
    description: 'Cancel "remove payment method" operation button text',
    defaultMessage: 'NO, CANCEL',
  },
  yesRemove: {
    id: 'card.btnRemoveYes',
    description: 'Apply "remove payment method" operation button text',
    defaultMessage: 'YES, REMOVE',
  },
  yesChange: {
    id: 'card.btnYesChange',
    description: 'Confirm "set payment method as default" operation',
    defaultMessage: 'YES, CHANGE',
  },
  removeText: {
    id: 'card.removeModaltext',
    description: 'Text of modal for removing card',
    defaultMessage: 'This payment method won’t be available anymore',
  },
  removeDefaultCardText: {
    id: 'card.removeDefaultCardText',
    description: 'Text of modal for removing default card',
    defaultMessage:
      'Are you sure you want to delete Default payment method? The other  payment method will be default.',
  },
  removeDefaultCardWithSubscriptionText: {
    id: 'card.removeDefaultCardWithSubscriptionText',
    description: 'Text of modal for removing default card with subscription',
    defaultMessage:
      'Are you sure you want to delete Default payment method? Please add valid payment method to renew subscription.',
  },
  removeDefaultCardWithoutSubscriptionText: {
    id: 'card.removeDefaultCardWithoutSubscriptionText',
    description: 'Text of modal for removing default card without subscription',
    defaultMessage: 'Are you sure you want to delete Default payment method?',
  },
  removeDefaultCardByNotAdminText: {
    id: 'card.removeDefaultCardByNotAdminText',
    description:
      'Text of modal for removing default card by not an admin of the house',
    defaultMessage: 'Are you sure you want to delete Default payment method?',
  },
  defaultText: {
    id: 'card.defaultModaltext',
    description: 'Text of modal for making card as default',
    defaultMessage:
      'This card will be used for the next charges for your subscriptions',
  },
})
