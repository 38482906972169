import styled from 'styled-components'
import { BluredBlock } from '../BluredBlock'
import theme from 'theme_'

export const StubPageWrapper = styled(BluredBlock)`
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing.xl} ${theme.spacing.md};
  width: 100%;

  @media ${theme.devices.tablet} {
    height: 450px;
  }

  @media ${theme.devices.desktop} {
    max-width: 1024px;
    height: 600px;
  }
`
