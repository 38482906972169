import React, { memo, useCallback, useContext, useState } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { mdiChevronDown } from '@mdi/js'

import Options from './Options'
import {
  LanguageSelectorContainer,
  LabelContainer,
  ChevronContainer,
  LangLabel,
  SelectedFlagImage,
  StyledChevronIcon,
  LabelContainerInner,
} from './styles'
import { LocalizationContext } from 'components/i18n/LocalizationWrapper/LocalizationWrapper'
import { displayFullLanguage, displayLangCode } from 'app/utils/displayLangCode'
import { flags } from 'lang/flags'

export const LANG = {
  EN: 'en-US',
  FR: 'fr-CA',
  RU: 'ru-RU',
  ES: 'es-MX',
  SV: 'sv-SE',
  PT: 'pt-BR',
  CN: 'zh-Hans-CN',
}

const LANG_OPTIONS = [
  { id: LANG.EN, label: 'English' },
  { id: LANG.FR, label: 'French' },
  { id: LANG.RU, label: 'Russian' },
  { id: LANG.ES, label: 'Spanish' },
  { id: LANG.SV, label: 'Swedish' },
  { id: LANG.PT, label: 'Portuguese' },
  { id: LANG.CN, label: 'Chinese' },
]

interface ILanguageSelectorProps {
  mobileClickHandler?: () => void
  isMobileMode?: boolean
}

const LanguageSelector: React.FC<ILanguageSelectorProps> = (props) => {
  const localizationContext = useContext(LocalizationContext)
  const currentAppLanguage = localStorage.getItem('sav-language')

  const [isOpen, setIsOpen] = useState(props.isMobileMode ? true : false)
  const [language, setLanguage] = useState<string>(
    currentAppLanguage || navigator.language
  )

  const handleOpenToggle = useCallback(() => setIsOpen(!isOpen), [isOpen])

  const onSelectedChange = (lang: string) => {
    setLanguage(lang)
    localizationContext.selectLanguage(lang)
  }

  return props.isMobileMode ? (
    <Options
      items={LANG_OPTIONS}
      selectedLang={language}
      isOpen={isOpen}
      inMobileMenu
      onSelectedChange={(lang: string) => onSelectedChange(lang)}
    />
  ) : (
    <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
      <LanguageSelectorContainer
        onClick={props.mobileClickHandler || handleOpenToggle}
        isOpen={isOpen}
      >
        <LabelContainer>
          <LabelContainerInner>
            <SelectedFlagImage alt="Flag" src={flags(language)} />
            <LangLabel>
              {props.mobileClickHandler
                ? displayFullLanguage(language)
                : displayLangCode(language)}
            </LangLabel>
          </LabelContainerInner>

          <ChevronContainer isOpen={isOpen}>
            <StyledChevronIcon path={mdiChevronDown} size={1} />
          </ChevronContainer>
        </LabelContainer>

        <Options
          items={LANG_OPTIONS}
          selectedLang={language}
          isOpen={isOpen}
          onSelectedChange={(lang: string) => onSelectedChange(lang)}
        />
      </LanguageSelectorContainer>
    </OutsideClickHandler>
  )
}

export default memo(LanguageSelector)
