import styled from 'styled-components'

import theme from 'theme_'
import { BluredBlock } from 'components/Layout'

export const StyledMySubscriptionWrapper = styled(BluredBlock)<{
  bordered?: boolean
}>`
  ${(p) =>
    p.bordered &&
    `
    border: 1px solid ${theme.colors.dimmedGold};
  `}
`

export const MySubscriptionItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  > p {
    margin: 6px 0;
  }
`

export const MySubscriptionItemTitle = styled.div`
  line-height: ${theme.spacing.base};
  height: ${theme.spacing.sm};
`
export const MySubscriptionItemSubTitle = styled.div`
  display: flex;
  align-items: center;
  line-height: ${theme.spacing.base};
  height: ${theme.spacing.sm};
  color: ${theme.colors.beachGrey};
  text-decoration: none;

  & > svg {
    flex-shrink: 0;
    margin-right: 5px;
  }
`

export const MySubscriptionItemSubTitleTextLong = styled.div`
  display: flex;
  align-items: center;
  line-height: 30px;
`

export const MySubscriptionItemSubTitleWithIcon = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: ${theme.spacing.sm};
  color: ${theme.colors.beachGrey};
`
