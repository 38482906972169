import { mdiEyeOutline } from '@mdi/js'
import { makePublicUrl } from 'app/utils/publicUrl'
import { MdiIcon } from 'components/Icons'
import { PaymentSystem } from 'components/Payment/Card/styles'
import { Secondary } from 'components/Typography'
import { homesListSelector } from 'features/homes/selectors'
import { useIntl } from 'react-intl'
import moment from 'moment'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { billingHistory } from '../../messages'
import { BillingTd, BillingTr } from '../../styles'
import { CardContainer, ErrorStatus, IconContainer, WhiteLable } from './styles'

export interface IBillingRow {
  id: string
  brand: string
  number: string
  amount: number
  status: string
  date: number
  receipt_url: string
  invoice_url: string
  homeId: string
  desc: string
  startAt: number
  endAt: number
}

const BillingRow: FC<IBillingRow> = (props) => {
  const { formatMessage } = useIntl()
  const homes = useSelector(homesListSelector)
  const home = homes.find((x: any) => x.id === props.homeId)

  return (
    <>
      <BillingTr>
        <BillingTd>{moment.unix(props.date).format('lll')}</BillingTd>
        <BillingTd>
          {!home ? formatMessage(billingHistory.homeNotFound) : home.name}
        </BillingTd>
        <BillingTd>
          {props.desc}
          <br></br>{' '}
          {moment.unix(props.startAt).format('ll') +
            '-' +
            moment.unix(props.endAt).format('ll')}
        </BillingTd>
        <BillingTd>
          {props.brand && (
            <>
              <CardContainer>
                <PaymentSystem
                  src={makePublicUrl(`images/paymentLogos/${props.brand}.svg`)}
                  alt={props.brand}
                />
                <Secondary>{`**** ${props.number}`}</Secondary>
              </CardContainer>
            </>
          )}
        </BillingTd>
        <BillingTd>
          <WhiteLable>{props.amount}</WhiteLable>
        </BillingTd>
        <BillingTd>
          {props.status === 'succeeded' ? (
            <>
              <IconContainer>
                <Link to={{ pathname: props.receipt_url }} target="_blank">
                  <MdiIcon path={mdiEyeOutline} />
                </Link>
              </IconContainer>
            </>
          ) : (
            <ErrorStatus>{props.status}</ErrorStatus>
          )}
        </BillingTd>
      </BillingTr>
    </>
  )
}

export default BillingRow
