import { PassInitResetParams, PassResetConfirmParams } from './types'
import { unauthorizedEdgeFetch } from 'app/axios/edge'

export const passInitReset = (params: PassInitResetParams) =>
  unauthorizedEdgeFetch.post('/users/password/reset', params)

export const passResetConfirm = (
  params: PassResetConfirmParams,
  userId: string
) => unauthorizedEdgeFetch.put(`/users/${userId}/password`, params)
