import { Fragment } from 'react'
import { useIntl } from 'react-intl'

import mock from './subscriptionTableMock.json'
import {
  SubscriptionTableInnerContainer,
  SubscriptionTableOuterContainer,
} from './styles'
import SubscriptionTableHeader from './SubscriptionTableHeader'
import SubscriptionTableRow from './SubscriptionTableRow'
import { useAppSelector } from 'app/hooks'
import { subscriptionTableMessages } from './messages'
import { activeSubscriptionSelector } from 'features/homes/Subscriptions/selectors'
import { H5 } from 'components/Typography'

function SubscriptionTable() {
  const { formatMessage } = useIntl()

  const plan = useAppSelector(activeSubscriptionSelector)

  const isBasicHomePlan = !plan

  return (
    <div>
      <H5>{formatMessage(subscriptionTableMessages.title)}</H5>
      <SubscriptionTableOuterContainer>
        <SubscriptionTableInnerContainer>
          <SubscriptionTableHeader isBasicHomePlan={isBasicHomePlan} />
          {mock.map((row) => (
            <Fragment key={row.features}>
              <SubscriptionTableRow
                {...row}
                isBasicHomePlan={isBasicHomePlan}
              />
            </Fragment>
          ))}
        </SubscriptionTableInnerContainer>
      </SubscriptionTableOuterContainer>
    </div>
  )
}

export default SubscriptionTable
