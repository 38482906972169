import { defineMessages } from 'react-intl'

export const resetPasswordMessages = defineMessages({
  title: {
    id: 'resetPassword.title',
    description: 'Reset Passwords title',
    defaultMessage: 'Reset Password',
  },
  description: {
    id: 'resetPassword.description',
    description: 'Description about how to reset password',
    defaultMessage: 'Please enter a new password for your Savant Account.',
  },
  validation: {
    id: 'resetPassword.validation',
    description: 'Describe password options',
    defaultMessage:
      'Password must contain a minimum of 8 characters with at least one uppercase character, one lowercase character, and one number.',
  },
  submitButton: {
    id: 'resetPassword.submit',
    description: 'Submit buttons text',
    defaultMessage: 'Submit ',
  },
  confirmPassword: {
    id: 'resetPassword.confirm',
    description: 'Confirm password text',
    defaultMessage: 'Confirm Password',
  },
  passwordsDoNotMatch: {
    id: 'resetPassword.doNotMatch',
    description: 'Warning about different passwords',
    defaultMessage: 'Passwords do not match',
  },
  shortPassword: {
    id: 'resetPassword.shortPassword',
    description: 'Warning about short passwords',
    defaultMessage: 'Password is too short - should be 8 characters minimum',
  },
})
