import styled from 'styled-components'
import Icon from '@mdi/react'

import { Regular } from 'components/Typography'
import theme from 'theme_'

export const StyledErrorTitleText = styled.div`
  font-size: 40px;
  line-height: 50px;
  color: ${theme.colors.duneWarmGrey};
  text-align: center;
  @media only screen and (max-width: ${theme.breakpoint.desktop}) {
    margin: ${theme.spacing.s} ${theme.spacing.md} ${theme.spacing.md};
  }
`
export const SuccessIcon = styled(Icon)`
  color: ${theme.colors.success};
  margin: ${theme.spacing.xl} auto 0;
`

export const StyledDescriptionText = styled(Regular)`
  margin: ${`${theme.spacing.s} ${theme.spacing.xl} ${theme.spacing.xl}`};
  text-align: center;

  @media only screen and (max-width: ${theme.breakpoint.desktop}) {
    margin: ${theme.spacing.s} ${theme.spacing.md} ${theme.spacing.md};
  }
`

export const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
`
