import React, { FC, useCallback, useState } from 'react'
import { PartnerShape } from 'app/types/partner'
import {
  ConnectedAppContainer,
  ConnectedAppIconContainer,
  ConnectedAppIconImg,
  ConnectedAppStatusCircle,
  ConnectedAppStatusWrapper,
  ConnectedAppStatus,
  ConnectedAppButton,
  ConnectedAppName,
  ConnectedAppHeader,
} from './styles'
import { FormattedMessage, useIntl } from 'react-intl'
import { connectedAppsMessages } from '../../../messages'
import RevokeAppConfirmation from '../../RevokeAppConfirmation'
import { useAppSelector } from '../../../../../app/hooks'
import { currentHomeIdSelector } from '../../../../homes/selectors'

interface ConnectedAppProps {
  connectedApp: PartnerShape
}

const ConnectedApp: FC<ConnectedAppProps> = ({ connectedApp }) => {
  const {
    name: appName,
    authUrl,
    friendlyName,
    active,
    iconUrl,
    label: appLabel,
    newPage,
  } = connectedApp
  const { formatMessage } = useIntl()
  const [isConfirmRevokeModalOpen, setIsConfirmRevokeModalOpen] = useState(
    false
  )
  const currentHomeId = useAppSelector(currentHomeIdSelector)
  const getStatusMessage = useCallback(
    (active: boolean) => {
      return active
        ? formatMessage(connectedAppsMessages.active)
        : formatMessage(connectedAppsMessages.inactive)
    },
    [formatMessage]
  )
  //const [isEnrollModalOpen, setIsEnrollModalOpen] = useState(false)
  //const onEnrollModalClose = () => setIsEnrollModalOpen(false)

  return (
    <>
      {isConfirmRevokeModalOpen && (
        <RevokeAppConfirmation
          isOpen
          {...{ currentHomeId, appName, appLabel }}
          handleDiscard={() => setIsConfirmRevokeModalOpen(false)}
          handleConfirm={() => setIsConfirmRevokeModalOpen(false)}
        />
      )}
      <ConnectedAppContainer>
        <ConnectedAppHeader>
          <ConnectedAppStatusWrapper>
            <ConnectedAppStatusCircle status={active} />
            <ConnectedAppStatus>{getStatusMessage(active)}</ConnectedAppStatus>
          </ConnectedAppStatusWrapper>

          {friendlyName !== appName && (
            <ConnectedAppName>{friendlyName}</ConnectedAppName>
          )}
        </ConnectedAppHeader>
        <ConnectedAppIconContainer>
          <ConnectedAppIconImg
            src={iconUrl}
            alt="Savant connected application icon"
          />
        </ConnectedAppIconContainer>

        {!active && newPage && (
          <ConnectedAppButton
            as="a"
            href={authUrl}
            variant="goldenFilled"
            disabled={!authUrl}
          >
            <FormattedMessage {...connectedAppsMessages.buttonTextActivate} />
          </ConnectedAppButton>
        )}

        {!active && !newPage && (
          <ConnectedAppButton
            as="a"
            href={authUrl}
            target="_blank"
            variant="goldenFilled"
            disabled={!authUrl}
          >
            <FormattedMessage {...connectedAppsMessages.buttonTextActivate} />
          </ConnectedAppButton>
        )}

        {active && (
          <ConnectedAppButton
            variant={'errorFilled'}
            onClick={() => setIsConfirmRevokeModalOpen(true)}
          >
            <FormattedMessage {...connectedAppsMessages.buttonTextRevoke} />
          </ConnectedAppButton>
        )}
      </ConnectedAppContainer>
    </>
  )
}

export default ConnectedApp
