import { defineMessages } from 'react-intl'

export const mobileMenuMessages = defineMessages({
  settings: {
    id: 'mobileMenu.settings',
    description:
      'Mobile Menu button text telling user he is on Account Settings screen',
    defaultMessage: 'Account Settings',
  },
})
