import styled from 'styled-components'
import Icon from '@mdi/react'
import theme from 'theme_'

export const StyledDealerImageWrap = styled.div`
  text-align: center;
  margin-bottom: ${theme.spacing.md};
`

export const StyledDealerImg = styled.img`
  display: block;
  margin: 0 auto;
  max-width: 100%;
  max-height: 200px;
  margin-bottom: 30px;
`

export const StyledDealerCardContacts = styled.div<{ fullWidth?: boolean }>`
  width: 100%;
  max-width: ${(p) => (p.fullWidth ? '460px' : '100%')};
  ${(p) => p.fullWidth && 'margin: 0 auto'};
`

export const StyledDealerContactsLink = styled.a`
  color: ${theme.colors.dolphinGrey};
  display: inline-flex;
  align-items: center;
  margin-top: ${theme.spacing.s};
  text-decoration: none;

  &:hover {
    color: ${theme.colors.beachGrey};
  }
`

export const StyledIcon = styled(Icon)`
  margin-right: ${theme.spacing.s};
`

export const StyledDealerName = styled.div`
  font-size: 20px;
  font-weight: 400;
  line-height: 1;
  color: ${theme.colors.beachGrey};
`
