import { authorizedBillingFetch } from 'app/axios/billing'

export const getBillingHistory = (userId: string, lastObjId: string) => {
  if (lastObjId !== '') {
    return authorizedBillingFetch.get(
      `/user/${userId}/charges?limit=10&lastObjId=${lastObjId}`
    )
  } else {
    return authorizedBillingFetch.get(`/user/${userId}/charges?limit=10`)
  }
}
