import { authorizedEdgeFetch } from 'app/axios/edge'
import { UserPermissions } from 'app/types/user'

export const getUsers = (homeId: string) =>
  authorizedEdgeFetch.get(`homes/${homeId}/users`)

export interface UserDetailsParams {
  homeId: string
  userId: string
}

export const getUserDetailsByHome = ({ homeId, userId }: UserDetailsParams) =>
  authorizedEdgeFetch.get(`homes/${homeId}/users/${userId}`)

export interface UpdatePermissionsParams extends UserDetailsParams {
  permissions: UserPermissions
}

export const updateUserPermissions = ({
  homeId,
  userId,
  permissions,
}: UpdatePermissionsParams) =>
  authorizedEdgeFetch.put(
    `homes/${homeId}/users/${userId}/permissions`,
    permissions
  )

export interface DeleteUserParams {
  homeId: string
  userId: string
}

export const deleteUser = ({ homeId, userId }: DeleteUserParams) =>
  authorizedEdgeFetch.delete(`homes/${homeId}/users/${userId}`)

export const INVITE_USER_ERROR = {
  HOME_009: 'HOME_009',
}

export interface InviteUserRequestParams {
  homeId: string
  email: string
  firstName: string
  lastName: string
  message: string
  permissions: UserPermissions
  revocationTime?: number
}

export const inviteUser = ({ homeId, ...params }: InviteUserRequestParams) =>
  authorizedEdgeFetch.post(`/homes/${homeId}/invitations`, params)
