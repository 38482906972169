import { FC } from 'react'

import Spinner from './Spinner'

import { CenteredContainer, SpinnerContainerProps } from './styles'

const SpinnerCentered: FC<SpinnerContainerProps> = (props) => (
  <CenteredContainer {...props}>
    <Spinner />
  </CenteredContainer>
)

export default SpinnerCentered
