import React from 'react'

import { ReactComponent as WhiteLogoSvg } from 'images/Savant_white_logo.svg'
import { StyledLanguageWrapper, StyledLoginHeader } from './styles'
import LanguageSelector from 'components/LanguageSelector'
import { useBgOnScroll } from 'app/utils/useBgOnScroll'

const LoginHeader: React.FC = () => {
  const shouldHaveBg = useBgOnScroll()

  return (
    <StyledLoginHeader shouldHaveBg={shouldHaveBg}>
      <WhiteLogoSvg />
      <StyledLanguageWrapper>
        <LanguageSelector />
      </StyledLanguageWrapper>
    </StyledLoginHeader>
  )
}

export default LoginHeader
