import { defineMessages } from 'react-intl'

export const twoFactorAuthSuccessMessages = defineMessages({
  title: {
    id: 'twoFactorSuccess.title',
    description: 'Title of success resend code page',
    defaultMessage: 'Verification Resent Successfully',
  },
  description: {
    id: 'twoFactorSuccess.description',
    description: 'Description text of 2fa success resnd code page',
    defaultMessage:
      'Your verification code has successfully been resent to your email.',
  },
  proceed: {
    id: 'twoFactorSuccess.proceed',
    description: 'Button text to proceed and go to back',
    defaultMessage: 'Proceed',
  },
})
