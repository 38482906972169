import Icon from '@mdi/react'
import styled from 'styled-components'

import theme from 'theme_'
import { Regular } from 'components/Typography'

const { spacing, colors } = theme

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 240px;
  max-width: fit-content;
  padding: ${spacing.xs} ${spacing.base} ${spacing.tiny} ${spacing.base};
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.3);
`

export const CopyIconBtn = styled.button`
  padding: 0;
  font-weight: bold;
  border: none;
  color: ${colors.dimmedGold};
  background-color: transparent;
  cursor: pointer;
`

export const Label = styled(Regular)`
  font-weight: 400;
  margin-right: ${spacing.s};
`

export const StyledCopyIcon = styled(Icon)<{ $copied?: boolean }>`
  color: ${(p) => (p.$copied ? colors.success : colors.dimmedGold)};
  transition: color 0.2s;
`
