import { defineMessages } from 'react-intl'

export const subscriptionFeaturesMessages = defineMessages({
  through_your_dealer: {
    id: 'subscriptionFeature.through_your_dealer',
    description:
      'Subscription provided feature of Support category "Through your dealer"',
    defaultMessage: 'Through your dealer',
  },
  homeowner_technical_support: {
    id: 'subscriptionFeature.homeowner_technical_support',
    description:
      'Subscription provided feature of Support category "Homeowner Technical Support"',
    defaultMessage: 'Homeowner Technical Support',
  },
  extended_hours: {
    id: 'subscriptionFeature.extended_hours',
    description: 'Subscription feature category "Extended Hours"',
    defaultMessage: 'Extended Hours',
  },
  google_home_voice: {
    id: 'subscriptionFeature.google_home_voice',
    description: 'Subscription feature category "Google Home Voice"',
    defaultMessage: 'Google Home Voice',
  },
  amazon_alexa_voice: {
    id: 'subscriptionFeature.amazon_alexa_voice',
    description: 'Subscription feature category "Amazon Alexa Voice"',
    defaultMessage: 'Amazon Alexa Voice',
  },
  remote_home_access: {
    id: 'subscriptionFeature.remote_home_access',
    description: 'Subscription feature category "Remote Home Access"',
    defaultMessage: 'Remote Home Access',
  },
  notifications: {
    id: 'subscriptionFeature.notifications',
    description: 'Subscription feature category "Notifications"',
    defaultMessage: 'Notifications',
  },
  door_station_clip_storage: {
    id: 'subscriptionFeature.door_station_clip_storage',
    description:
      'Subscription feature category "Door Station Clip Storage (2Gb)"',
    defaultMessage: 'Door Station Clip Storage (2Gb)',
  },
  aI_security: {
    id: 'subscriptionFeature.aI_security',
    description: 'Subscription feature category "AI Security"',
    defaultMessage: 'AI Security',
  },
  nvr_video_clips: {
    id: 'subscriptionFeature.nvr_video_clips',
    description: 'Subscription feature category "NVR Video Clips"',
    defaultMessage: 'NVR Video Clips',
  },
  all_standard_profiles: {
    id: 'subscriptionFeature.all_standard_profiles',
    description: 'Subscription feature category "All Standard Profiles"',
    defaultMessage: 'All Standard Profiles',
  },
  ring: {
    id: 'subscriptionFeature.ring',
    description: 'Subscription feature category "Ring"',
    defaultMessage: 'Ring',
  },
  lutron: {
    id: 'subscriptionFeature.lutron',
    description: 'Subscription feature category "Lutron"',
    defaultMessage: 'Lutron',
  },
  zoom_rooms: {
    id: 'subscriptionFeature.zoom_rooms',
    description: 'Subscription feature category "Zoom Rooms"',
    defaultMessage: 'Zoom Rooms',
  },
  josh_ai: {
    id: 'subscriptionFeature.josh_ai',
    description: 'Subscription feature category "Josh.ai"',
    defaultMessage: 'Josh.ai',
  },
  local_use_of_standart_features: {
    id: 'subscriptionFeature.local_use_of_standart_features',
    description:
      'Subscription feature category "Local use of standart features"',
    defaultMessage: 'Local use of standart features',
  },
  in_app_channel_guide: {
    id: 'subscriptionFeature.in_app_channel_guide',
    description: 'Subscription feature category "In app channel guide"',
    defaultMessage: 'In app channel guide',
  },
  advanced_automation: {
    id: 'subscriptionFeature.advanced_automation',
    description: 'Subscription feature category "Advanced Automation"',
    defaultMessage: 'Advanced Automation',
  },
  savant_voice: {
    id: 'subscriptionFeature.savant_voice',
    description: 'Subscription feature category "Savant Voice"',
    defaultMessage: 'Savant Voice',
  },
})
