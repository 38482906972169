import styled from 'styled-components'
import theme from 'theme_'
import {
  StyledHeaderInner,
  StyledHeaderWrapper,
} from 'components/Header/styles'

export const StyledMobileMenuInner = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  /* mobile viewport bug fix */
  height: -webkit-fill-available;
  background: ${theme.overlay.newDropdownBlack};
  backdrop-filter: ${theme.overlay.SBlur};
  overflow: auto;
`

export const MobileMenuHeader = styled(StyledHeaderWrapper)`
  position: static;
`

export const MobileMenuHeaderInner = styled(StyledHeaderInner)``

export const MobileInnerCloseBtn = styled.button`
  appearance: none;
  width: ${theme.spacing.md};
  height: ${theme.spacing.md};
  padding: 0;
  border: none;
  background: transparent;
`
