import English from 'translations/en_US.json'
import French from 'translations/fr_CA.json'
import Russian from 'translations/ru_RU.json'
import Spanish from 'translations/es_MX.json'
import Swedish from 'translations/sv_SE.json'
import Portuguese from 'translations/pt_BR.json'
import Chinese from 'translations/zh_Hans_CN.json'
import {
  isChineseLocale,
  isFrenchLocale,
  isPortugueseLocale,
  isRussianLocale,
  isSpanishLocale,
  isSwedishLocale,
} from 'app/utils/locales'

const getTranslationByLocale = (locale: string): Record<string, string> => {
  if (isFrenchLocale(locale)) {
    return French
  }
  if (isRussianLocale(locale)) {
    return Russian
  }
  if (isSpanishLocale(locale)) {
    return Spanish
  }
  if (isSwedishLocale(locale)) {
    return Swedish
  }
  if (isPortugueseLocale(locale)) {
    return Portuguese
  }
  if (isChineseLocale(locale)) {
    return Chinese
  }
  return English
}

export default getTranslationByLocale
