import { defineMessages } from 'react-intl'

export const forgotPasswordMessages = defineMessages({
  title: {
    id: 'forgotPassword.title',
    description: 'Forgot Passwords title',
    defaultMessage: 'Forgot Password',
  },
  description: {
    id: 'forgotPassword.description',
    description: 'Description about how to get a link to reset password',
    defaultMessage:
      ' Enter your email and we’ll send you a link to reset your password.',
  },
  goToLogin: {
    id: 'forgotPassword.goToLogin',
    description: 'Button text to cancel and back to Log In page',
    defaultMessage: 'Cancel',
  },
  sendEmail: {
    id: 'forgotPassword.sendEmail',
    description: 'Send email button',
    defaultMessage: 'Send Email',
  },
  relogWarning: {
    id: 'forgotPassword.relogWarning',
    description:
      'Warning message for user about need to relogin after password reset',
    defaultMessage:
      'Resetting your password will sign you out of all Savant applications.',
  },
})
