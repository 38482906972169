import { FC, useMemo, useState } from 'react'

import { useAppSelector } from 'app/hooks'
import { UserShape } from 'app/types/user'
import { currUserSelector } from 'features/auth/login/selectors'
import { getUserRoleSort } from 'features/auth/utils'

import RemoveUserConfirmation from '../RemoveUserConfirmation'

import User from './User'
import { Container } from './styles'

interface UsersProps {
  homeId: string
  items: UserShape[]
}

const Users: FC<UsersProps> = ({ homeId, items }) => {
  const currentUser = useAppSelector(currUserSelector)
  const [removedUserId, setRemovedUserId] = useState<null | string>(null)

  const itemsSorted = useMemo(() => {
    if (!items || items.length === 0) {
      return []
    }
    return items.slice(0).sort((a, b) => {
      if (a.id === currentUser.id) {
        return -1
      }
      if (b.id === currentUser.id) {
        return 1
      }
      const aRole = getUserRoleSort(a.permissions)
      const bRole = getUserRoleSort(b.permissions)

      if (aRole === bRole) {
        return a.firstName.localeCompare(b.firstName)
      }

      return aRole < bRole ? -1 : 1
    })
  }, [items, currentUser])

  return (
    <>
      {removedUserId && (
        <RemoveUserConfirmation
          isOpen
          {...{ homeId }}
          userId={removedUserId}
          onDiscard={() => setRemovedUserId(null)}
          onConfirm={() => setRemovedUserId(null)}
        />
      )}

      <Container>
        {itemsSorted.map((item) => (
          <User
            key={item.id}
            {...{ homeId, item }}
            onDelete={() => setRemovedUserId(item.id)}
          />
        ))}
      </Container>
    </>
  )
}

export default Users
