import { FC, useMemo } from 'react'
import { FieldAttributes } from 'formik'
import { useIntl } from 'react-intl'

import DropdownField from '../DropdownField'

import { Container, Label } from './styles'
import messages from './messages'

export enum RevocationHours {
  Never = -1,
  Day1 = 24,
  Days2 = 48,
  Days3 = 72,
}

const AccessRevocationField: FC<FieldAttributes<any>> = (props) => {
  const { formatMessage } = useIntl()

  const items = useMemo(() => {
    return [
      {
        value: RevocationHours.Never,
        label: formatMessage(messages.never),
      },
      {
        value: RevocationHours.Day1,
        label: formatMessage(messages.day1),
      },
      {
        value: RevocationHours.Days2,
        label: formatMessage(messages.days2),
      },
      {
        value: RevocationHours.Days3,
        label: formatMessage(messages.days3),
      },
    ]
  }, [formatMessage])

  return (
    <Container>
      <Label>{formatMessage(messages.label)}</Label>
      <DropdownField options={items} {...props} />
    </Container>
  )
}

export default AccessRevocationField
