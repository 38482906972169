import styled from 'styled-components'
import theme from 'theme_'

export const ButtonsFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  height: 86px;
  backdrop-filter: ${theme.overlay.SBlur};
  z-index: 2;
  animation: ${theme.animations.fadeIn} 0.2s ease;

  > button:first-of-type {
    margin-right: 19px;
  }

  > button:last-of-type {
    margin-left: 19px;
  }
`
