import { defineMessages } from 'react-intl'

export const loginTermsAndPolicyMessages = defineMessages({
  copy: {
    id: 'loginTerms.copy',
    description:
      'Copyright message to user about accepting Terms and Conditions',
    defaultMessage:
      'By continuing, I agree to the {TermsLink}. See {PrivacyLink} for additional information.',
  },
  termsText: {
    id: 'loginTerms.termsText',
    description: 'Text on Terms of Service link',
    defaultMessage: 'Terms of Service',
  },
  policyText: {
    id: 'loginTetms.policyText',
    description: 'Text on Privacy Policy link',
    defaultMessage: 'Privacy Policy',
  },
})

export const loginMessages = defineMessages({
  helpButton: {
    id: 'login.helpButton',
    description: 'Text for help button in header',
    defaultMessage: 'Need help',
  },
  getAccount: {
    id: 'login.getAccount',
    description: 'Information about where the user can get an account',
    defaultMessage:
      'Need an account? You must already have a Savant System installed, and have the Savant app.',
  },
  findDealer: {
    id: 'login.findDealer',
    description: 'Text on a button that leads to "Find a Dealer" ',
    defaultMessage: 'Find a Dealer',
  },
  loginHeading: {
    id: 'login.loginHeading',
    description: 'Main heading of "Log In" page',
    defaultMessage: 'Home Manager Sign In',
  },
  loginButton: {
    id: 'login.loginButton',
    description: 'Text on a button to submit "login" form ',
    defaultMessage: 'Sign In',
  },
  forgotPassword: {
    id: 'login.ForgotPassword',
    description: 'Text on a button that will open "Forgot password" form',
    defaultMessage: 'Forgot Password?',
  },
  emailPlaceholder: {
    id: 'login.emailPlaceholder',
    description: 'Text to be used in "Email" input',
    defaultMessage: 'Email',
  },
  passwordPlaceholder: {
    id: 'login.passwordPlaceholder',
    description: 'Text to be used in "Password" input',
    defaultMessage: 'Password',
  },
  needSavantSystem: {
    id: 'login.needSavant',
    description: 'Text asks if user need to install Savant system',
    defaultMessage: 'Need a Savant system installed?',
  },
  manageSavantHomes: {
    id: 'login.manageHomes',
    description: 'Log in page subtitle',
    defaultMessage: 'Sign in to manage your Savant Home.',
  },
  termsAndPolicy: {
    id: 'login.termsAndPolicy',
    description: 'Terms and Policy text',
    defaultMessage:
      'By continuing, I agree to the Terms of Service. See Privacy Policy for additional information.',
  },
  wrongCredentials: {
    id: 'login.validation.wrongCredentials',
    description: 'Error text when users not found or credentials are wrong',
    defaultMessage:
      'The email / password entered is incorrect.  If you’re having trouble accessing your account, select the “Forgot Password?” option below.',
  },
})
