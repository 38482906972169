import styled from 'styled-components'
import theme from 'theme_'

const { devices, spacing, sizes } = theme

// To have a footer always on bottom
// We need to exclude height of footer and main's bottom margin
const contentMinHeight = `calc(100vh - ${spacing.base} - ${sizes.footerHeight})`

export const StyledHomesLayoutInner = styled.div`
  display: flex;
  width: 100%;
  max-width: ${sizes.maxContentWidth};
  padding-top: 205px;
  padding-bottom: 140px;
  margin: 0 auto ${spacing.base};
  min-height: ${contentMinHeight};
`

export const StyledHomesAside = styled.aside`
  display: none;
  flex-basis: 300px;
  flex-grow: 1;
  flex-shrink: 0;
  position: sticky;
  z-index: 3;
  top: 100px;
  align-self: start;
  margin: 0 ${spacing.md} ${spacing.md} ${spacing.base};

  @media ${devices.desktop} {
    display: block;
  }
`

export const StyledHomesContent = styled.main`
  flex-basis: 0;
  flex-grow: 999;
  margin-right: 0;
  padding: 0 ${spacing.s};

  @media ${devices.tablet} {
    padding: 0 ${spacing.base} 0 ${spacing.base};
  }
  @media ${devices.desktop} {
    padding: 0 ${spacing.base} 0 0;
  }
`

export const StyledFullWidthContent = styled.main`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 ${spacing.s};

  @media ${devices.tablet} {
    padding: 0 ${spacing.sm};
  }
`
