import React from 'react'
import { CommonStyledInput } from './styles'

export interface IInputControlProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const InputControl: React.FC<IInputControlProps> = (props) => {
  return <CommonStyledInput {...props} />
}

export default InputControl
