import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import LoaderOverlay from 'components/Loader/LoaderOverlay'
import { H1 } from 'components/Typography'
import PaymentMethods from 'features/checkout/PaymentSelection/PaymentMethods'
import { PaymentInfo } from 'features/checkout/PaymentSelection/styles'
import { StyledPaymentsContainer } from './styles'
import ReactHelmet from 'features/analytics/RenderHelmet'
import { paymentsPageMessages } from './messages'

const Payments: React.FC = () => {
  const { formatMessage } = useIntl()

  const [hasCartAddStarted, setHasCartAddStarted] = useState(false)

  return (
    <>
      <ReactHelmet title={paymentsPageMessages.title.defaultMessage} />

      <H1>{formatMessage(paymentsPageMessages.title)}</H1>
      <StyledPaymentsContainer>
        {hasCartAddStarted && <LoaderOverlay />}
        <PaymentInfo>
          <PaymentMethods
            onInitAddCard={() => setHasCartAddStarted(true)}
            noHeading
          />
        </PaymentInfo>
      </StyledPaymentsContainer>
    </>
  )
}

export default Payments
