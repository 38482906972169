import styled from 'styled-components'

import theme from 'theme_'
import { BluredBlock } from 'components/Layout'

export const GuidesList = styled.ul`
  margin-top: ${theme.spacing.s};
  padding: 0;
  list-style: none;
  display: grid;
  row-gap: ${theme.spacing.s};
  column-gap: ${theme.spacing.s};
  grid-template-columns: 1fr;

  @media ${theme.devices.tablet} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${theme.devices.desktop} {
    margin-top: ${theme.spacing.md};
  }

  @media screen and (min-width: 1300px) {
    row-gap: ${theme.spacing.base};
    column-gap: ${theme.spacing.base};
    grid-template-columns: 1fr 1fr 1fr;
  }
`

export const GuidesItemTitle = styled.div`
  display: flex;
  flex-grow: 2;
  align-items: center;
  min-height: 60px;
  padding: 8px 20px;
  transition: color 0.4s;
`

export const GuidesImgWrap = styled.div`
  width: 100%;
  height: 250px;
  overflow: hidden;
`

export const GuidesImg = styled.img`
  display: block;
  object-fit: cover;
  width: 100%;
  max-height: 100%;
  transition: transform 0.4s;
  will-change: transform;
`

export const GuidesListItem = styled(BluredBlock)`
  margin: 0;
  padding: 0;
  overflow: hidden;
  text-decoration: none;

  :hover {
    ${GuidesImg} {
      transform: scale(1.05);
    }
    ${GuidesItemTitle} {
      color: ${theme.colors.accentGold};
    }
  }
`
