import styled from 'styled-components'
import theme from 'theme_'

import { StyledMySubscriptionWrapper } from 'components/MySubscription/styles'

export const DashboardWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    margin: 0 0 ${theme.spacing.s} 0;
  }

  @media ${theme.devices.tablet} {
    display: block;
    columns: 2 360px;
    column-gap: ${theme.spacing.base};

    > div {
      display: inline-block;
      width: 100%;
      margin: 0 ${theme.spacing.base} ${theme.spacing.base} 0;
    }
  }
`

export const DashboardContent = styled.div`
  margin-top: ${theme.spacing.md};
`

export const DashboardSubscriptionContainer = styled(
  StyledMySubscriptionWrapper
)`
  order: 0;
`
