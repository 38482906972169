import styled from 'styled-components'

export const Container = styled.ul`
  display: flex;
  padding: 0;
  margin: 0;
  margin: 0 -6px;

  & > li {
    flex: 1;
    padding: 0 6px;
  }
`
