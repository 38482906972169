import React from 'react'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { useAppSelector } from 'app/hooks'
import routes from 'app/routes'

import AlertBanner from '..'
import { defaultPaymentMethodSelector } from 'features/payment/selectors'
import { isExpiredCard, isExpiringCardInDays } from 'app/utils/checkExpiration'
import { alertBannerMessages } from '../messages'
import { isAdminSelector } from 'features/homes/selectors'
import { activeSubscriptionSelector } from 'features/homes/Subscriptions/selectors'

const AlertBannerDrawer: React.FC = () => {
  const history = useHistory()
  const { formatMessage } = useIntl()

  const activeSubscription = useAppSelector(activeSubscriptionSelector)
  const defaultPaymentMethod = useAppSelector(defaultPaymentMethodSelector)
  const isAdmin = useAppSelector(isAdminSelector)

  const defaultPaymentCard = defaultPaymentMethod?.card
  const isExpiredDefaultPaymentCard = isExpiredCard(defaultPaymentCard)
  const isExpiringDays = isExpiringCardInDays(defaultPaymentCard)

  if (isExpiredDefaultPaymentCard) {
    return (
      <AlertBanner
        bannerText={formatMessage(alertBannerMessages.cardExpired)}
        onBannerClick={() => history.push(routes.paymentMethods)}
      />
    )
  } else if (activeSubscription && isAdmin && isExpiringDays) {
    return (
      <AlertBanner
        bannerText={formatMessage(alertBannerMessages.cardExpiresSoon, {
          daysNumber: isExpiringDays,
        })}
        onBannerClick={() => history.push(routes.paymentMethods)}
      />
    )
  }

  return null
}

export default AlertBannerDrawer
