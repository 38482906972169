import { defineMessages } from 'react-intl'

export const findDealerMessages = defineMessages({
  title: {
    id: 'findDealer.title',
    description: 'Text about specific dealer',
    defaultMessage: 'My Savant Dealer',
  },
  extendedText: {
    id: 'findDealer.extendedText',
    description: 'Text about explains what dealer does',
    defaultMessage:
      'Connect with a trusted Savant Dealer near you. Our network of certified professionals can assist with service or enhancements for your Savant Home.',
  },
  findDealer: {
    id: 'findDealer.findDealerButton',
    description: 'Text about `findDealer` button',
    defaultMessage: 'Find a Dealer',
  },
})
