import styled from 'styled-components'
import theme from 'theme_'

export const StyledUserImgContainer = styled.div<{ $size: number }>`
  height: ${(p) => p.$size}px;
  width: ${(p) => p.$size}px;
  border-radius: ${(p) => p.$size / 2}px;
  overflow: hidden;
`

export const ProfileImg = styled.img<{ $size: number }>`
  background-color: ${theme.colors.beachGrey};
  height: ${(p) => p.$size}px;
  width: ${(p) => p.$size}px;
  object-fit: cover;
`
