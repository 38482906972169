import React, { FC } from 'react'

import DateRangeFilter from './DateRangeFilter'
import CameraFilter from './CameraFilter'
import OldestFirstFilter from './OldestFirstFilter'
import FilterModal from './FilterModal'
import {
  ClipsFiltersContainer,
  LeftSideDesktop,
  LeftSideMobile,
  RightSide,
} from './styles'
import EventFilter from './EventFilter'
import { useAppSelector } from 'app/hooks'
import {
  selectedCameraSelector,
  cameraObjectByIdSelector,
} from 'features/cameraRecordings/selectors'
import { CAMERA_MANUFACTURERS } from 'app/consts'

const ClipsFilters: FC = () => {
  const selectedCamera = useAppSelector(selectedCameraSelector)
  const currentCameraObject = useAppSelector(cameraObjectByIdSelector)(
    selectedCamera?.value
  )

  const isRingCamera =
    currentCameraObject?.manufacturer?.toLowerCase() ===
    CAMERA_MANUFACTURERS.ring

  const shouldShowFilters = currentCameraObject && !isRingCamera

  return (
    <ClipsFiltersContainer>
      <LeftSideDesktop>
        <CameraFilter />
        {shouldShowFilters && (
          <>
            <DateRangeFilter />
            <EventFilter />
          </>
        )}
      </LeftSideDesktop>

      <LeftSideMobile>
        <FilterModal>
          <CameraFilter />
          {shouldShowFilters && (
            <>
              <DateRangeFilter />
              <EventFilter />
            </>
          )}
        </FilterModal>
      </LeftSideMobile>

      {shouldShowFilters && (
        <RightSide>
          <OldestFirstFilter />
        </RightSide>
      )}
    </ClipsFiltersContainer>
  )
}

export default ClipsFilters
