import styled from 'styled-components'
import { InputHTMLAttributes } from 'react'

export const HiddenCheckbox = styled.input<
  InputHTMLAttributes<HTMLInputElement>
>`
  // Hide checkbox visually but remain accessible to screen readers.
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

export const CheckboxContainer = styled.div<{ disabled?: boolean }>`
  display: inline-block;
  vertical-align: middle;
  cursor: ${(p) => (p.disabled ? 'now-allowed' : 'pointer')};
`
