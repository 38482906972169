import styled from 'styled-components'

import theme from 'theme_'

export const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${theme.spacing.md};
`
