import { defineMessages } from 'react-intl'

export const billingHistory = defineMessages({
  createAt: {
    id: 'billingHistory.createdAt',
    description: 'Header of column - Date of created payment',
    defaultMessage: 'Date',
  },
  home: {
    id: 'billingHistory.home',
    description: 'Header of column - Name of home related to payment',
    defaultMessage: 'Home',
  },
  homeNotFound: {
    id: 'billingHistory.homeNotFound',
    description: 'Label for not found home',
    defaultMessage: 'Home not found',
  },
  description: {
    id: 'billingHistory.description',
    description: 'Header of column - Description of payment',
    defaultMessage: 'Description',
  },
  paymentMethod: {
    id: 'billingHistory.paymentMethod',
    description: 'Header of column - payment method',
    defaultMessage: 'Payment method',
  },
  amount: {
    id: 'billingHistory.amount',
    description: 'Header of column - amount of payment',
    defaultMessage: 'Amount',
  },
  status: {
    id: 'billingHistory.status',
    description: 'Header of column - status of payment',
    defaultMessage: 'Status',
  },
  nextPayment: {
    id: 'billingHistory.nextPayment',
    description: 'Next payment title',
    defaultMessage: 'Next payment: ',
  },
  title: {
    id: 'billingHistory.title',
    description: 'Title of billing history page',
    defaultMessage: 'Billing History',
  },
  noBilling: {
    id: 'billingHistory.noBilling',
    description: 'Title ofno  billing history',
    defaultMessage: 'No billing history yet',
  },
})
