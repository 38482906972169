import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { makeFetchSlice, fetchDataThunk } from 'app/redux-fetch'
import { AppDispatch } from 'app/store'
import { combineReducers } from 'redux'
import { getBillingHistory } from './api'
import { IBillingRow } from './components/BillingRow'
import {
  BILLING_CHARGES_LIST_REDUCER_NAME,
  BILLING_CHARGES_REDUCER_NAME,
} from './consts'

export interface IListChargesState {
  list: IBillingRow[]
}

const initialState: IListChargesState = {
  list: [] as IBillingRow[],
}

const setChargesSlice = createSlice({
  initialState,
  name: BILLING_CHARGES_LIST_REDUCER_NAME,
  reducers: {
    setListCharges: (state, action: PayloadAction<IBillingRow[]>) => {
      state.list = state.list.concat(action.payload)
    },
    initListCharges: (state) => {
      state.list = []
    },
  },
})

export const setChargesReducer = setChargesSlice.reducer
export const setChargesActions = setChargesSlice.actions

const getChargesSlice = makeFetchSlice('charges')

export const getChargesReducer = getChargesSlice.reducer
export const getChargesActions = getChargesSlice.actions

export const getChargesThunk = (userId: string, recentChargeId: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await fetchDataThunk({
        dispatch,
        actions: getChargesActions,
        asyncFunc: () => getBillingHistory(userId, recentChargeId),
      })
      dispatch(setChargesSlice.actions.setListCharges(response))
    } catch (err) {
      console.log('Error fetching charges', err)
    }
  }
}

export const billingHistoryReducer = combineReducers({
  [BILLING_CHARGES_REDUCER_NAME]: getChargesReducer,
  [BILLING_CHARGES_LIST_REDUCER_NAME]: setChargesSlice.reducer,
})
