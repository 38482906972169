import React from 'react'
import LanguageSelector from 'components/LanguageSelector'
import {
  StyledHeaderActions,
  StyledHeaderBgOverlay,
  StyledHeaderInner,
  StyledHeaderWrapper,
  StyledSelectorWrapper,
} from './styles'
import { DesktopAccountMenu } from 'components/AccountMenu'
import HeaderLogo from './HeaderLogo'
import MobileMenu from 'components/MobileMenu'
import { useBgOnScroll } from 'app/utils/useBgOnScroll'

const Header: React.FC = () => {
  const shouldHaveBg = useBgOnScroll()

  return (
    <StyledHeaderWrapper shouldHaveBg={shouldHaveBg}>
      {shouldHaveBg && <StyledHeaderBgOverlay />}

      <StyledHeaderInner>
        <HeaderLogo />

        <StyledHeaderActions>
          <StyledSelectorWrapper>
            <LanguageSelector />
          </StyledSelectorWrapper>
          <DesktopAccountMenu />
          <MobileMenu />
        </StyledHeaderActions>
      </StyledHeaderInner>
    </StyledHeaderWrapper>
  )
}

export default Header
