import React, { useState } from 'react'
import { IntlProvider } from 'react-intl'

import { LANGUAGE_KEY } from 'app/consts'
import getTranslationByLocale from 'app/utils/locales/getTranslationByLocale'

interface ILocalizationContext {
  locale: string
  selectLanguage: (lang: string) => void
}

const initialLocalizationContext: ILocalizationContext = {
  locale: '',
  selectLanguage: () => {},
}

export const LocalizationContext = React.createContext<ILocalizationContext>(
  initialLocalizationContext
)

const currentAppLanguage = localStorage.getItem(LANGUAGE_KEY)

const local = currentAppLanguage || navigator.language

let lang = getTranslationByLocale(local)

const LocalizationWrapper: React.FC = (props) => {
  const [locale, setLocale] = useState(local)
  const [messages, setMessages] = useState(lang)

  const selectLanguage = (lang: string) => {
    setLocale(lang)
    setMessages(getTranslationByLocale(lang))
    localStorage.setItem(LANGUAGE_KEY, lang)
  }

  return (
    <LocalizationContext.Provider value={{ locale, selectLanguage }}>
      <IntlProvider messages={messages} locale={locale}>
        {props.children}
      </IntlProvider>
    </LocalizationContext.Provider>
  )
}

export default LocalizationWrapper
