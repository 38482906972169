import styled from 'styled-components'

import theme from '../../theme_'
import { BaseText } from './Base'

export const BigLabel = styled(BaseText).attrs({ as: 'label' })`
  font-size: 20px;
  color: ${theme.colors.newDarkGrey};
`

export const Secondary = styled(BaseText)`
  font-size: 14px;
  line-height: 20px;
  color: ${(p) => p.color || theme.colors.beachGrey};
`

export const SecondaryWithBg = styled(Secondary)`
  padding: ${theme.spacing.base};
  border-radius: ${theme.spacing.xs};
  background-color: ${theme.overlay.dropdownBlack};
`

export const Regular = styled(BaseText)`
  font-size: 14px;
  line-height: ${(p) => p.lh || '1.5'};
  color: ${(p) => p.color || theme.colors.beachGrey};

  @media ${theme.devices.tablet} {
    font-size: 16px;
  }
`

export const RegularModerate = styled(Regular)`
  line-height: 22px;
`

export const BodyXL = styled(BaseText)`
  font-size: 20px;
  line-height: 20px;
  color: ${theme.colors.beachGrey};
`

export const BodyPrimary = styled(BaseText)`
  font-size: 18px;
  line-height: 20px;
  color: ${theme.colors.dimmedGold};
`

export const ControlLabel = styled(BaseText).attrs({ as: 'span' })`
  font-size: 16px;
  line-height: 24px;
`
