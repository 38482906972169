import styled, { css } from 'styled-components'

import { makePublicUrl } from 'app/utils/publicUrl'
import { BigTitle, SectionTitle } from 'components/Typography'
import { BluredBlock } from 'components/Layout'
import theme from 'theme_'
import { CommonButton } from 'components/Button'

const customBgCss = css`
  background: url(${makePublicUrl('images/stone_bg.png')});
  background-size: cover;
`

const expandedInfoLeftCss = css`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${theme.devices.tablet} {
    width: 50%;
    padding-right: 25px;

    &:after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 30px;
      width: 1px;
      height: calc(100% - 30px);
      border-right: 1px dashed ${theme.colors.mutedGray};
      opacity: 0.5;
    }
  }
`

export const SubscriptionInfoWrapper = styled.div<{ $isExpanded?: boolean }>`
  display: flex;

  ${(p) => p.$isExpanded && `margin-top: 16px`}
`

export const StyledSubscriptionInfo = styled(BluredBlock)<{
  $customBg?: boolean
  $isExpanded?: boolean
}>`
  color: ${theme.colors.beachGrey};
  ${(p) => p.$customBg && customBgCss}
  ${(p) => p.$isExpanded && `flex-direction: row; width: 100%`};
`

export const SubscriptionInfoLeft = styled.div<{ $isExpanded?: boolean }>`
  ${(p) => p.$isExpanded && expandedInfoLeftCss}
`

export const SubsInfoTitle = styled(SectionTitle)`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const SubsInfoTitleImg = styled.img`
  margin-right: 13px;
`

export const SubsInfoHeaderTitle = styled(BigTitle)`
  display: flex;
  text-transform: capitalize;
  margin-bottom: ${theme.spacing.base};
`

export const SubsManageBy = styled.div`
  display: flex;
  align-items: center;
  line-height: 30px;
`

export const SubsInfoBottom = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${theme.spacing.md};
`

export const SubsInfoExpadedRight = styled.div`
  display: none;
  width: 50%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media ${theme.devices.tablet} {
    display: flex;
  }

  @media ${theme.devices.mediumDesktop} {
    justify-content: flex-end;
  }

  img {
    flex: 0;
    max-width: 100%;
  }
`

export const SubsInfoEnrolledHelperText = styled.div<{ $hasWarn?: boolean }>`
  color: ${(p) =>
    p.$hasWarn ? theme.colors.accentOrange : theme.colors.beachGrey};
`

export const SubsInfoHelperTextRow = styled.div`
  margin-top: ${theme.spacing.md};
`

export const SubsInfoButtomBtns = styled.div`
  width: 100%;

  & ${CommonButton} {
    margin-right: auto;
  }
`
