import styled from 'styled-components'
import theme from 'theme_'
import { BluredBlock } from 'components/Layout'
import Icon from '@mdi/react'
import { CommonButton } from 'components/Button'

export const FeedbackContainer = styled(BluredBlock)`
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const FeedbackIcon = styled(Icon)`
  color: ${theme.colors.mutedGray};
  width: ${theme.spacing.xxl};
  margin-bottom: ${theme.spacing.md};
`

export const FeedbackText = styled.div`
  line-height: 22px;
  font-size: 16px;
  color: ${theme.colors.beachGrey};
  margin-bottom: ${theme.spacing.lg};
`

export const FeedbackButton = styled(CommonButton)`
  width: fit-content;
`
