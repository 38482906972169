import React from 'react'
import { useIntl } from 'react-intl'

import MyDealer from 'features/homes/components/MyDealer'
import { H1 } from 'components/Typography/Headings'
import HomeInfo from '../components/HomeInfo'
import { dashboardMessages } from './messages'
import { DashboardWrapper, DashboardContent } from './styles'
import ReactHelmet from 'features/analytics/RenderHelmet'
import WhatsNew from '../components/WhatsNew'
import MySupport from 'features/getHelp/components/MySupport'
// import DiscoverSavant from '../components/DiscoverSavant'
import SubscriptionInfo from '../components/SubscriptionInfo'
import { useAppSelector } from 'app/hooks'
import { currentHomeInfoSelectors } from '../selectors'
import { SpinnerCentered } from 'components/Loader'

const Dashboard: React.FC = () => {
  const { formatMessage } = useIntl()

  const isFetchingHomeInfo = useAppSelector(currentHomeInfoSelectors.isFetching)

  return (
    <>
      <ReactHelmet title={dashboardMessages.title.defaultMessage} />
      <H1>{formatMessage(dashboardMessages.title)}</H1>
      <DashboardContent>
        <HomeInfo />

        <WhatsNew />

        <DashboardWrapper>
          {!isFetchingHomeInfo ? (
            <SubscriptionInfo customBg />
          ) : (
            <SpinnerCentered />
          )}
          {/* <DiscoverSavant /> */}
          <MySupport />
          <MyDealer />
        </DashboardWrapper>
      </DashboardContent>
    </>
  )
}

export default Dashboard
