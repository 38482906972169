import styled from 'styled-components'
import Icon from '@mdi/react'
import { H2, Regular } from 'components/Typography'
import { CommonButton } from 'components/Button'
import theme from 'theme_'

export const StyledNotFoundWarn = styled(Icon)`
  margin-bottom: ${theme.spacing.sm};

  @media ${theme.devices.tablet} {
    margin-bottom: ${theme.spacing.md};
  }
`

export const StyledNotFoundTitle = styled(H2)`
  margin-bottom: ${theme.spacing.sm};

  @media ${theme.devices.tablet} {
    margin-bottom: ${theme.spacing.md};
  }

  @media ${theme.devices.desktop} {
    margin-bottom: ${theme.spacing.lg};
  }
`
export const StyledNotFoundText = styled(Regular)`
  margin-bottom: ${theme.spacing.lg};
`

export const StyledNotFoundBackBtn = styled(CommonButton)`
  font-size: 14px;

  @media ${theme.devices.tablet} {
    font-size: 16px;
  }

  @media ${theme.devices.desktop} {
    font-size: 24px;
  }
`

