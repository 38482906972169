import {
  middleware as swcApiMiddleware,
  reducers as swcReducers,
} from '@racepointenergy/swc'
import { configureClient } from '@racepointenergy/swc'
import type { ClientState } from '@racepointenergy/swc/client/initial-state'
import { clientMiddleware as swcClientMiddleware } from '@racepointenergy/swc/client/middleware'
import { scsAuthListenerOptions as swcScsAuthListenerOptions } from '@racepointenergy/swc/scs/client/middleware'
import {
  configureStore,
  ThunkAction,
  Action,
  ThunkDispatch,
  AnyAction,
} from '@reduxjs/toolkit'
import authReducer from 'features/auth/reducer'
import { AUTH_REDUCER_NAME } from 'features/auth/consts'
import { CLOUD_ENV, HOMES_REDUCER_NAME, SCS_AGENT } from './consts'
import {
  CURR_HOME_INFO_REDUCER_NAME,
  UBER_REDUCER_NAME,
} from 'features/homes/consts'
import {
  currentHomeInfoReducer,
  uberListReducer,
} from 'features/homes/homesSlice'
import { homesReducer } from 'features/homes/homesSlice'
import { paymentReducer } from 'features/payment/slice'
import { PAYMENT_REDUCER_NAME } from 'features/payment/consts'
import usersReducer, { USERS_REDUCER_NAME } from 'features/users/reducer'
import { billingHistoryReducer } from 'features/billingHistory/slice'
import { BILLING_REDUCER_NAME } from 'features/billingHistory/consts'
import dealerAccessReducer, {
  DEALER_REDUCER_NAME,
} from 'features/MyDealerInfo/reducer'
import { subscriptionReducer } from 'features/homes/Subscriptions/slice'
import { SUBSCRIPTIONS_REDUCER_NAME } from 'features/homes/Subscriptions/consts'
import connectedAppsReducer, {
  CONNECTED_APPS_REDUCER_NAME,
} from '../features/connectedApps/reducer'
import cameraRecordingsReducer from '../features/cameraRecordings/reducer'
import { CAMERA_RECORDINGS_REDUCER_NAME } from '../features/cameraRecordings/consts'

const swcConfig: {
  config: Partial<ClientState>
} = require(`@racepointenergy/swc/client/config/config.${CLOUD_ENV}.js`)

swcClientMiddleware.startListening(swcScsAuthListenerOptions)

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .prepend(swcClientMiddleware.middleware)
      .concat(swcApiMiddleware),
  reducer: {
    [AUTH_REDUCER_NAME]: authReducer,
    [HOMES_REDUCER_NAME]: homesReducer,
    [CURR_HOME_INFO_REDUCER_NAME]: currentHomeInfoReducer,
    [UBER_REDUCER_NAME]: uberListReducer,
    [SUBSCRIPTIONS_REDUCER_NAME]: subscriptionReducer,
    [PAYMENT_REDUCER_NAME]: paymentReducer,
    [USERS_REDUCER_NAME]: usersReducer,
    [CONNECTED_APPS_REDUCER_NAME]: connectedAppsReducer,
    [CAMERA_RECORDINGS_REDUCER_NAME]: cameraRecordingsReducer,
    [BILLING_REDUCER_NAME]: billingHistoryReducer,
    [DEALER_REDUCER_NAME]: dealerAccessReducer,
    ...swcReducers,
  },
})

// No semi-colon rule makes confusing code. The leading semi-colon is important
;(store.dispatch as AppDispatch)(
  configureClient(swcConfig.config as Partial<ClientState>)
)
;(store.dispatch as AppDispatch)(
  configureClient({ scs$agent: SCS_AGENT, scs$channel: undefined })
)

export type AppDispatch = ThunkDispatch<RootState, {}, AnyAction>
export type AppGetState = typeof store.getState
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
