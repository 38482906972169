import type { SubscriptionPlan } from './types'

export const SUBSCRIPTIONS_REDUCER_NAME = 'subscriptions'
export const SUBSCRIPTIONS_CONFIG_REDUCER_NAME = 'config'
export const SUBSCRIPTIONS_PAYMENT_FAIL = 'subscriptionPaymentFail'

export const NO_PLAN: SubscriptionPlan = {
  id: 'no-plan',
  type: 'none',
  nickname: '',
  price: 0,
  interval: 'day',
}

export const ESSENTIAL_PLAN_TO_MIGRATE: SubscriptionPlan = {
  id: 'essential-plan-to-migrate',
  type: 'essential-to-migrate',
  nickname: '',
  price: 0,
  interval: 'day',
}

export const ESSENTIAL_PLAN: SubscriptionPlan = {
  id: 'essential-plan',
  type: 'essential',
  nickname: '',
  price: 0,
  interval: 'year',
}
