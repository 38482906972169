import React from 'react'
import { useSelector } from 'react-redux'
import { SelectorMenuInner, StyledHomeSelectorMenu } from './styles'
import { storeSelectedHomeId } from 'app/services/LocalStorage'
import HomeSelectorLink from './HomeSelectorLink'
import {
  adminHomesListSelector,
  currentHomeIdSelector,
} from 'features/homes/selectors'
import { currHomeActions } from 'features/homes/homesSlice'
import { useAppDispatch } from 'app/hooks'
import { isFirefox } from 'app/utils/userAgent'

interface IHomeSelectorMenuProps {
  isOpen?: boolean
  isMobileMenu?: boolean
  closeMenuHandler?: () => void
}

const HomeSelectorMenu: React.FC<IHomeSelectorMenuProps> = (props) => {
  const dispatch = useAppDispatch()

  const currentHomeId = useSelector(currentHomeIdSelector)

  const adminHomesList = useSelector(adminHomesListSelector)
  const handleSelectHome = (homeId: string) => {
    dispatch(currHomeActions.setCurrentHomeId(homeId))
    storeSelectedHomeId(homeId)
    props.closeMenuHandler?.()
  }

  return (
    <StyledHomeSelectorMenu
      $isOpen={props.isOpen || props.isMobileMenu}
      $isFirefox={isFirefox()}
    >
      <SelectorMenuInner>
        {adminHomesList.map((home: any) => {
          return (
            <HomeSelectorLink
              key={home.id}
              onClick={() => handleSelectHome(home.id)}
              name={home.name}
              imageUrl={home.imageUrl}
              isActive={home.id === currentHomeId}
            />
          )
        })}
      </SelectorMenuInner>
    </StyledHomeSelectorMenu>
  )
}

export default HomeSelectorMenu
