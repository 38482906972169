import { DateTime } from 'luxon'

import { HostCapability } from 'features/homes/types'
import { SubscriptionInfoType } from '../types'

export const isExpiringSoon = (
  subscription: SubscriptionInfoType | null | undefined,
  dateNow: DateTime
) => {
  if (subscription) {
    return (
      !subscription.autoRenew &&
      DateTime.fromMillis(subscription.expiresAt).diff(dateNow, 'months')
        .months <= 1
    )
  }
  return false
}

// Mostly analogue to SCM. Is also used to check if home's software is outdated
export const hasSysMonSupport = (
  hostCapabilities: HostCapability[] | undefined
) => {
  return (hostCapabilities || []).reduce((acc, { type, version, enabled }) => {
    return acc
      ? acc
      : type === 'remoteDealerInterface' && enabled === 1 && version >= 1
  }, false)
}
