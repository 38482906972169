import styled from 'styled-components'

import theme from 'theme_'

export const Container = styled.ul`
  margin-top: 12px;
  padding: 0;
  list-style: none;
  display: grid;
  row-gap: 12px;
  column-gap: 12px;
  grid-template-columns: 1fr;

  @media ${theme.devices.tablet} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${theme.devices.desktop} {
    margin-top: 24px;
  }

  @media screen and (min-width: 1300px) {
    row-gap: ${theme.spacing.base};
    column-gap: ${theme.spacing.base};
    grid-template-columns: 1fr 1fr 1fr;
  }
`
