import React from 'react'
import { Route } from 'react-router-dom'

import PaymentSelection from './PaymentSelection'

const AuthorizedRoutes: React.FC = () => {
  return (
    <>
      <Route path="/checkout/:priceId" exact component={PaymentSelection} />
    </>
  )
}

export default AuthorizedRoutes
