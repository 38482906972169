import styled from 'styled-components'
import { Form } from 'formik'
import { CardElement } from '@stripe/react-stripe-js'

import theme from 'theme_'
import { StyledInputError } from 'components/Input/styles'
import { H2 } from 'components/Typography'
import { BaseText } from 'components/Typography/Base'
import { Regular } from 'components/Typography/Labels'

export const StyledAddPaymentHeading = styled(H2)`
  text-align: center;
`

export const StyledPaymentIcons = styled.div`
  text-align: center;
`

export const StyledBrandIcon = styled.img`
  margin: 0 5px;
`

export const StyledAddPaymentMethodForm = styled(Form)`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: ${theme.spacing.sm} 0;

  input {
    width: 100%;
  }
`

export const StyledMakeDefaultToggleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${theme.spacing.sm} 0 ${theme.spacing.s};
`

export const StyledMakeDefaultToggle = styled.div`
  display: flex;
`

export const StyledMakeDefaultText = styled(Regular)`
  margin-right: ${theme.spacing.s};
  @media ${theme.devices.tablet} {
    margin-rigth: ${theme.spacing.xxl};
  }
`

export const StyledAddPaymentInner = styled.div`
  position: relative;
  z-index: 1;
`

export const StyledExpiryCvc = styled.div`
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
`

export const StyledExpiryWrap = styled.div`
  width: calc(50% - 6px);
`

export const StyledAddPaymentFormButtons = styled.div`
  display: flex;
  justify-content: space-evenly;
`

export const StyledCountryPostal = styled.div`
  display: inline-flex;
  width: 100%;
  margin-top: ${theme.spacing.s};
`

export const StyledCountryWrap = styled.div`
  width: 66%;
  flex-shrink: 0;
  margin-right: ${theme.spacing.s};
`

export const StyledCardElement = styled(CardElement)`
  padding: 10px 0;
  color: ${theme.colors.beachGrey};
  background-color: ${theme.colors.inputFill};
  box-shadow: inset 0px -1px 0px #8a847b;
`

export const StyledChargesInfo = styled(BaseText)`
  margin-bottom: ${theme.spacing.lg};
  color: ${theme.colors.mutedGray};
  text-align: center;
`

export const StyledAddPaymentFormError = styled(StyledInputError)``

export const StyledFailedScreen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const StyledFailedScreenError = styled(StyledInputError)`
  font-size: 16px;
  margin: ${theme.spacing.md} 0 ${theme.spacing.lg};
`

export const stripeElementsCommonInputStyle = {
  base: {
    fontSize: '16px',
    color: theme.colors.beachGrey,
    '::placeholder': {
      color: theme.colors.disabledGrey,
    },
    fontFamily: theme.fonts.base,
    fontWeight: 100,
  },
  invalid: {
    color: theme.colors.error,
  },
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css?family=Raleway',
    },
  ],
}
