import { FC, ReactNode } from 'react'

import { StyledDealerContainer, DealerHeader, DealerBlockTitle } from './styles'

interface DealerBlockProps {
  title?: ReactNode
  children: ReactNode
  headerAside?: ReactNode
  bordered?: boolean
  fullWidth?: boolean
}

const DealerBlock: FC<DealerBlockProps> = ({
  title,
  children,
  headerAside,
  bordered,
  fullWidth,
}) => (
  <StyledDealerContainer {...{ bordered, fullWidth }}>
    {headerAside || title ? (
      <DealerHeader>
        {title && <DealerBlockTitle>{title}</DealerBlockTitle>}

        {headerAside}
      </DealerHeader>
    ) : null}

    {children}
  </StyledDealerContainer>
)

export default DealerBlock
