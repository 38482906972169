import styled from 'styled-components'
import Icon from '@mdi/react'
import theme from 'theme_'

export const StyledAccountMenuWrapper = styled.div`
  display: none;
  position: relative;
  margin-left: ${theme.spacing.base};

  @media ${theme.devices.desktop} {
    display: block;
  }
`
export const StyledAccountMenuBtn = styled.button<{ isMenuOpen: boolean }>`
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0 ${theme.spacing.s};
  height: 44px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  background-color: ${(p) =>
    p.isMenuOpen ? `${theme.overlay.newDropdownBlack}` : 'transparent'};
  backdrop-filter: ${(p) => (p.isMenuOpen ? `${theme.overlay.SBlur}` : 'none')};

  :hover {
    background-color: ${theme.overlay.newDropdownBlack};
    backdrop-filter: ${theme.overlay.SBlur};
  }
`

export const StyledArrowIcon = styled(Icon)`
  transition: transform 0.2s ease;
`
