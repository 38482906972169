import React, { useMemo } from 'react'
import { mdiChevronRight } from '@mdi/js'
import { useSelector } from 'react-redux'
import {
  AccountMenuHeaderEmail,
  AccountMenuHeaderLeft,
  AccountMenuHeaderName,
  AccountMenuHeaderRight,
  StyledAccountMenuHeader,
  StyledMobileChevron,
} from './styles'
import { currUserSelector } from 'features/auth/login/selectors'
import { AuthorizedUser } from 'app/types'
import theme from 'theme_'
import formatUserFullName from 'app/utils/formatUserFullName'
import { UserImgContainer } from 'components/UserItems'

interface IAccounMenuHeaderProps {
  mobileClickHandler?: () => void
}

const AccountMenuHeader: React.FC<IAccounMenuHeaderProps> = (props) => {
  const user: AuthorizedUser = useSelector(currUserSelector)

  const userFullName = useMemo(() => formatUserFullName(user), [user])

  return (
    <StyledAccountMenuHeader onClick={props.mobileClickHandler || undefined}>
      <AccountMenuHeaderLeft>
        <UserImgContainer size={64} profilePicUrl={user.profilePicUrl} />
      </AccountMenuHeaderLeft>
      <AccountMenuHeaderRight>
        <AccountMenuHeaderName title={userFullName}>
          {userFullName}
        </AccountMenuHeaderName>
        <AccountMenuHeaderEmail title={user.email}>
          {user.email}
        </AccountMenuHeaderEmail>
      </AccountMenuHeaderRight>
      {props.mobileClickHandler ? (
        <StyledMobileChevron
          path={mdiChevronRight}
          size={1}
          color={theme.colors.beachGrey}
        />
      ) : null}
    </StyledAccountMenuHeader>
  )
}

export default AccountMenuHeader
