import { BILLING_API_URL } from '../consts'
import { makeBillingHeaders, makeAuthorizedBillingHeaders } from './headers'
import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios'

export interface BillingAuthorizeParams {
  token: string
  secretKey: string
  onUnauthorized: () => void
}

export let authorizedBillingFetch: AxiosInstance = axios.create({
  baseURL: `${BILLING_API_URL}`,
  headers: makeBillingHeaders(),
})

export const authorizeBillingAxios = ({
  token,
  secretKey,
  onUnauthorized,
}: BillingAuthorizeParams): AxiosInstance => {
  authorizedBillingFetch = axios.create({
    baseURL: `${BILLING_API_URL}`,
    headers: {
      ...makeAuthorizedBillingHeaders(token, secretKey),
    },
  })

  authorizedBillingFetch.interceptors.response.use(
    (response: AxiosResponse) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response
    },
    (error: AxiosError) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      if (error.response?.status === 401) {
        onUnauthorized()
      }

      return Promise.reject(error)
    }
  )

  return authorizedBillingFetch
}
