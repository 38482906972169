import { FC, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { map, keys, toPairs, compose, all, forEach } from 'lodash/fp'
import { FormikProps } from 'formik'
import { mdiCircleOutline, mdiCheckCircle } from '@mdi/js'

import { RegularModerate } from 'components/Typography'

import theme from 'theme_'
import { messages } from '../../../messages'

import Permission from '../Permission'
import PermissionsBlock from '../PermissionsBlock'
import { PermissionsForm } from '../types'

import {
  StyledCheckPermissionIcon,
  RoomsHeader,
  RoomsHeaderContainer,
} from './styles'
import DisabledCheckIcon from 'components/Input/Checkbox/DisabledCheck'

interface RoomPermissionsProps {
  formikProps: FormikProps<PermissionsForm>
}

const RoomPermissions: FC<RoomPermissionsProps> = ({ formikProps }) => {
  const isAdmin = formikProps.values.admin
  const homeRooms = formikProps.values.homeRooms

  const hasAllSelected = useMemo(() => {
    return compose(
      all(([, value]) => !!value),
      toPairs
    )(homeRooms)
  }, [homeRooms])

  const roomsHeaderIcon = useMemo(() => {
    if (hasAllSelected) {
      return isAdmin ? (
        <DisabledCheckIcon />
      ) : (
        <StyledCheckPermissionIcon size={1} path={mdiCheckCircle} />
      )
    }

    return (
      <StyledCheckPermissionIcon
        size={1}
        path={mdiCircleOutline}
        color={theme.colors.dimmedGold}
      />
    )
  }, [hasAllSelected, isAdmin])

  return (
    <PermissionsBlock title={<FormattedMessage {...messages.rooms} />}>
      <>
        <RoomsHeader>
          <RoomsHeaderContainer
            onClick={() => {
              const roomNames = keys(homeRooms)
              forEach((key: string) => {
                formikProps.setFieldValue(`homeRooms.${key}`, !hasAllSelected)
              })(roomNames)
            }}
          >
            <RegularModerate>
              <FormattedMessage {...messages.allRooms} />
            </RegularModerate>

            {roomsHeaderIcon}
          </RoomsHeaderContainer>
        </RoomsHeader>
        {map(
          (name: string) => (
            <Permission
              key={name}
              name={`homeRooms.${name}`}
              title={name}
              type="checkbox"
              // prevent restricting room access for admins
              disabled={isAdmin}
            />
          ),
          keys(formikProps.values.homeRooms)
        )}
      </>
    </PermissionsBlock>
  )
}

export default RoomPermissions
