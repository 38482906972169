import styled from 'styled-components'

import theme from 'theme_'
import CommonLink from 'components/Button/CommonLink'
import { Regular } from 'components/Typography'

export const ResendCodeWrapper = styled.div`
  text-align: center;
`
export const StyledErrorTitleText = styled.div`
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 50px;
  color: ${(p) => p.theme.colors.duneWarmGrey};
  text-align: center;
  @media only screen and (max-width: ${theme.breakpoint.desktop}) {
    margin: ${theme.spacing.s} ${theme.spacing.md} ${theme.spacing.md};
  }
`

export const StyledDescriptionTextWrapper = styled.div`
  margin-top: ${theme.spacing.s};
`

export const StyledDescriptionText = styled(Regular)`
  text-align: center;
  margin-bottom: ${theme.spacing.md};
`

export const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: ${(p) => p.theme.spacing.s} 0 ${theme.spacing.md};

  & > button:first-child {
    margin-right: 32px;
  }

  @media only screen and (max-width: ${theme.breakpoint.desktop}) {
    & > button:first-child {
      margin-right: 16px;
    }
  }
`

export const StyledResendVerification = styled(CommonLink)`
  font-weight: 700;

  @media ${theme.devices.tablet} {
    margin-left: ${theme.spacing.tiny};
  }
`
export const StyledEmailBold = styled.b`
  color: ${theme.colors.dimmedGold};
`
