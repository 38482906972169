import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { FooterMessages } from '../messages'
import { LoginFooterLinksContainer, LoginFooterWrapper } from './styles'
import { StyledFooterLink } from '../styles'
import { Secondary } from 'components/Typography'
import { TERMS_LINKS } from 'app/consts'

const currentYear = new Date().getFullYear()

const LoginFooter: React.FC = () => {
  const { formatMessage } = useIntl()

  return (
    <LoginFooterWrapper>
      <Secondary>
        <FormattedMessage
          {...FooterMessages.loginCopyright}
          values={{ currentYear }}
        />
      </Secondary>
      <LoginFooterLinksContainer>
        <StyledFooterLink target="_blank" href={TERMS_LINKS.termsAndConditions}>
          {formatMessage(FooterMessages.termsOfService)}
        </StyledFooterLink>
        <StyledFooterLink target="_blank" href={TERMS_LINKS.privacyPolicy}>
          {formatMessage(FooterMessages.privacyPolicy)}
        </StyledFooterLink>
      </LoginFooterLinksContainer>
    </LoginFooterWrapper>
  )
}

export default LoginFooter
