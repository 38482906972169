import { defineMessages } from 'react-intl'

export const discoverMoreMessages = defineMessages({
  title: {
    id: 'discoverMore.title',
    description: '"Discover More" page title',
    defaultMessage: 'Discover More',
  },
  audio: {
    id: 'discoverMore.audio',
    description: "Discover More page item-link about Savant's Audio",
    defaultMessage: 'Audio & Speakers',
  },
  smartAutomatiom: {
    id: 'discoverMore.smartAutomatiom',
    description:
      "Discover More page item-link about Savant's Smart Home and Automation Control",
    defaultMessage: 'Smart Living, Home Automation & Control',
  },
  lighting: {
    id: 'discoverMore.lighting',
    description: "Discover More page item-link about Savant's Lighting",
    defaultMessage: 'Lighting',
  },
  theater: {
    id: 'discoverMore.theater',
    description: "Discover More page item-link about Savant's Home Theater",
    defaultMessage: 'Home Theater',
  },
  shades: {
    id: 'discoverMore.shades',
    description: "Discover More page item-link about Savant's Shades",
    defaultMessage: 'Shades',
  },
  cameras: {
    id: 'discoverMore.cameras',
    description: "Discover More page item-link about Savant's Entry & Cameras",
    defaultMessage: 'Entry & Cameras',
  },
  energy: {
    id: 'discoverMore.energy',
    description: "Discover More page item-link about Savant's Energy solutions",
    defaultMessage: 'Energy',
  },
  partners: {
    id: 'discoverMore.partners',
    description: "Discover More page item-link about Savant's partners",
    defaultMessage: 'Savant Partners',
  },
})
