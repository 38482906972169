import CopyField from 'features/homes/components/CopyField'

interface HomeUIDProps {
  uid: string
}

function HomeUID({ uid }: HomeUIDProps) {
  if (!uid) {
    return null
  }

  return <CopyField text={uid} />
}

export default HomeUID
