import { defineMessages } from 'react-intl'

export const subscrInfoMessages = defineMessages({
  title: {
    id: 'subscrInfoMessages.title',
    description: 'Subscrioption Info Card title',
    defaultMessage: 'Subscription Status',
  },
  altTitle: {
    id: 'subscrInfoMessages.altTitle',
    description: 'Subscrioption Info Card alternative title',
    defaultMessage: 'Subscriptions',
  },
  upgradeToPlan: {
    id: 'subscrInfoMessages.upgradeToPlan',
    description:
      'Subscrioption Info Card call to upgrade/subscribe to paid subscription plan',
    defaultMessage: '{planName} is Not Active',
  },
  hasPaidPlan: {
    id: 'subscrInfoMessages.havePremPlan',
    description:
      'Subscrioption Info Card text inform that user has some paid subscription plan',
    defaultMessage:
      '{planName} delivers access to the latest smart home features and advanced integrations, plus enhanced support through Savant Home Backup & Restore.',
  },
  hasFreePlan: {
    id: 'subscrInfoMessages.hasFreePlan',
    description:
      'Subscrioption Info Card text inform that user has basic/free subscription plan',
    defaultMessage:
      'Savant Essentials delivers access to the latest smart home features and advanced integrations, plus enhanced support through Savant Home Backup & Restore.',
  },
  contactToManage: {
    id: 'subscrInfoMessages.contactToManage',
    description:
      'Subscrioption Info Card text telling what user should do to manage his subscription plan',
    defaultMessage:
      'Contact your Savant Dealer to extend Savant Essentials features for your home.',
  },
  afterExpire: {
    id: 'subscrInfoMessages.afterExpire',
    description:
      'Subscrioption Info Card text telling that user will have reduced features after paid subscription',
    defaultMessage: 'After expiration date you will have limited features.',
  },
  enrollToPlan: {
    id: 'subscrInfoMessages.enrollToPlan',
    description:
      'Subscrioption Info Card text calling user to upgrade his subscription plan',
    defaultMessage:
      '{planName} delivers access to the latest smart home features and advanced integrations, plus enhanced support through Savant Home Backup & Restore.',
  },
  findToUpgrade: {
    id: 'subscrInfoMessages.findToUpgrade',
    description:
      'Subscrioption Info Card text telling user to do to upgrade his subscription plan',
    defaultMessage: 'Find a dealer to manage and enable your {planName}.',
  },
  contactToEnablePlan: {
    id: 'subscrInfoMessages.contactToEnablePlan',
    description:
      'Subscrioption Info Card text telling that user should contact dealer to manage and enable subscription',
    defaultMessage: 'Contact your dealer to manage and enable your {planName}',
  },
  findDealerBtn: {
    id: 'subscrInfoMessages.findDealerBtn',
    description:
      'Subscrioption Info Card button text leading user to "Dealer" page',
    defaultMessage: 'Find a Dealer',
  },
  isActive: {
    id: 'subscrInfoMessages.isActive',
    description:
      'Subscrioption Info Card heading text showing that active subscription plan is subscribed/active',
    defaultMessage: 'Active',
  },
  isExpiring: {
    id: 'subscrInfoMessages.isExpiring',
    description:
      'Subscrioption Info Card heading text showing that active subscription plan is expiring',
    defaultMessage: 'Ending Soon',
  },
})
