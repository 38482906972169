import styled, { css } from 'styled-components'
import theme from 'theme_'

const RowSettings = styled.div`
  min-height: 56px;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 15px 25px;
  border-bottom: 1px solid ${theme.colors.separatorColor};
`

export const SubscriptionTableRowFeatures = styled(RowSettings)`
  flex-basis: 100%;
  line-height: 1.5;
  display: block;
  // justify-content: flex-start;

  @media ${theme.devices.tablet} {
    flex-basis: 56%;
    justify-content: flex-start;
    text-align: left;
  }
`

export const SubscriptionTableRowIncluded = styled(RowSettings)<{
  isBasicHomePlan: boolean
}>`
  flex-basis: 50%;
  background-color: ${(p) =>
    p.isBasicHomePlan ? theme.colors.nonTransparentHighlight : 'transparent'};
  border-right: 1px solid ${theme.colors.separatorColor};

  @media ${theme.devices.tablet} {
    flex-basis: 22%;
    border-right: 1px solid
      ${(p) =>
        p.isBasicHomePlan
          ? theme.colors.accentGold
          : theme.colors.separatorColor};
    border-bottom: 1px solid ${theme.colors.separatorColor};
    border-left: 1px solid
      ${(p) =>
        p.isBasicHomePlan
          ? theme.colors.accentGold
          : theme.colors.separatorColor};
  }
`

export const SubscriptionTableRowEssentials = styled(RowSettings)<{
  isBasicHomePlan: boolean
}>`
  flex-basis: 50%;
  background-color: ${({ isBasicHomePlan }) =>
    isBasicHomePlan ? 'transparent' : theme.colors.nonTransparentHighlight};

  @media ${theme.devices.tablet} {
    flex-basis: 22%;
    flex-shrink: 0;
    border-right: 1px solid
      ${(p) =>
        !p.isBasicHomePlan
          ? theme.colors.accentGold
          : theme.colors.separatorColor};
    border-left: 1px solid
      ${(p) =>
        !p.isBasicHomePlan
          ? theme.colors.accentGold
          : theme.colors.separatorColor};
  }
`

const activeCellBorderCss = css`
  border-bottom: 1px solid ${theme.colors.accentGold};
  border-bottom-right-radius: ${theme.spacing.xs};
  border-bottom-left-radius: ${theme.spacing.xs};
`

export const SubscriptionTableRowContainer = styled.div<{
  isBasicHomePlan: boolean
}>`
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;

  @media ${theme.devices.tablet} {
    width: 100%;

    &:last-child ${SubscriptionTableRowIncluded}.active_cell {
      ${activeCellBorderCss};
    }

    &:last-child ${SubscriptionTableRowEssentials}.active_cell {
      ${activeCellBorderCss};
    }
  }
`

export const SubscriptionTableRowNote = styled.span`
  font-size: 12px;
`
