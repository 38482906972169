import { FC } from 'react'

import { H4, Regular } from 'components/Typography'

import { Container, Bullets, Bullet } from './styles'

interface BulletItem {
  label: string
}

interface OptionsProps {
  title: string
  bullets: BulletItem[]
  className?: string
}

const OptionsList: FC<OptionsProps> = ({ title, bullets, className }) => (
  <Container {...{ className }}>
    <H4>{title}</H4>

    <Bullets>
      {bullets.map(({ label }, index) => (
        <Bullet key={index}>
          <Regular>{label}</Regular>
        </Bullet>
      ))}
    </Bullets>
  </Container>
)

export default OptionsList
