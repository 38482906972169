import { FC, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { RouteComponentProps, useHistory } from 'react-router-dom'

import { H1 } from 'components/Typography'
import { UserShape } from 'app/types/user'
import ReactHelmet from 'features/analytics/RenderHelmet'

import { usersMessages } from './messages'
import { getUsersThunk } from './slice'
import { userListSelectors } from './selectors'
import UserList from './components/Users'
import InviteUser from './components/InviteUser'
import { UsersHeader, UsersContentContainer, InviteUserButton } from './styles'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { currentHomeIdSelector } from 'features/homes/selectors'
import { getUsersRoute } from 'app/utils/getUsersRoute'
import { LoaderOverlay } from 'components/Loader'
import SuccessModal from 'components/Modal/SuccessModal'
import { CheckCircleOutline } from 'components/Icons'

interface MatchParams {
  homeId: string
}

interface UsersPageProps extends RouteComponentProps<MatchParams> {}

const UsersPage: FC<UsersPageProps> = ({
  match: {
    params: { homeId },
  },
}) => {
  const dispatch = useAppDispatch()
  const history = useHistory()

  const isLoading = useAppSelector<boolean>(userListSelectors.isFetching)
  const userList = useAppSelector<UserShape[]>(userListSelectors.data)
  const currentHomeId = useAppSelector(currentHomeIdSelector)

  useEffect(() => {
    if (currentHomeId) {
      history.push(getUsersRoute(currentHomeId))
    }
  }, [currentHomeId, history])

  useEffect(() => {
    dispatch(getUsersThunk(homeId))
  }, [dispatch, homeId])

  const [isInviteUserModalOpen, setIsInviteModalOpen] = useState(false)
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false)

  const onInviteUserModalClose = () => setIsInviteModalOpen(false)

  const onInviteUserModalSuccess = () => setIsSuccessModalVisible(true)

  const onConfirmSuccessModal = () => {
    setIsSuccessModalVisible(false)
  }

  const { formatMessage } = useIntl()

  return (
    <>
      <ReactHelmet title={usersMessages.title.defaultMessage} />
      {isInviteUserModalOpen && (
        <InviteUser
          homeId={homeId}
          onClose={onInviteUserModalClose}
          onSuccess={onInviteUserModalSuccess}
        />
      )}
      <SuccessModal
        isOpen={isSuccessModalVisible}
        confirmBtnTitle={formatMessage(usersMessages.modalSuccess)}
        title={formatMessage(usersMessages.invitationSent)}
        icon={<CheckCircleOutline />}
        onConfirm={onConfirmSuccessModal}
      />

      <UsersHeader>
        <H1>
          <FormattedMessage {...usersMessages.title} />
        </H1>

        <InviteUserButton
          variant="goldenFilled"
          onClick={() => setIsInviteModalOpen(true)}
        >
          <FormattedMessage {...usersMessages.inviteUserBtn} />
        </InviteUserButton>
      </UsersHeader>

      <UsersContentContainer>
        {!isLoading ? (
          <UserList {...{ homeId }} items={userList} />
        ) : (
          <LoaderOverlay backgroundColor="transparent" />
        )}
      </UsersContentContainer>
    </>
  )
}

export default UsersPage
