import { api } from '@racepointenergy/swc/client/api'
import { configureClient } from '@racepointenergy/swc/scs/client'
import { createClients } from '@racepointenergy/swc/scs/create-clients'
import { createUsers2faCheck } from '@racepointenergy/swc/scs/create-users-2fa-check'
import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

import { NODE_ENV } from 'app/consts'
import { AppDispatch } from 'app/store'
import { makeFetchSlice } from 'app/redux-fetch'

import { USER_REDUCER_NAME } from 'features/auth/consts'
import { ILoginFormValues } from './types'
import { authorizeEdgeAxios } from 'app/axios/edge'
import {
  getAuthorizedUser,
  clearAuthorizedUser,
  storeClientId,
} from 'app/services/LocalStorage'
import { authorizeBillingAxios } from 'app/axios/billing'
import { UAparser } from 'app/utils/userAgent'
import { currHomeActions } from 'features/homes/homesSlice'
import { isAuthRoute } from 'app/utils/isAuthRoute'
import { gaLoginFail } from 'features/analytics/utils'

const userSlice = createSlice({
  initialState: {
    user: null,
    hasLoaded: false,
    failedLoginAttempts: 0,
  },
  name: USER_REDUCER_NAME,
  reducers: {
    authorize: (state, action) => ({
      ...state,
      hasLoaded: true,
      user: action.payload,
    }),
    unauthorize: (state) => ({ ...state, hasLoaded: true, user: null }),
    incrementLoginAttempts: (state) => ({
      ...state,
      failedLoginAttempts: state.failedLoginAttempts + 1,
    }),
    resetLoginAttempts: (state) => ({ ...state, failedLoginAttempts: 0 }),
  },
})
export const userReducer = userSlice.reducer
export const userActions = userSlice.actions

export const logOutThunk = () => {
  return (dispatch: AppDispatch) => {
    dispatch(api.util.resetApiState())
    dispatch(
      configureClient({ clientId: '', userId: '', secretKey: '', token: '' })
    )
    clearAuthorizedUser()
    dispatch(currHomeActions.setCurrentHomeId(''))
    dispatch(userActions.unauthorize())

    if (!isAuthRoute(window.location.pathname)) {
      return document.location.replace(window.location.origin)
    }
  }
}

export const updateAuthStatus = () => {
  return (dispatch: AppDispatch) => {
    const storedUser = getAuthorizedUser()
    if (storedUser) {
      dispatch(
        configureClient({
          clientId: localStorage.getItem('sav-clientId') ?? '',
          email: storedUser.email,
          secretKey: storedUser.secretKey,
          token: storedUser.token,
          userId: storedUser.id,
        })
      )
      const authorizeData = {
        token: storedUser.token,
        secretKey: storedUser.secretKey,
        onUnauthorized: () => dispatch(logOutThunk()),
      }
      authorizeBillingAxios(authorizeData)
      authorizeEdgeAxios(authorizeData)
      dispatch(userActions.authorize(storedUser))
    } else {
      dispatch(logOutThunk())
    }
  }
}

const loginSlice = makeFetchSlice('login')
export const loginReducer = loginSlice.reducer
export const loginActions = loginSlice.actions

const clientSlice = makeFetchSlice('client')
export const clientReducer = clientSlice.reducer
export const clientActions = clientSlice.actions

interface LoginEventsActionParams {
  onSuccess: (payload: any) => void
  onError: (err: any) => void
}

export const loginThunk = (
  params: ILoginFormValues,
  { onSuccess, onError }: LoginEventsActionParams
) => {
  return async (dispatch: AppDispatch) => {
    try {
      const UAParserResult = UAparser.getResult()

      const clientIdQuery = await dispatch(
        createClients.initiate({
          deviceType: UAParserResult.device.type || process.title,
          manufacturer: UAParserResult.device.vendor,
          model: UAParserResult.device.model,
          modelVersion: '',
          os: UAParserResult.os.name,
          osVersion: UAParserResult.os.version,
          appVersion: '1.0.0',
          devBuild: !(NODE_ENV === 'production'),
        })
      )

      if ('error' in clientIdQuery) {
        const scsError = _.get(
          clientIdQuery,
          'error.data.error',
          clientIdQuery.error
        )
        dispatch(loginActions.failure(scsError))
        return scsError
      }

      const twoFactorQuery = await dispatch(
        createUsers2faCheck.initiate({
          email: params.email,
          password: params.password,
          clientId: clientIdQuery.data,
        })
      )

      storeClientId(clientIdQuery.data)

      if ('error' in twoFactorQuery) {
        const scsError = _.get(
          twoFactorQuery,
          'error.data.error',
          twoFactorQuery.error
        )
        gaLoginFail()
        dispatch(userActions.incrementLoginAttempts())
        dispatch(loginActions.failure(scsError))
        return scsError
      } else {
        onSuccess(twoFactorQuery.data)
        return twoFactorQuery.data
      }
    } catch (err) {
      console.log('Error logging in the user', err)
      gaLoginFail()
      dispatch(userActions.incrementLoginAttempts())
      onError(err)
      dispatch(updateAuthStatus())
    }
  }
}
