import { defineMessages } from 'react-intl'

export const whatsNewMessages = defineMessages({
  title: {
    id: 'whatsNewMessages.title',
    description: 'Whats New block title',
    defaultMessage: "What's New?",
  },
  description: {
    id: 'whatsNewMessages.description',
    description: 'Whats New block description',
    defaultMessage:
      'Check out new Savant products, features and services here.',
  },
  savantPower: {
    id: 'whatsNewMessages.savantPower',
    description: 'Savant Power block',
    defaultMessage: 'YOUR SMART HOME CAN DO MORE',
  },
  savantPowerSubTitle: {
    id: 'whatsNewMessages.savantPowerSubTitle',
    description: 'Savant Power subtitle',
    defaultMessage: 'Delivering you Complete Control of your Energy Assets',
  },
  intercom: {
    id: 'whatsNewMessages.intercom',
    description: 'Intercom block',
    defaultMessage: 'ALL NEW SAVANT INTERCOM & PAGING',
  },
  intercomSubTitle: {
    id: 'whatsNewMessages.intercomSubTitle',
    description: 'Intercom subtitle',
    defaultMessage:
      "Savant's new Intercom feature now provides room-to-room communication and whole home paging through the home's distributed audio system.",
  },
})
