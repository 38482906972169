import { createSelector } from '@reduxjs/toolkit'
import { find, path } from 'lodash/fp'
import { ISubscriptionsState } from './slice'
import {
  SUBSCRIPTIONS_CONFIG_REDUCER_NAME,
  SUBSCRIPTIONS_REDUCER_NAME,
  SUBSCRIPTIONS_PAYMENT_FAIL,
} from './consts'
import { RootState } from 'app/store'
import { currentHomeInfoSelectors } from '../selectors'
import { SubscriptionInfoType } from './types'

const selectSubscriptions = createSelector(
  (store: RootState) =>
    store[SUBSCRIPTIONS_REDUCER_NAME][SUBSCRIPTIONS_CONFIG_REDUCER_NAME],
  (config): ISubscriptionsState => config
)

export const uiPeriodPlanSelector = createSelector(
  selectSubscriptions,
  (state) => {
    return state.uiPeriodPlan
  }
)

export const subscrPricesSelector = createSelector(
  selectSubscriptions,
  (state) => state.prices || []
)

export const activeSubscriptionSelector = createSelector(
  currentHomeInfoSelectors.data,
  (data): SubscriptionInfoType | undefined => data?.subscriptionInfo?.[0]
)

export const subscrMonthlyPricesSelector = createSelector(
  subscrPricesSelector,
  (prices) =>
    prices.filter((price) => {
      return price.metadata.typeInfo.intervalId === 0
    })
)

const pathSubscriptionPaymentFail = path([
  SUBSCRIPTIONS_REDUCER_NAME,
  SUBSCRIPTIONS_PAYMENT_FAIL,
])

export const subscriptionPaymentFail = createSelector(
  pathSubscriptionPaymentFail,
  (state) => state.error
)

export const subscrAnnualPricesSelector = createSelector(
  subscrPricesSelector,
  (prices) =>
    prices.filter((price) => {
      return price.metadata.typeInfo.intervalId === 1
    })
)

export const priceByIdSelector = (id: string) =>
  createSelector(
    subscrPricesSelector,
    find((price) => price.id === id)
  )
