import { defineMessages } from 'react-intl'

export const homeInfoMessages = defineMessages({
  title: {
    id: 'home.title',
    description: 'Text about specific home',
    defaultMessage: 'Home Status',
  },
  systemVersion: {
    id: 'home.systemVersion',
    description: 'Text about what your homes system version',
    defaultMessage: 'SavantOS Version',
  },
  homeId: {
    id: 'home.homeId',
    description: `Home Info block's Home Id title`,
    defaultMessage: 'Home ID',
  },
  hostUid: {
    id: 'home.hostUid',
    description: `Home Info block's Host UID title`,
    defaultMessage: 'Host UID',
  },
  hostType: {
    id: 'home.hostType',
    description: `Home Info block's Host Type title`,
    defaultMessage: 'Host type',
  },
  lastChange: {
    id: 'home.lastChange',
    description: `Home Info block's Last Configuration Date title`,
    defaultMessage: 'Last configuration change',
  },
  lastUpdated: {
    id: 'home.lastUpdated',
    description: `Home Info block's Last Updated title`,
    defaultMessage: 'Last updated',
  },
  online: {
    id: 'home.online',
    description: `Home Info block's online title`,
    defaultMessage: 'Online',
  },
  offline: {
    id: 'home.offline',
    description: `Home Info block's offline title`,
    defaultMessage: 'Offline',
  },
  lastBackup: {
    id: 'home.lastBackup',
    description: `Home Info block's Last Backup Date title`,
    defaultMessage: 'Last Home backup',
  },
  requiresPlan: {
    id: 'home.requiresPlan',
    description: `Home Info block's message telling that user needs to have paid subscription plan`,
    defaultMessage: 'Requires {planName}',
  },
  requiresForBackup: {
    id: 'home.requiresForBackup',
    description: `Home Info block's message telling that user requires minimum host version and subscription`,
    defaultMessage: 'Requires SavantOS 10.1 or later & {planName}',
  },
})
