import styled from 'styled-components'
import theme from 'theme_'

export const StyledHeaderWrapper = styled.header<{ shouldHaveBg: boolean }>`
  position: fixed;
  z-index: ${theme.zIndex.fixedHeader};
  top: 0;
  left: 0;
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(p) =>
    p.shouldHaveBg ? theme.overlay.newDropdownBlack : 'transparent'};
  transition: background-color 0.3s;

  > svg {
    display: block;
  }
`

export const StyledHeaderInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: ${theme.sizes.maxContentWidth};
  padding: 0 ${theme.spacing.sm};
`

export const StyledHeaderBgOverlay = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  backdrop-filter: ${theme.overlay.SBlur};
`

export const StyledSelectorWrapper = styled.div`
  display: none;
  @media ${theme.devices.desktop} {
    display: flex;
    margin: 0 24px;
  }
`
export const StyledHeaderActions = styled.div`
  display: flex;
  align-items: center;
`
