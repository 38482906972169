import React from 'react'
import MobileBackBtn from '../MobileBackBtn'
import { StyledScreenWrapper } from '../styles'
import LanguageSelector from 'components/LanguageSelector'

interface IScreenLanguageProps {
  handleScreenDefault: () => void
  handleCloseMenu: () => void
}

const ScreenLanguage: React.FC<IScreenLanguageProps> = (props) => {
  return (
    <StyledScreenWrapper>
      <MobileBackBtn
        handleClick={props.handleScreenDefault}
        text={'Select Language'}
      />
      <div onClick={props.handleCloseMenu}>
        <LanguageSelector isMobileMode />
      </div>
    </StyledScreenWrapper>
  )
}

export default ScreenLanguage
