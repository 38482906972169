import { FC } from 'react'
import ClipHeader from './../../ClipHeader/index'
import { mdiFilmstripBoxMultiple } from '@mdi/js'
import { MdiIcon } from 'components/Icons'

import theme from 'theme_'
import { ClipShape } from 'app/types/clip'
import { ClipBody, ClipContainer, ClipThumbnail } from './styles'

interface ClipProps {
  clip: ClipShape
  handleClick: (clip: ClipShape) => void
}

const Clip: FC<ClipProps> = ({ clip, handleClick }) => {
  return (
    <ClipContainer>
      <ClipHeader clipInfo={clip} isCompactView />
      <ClipBody onClick={() => handleClick(clip)}>
        {clip.thumbUrl ? (
          <ClipThumbnail src={clip.thumbUrl} />
        ) : (
          <MdiIcon
            path={mdiFilmstripBoxMultiple}
            size={4}
            color={theme.colors.disabledGrey}
          />
        )}
      </ClipBody>
    </ClipContainer>
  )
}

export default Clip
