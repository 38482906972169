// import { LoaderOverlay } from 'components/Loader'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { dealerSelector } from 'features/homes/selectors'

import routes from 'app/routes'
import { currentHomeIdSelector } from 'features/homes/selectors'
import { whatsNewMessages } from './messages'
import { commonMessages } from 'app/i18n'
import {
  WhatsNewContainer,
  WhatsNewTitle,
  WhatsNewDescription,
  WidgetBlock,
  WidgetContent,
  WidgetImage,
  WidgetImageWrapper,
  WidgetTitle,
  WidgetSubTitle,
  WidgetWrapper,
  WidgetButton,
} from './styles'
import { getBlocks } from './blocks'

const WhatsNew: React.FC<{ fullWidth?: boolean }> = ({ fullWidth }) => {
  const history = useHistory()
  const { formatMessage } = useIntl()
  const currentHomeId = useSelector(currentHomeIdSelector)
  const dealer = useSelector(dealerSelector)

  return (
    <WhatsNewContainer>
      <WhatsNewTitle>{formatMessage(whatsNewMessages.title)}</WhatsNewTitle>
      <WhatsNewDescription>
        <FormattedMessage {...whatsNewMessages.description} />
      </WhatsNewDescription>

      <WidgetWrapper>
        {getBlocks(formatMessage).map((block) => (
          <WidgetBlock key={block.imgSrc}>
            <WidgetImageWrapper>
              <WidgetImage src={block.imgSrc} />
            </WidgetImageWrapper>
            <WidgetContent>
              <WidgetTitle>{block.title}</WidgetTitle>
              <WidgetSubTitle>{block.subTitle}</WidgetSubTitle>
              <WidgetButton
                variant="goldenOutlined"
                onClick={() => {
                  if (block.internalRoute) {
                    history.push(routes.subscription)
                  } else if (block.externalRoute) {
                    window.open(
                      `${block.externalRoute}?homeID=${currentHomeId}&d=${dealer?.dealerInfo.id}`,
                      '_blank'
                    )
                  }
                }}
              >
                <FormattedMessage {...commonMessages.learnMore} />
              </WidgetButton>
            </WidgetContent>
          </WidgetBlock>
        ))}
      </WidgetWrapper>
    </WhatsNewContainer>
  )
}

export default WhatsNew
