import styled from 'styled-components'
import Select from 'react-select'

import theme from 'theme_'

export const DROPDOWN_PREFIX = 'StyledCommonDropdown'

export const StyledCommonDropdown = styled(Select)`
  max-height: ${theme.spacing.sm};
  padding: 0;
  margin: 0;
  background-color: transparent;

  .StyledCommonDropdown__indicator-separator {
    display: none;
  }

  .StyledCommonDropdown__dropdown-indicator {
    color: ${theme.colors.beachGrey};
    max-height: ${theme.spacing.sm};
    width: 24px;
    padding: 0 !important;
    margin-right: -4px;
    display: flex;
    justify-content: end;
  }

  .StyledCommonDropdown__menu {
    border: none;
    border-radius: 0;
    background: ${theme.colors.newBackgroundGrey};
    backdrop-filter: ${theme.overlay.SBlur};
    position: absolute;
    top: 132%;
    left: 0;
    opacity: 1;
    will-change: tranform;
    transform-origin: top;
    transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      transform 167ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    margin-top: 0;

    .StyledCommonDropdown__menu-list {
      padding: 0;
    }
    .StyledCommonDropdown__option {
      color: ${theme.colors.beachGrey};
      transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      cursor: pointer;

      :hover {
        background-color: rgba(53, 50, 49, 0.5);
      }
    }

    .StyledCommonDropdown__option--is-focused {
      background-color: rgba(53, 50, 49, 0.5) !important;
    }

    .StyledCommonDropdown__option--is-selected,
    .StyledCommonDropdown__option--is-focused {
      background-color: transparent;
    }
  }

  .StyledCommonDropdown__control--is-focused {
    background-color: transparent !important;
    box-shadow: none;
  }

  .StyledCommonDropdown__control {
    max-height: ${theme.spacing.sm};
    min-height: ${theme.spacing.sm}!important;
    border: none;
    border-radius: 0;
    background: transparent;
    cursor: pointer;

    .StyledCommonDropdown__value-container {
      padding: 2px 0;
    }

    .StyledCommonDropdown__single-value {
      color: ${theme.colors.beachGrey};
      background-color: transparent;
    }

    .StyledCommonDropdown__placeholder {
      max-width: 100%;
      color: ${theme.colors.beachGrey};
      background-color: transparent;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
`
