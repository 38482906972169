import { FC } from 'react'
import { Field, FieldProps } from 'formik'

import Toggle from 'components/Input/Toggle'

import { IFieldProps } from '../types'

interface ToggleProps extends FieldProps {
  disabled?: boolean
}

const ToggleDrawer: FC<ToggleProps> = ({ field, disabled }) => {
  return <Toggle {...field} {...{ disabled }} checked={field.value} />
}

const ToggleField: FC<IFieldProps> = (props) => (
  <Field component={ToggleDrawer} {...props} />
)

export default ToggleField
