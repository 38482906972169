// TODO should be in auth/reducer
import {
  LOGIN2FA_REDUCER_NAME,
  LOGIN_REDUCER_NAME,
  PASS_INIT_RESET_REDUCER_NAME,
  USER_REDUCER_NAME,
  ACTIVE_USER_INFO_REDUCER_NAME,
  UPDATE_USER_REDUCER_NAME,
  PASS_RESET_CONFIRM_REDUCER_NAME,
  CLIENT_REDUCER_NAME,
} from './consts'
import { passInitResetReducer } from './components/ForgotPassword/slice'
import { clientReducer, loginReducer, userReducer } from './login/slice'
import { passResetConfirmReducer } from './components/ForgotPassword/slice'
import { combineReducers } from '@reduxjs/toolkit'
import { twoFaLoginSliceReducer } from './components/TwoFaAuth/slice'
import {
  activeUserInfoReducer,
  requestUserUpdateReducer,
} from './account/slice'

export default combineReducers({
  [LOGIN_REDUCER_NAME]: loginReducer,
  [PASS_INIT_RESET_REDUCER_NAME]: passInitResetReducer,
  [USER_REDUCER_NAME]: userReducer,
  [ACTIVE_USER_INFO_REDUCER_NAME]: activeUserInfoReducer,
  [UPDATE_USER_REDUCER_NAME]: requestUserUpdateReducer,
  [LOGIN2FA_REDUCER_NAME]: twoFaLoginSliceReducer,
  [PASS_RESET_CONFIRM_REDUCER_NAME]: passResetConfirmReducer,
  [CLIENT_REDUCER_NAME]: clientReducer,
})
