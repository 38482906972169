import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    id: 'inviteUser.title',
    description: 'Invite user modal title',
    defaultMessage: 'Invite user',
  },
  messageForAdmin: {
    id: 'inviteUser.messageForAdmin',
    description: 'Message on invite user modal for admins',
    defaultMessage:
      'This user will have administrative access to the home. They will be able to invite users and manage other users within the home.',
  },
  messageForHousehold: {
    id: 'inviteUser.messageForHousehold',
    description: 'Message on invite user modal for households',
    defaultMessage:
      'Household users can be restricted to specific rooms and services. They will not have access to all scenes. They will only have access to shared scenes and scenes that they create for themselves.',
  },
  messageForGuest: {
    id: 'inviteUser.messageForGuest',
    description: 'Message on invite user modal for guests',
    defaultMessage:
      'This user will only have access while within the home. They can be restricted from rooms and services and only have access to shared scenes.',
  },
  expiryDate: {
    id: 'inviteUser.expiryDate',
    description: 'Invite user modal expiry access',
    defaultMessage: 'Home Access Expires',
  },
  ctaBtn: {
    id: 'inviteUser.ctaBtn',
    description: 'Invite user modal CTA button',
    defaultMessage: 'send',
  },
  userFirstNameMock: {
    id: 'inviteUser.userFirstNameMock',
    description: 'Default first name placeholder for invited user',
    defaultMessage: 'New',
  },
  adminLastNameMock: {
    id: 'inviteUser.adminLastNameMock',
    description: 'Default last name placeholder for invited admin user',
    defaultMessage: 'Administrator',
  },
  householdLastNameMock: {
    id: 'inviteUser.householdLastNameMock',
    description: 'Default last name placeholder for invited household user',
    defaultMessage: 'Household Member',
  },
  guestLastNameMock: {
    id: 'inviteUser.guestLastNameMock',
    description: 'Default last name placeholder for invited guest user',
    defaultMessage: 'Guest Member',
  },
  customLastNameMock: {
    id: 'inviteUser.customLastNameMock',
    description: 'Default last name placeholder for invited custom user',
    defaultMessage: 'Custom Member',
  },
  inviteMessage: {
    id: 'inviteUser.inviteMessage',
    description: "You've been invited to a home",
    defaultMessage: 'Custom Member',
  },
  error_HOME_009: {
    id: 'inviteUser.error_HOME_009',
    description: 'Error when inviting the same user for home multiple times',
    defaultMessage: 'User already associated with home',
  },
})
