import styled from 'styled-components'
import theme from 'theme_'

export const StyledLinkImg = styled.img`
  flex: none;
  display: block;
  width: 77px;
  height: 55px;
  margin-right: ${theme.spacing.s};
  border-radius: ${theme.spacing.tiny};
  object-fit: cover;
`
