import isString from 'lodash/isString'

interface IUserWithName {
  firstName: string
  lastName: string
}

const formatUserFullName = (user: IUserWithName): string => {
  const { firstName, lastName } = user
  const nameFields = [firstName, lastName]

  return nameFields
    .filter((name) => isString(name) && name.trim().length > 0)
    .join(' ')
}

export default formatUserFullName
