import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import { mdiMagnify } from '@mdi/js'
import {
  AdminSelectorMenuInner,
  AdminHomeSelectorLink,
  HomeSearchHeader,
  HomeSearchInput,
  InputIcon,
  InputWrapper,
  ButtonWrapper,
} from './styles'
import { storeSelectedHomeId } from 'app/services/LocalStorage'
import Modal from 'components/Modal'
import { CommonButton } from 'components/Button'
import {
  adminHomesListSelector,
  currentHomeIdSelector,
} from 'features/homes/selectors'
import { commonMessages } from 'app/i18n'
import { currHomeActions } from 'features/homes/homesSlice'
import { useAppDispatch } from 'app/hooks'
import { homeSelectorMessages } from '../messages'

interface IHomeSelectorMenuProps {
  isOpen?: boolean
  isMobileMenu?: boolean
  closeMenuHandler?: () => void
}

const HomeSearch: React.FC<IHomeSelectorMenuProps> = ({
  isOpen = false,
  closeMenuHandler,
}) => {
  const dispatch = useAppDispatch()
  const { formatMessage } = useIntl()
  const currentHomeId = useSelector(currentHomeIdSelector)
  const [search, setSearch] = useState('')

  const adminHomesList = useSelector(adminHomesListSelector)
  const handleSelectHome = (homeId: string) => {
    dispatch(currHomeActions.setCurrentHomeId(homeId))
    storeSelectedHomeId(homeId)
    closeMenuHandler?.()
  }

  const sortedHomesList = adminHomesList.sort(
    ({ name: a }: { name: string }, { name: b }: { name: string }) =>
      a.localeCompare(b)
  )

  // Move selected home to top of list
  const selectedIndex = sortedHomesList.findIndex(
    ({ id }: { id: string }) => id === currentHomeId
  )
  const selectedHome = sortedHomesList[selectedIndex]
  sortedHomesList.splice(selectedIndex, 1)
  sortedHomesList.splice(0, 0, selectedHome)

  // Filter homes if search input
  const filteredHomesList = sortedHomesList.filter(
    ({ name }: { name: string }) =>
      name.toLowerCase().includes(search.toLowerCase())
  )

  return (
    <Modal isOpen={isOpen} onRequestClose={closeMenuHandler}>
      <HomeSearchHeader>
        <FormattedMessage {...homeSelectorMessages.selectHome} />
      </HomeSearchHeader>

      <InputWrapper>
        <InputIcon path={mdiMagnify} size={1.25} />
        <HomeSearchInput
          name="homeSearch"
          placeholder={formatMessage(homeSelectorMessages.inputPlaceholder)}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </InputWrapper>

      <AdminSelectorMenuInner>
        {filteredHomesList.map((home: any) => {
          return (
            <AdminHomeSelectorLink
              key={home.id}
              onClick={() => handleSelectHome(home.id)}
              name={home.name}
              imageUrl={home.imageUrl}
              isActive={home.id === currentHomeId}
              isAdmin
            />
          )
        })}
      </AdminSelectorMenuInner>

      <ButtonWrapper>
        <CommonButton
          variant="goldenOutlined"
          onClick={() => closeMenuHandler?.()}
        >
          <FormattedMessage {...commonMessages.close} />
        </CommonButton>
      </ButtonWrapper>
    </Modal>
  )
}

export default HomeSearch
