import React, { useCallback, useState } from 'react'
import Icon from '@mdi/react'
import {
  MobileInnerCloseBtn,
  MobileMenuHeader,
  MobileMenuHeaderInner,
  StyledMobileMenuInner,
} from './styles'
import HeaderLogo from 'components/Header/HeaderLogo'
import { mdiClose } from '@mdi/js'
import ScreenDefault from '../MobileMenuScreens/ScreenDefault'
import ScreenAccount from './../MobileMenuScreens/ScreenAccount'
import ScreenHomes from './../MobileMenuScreens/ScreenHomes'
import ScreenLanguage from './../MobileMenuScreens/ScreenLanguage'

interface IMobileMenuInnerProps {
  handleClose: () => void
}

type ActiveScreensType = 'default' | 'account' | 'language' | 'homes'

const MobileMenuInner: React.FC<IMobileMenuInnerProps> = (props) => {
  const [activeScreen, setActiveScreen] = useState<ActiveScreensType>('default')

  const handleScreenDefault = useCallback(() => setActiveScreen('default'), [])

  return (
    <StyledMobileMenuInner>
      <MobileMenuHeader shouldHaveBg>
        <MobileMenuHeaderInner>
          <HeaderLogo />
          <MobileInnerCloseBtn onClick={props.handleClose}>
            <Icon path={mdiClose} size={1} color="#fff" />
          </MobileInnerCloseBtn>
        </MobileMenuHeaderInner>
      </MobileMenuHeader>

      {activeScreen === 'default' && (
        <ScreenDefault
          handleScreenAccount={() => setActiveScreen('account')}
          handleScreenHomes={() => setActiveScreen('homes')}
          handleScreenLanguage={() => setActiveScreen('language')}
          handleCloseMenu={props.handleClose}
        />
      )}
      {activeScreen === 'account' && (
        <ScreenAccount
          handleScreenDefault={handleScreenDefault}
          handleCloseMenu={props.handleClose}
        />
      )}
      {activeScreen === 'homes' && (
        <ScreenHomes
          handleScreenDefault={handleScreenDefault}
          handleCloseMenu={props.handleClose}
        />
      )}
      {activeScreen === 'language' && (
        <ScreenLanguage
          handleScreenDefault={handleScreenDefault}
          handleCloseMenu={props.handleClose}
        />
      )}
    </StyledMobileMenuInner>
  )
}

export default MobileMenuInner
