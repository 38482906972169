import styled from 'styled-components'
import { BluredBlock } from 'components/Layout'
import theme from 'theme_'
import { CommonButton } from 'components/Button'

export const ConnectedAppContainer = styled(BluredBlock).attrs({ as: 'li' })`
  margin: 0;
  overflow: hidden;
`

export const ConnectedAppIconContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const ConnectedAppName = styled.div`
  position: relative;
  text-align: center;
  color: ${theme.colors.beachGrey};
  font-weight: 400;
`

export const ConnectedAppIconImg = styled.img`
  height: 120px;
  max-width: 200px;
`

export const ConnectedAppHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${theme.spacing.xxs};
`

export const ConnectedAppStatusWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
`

export const ConnectedAppStatus = styled.div`
  padding: 0;
  margin-left: ${theme.spacing.xs};
  line-height: 18px;
  font-weight: 400;
  font-size: 12px;
  color: ${theme.colors.beachGrey};
  @media ${theme.devices.tablet} {
    line-height: 20px;
    font-size: 14px;
  } ;
`

export const ConnectedAppStatusCircle = styled.div<{ status: boolean }>`
  margin-top: ${theme.spacing.tiny};
  border-radius: 50%;
  height: ${theme.spacing.xs};
  width: ${theme.spacing.xs};
  ${(p) =>
    p.status
      ? `background: ${theme.colors.success};`
      : `background: ${theme.colors.error};`};

  @media ${theme.devices.tablet} {
    margin-top: ${theme.spacing.xxs};
  }
`
export const ConnectedAppButton = styled(CommonButton)`
  width: fit-content;
  align-self: center;
  margin-bottom: ${theme.spacing.tiny};
  margin-top: ${theme.spacing.xs};
`
