import { useIntl } from 'react-intl'

import { PlansTableElement, PlansTableIcons } from './consts'
import { subscriptionTableMessages } from './messages'
import {
  SubscriptionCheckIcon,
  SubscriptionMinusIcon,
  SubscriptionUnlimitedText,
} from './styles'

interface PlansTableItemProps {
  item: PlansTableElement
  colorful?: boolean
}

function PlansTableItem({ item, colorful = false }: PlansTableItemProps) {
  const { formatMessage } = useIntl()

  switch (item) {
    case 'unlimited':
      return (
        <SubscriptionUnlimitedText colorful={colorful}>
          {formatMessage(subscriptionTableMessages.dynamicUnlimitedLabel)}
        </SubscriptionUnlimitedText>
      )
    case 'video':
      return (
        <SubscriptionUnlimitedText colorful={colorful}>
          {formatMessage(subscriptionTableMessages.videoClipsLabel)}
        </SubscriptionUnlimitedText>
      )
    case 'minus':
      return (
        <SubscriptionMinusIcon
          path={PlansTableIcons.minus}
          $colorful={colorful}
        />
      )
    default:
      return (
        <SubscriptionCheckIcon
          path={PlansTableIcons.checkmark}
          $colorful={colorful}
        />
      )
  }
}

export default PlansTableItem
