import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import routes from 'app/routes'

import {
  StyledMySupport,
  StyledMySupportBtnWrap,
  StyledMySupportSolution,
  StyledMySupportTitle,
  StyledMySupportTrouble,
} from './styles'
import { MySupportMessages } from './messages'
import { CommonButton } from 'components/Button'
import IconLink from 'components/Button/IconLink'
// import { useAppSelector } from 'app/hooks'
// import { dealerSelector } from 'features/homes/selectors'

const MySupport: React.FC = () => {
  // const dealer = useAppSelector(dealerSelector)
  return (
    <StyledMySupport>
      <StyledMySupportTitle>
        <FormattedMessage {...MySupportMessages.titleSimple} />
        <IconLink to={routes.getHelp} />
      </StyledMySupportTitle>
      <StyledMySupportTrouble>
        <FormattedMessage {...MySupportMessages.havingTrouble} />
      </StyledMySupportTrouble>
      <StyledMySupportSolution>
        <FormattedMessage {...MySupportMessages.contactDealer} />
      </StyledMySupportSolution>
      <StyledMySupportBtnWrap>
        <CommonButton as={Link} variant="goldenOutlined" to={routes.getHelp}>
          <FormattedMessage {...MySupportMessages.getSupport} />
        </CommonButton>
      </StyledMySupportBtnWrap>
    </StyledMySupport>
  )
}

export default MySupport
