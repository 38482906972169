import React from 'react'
import { useIntl } from 'react-intl'
import { mdiInformationOutline } from '@mdi/js'
import { useHistory } from 'react-router-dom'

import { resetErrorPageMessages } from './messages'
import { BackToLoginWrapper, StyledDescriptionText, ErrorIcon } from './styles'
import CommonButton from 'components/Button/CommonButton'
import LoginCommonLayout from '../../LoginCommonLayout'
import { StyledAuthForm } from 'features/auth/login/styles'
import { StyledMainHeading } from '../../MainHeading/MainHeading'
import theme from 'theme_'

const ResetErrorPage: React.FC = () => {
  const { formatMessage } = useIntl()
  const history = useHistory()
  return (
    <LoginCommonLayout>
      <StyledAuthForm as="div">
        <ErrorIcon
          path={mdiInformationOutline}
          size={3}
          color={theme.colors.error}
        />
        <StyledMainHeading>
          {formatMessage(resetErrorPageMessages.title)}
        </StyledMainHeading>
        <StyledDescriptionText>
          {formatMessage(resetErrorPageMessages.description)}
        </StyledDescriptionText>
        <BackToLoginWrapper>
          <CommonButton
            type="button"
            variant="goldenOutlined"
            onClick={() => history.push('/')}
          >
            {formatMessage(resetErrorPageMessages.goToLogin)}
          </CommonButton>
        </BackToLoginWrapper>
      </StyledAuthForm>
    </LoginCommonLayout>
  )
}

export default ResetErrorPage
