import { createSelector } from '@reduxjs/toolkit'
import { path } from 'lodash/fp'

import { FetchState, makeFetchSelectors } from 'app/redux-fetch'

import {
  PAYMENT_REDUCER_NAME,
  PAYMENT_METHODS_REDUCER_NAME,
  SELECTED_PAYMENT_METHOD_REDUCER_NAME,
  INVOICE_REDUCER_NAME,
  CHANGED_DEFAULT_PAYMENT_METHODS_REDUCER_NAME,
  DETACH_PAYMENT_METHODS_REDUCER_NAME,
  SETUP_INTENT_REDUCER_NAME,
} from './consts'
import { PaymentMethod } from './types'
interface PaymentMethods {
  data: PaymentMethod[]
}

export interface PaymentState {
  paymentMethods: FetchState<PaymentMethods | null>
  checkoutSession: FetchState<string | null>
}

export const paymentMethodSelectors = makeFetchSelectors(
  PAYMENT_REDUCER_NAME,
  PAYMENT_METHODS_REDUCER_NAME
)

export const defaultPaymentMethodSelector = createSelector(
  paymentMethodSelectors.data,
  (data) => (data as PaymentMethods)?.data.find((method) => method.isDefault)
)

export const changedDefaultPaymentMethodSelectors = makeFetchSelectors(
  PAYMENT_REDUCER_NAME,
  CHANGED_DEFAULT_PAYMENT_METHODS_REDUCER_NAME
)

export const detachPaymentMethodSelectors = makeFetchSelectors(
  PAYMENT_REDUCER_NAME,
  DETACH_PAYMENT_METHODS_REDUCER_NAME
)

export const paymentMethodsSelector = createSelector(
  paymentMethodSelectors.data,
  (data) => (data as PaymentMethods)?.data || []
)

export const selectedPaymentMethodSelector = path([
  PAYMENT_REDUCER_NAME,
  SELECTED_PAYMENT_METHOD_REDUCER_NAME,
  'id',
])

export const upcomingInvoiceSelector = path([
  PAYMENT_REDUCER_NAME,
  INVOICE_REDUCER_NAME,
  'data',
  'data',
])

export const isFetchingInvoiceSelector = path([
  PAYMENT_REDUCER_NAME,
  INVOICE_REDUCER_NAME,
  'isFetching',
])

export const setupIntentSelector = path([
  PAYMENT_REDUCER_NAME,
  SETUP_INTENT_REDUCER_NAME,
  'data',
  'data',
])
