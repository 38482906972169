import styled from 'styled-components'

import theme from 'theme_'
import { BluredBlock } from 'components/Layout'
import { SectionTitle } from 'components/Typography'

export const StyledDealerContainer = styled(BluredBlock)<{
  bordered?: boolean
  fullWidth?: boolean
}>`
  order: 1;
  height: auto;
  width: 100%;
  margin: ${theme.spacing.sm} 0;

  ${(p) =>
    p.bordered &&
    `
    border: 1px solid ${theme.colors.dimmedGold};
  `}

  @media ${theme.devices.desktop} {
    margin: ${(p) =>
      p.fullWidth ? `0` : `0 0 ${theme.spacing.sm} ${theme.spacing.xs}`};
  }
`

export const DealerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${theme.spacing.tiny};
`

export const DealerBlockTitle = styled(SectionTitle)``
