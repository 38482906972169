import { defineMessages } from 'react-intl'

export const noHomesPageMessages = defineMessages({
  title: {
    id: 'noHomesPage.title',
    description: 'Title of the page',
    defaultMessage: 'Only homes you manage will appear here',
  },
  description: {
    id: 'noHomesPage.description',
    description: 'Description of the page',
    defaultMessage:
      "Contact your home's admin to make changes to your home or contact a Savant Dealer to get your own Savant Home.",
  },
})
