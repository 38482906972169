import React from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from 'styled-components'
import { Provider } from 'react-redux'
import { HelmetProvider } from 'react-helmet-async'

import App from './App'
import { store } from './app/store'
import * as serviceWorker from './serviceWorker'
import LocalizationWrapper from './components/i18n/LocalizationWrapper/LocalizationWrapper'
import { GlobalStyle } from './globalStyles'
import theme from './theme_'
import ReactHelmet from 'features/analytics/RenderHelmet'

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <LocalizationWrapper>
      <React.StrictMode>
        <GlobalStyle />
        <HelmetProvider>
          <ReactHelmet />
          <Provider store={store}>
            <App />
          </Provider>
        </HelmetProvider>
      </React.StrictMode>
    </LocalizationWrapper>
  </ThemeProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
