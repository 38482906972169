import { defineMessages } from 'react-intl'

export const purchaseMessages = defineMessages({
  successTitle: {
    id: 'purchase.successTitle',
    description: 'Successful purchase modal title',
    defaultMessage: 'Done',
  },
  paymentMethodsTitle: {
    id: 'purchase.paymentMethodsTitle',
    description: 'Payment methods section title',
    defaultMessage: 'Payment Methods',
  },
  paymentErrorTitle: {
    id: 'purchase.paymentErrorTitle',
    description: 'Payment attempt error title',
    defaultMessage: 'Error Occurred',
  },
  paymentErrorText: {
    id: 'purchase.paymentErrorText',
    description: 'Payment attempt error details text',
    defaultMessage: 'We have processed an error...',
  },
  paymentErrorButton: {
    id: 'purchase.paymentErrorButton',
    description: 'Payment attempt error text on button in modal',
    defaultMessage: 'Back to Checkout',
  },
  backBtn: {
    id: 'purchase.backBtn',
    description: 'Back to Subscriptions page button title',
    defaultMessage: 'Back to Subscription Plans',
  },
  addPaymentBtn: {
    id: 'purchase.addPaymentBtn',
    description: 'Add payment method button title',
    defaultMessage: 'Add payment method',
  },
  addFirstPaymentMethodText: {
    id: 'purchase.addFirstPaymentMethodText',
    description: 'Text block before addFirstPaymentMethod button',
    defaultMessage:
      'You don’t have any payment methods added!{br}Add payment methods to keep your subscription...',
  },
  promocodeInvalid: {
    id: 'purchase.promocodeInvalid',
    description: 'Message of error when no such promocode is invalid/not found',
    defaultMessage: 'Promocode is invalid',
  },
  promocodeExpired: {
    id: 'purchase.promocodeExpired',
    description: 'Message of error when a promocode has expired',
    defaultMessage: 'This promo code has expired',
  },
  promocodeUsed: {
    id: 'purchase.promocodeUsed',
    description: 'Message of error when a promocode was used',
    defaultMessage: 'This promo code is already used',
  },
  applyPromocode: {
    id: 'purchase.applyPromocode',
    description: 'Text on a button to apply promocode',
    defaultMessage: 'APPLY',
  },
  addNewPaymentBtn: {
    id: 'purchase.addNewPaymentBtn',
    description: 'Add payment method button title',
    defaultMessage: 'Add new',
  },
  purchaseSubscription: {
    id: 'purchase.purchaseSubscription',
    description: 'Main heading of checkout page',
    defaultMessage: 'Purchase Subscription',
  },
  promocodeAlreadyApplied: {
    id: 'purchase.promocodeAlreadyApplied',
    description:
      'Hint text for user showing that current subscription already has applied promocode',
    defaultMessage: 'Promocode for current subscription already applied',
  },
})
