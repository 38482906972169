import styled from 'styled-components'
import theme from 'theme_'

export const CommonStyledInput = styled.input<{ invalid?: boolean }>`
  margin: 0 0 16px;
  padding: 0 ${theme.spacing.s};
  height: 40px;
  font-size: 16px;
  font-family: ${theme.fonts.base};
  color: ${theme.colors.beachGrey};
  background-color: ${theme.colors.inputFill};
  border: none;
  box-shadow: inset 0px -1px 0px #8a847b;
  outline: none;

  :hover {
    background-color: ${theme.colors.inputFillFocus};
  }
  :focus {
    background-color: ${theme.colors.inputFillFocus};
    box-shadow: inset 0px -1px 0px #edbb76;
  }
  :disabled {
    color: ${theme.colors.disabledGrey};
    box-shadow: none;
    pointer-events: none;
  }
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    -webkit-text-fill-color: ${theme.colors.accentGold};
    transition: background-color 5000s ease-in-out 0s;
  }

  ${(p) =>
    p.invalid &&
    `box-shadow: inset 0px -1px 0px ${theme.colors.horizonOrange};`};
`

export const StyledInputError = styled.p`
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  margin-top: -15px;
  margin-bottom: ${theme.spacing.s};
  color: ${theme.colors.horizonOrange};
`
