import styled from 'styled-components'
import Icon from '@mdi/react'
import theme from 'theme_'

export const StyledHomeSelectorWrapper = styled.div`
  position: relative;
`

export const StyledHomeSelector = styled.button<{ isOpen: boolean }>`
  appearance: none;
  width: 100%;
  display: flex;
  align-items: center;
  height: 88px;
  padding: 0 ${theme.spacing.xl} 0 ${theme.spacing.base};
  margin: 0;
  color: ${theme.colors.beachGrey};
  text-align: left;
  border: none;
  background: transparent;
  cursor: pointer;
  transition: background-color 0.2s ease;
  ${(p) => p.isOpen && `background-color: ${theme.overlay.menuHover};`};

  :hover {
    background-color: ${theme.overlay.menuHover};
  }

  @media ${theme.devices.desktop} {
    height: 88px;
    border-radius: ${theme.spacing.xs} ${theme.spacing.xs} 0 0;
  }
`

export const StyledHomeSelectorArrow = styled(Icon)`
  position: absolute;
  z-index: 2;
  top: 32px;
  right: ${theme.spacing.base};
`
