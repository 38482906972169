import styled from 'styled-components'
import { Form } from 'formik'

import CommonLink from 'components/Button/CommonLink'
import theme from 'theme_'
import { Secondary } from 'components/Typography'

export const StyledLoginForm = styled(Form)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 347px;
  margin: 0 auto;

  input + p {
    min-height: 16px;
    margin-top: -16px;
    margin-bottom: 2px;
  }

  @media ${theme.devices.tablet} {
    max-width: 471px;
    padding: 0;
  }
`

export const ForgotPasswordWrapper = styled.div`
  text-align: right;
  margin-bottom: ${theme.spacing.md};
`
export const ForgotPasswordLink = styled(CommonLink)`
  font-weight: 700;
`

export const StyledTermsAndPolicyText = styled(Secondary)`
  margin-top: ${theme.spacing.md};
  text-align: center;
`
export const StyledTermsAndPolicyLink = styled(CommonLink)`
  font-weight: 700;
`

export const SubmitLoginWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${theme.spacing.base};

  @media ${theme.devices.tablet} {
    margin-top: 4px;
  }
`
