import { useCallback, useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import type { ActionMeta, OptionProps } from 'react-select/src/types'

import { EventFilterContainer } from './styles'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  camerasListSelectors,
  selectedCameraEventSelector,
} from 'features/cameraRecordings/selectors'
import { clipsFiltersActions } from 'features/cameraRecordings/slice'
import { eventFilterMessages } from './messages'
import type { SavantCameraEventIdType } from 'features/cameraRecordings/types'
import {
  DROPDOWN_PREFIX,
  StyledCommonDropdown,
} from 'components/Dropdown/ReactSelect/styles'

export interface EventFilterOption {
  value: SavantCameraEventIdType
  label: string
}

const EventFilter = () => {
  const dispatch = useAppDispatch()
  const { formatMessage } = useIntl()

  const selectedCameraEvent = useAppSelector(selectedCameraEventSelector)
  const isLoading = useAppSelector<boolean>(camerasListSelectors.isFetching)

  const { locale } = useIntl()

  const eventOptions: EventFilterOption[] = useMemo(
    () => [
      { value: null, label: formatMessage(eventFilterMessages.allEvents) },
      {
        value: 'EntryNoiseEvent',
        label: formatMessage(eventFilterMessages.noiseEvent),
      },
      {
        value: 'EntryLiveEvent',
        label: formatMessage(eventFilterMessages.liveEvent),
      },
      {
        value: 'EntryMotionEvent',
        label: formatMessage(eventFilterMessages.motionEvent),
      },
      {
        value: 'EntryTamperEvent',
        label: formatMessage(eventFilterMessages.tamperEvent),
      },
      {
        value: 'EntryRing',
        label: formatMessage(eventFilterMessages.ringEvent),
      },
      {
        value: 'EntryKeyEvent',
        label: formatMessage(eventFilterMessages.keyEvent),
      },
    ],
    [formatMessage]
  )

  useEffect(() => {
    const currentEventOption = selectedCameraEvent
    const localizedEventOption = eventOptions.find(
      (option) => option.value === currentEventOption?.value
    )
    if (currentEventOption?.label !== localizedEventOption?.label) {
      dispatch(clipsFiltersActions.setEventOption(localizedEventOption))
    }
  }, [dispatch, eventOptions, locale, selectedCameraEvent])

  const handleChange = useCallback(
    (newValue: OptionProps, triggeredAction: ActionMeta<OptionProps>) => {
      if (triggeredAction.action === 'clear') {
        dispatch(clipsFiltersActions.clearEventId())
      }
      dispatch(clipsFiltersActions.setEventOption(newValue))
    },
    [dispatch]
  )

  return (
    <EventFilterContainer>
      <StyledCommonDropdown
        isDisabled={isLoading}
        value={selectedCameraEvent || null}
        classNamePrefix={DROPDOWN_PREFIX}
        isLoading={isLoading}
        isSearchable={false}
        options={eventOptions}
        placeholder={formatMessage(eventFilterMessages.allEvents)}
        onChange={handleChange}
      />
    </EventFilterContainer>
  )
}

export default EventFilter
