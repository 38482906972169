import React from 'react'
import { useIntl } from 'react-intl'

import { MySubscriptionItemSubTitle, MySubscriptionItemTitle } from '../styles'
import { subscriptionMessages } from '../messages'
import { MySubscriptionBillingItem } from './styles'
import IntlFormattedDate from 'components/i18n/IntlFormattedDate'

interface SubscrBillingDateProps {
  nextBillingDate: number | null | undefined
}

const SubscrBillingDate: React.FC<SubscrBillingDateProps> = ({
  nextBillingDate,
}) => {
  const intl = useIntl()
  const { formatMessage } = intl

  return (
    <MySubscriptionBillingItem>
      <MySubscriptionItemTitle>
        {formatMessage(subscriptionMessages.billingDate)}
      </MySubscriptionItemTitle>
      <MySubscriptionItemSubTitle>
        <IntlFormattedDate date={nextBillingDate} />
      </MySubscriptionItemSubTitle>
    </MySubscriptionBillingItem>
  )
}

export default SubscrBillingDate
