import { defineMessages } from 'react-intl'

export const eventFilterMessages = defineMessages({
  allEvents: {
    id: 'eventFilter.allEvents',
    description:
      'Text in filter-input when no specific event is selected (all selected)',
    defaultMessage: 'All Events',
  },
  liveEvent: {
    id: 'eventFilter.liveEvent',
    description: "Text in filter-input for camera's Live Events",
    defaultMessage: 'Live Event',
  },
  motionEvent: {
    id: 'eventFilter.motionEvent',
    description: "Text in filter-input for camera's Motion Events",
    defaultMessage: 'Motion Event',
  },
  ringEvent: {
    id: 'eventFilter.ringEvent',
    description: "Text in filter-input for camera's Ring Events",
    defaultMessage: 'Ring Event',
  },
  keyEvent: {
    id: 'eventFilter.keyEvent',
    description: "Text in filter-input for camera's Key Events",
    defaultMessage: 'Key Event',
  },
  noiseEvent: {
    id: 'eventFilter.noiseEvent',
    description: "Text in filter-input for camera's Noise Events",
    defaultMessage: 'Noise Event',
  },
  tamperEvent: {
    id: 'eventFilter.tamperEvent',
    description: "Text in filter-input for camera's Tamper Events",
    defaultMessage: 'Tamper Event',
  },
})
