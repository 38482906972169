import styled from 'styled-components'
import theme from 'theme_'

export const GetHelpHeader = styled.header`
  display: flex;
  justify-content: space-between;
  H1 {
    line-height: 18px;
    font-size: 18px;
    @media ${theme.devices.tablet} {
      line-height: 40px;
      font-size: 32px;
    }
  }
`

export const DashboardWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    margin: 0 0 ${theme.spacing.s} 0;
  }

  @media ${theme.devices.tablet} {
    display: block;
    columns: 2 360px;
    column-gap: ${theme.spacing.base};

    > div {
      display: inline-block;
      width: 100%;
      margin: 0 ${theme.spacing.base} ${theme.spacing.base} 0;
    }
  }
`

export const HelpContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.base};
  margin-top: ${theme.spacing.md};

  & > * {
    margin: 0;
    order: initial;
  }

  @media ${theme.devices.tablet} {
    flex-direction: row;

    & > * {
      flex: 1 1 0px;
    }
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.base};
`
