import { defineMessages } from 'react-intl'

export const dealerMessages = defineMessages({
  dealerRequestTitle: {
    id: 'dealer.dealerRequestTitle',
    description: 'Title of dealer request block',
    defaultMessage: 'Dealer request',
  },
  dealerRequestAcceptBtn: {
    id: 'dealer.dealerRequestAcceptBtn',
    description: 'Title of Accept button of dealer request block',
    defaultMessage: 'Accept',
  },
})
