import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

import { Spinner } from 'components/Loader'
import { dealerSelector, uberSelectors } from 'features/homes/selectors'
import { SpinnerContainer } from 'features/users/styles'
import Permissions from './permissions'
import { DealerH1 } from './styles'
import { messages } from './messages'
import MyDealer from 'features/homes/components/MyDealer'
import { useAppSelector } from 'app/hooks'
import ReactHelmet from 'features/analytics/RenderHelmet'

const MyDealerInfo: React.FC = () => {
  const { formatMessage } = useIntl()
  const uberIsFinished = useAppSelector(uberSelectors.isFinished)
  const dealer = useSelector(dealerSelector)

  return (
    <>
      <ReactHelmet title={messages.pageTitle.defaultMessage} />

      <DealerH1>{formatMessage(messages.pageTitle)}</DealerH1>
      {uberIsFinished ? (
        <>
          <MyDealer fullWidth />
          {dealer && <Permissions dealer={dealer} />}
        </>
      ) : (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      )}
    </>
  )
}

export default MyDealerInfo
