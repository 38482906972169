import { createSelector } from '@reduxjs/toolkit'
import { path } from 'lodash/fp'
import _get from 'lodash/get'

import { makeFetchSelectors } from 'app/redux-fetch'
import { createDeepEqualSelector } from 'app/utils/createDeepEqualSelector'
import {
  CAMERA_CLIP_REDUCER_NAME,
  CAMERA_CLIPS_LIST_REDUCER_NAME,
  CAMERA_RECORDINGS_REDUCER_NAME,
  CAMERAS_LIST_REDUCER_NAME,
  CLIPS_FILTERS_REDUCER_NAME,
  CLIPS_LIST_REDUCER_NAME,
} from './consts'
import { CameraShape } from 'app/types/camera'
import { clipsFiltersInitState } from './slice'
import { RootState } from 'app/store'
import { CameraFilterOption } from './components/ClipsFilters/CameraFilter'

export const camerasListSelectors = makeFetchSelectors(
  CAMERA_RECORDINGS_REDUCER_NAME,
  CAMERAS_LIST_REDUCER_NAME
)

export const cameraObjectByIdSelector = createSelector(
  (state: RootState) =>
    state[CAMERA_RECORDINGS_REDUCER_NAME][CAMERAS_LIST_REDUCER_NAME].data,
  (cameras: null | any[]) => (cameraId: string) =>
    Array.isArray(cameras)
      ? cameras.find((camera: any) => camera.id === cameraId)
      : null
)

export const clipsListSelectors = makeFetchSelectors(
  CAMERA_RECORDINGS_REDUCER_NAME,
  CLIPS_LIST_REDUCER_NAME
)

export const clipsListSelector = createDeepEqualSelector(
  [clipsListSelectors.data],
  (data) => data?.clips
)

export const nextClipsSelector = createDeepEqualSelector(
  [clipsListSelectors.data],
  (data) => data?.next
)

export const clipsToRenderSelector = (state: RootState) =>
  state[CAMERA_RECORDINGS_REDUCER_NAME]?.clipsToRenderList

export const cameraFilterOptionsSelector = createDeepEqualSelector(
  [camerasListSelectors.data, camerasListSelectors.isFinished],
  (cameraList, isFinished) =>
    isFinished && Array.isArray(cameraList)
      ? cameraList.map(
          (camera: CameraShape): CameraFilterOption => ({
            value: camera.id,
            label: camera.name,
          })
        )
      : []
)

export const getDefaultCameraSelector = createSelector(
  cameraFilterOptionsSelector,
  (cameraFilterOptions) => cameraFilterOptions?.[0]
)

export const cameraClipsListSelectors = makeFetchSelectors(
  CAMERA_RECORDINGS_REDUCER_NAME,
  CAMERA_CLIPS_LIST_REDUCER_NAME
)

export const cameraClipSelectors = makeFetchSelectors(
  CAMERA_RECORDINGS_REDUCER_NAME,
  CAMERA_CLIP_REDUCER_NAME
)

export const selectedCameraSelector = createSelector(
  path([CAMERA_RECORDINGS_REDUCER_NAME, CLIPS_FILTERS_REDUCER_NAME]),
  (filters: any) =>
    _get(filters, 'selectedCamera', clipsFiltersInitState.selectedCamera)
)

export const cameraOldestFirstSelector = createSelector(
  path([CAMERA_RECORDINGS_REDUCER_NAME, CLIPS_FILTERS_REDUCER_NAME]),
  (filters: any) =>
    _get(filters, 'oldestFirst', clipsFiltersInitState.oldestFirst)
)

export const cameraStartDateSelector = createSelector(
  path([CAMERA_RECORDINGS_REDUCER_NAME, CLIPS_FILTERS_REDUCER_NAME]),
  (filters: any) => _get(filters, 'startDate', clipsFiltersInitState.startDate)
)

export const cameraEndDateSelector = createSelector(
  path([CAMERA_RECORDINGS_REDUCER_NAME, CLIPS_FILTERS_REDUCER_NAME]),
  (filters: any) => _get(filters, 'endDate', clipsFiltersInitState.endDate)
)

export const selectedCameraEventSelector = createSelector(
  path([CAMERA_RECORDINGS_REDUCER_NAME, CLIPS_FILTERS_REDUCER_NAME]),
  (filters: any) => _get(filters, 'eventId', clipsFiltersInitState.eventId)
)
