import styled from 'styled-components'

import theme from 'theme_'
import { CommonButton } from 'components/Button'

const { spacing, devices } = theme

export const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${spacing.md};
`

export const UsersHeader = styled.header`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  @media ${devices.tablet} {
    align-items: flex-start;
  }
`

export const UsersContentContainer = styled.div`
  position: relative;
  z-index: 1;
  min-height: 150px;
`

export const InviteUserButton = styled(CommonButton)`
  margin: ${spacing.s} 0;

  @media ${devices.tablet} {
    margin: 0;
  }
`
