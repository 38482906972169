import { FC } from 'react'
import { Formik, Form, Field, FieldProps } from 'formik'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import Modal from 'components/Modal'
import { H2, RegularModerate } from 'components/Typography'
import { EmailField } from 'components/Fields'
import { RevocationHours } from 'components/Fields/AccessRevocationField'
import { UserRole } from 'features/auth/consts'
import { CommonButton } from 'components/Button'
import { commonMessages } from 'app/i18n/messages'
import { inviteUserThunk } from 'features/users/slice'
import { inviteUserSelectors } from 'features/users/selectors'
import { LoaderOverlay } from 'components/Loader'
import { INVITE_USER_ERROR } from 'features/users/api'

import Roles from './Roles'
import messages from './messages'
import { InnerContainer, EmailContainer, Message, Footer } from './styles'

const messagePerUserRole = {
  [UserRole.ADMIN]: messages.messageForAdmin,
  [UserRole.HOUSEHOLD]: messages.messageForHousehold,
  [UserRole.GUEST]: messages.messageForGuest,
  [UserRole.CUSTOM]: messages.messageForGuest,
}

interface InviteUserProps {
  onSuccess: () => void
  onClose: () => void
  homeId: string
}

const INVITE_BY_ROLE = {
  [UserRole.ADMIN]: {
    firstName: messages.userFirstNameMock,
    lastName: messages.adminLastNameMock,
  },
  [UserRole.HOUSEHOLD]: {
    firstName: messages.userFirstNameMock,
    lastName: messages.householdLastNameMock,
  },
  [UserRole.GUEST]: {
    firstName: messages.userFirstNameMock,
    lastName: messages.guestLastNameMock,
  },
  [UserRole.CUSTOM]: {
    firstName: messages.userFirstNameMock,
    lastName: messages.customLastNameMock,
  },
}

const InviteUser: FC<InviteUserProps> = ({ homeId, onClose, onSuccess }) => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const isLoading = useSelector(inviteUserSelectors.isFetching)

  return (
    <>
      {isLoading && <LoaderOverlay isFullScreen />}

      <Modal isOpen onRequestClose={onClose}>
        <Formik
          initialValues={{
            email: '',
            role: UserRole.ADMIN,
            revocationTime: RevocationHours.Never,
          }}
          onSubmit={async ({ email, role, revocationTime }, formik) => {
            const inviteConfig = INVITE_BY_ROLE[role]
            const revocationParams =
              revocationTime === RevocationHours.Never
                ? {}
                : {
                    revocationTime: moment()
                      .add(revocationTime, 'hours')
                      .valueOf(),
                  }

            try {
              await dispatch(
                inviteUserThunk(homeId, {
                  email,
                  role,
                  firstName: formatMessage(inviteConfig.firstName),
                  lastName: formatMessage(inviteConfig.lastName),
                  message: formatMessage(messages.inviteMessage),
                  ...revocationParams,
                })
              )
              onSuccess()
              onClose()
            } catch (err: any) {
              if (err?.name === INVITE_USER_ERROR.HOME_009) {
                formik.setFieldError(
                  'email',
                  formatMessage(messages.error_HOME_009)
                )
              } else {
                formik.setFieldError(
                  'email',
                  formatMessage(commonMessages.genericError)
                )
              }
            }
          }}
        >
          {(formikProps) => (
            <Form>
              <InnerContainer>
                <H2>
                  <FormattedMessage {...messages.title} />
                </H2>

                <EmailContainer>
                  <EmailField
                    autoFocus
                    name="email"
                    invalid={formikProps.errors.email}
                  />
                </EmailContainer>

                <Field name="role">
                  {({ field }: FieldProps) => (
                    <Roles
                      value={field.value}
                      onChange={(value) => {
                        formikProps.setFieldValue(field.name, value)
                      }}
                    />
                  )}
                </Field>

                <Message>
                  <RegularModerate>
                    <FormattedMessage
                      {...messagePerUserRole[formikProps.values.role]}
                    />
                  </RegularModerate>
                </Message>
                {/*
                <AccessRevocationContainer>
                  <AccessRevocationField name="revocationTime" />
                </AccessRevocationContainer> */}

                <Footer>
                  <CommonButton
                    variant="goldenOutlined"
                    onClick={onClose}
                    disabled={formikProps.isSubmitting}
                  >
                    <FormattedMessage {...commonMessages.cancel} />
                  </CommonButton>
                  <CommonButton
                    variant="goldenFilled"
                    type="submit"
                    disabled={formikProps.isSubmitting}
                  >
                    <FormattedMessage {...messages.ctaBtn} />
                  </CommonButton>
                </Footer>
              </InnerContainer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  )
}

export default InviteUser
