import styled from 'styled-components'
import theme from 'theme_'

import { BluredBlock } from 'components/Layout'

export const ConnectedAppsContainer = styled.ul`
  margin-top: ${theme.spacing.s};
  padding: 0;
  list-style: none;
  display: grid;
  row-gap: ${theme.spacing.s};
  column-gap: ${theme.spacing.s};
  grid-template-columns: 1fr;

  @media ${theme.devices.tablet} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${theme.devices.desktop} {
    margin-top: ${theme.spacing.md};
  }

  @media screen and (min-width: 1300px) {
    row-gap: ${theme.spacing.base};
    column-gap: ${theme.spacing.base};
    grid-template-columns: 1fr 1fr;
  }
`

export const ConnectedAppsInfoContainer = styled(BluredBlock)`
  height: auto;
  width: 100%;
  margin-top: ${theme.spacing.md};
  padding: ${theme.spacing.md};
  text-align: center;
`
