import React from 'react'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'

import LoginForm from '../components/LoginForm/LoginForm'
import { loginMessages } from './messages'
import { loginThunk, updateAuthStatus, userActions } from './slice'
import { StyledDescriptionText, StyledHeaderContainer } from './styles'
import { StyledMainHeading } from '../components/MainHeading/MainHeading'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { storeAuthorizedUser } from 'app/services/LocalStorage'
import { ILoginFormValues } from './types'
import routes from 'app/routes'
import LoginCommonLayout from '../components/LoginCommonLayout'
import { failedLoginAttemptsSelector } from './selectors'
import { gaLoginSuccess } from 'features/analytics/utils'

const LoginPage: React.FC = () => {
  const { formatMessage } = useIntl()
  const dispatch = useAppDispatch()
  const history = useHistory()

  const failedLoginAttempts = useAppSelector(failedLoginAttemptsSelector)

  const handleSubmit = (values: ILoginFormValues) =>
    dispatch(
      loginThunk(values, {
        onSuccess: async (payload) => {
          if (
            payload?.twoFactor?.length &&
            payload?.twoFactor[0]?.enabled === 'Enabled'
          ) {
            history.push(routes.auth.twoFa, { savCreds: values })
          } else {
            storeAuthorizedUser(payload)
            gaLoginSuccess(failedLoginAttempts)
            dispatch(userActions.resetLoginAttempts())
            await dispatch(updateAuthStatus())
            history.push('/')
          }
        },
        onError: () => {},
      })
    )

  return (
    <>
      <LoginCommonLayout>
        <StyledHeaderContainer>
          <StyledMainHeading>
            {formatMessage(loginMessages.loginHeading)}
          </StyledMainHeading>
        </StyledHeaderContainer>

        <StyledDescriptionText>
          {formatMessage(loginMessages.manageSavantHomes)}
        </StyledDescriptionText>
        <LoginForm onSubmit={handleSubmit} />
      </LoginCommonLayout>
    </>
  )
}

export default LoginPage
