import styled from 'styled-components'
import theme from 'theme_'

export const BillingTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 14px;
  flex-shrink: 0;
  flex-grow: 1;
  border-collapse: collapse;
  border-radius: ${theme.spacing.xs};
  .infinite-scroll-component {
    overflow: hidden !important;
    border-radius: ${theme.spacing.xs};
  }
`
export const BillingTableTh = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 2;
  width: 33.33%;
  color: white;
  text-align: center;
  vertical-align: bottom;
  background-color: #272523;
  color: ${theme.colors.beachGrey};
  padding: ${theme.spacing.s} ${theme.spacing.s} ${theme.spacing.md};

  @media ${theme.devices.desktop} {
    padding: ${theme.spacing.xs} ${theme.spacing.tiny};
  }
`

export const BillingHeaderTableContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 1 0px;
  background-color: ${theme.overlay.slightLight};
  color: ${theme.colors.beachGrey};
  cursor: pointer;
  user-select: none;
  border-bottom: 1px solid rgba(161, 149, 132, 0.2);
  @media screen and (max-width: 721px) {
    display: none;
  }
`
export const BillingTr = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 1 0px;
  background-color: ${theme.overlay.slightLight};
  color: ${theme.colors.beachGrey};
  user-select: none;
  border-bottom: 1px solid rgba(161, 149, 132, 0.2);

  div:nth-child(1) {
    order: 1;
    align-items: flex-start;
  }
  div:nth-child(2) {
    order: 2;
  }
  div:nth-child(3) {
    order: 3;
  }
  div:nth-child(4) {
    order: 4;
  }
  div:nth-child(5) {
    order: 5;
  }
  div:nth-child(6) {
    order: 6;
  }

  > div:last-child {
    padding-right: ${theme.spacing.sm};
  }
  > div:first-child {
    text-align: left;
    padding-left: ${theme.spacing.sm};
  }
  @media screen and (max-width: 721px) {
    flex-wrap: wrap;
    > div:nth-child(1) {
      order: 1;
      align-items: flex-start;
      height: 25px;
      padding-top: 10px;
    }
    > div:nth-child(2) {
      order: 4;
      align-items: flex-end;
      padding-right: 20px;
    }
    > div:nth-child(3) {
      order: 5;
      align-items: flex-start;
      padding-bottom: 20px;
    }
    > div:nth-child(4) {
      order: 3;
      align-items: flex-start;
    }
    > div:nth-child(5) {
      order: 2;
      align-items: flex-end;
      padding-right: 20px;
      height: 25px;
      padding-top: 10px;
    }
    > div:nth-child(6) {
      order: 6;
      align-items: flex-end;
      padding-right: 20px;
      padding-bottom: 20px;
    }
  }
`

export const BillingTd = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  height: 56px;
  width: 33.33%;
  text-align: left;
  padding-left: ${theme.spacing.sm};
  @media screen and (max-width: 721px) {
    width: 50%;
  }
  background-color: ${(p) =>
    p.isactive ? theme.colors.nonTransparentHighlight : 'transparent'};
  color: ${(p) =>
    p.isactive ? theme.colors.accentGold : theme.colors.dolphinGrey};
`

export const NextContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: ${theme.spacing.md};
  padding: ${theme.spacing.sm};
  border-radius: ${theme.spacing.xs};
  background-color: ${theme.overlay.cardColor2};
  backdrop-filter: ${theme.overlay.SBlur};
`

export const NoPaymentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: ${theme.spacing.md};
  padding: 150px;
  border-radius: ${theme.spacing.xs};
  background-color: ${theme.overlay.cardColor2};
  backdrop-filter: ${theme.overlay.SBlur};
`
export const BillingContainer = styled.div`
  margin-top: ${theme.spacing.md};
  border-radius: ${theme.spacing.xs};
  background-color: ${theme.overlay.cardColor2};
  backdrop-filter: ${theme.overlay.SBlur};
`

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const GoldLabel = styled.label`
  color: ${theme.colors.dimmedGold};
`
export const NextPaymentText = styled.p`
  font-size: 16px;
  padding-right: 10px;
  line-height: ${theme.spacing.sm};
  color: ${theme.colors.dimmedGold};
`
