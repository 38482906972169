import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { CommonButton } from 'components/Button'
import { commonMessages } from 'app/i18n/messages'
import { LoaderOverlay } from 'components/Loader'

import {
  acceptDealerRequestThunk,
  declineDealerRequestThunk,
} from 'features/homes/homesSlice'
import {
  dealerRequestAcceptSelectors,
  dealerRequestDeclineSelectors,
} from 'features/homes/selectors'

import { DealerRequest as DealerRequestShape } from '../types'
import { dealerMessages } from '../messages'
import DealerBlock from '../DealerBlock'
import DealerCard from '../DealerCard'

import { Footer } from './styles'
import { useHistory } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import routes from 'app/routes'

const DealerRequest: FC<{
  dealerRequest: DealerRequestShape
  fullWidth?: boolean
}> = ({ dealerRequest, fullWidth }) => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const isAcceptLoading = useAppSelector(
    dealerRequestAcceptSelectors.isFetching
  )
  const isDeclineLoading = useAppSelector(
    dealerRequestDeclineSelectors.isFetching
  )
  const isLoading = isAcceptLoading || isDeclineLoading

  return (
    <DealerBlock
      bordered
      fullWidth={fullWidth}
      title={<FormattedMessage {...dealerMessages.dealerRequestTitle} />}
    >
      {isLoading && <LoaderOverlay isFullScreen />}
      <DealerCard dealerInfo={dealerRequest.dealerInfo} />

      <Footer>
        <CommonButton
          variant="goldenOutlined"
          onClick={() => {
            dispatch(
              declineDealerRequestThunk({
                homeId: dealerRequest.homeId,
                domainId: dealerRequest.requestedDomainId,
                requestId: dealerRequest.id,
              })
            )
          }}
        >
          <FormattedMessage {...commonMessages.cancel} />
        </CommonButton>
        <CommonButton
          variant="goldenFilled"
          onClick={() => {
            dispatch(
              acceptDealerRequestThunk(
                {
                  homeId: dealerRequest.homeId,
                  domainId: dealerRequest.requestedDomainId,
                  requestId: dealerRequest.id,
                },
                () => history.push(routes.myDealer)
              )
            )
          }}
        >
          <FormattedMessage {...dealerMessages.dealerRequestAcceptBtn} />
        </CommonButton>
      </Footer>
    </DealerBlock>
  )
}

export default DealerRequest
