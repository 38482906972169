import styled from 'styled-components'
import theme from 'theme_'

export const UserContentContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  margin: ${theme.spacing.md} auto 0;
`
