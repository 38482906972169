import React from 'react'
import { Styles } from 'react-modal'
import theme from 'theme_'
import { StyledModalContent, StyledModalInner } from './styles'
import { IModalProps } from './types'

// Content styling in styles.ts; Overlay styling here
export const customModalStyles: Styles = {
  content: {},
  overlay: {
    zIndex: theme.zIndex.modal,
    backgroundColor: 'rgba(0,0,0,0.5)',
    backdropFilter: theme.overlay.SBlur,
  },
}

const Modal: React.FC<IModalProps> = (props) => {
  return (
    <StyledModalContent
      ariaHideApp={false}
      style={customModalStyles}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      {...props}
    >
      <StyledModalInner>{props.children}</StyledModalInner>
    </StyledModalContent>
  )
}

export default Modal
