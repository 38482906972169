import styled from 'styled-components'
import Icon from '@mdi/react'
import { NavLink } from 'react-router-dom'
import theme from 'theme_'

export const StyledAsideMenuLink = styled(NavLink)`
  display: flex;
  align-items: center;
  height: 64px;
  padding: 0 ${theme.spacing.md};
  color: ${theme.colors.dolphinGrey};
  text-decoration: none;
  transition: all 0.2s ease;

  :hover {
    background-color: ${theme.overlay.newMenuHover};
  }

  &.active {
    color: ${theme.colors.white};
    background-color: ${theme.overlay.newMenuHover};
  }
`
export const StyledAsideMenuIcon = styled(Icon)`
  flex-shrink: 0;
  margin-right: ${theme.spacing.s};
  color: ${theme.colors.beachGrey};
`

export const StyledAsideMenuImg = styled.div`
  margin-right: 12px;
`
