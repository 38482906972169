import styled from 'styled-components'

import { Regular } from 'components/Typography'
import theme from 'theme_'

export const UserInfoHeader = styled.header`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`

export const UserName = styled.div`
  margin-top: ${theme.spacing.md};
`

export const UserRoleLabel = styled(Regular)`
  line-height: 22px;
  margin-top: ${theme.spacing.s};
`

export const UserEmail = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-top: ${theme.spacing.s};

  > svg {
    margin-right: ${theme.spacing.xs};
  }
`

export const UserInfoDates = styled.div`
  width: 100%;
  margin-top: ${theme.spacing.s};
  border-top: 1px solid ${theme.colors.separatorColor};

  > div {
    margin-top: ${theme.spacing.s};
  }
`
