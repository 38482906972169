import styled from 'styled-components'
import theme from 'theme_'
import Icon from '@mdi/react'

export const InnerContainer = styled.div`
  padding: 64px 0;
  text-align: center;
  display: flex;
  flex-flow: column;
  align-items: center;
`

export const StyledFailIcon = styled(Icon)`
  color: ${theme.colors.error};
`
