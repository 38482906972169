import { defineMessages } from 'react-intl'

export const twoFactorAuthErrorMessages = defineMessages({
  errorTitle: {
    id: 'twoFactorError.errorTitle',
    description: 'Title text of 2fa error page',
    defaultMessage: 'Resending Verification Failed',
  },
  errorDescription: {
    id: 'twoFactorError.errorDescription',
    description: 'Text of error description for error page of 2fa',
    defaultMessage:
      'We encountered and error processing your verification code resend request.',
  },
  goToLogin: {
    id: 'twoFactorError.goToLogin',
    description: 'Go to log In page button',
    defaultMessage: 'Go to Sign In',
  },
  tryAgain: {
    id: 'twoFactorError.tryAgain',
    description: 'Text of try again button',
    defaultMessage: 'Try Again',
  },
})
