import React, { useState } from 'react'
import { Formik, FormikHelpers, Form } from 'formik'
import * as Yup from 'yup'
import { SectionTitle } from 'components/Typography'
import TextField from 'components/Fields/TextField/TextField'
import CommonButton from 'components/Button/CommonButton'

import { EnrollContainer, EnrollText } from './styles'
import { enrollPartnerThunk } from '../../slice'
import { currentHomeIdSelector } from '../../../homes/selectors'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { IPinValues } from './types'
import { fieldsMessages } from 'components/Fields/messages'
import { useIntl } from 'react-intl'

const VpEnrollPage: React.FC = () => {
  const currentHomeId = useAppSelector(currentHomeIdSelector)
  const dispatch = useAppDispatch()
  const { formatMessage } = useIntl()
  const [loading, setIsLoading] = useState(false)
  const [enrollMsg, setEnrollMsg] = useState('Enroll')

  const initialPinValues: IPinValues = {
    code: '',
  }

  const handleSubmit = async (
    values: IPinValues,
    formikHelpers: FormikHelpers<IPinValues>
  ) => {
    setIsLoading(true)
    dispatch(
      enrollPartnerThunk({
        homeId: currentHomeId,
        partner: 'FfMVupe4rN5w7fA4wqLeHdLeacDL2iOf',
        pairingCode: values.code,
      })
    )
    setEnrollMsg('Enrollment Request Sent')
  }

  return (
    <Formik
      initialValues={initialPinValues}
      onSubmit={handleSubmit}
      validationSchema={Yup.object().shape({
        code: Yup.string().required(
          formatMessage(fieldsMessages.requiredField)
        ),
      })}
    >
      {(formikProps) => (
        <Form>
          <EnrollContainer>
            <SectionTitle>Enter your Virtual Peaker Pin</SectionTitle>
            <EnrollText>
              <TextField
                name="code"
                placeholder="PIN Code"
                autoComplete="off"
              />
            </EnrollText>

            <CommonButton
              type="submit"
              variant="goldenFilled"
              disabled={loading}
            >
              {enrollMsg}
            </CommonButton>
          </EnrollContainer>
        </Form>
      )}
    </Formik>
  )
}

export default VpEnrollPage
