import styled from 'styled-components'
import theme from 'theme_'

export const StyledInstructionsBlock = styled.div`
  max-width: 600px;
  margin: ${theme.spacing.md} auto;
  text-align: center;
`

export const StyledChangePassBtnWrap = styled.div`
  display: flex;
  justify-content: center;
`

export const ChangePassModalContainer = styled.div`
  padding: 64px 0;
  text-align: center;
  display: flex;
  flex-flow: column;
  align-items: center;
`
