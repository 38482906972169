import styled from 'styled-components'

export const IconButton = styled.button`
  outline: 0;
  border: 0;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  height: 24px;
  width: 24px;
`
