import { defineMessages } from 'react-intl'

export const paymentMethodsMessages = defineMessages({
  formTitle: {
    id: 'paymentMethods.formTitle',
    description: 'Heading of the form to add payment method',
    defaultMessage: 'Add Payment Method',
  },
  cardNamePlaceholder: {
    id: 'paymentMethods.cardNamePlaceholder',
    description: '"Name On Card" input text',
    defaultMessage: 'Name On Card',
  },
  countryPlaceholder: {
    id: 'paymentMethods.countryPlaceholder',
    description: '"Select Country" input text',
    defaultMessage: 'Country',
  },
  postalPlaceholder: {
    id: 'paymentMethods.postalPlaceholder',
    description: '"Zip Code" input text',
    defaultMessage: 'Zip Code',
  },
  chargesInfo: {
    id: 'paymentMethods.chargesInfo',
    description: 'Message in payment form about taxes and non-refunds',
    defaultMessage:
      'Any applicable taxes will be calculated after checkout. Subscriptions are non-refundable.',
  },
  modalClose: {
    id: 'paymentMethods.modalClose',
    description: 'Button text to close modal with form',
    defaultMessage: 'Close',
  },
  saveMethod: {
    id: 'paymentMethods.saveMethod',
    description: 'Button text to submit and save payment method',
    defaultMessage: 'Save',
  },
  backToForm: {
    id: 'paymentMethods.backToForm',
    description:
      'Button text on failed adding payment method screen to go back',
    defaultMessage: 'Back',
  },
  notValidUSACode: {
    id: 'paymentMethods.notValidUSACode',
    description: 'Not valid USA zip code',
    defaultMessage: 'Not valid USA zip code',
  },
  notValidCanadaCode: {
    id: 'paymentMethods.notValidCanadaCode',
    description: 'Not valid Canada zip code',
    defaultMessage: 'Not valid Canada zip code',
  },
  usaLabel: {
    id: 'paymentMethods.usaLabel',
    description: 'Dropdown label for USA',
    defaultMessage: 'USA',
  },
  canadaLabel: {
    id: 'paymentMethods.canadaLabel',
    description: 'Dropdown label for Canada',
    defaultMessage: 'Canada',
  },
  makeDefault: {
    id: 'paymentMethods.makeDefault',
    description: 'Text to make payment method default',
    defaultMessage: 'Make Default',
  },
})
