import styled from 'styled-components'

import theme from 'theme_'
import CommonLink from 'components/Button/CommonLink'
import { BluredBlock } from 'components/Layout'
import { Secondary } from 'components/Typography'

export const StyledLoginContainer = styled(BluredBlock)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 120px 0 0;
  border-radius: 0;
  background-color: ${theme.overlay.darkImage};
  backdrop-filter: blur(10px);

  @media ${theme.devices.tablet} {
    margin: 0 auto 110px;
    padding-top: 0;
    border-radius: ${theme.spacing.xs};

    input {
      margin-bottom: 20px;
    }
  }
`

export const StyledLoginInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing.xxxl} ${theme.spacing.md};
  border-radius: 0 0 ${theme.spacing.xs} ${theme.spacing.xs};
  background-color: rgba(0, 0, 0, 0.3);

  @media ${theme.devices.tablet} {
    padding: ${theme.spacing.lg};
  }
`

export const StyledNeedAccount = styled(Secondary)`
  text-align: center;
  color: ${theme.colors.mutedGray};

  @media ${theme.devices.tablet} {
    font-size: 16px;
    line-height: 22px;
    padding: 0 52px;
  }
`
export const StyledStoreIconsWrapper = styled.div`
  margin: ${theme.spacing.sm} 0;
  > a:first-child {
    margin-right: ${theme.spacing.base};
  }

  @media ${theme.devices.desktop} {
    margin: ${theme.spacing.xl} 0;
  }
`
export const StyledStoreIcon = styled.a`
  :hover {
    opacity: 0.8;
  }
`
export const StyledStoreIconImg = styled.img`
  height: 40px;

  @media ${theme.devices.tablet} {
    height: auto;
  }
`
export const StyledSystemInstalled = styled.div`
  margin-bottom: ${theme.spacing.xs};
  text-align: center;
`

export const StyledFindDealer = styled(CommonLink)`
  display: block;
  font-weight: 700;
  margin-left: ${theme.spacing.xxs};

  @media ${theme.devices.tablet} {
    display: inline;
  }
`
