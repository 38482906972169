import { FC, memo } from 'react'

import { IFieldProps } from '../types'
import { StyledTextArea } from './styles'
import { StyledInputError } from 'components/Input/styles'
import { ErrorMessage, Field, FieldProps } from 'formik'

interface TextAreaProps extends FieldProps {
  placeholder?: string
}

const TextArea: FC<TextAreaProps> = ({ field, placeholder = '' }) => {
  return <StyledTextArea {...field} placeholder={placeholder} />
}

const TextAreaField: FC<IFieldProps> = (props) => (
  <>
    <Field
      component={TextArea}
      id={props.name}
      placeholder={props.placeholder}
      {...props}
    />
    <StyledInputError>
      <ErrorMessage name={props.name} />
    </StyledInputError>
  </>
)

export default memo(TextAreaField)
