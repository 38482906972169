import { fetchEdgeDataThunk, makeFetchSlice } from 'app/redux-fetch'
import { AppDispatch } from 'app/store'
import { UserShape, UserPermissions } from 'app/types/user'
import { UserRole } from 'features/auth/consts'
import { getDefaultPermissionsByRole } from 'features/auth/utils'

import {
  getUsers,
  UserDetailsParams,
  getUserDetailsByHome,
  UpdatePermissionsParams,
  updateUserPermissions,
  DeleteUserParams,
  deleteUser,
  inviteUser,
} from './api'

const userListSlice = makeFetchSlice('users')
export const userListReducer = userListSlice.reducer
export const userListActions = userListSlice.actions

export const getUsersThunk = (homeId: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      await fetchEdgeDataThunk({
        dispatch,
        actions: userListActions,
        asyncFunc: () => getUsers(homeId),
      })
    } catch (error) {
      console.log('Error fetching users for home', homeId, error)
    }
  }
}

const userDetailsSlice = makeFetchSlice('userDetails')
export const userDetailsReducer = userDetailsSlice.reducer
export const userDetailsActions = userDetailsSlice.actions

export const getUserDetailsThunk = ({ homeId, userId }: UserDetailsParams) => {
  return async (dispatch: AppDispatch): Promise<UserShape | null> => {
    try {
      const userDetails = await fetchEdgeDataThunk({
        dispatch,
        actions: userDetailsActions,
        asyncFunc: () => getUserDetailsByHome({ homeId, userId }),
      })
      return userDetails
    } catch (error) {
      console.log('Error fetching user details', userId, error)
      return null
    }
  }
}

const updateUserPermissionsSlice = makeFetchSlice('updateUserPermissions')
export const updateUserPermissionsReducer = updateUserPermissionsSlice.reducer
export const updateUserPermissionsActions = updateUserPermissionsSlice.actions

export const updateUserPermissionsThunk = (params: UpdatePermissionsParams) => {
  return async (dispatch: AppDispatch): Promise<UserPermissions | null> => {
    try {
      await fetchEdgeDataThunk({
        dispatch,
        actions: updateUserPermissionsActions,
        asyncFunc: () => updateUserPermissions(params),
      })

      const userDetails = await dispatch(
        getUserDetailsThunk({ homeId: params.homeId, userId: params.userId })
      )
      return userDetails?.permissions || null
    } catch (error) {
      console.log('Error updating user permissions', params.userId, error)
      return null
    }
  }
}

const deleteUserSlice = makeFetchSlice('deleteUser')
export const deleteUserReducer = deleteUserSlice.reducer
export const deleteUserActions = deleteUserSlice.actions

export const deleteUserThunk = (params: DeleteUserParams) => {
  return async (dispatch: AppDispatch): Promise<void> => {
    try {
      await fetchEdgeDataThunk({
        dispatch,
        actions: deleteUserActions,
        asyncFunc: () => deleteUser(params),
      })

      dispatch(getUsersThunk(params.homeId))
    } catch (error) {
      console.log('Error deleting user', params.userId, error)
    }
  }
}

const inviteUserSlice = makeFetchSlice('inviteUser')
export const inviteUserReducer = inviteUserSlice.reducer
export const inviteUserActions = inviteUserSlice.actions

export interface InviteUserParams {
  email: string
  role: UserRole
  firstName: string
  lastName: string
  message: string
  revocationTime?: number
}

export const inviteUserThunk = (
  homeId: string,
  {
    email,
    role,
    firstName,
    lastName,
    message,
    revocationTime,
  }: InviteUserParams
) => {
  return async (dispatch: AppDispatch): Promise<void> => {
    try {
      await fetchEdgeDataThunk({
        dispatch,
        actions: inviteUserActions,
        asyncFunc: () =>
          inviteUser({
            homeId,
            email,
            permissions: getDefaultPermissionsByRole(role),
            firstName,
            lastName,
            message,
            revocationTime,
          }),
      })

      await dispatch(getUsersThunk(homeId))
    } catch (error) {
      console.log('Error inviting user', email, role, error)
      throw error
    }
  }
}
