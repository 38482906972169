import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import Icon from '@mdi/react'
import theme from 'theme_'

export const StyledAccountMenu = styled.nav<{ $isFirefox?: boolean }>`
  display: flex;
  flex-direction: column;
  color: ${theme.colors.dolphinGrey};
  animation: ${theme.animations.fadeIn} 0.2s ease;

  @media ${theme.devices.desktop} {
    position: absolute;
    z-index: 2;
    top: calc(100% + ${theme.spacing.s});
    right: 0;
    width: 350px;
    border-radius: ${theme.spacing.xs};
    background: ${(p) =>
      p.$isFirefox
        ? theme.overlay.tinyAlphaBlack
        : theme.overlay.newDropdownBlack};
    backdrop-filter: ${theme.overlay.SBlur};
  }
`
export const StyledAccountMenuLink = styled(NavLink)`
  display: flex;
  align-items: center;
  height: 64px;
  width: 100%;
  padding: 0 ${theme.spacing.sm};
  color: ${theme.colors.beachGrey};
  text-decoration: none;
  transition: all 0.2s ease;

  :hover {
    background-color: ${theme.overlay.newMenuHover};
  }

  &.active {
    color: ${theme.colors.white};
  }
`

export const StyledAccountMenuLogout = styled.button`
  display: flex;
  align-items: center;
  text-align: left;
  height: 64px;
  width: 100%;
  padding: 0 ${theme.spacing.sm};
  color: ${theme.colors.beachGrey};
  border: none;
  border-top: 1px solid ${theme.colors.separatorColor};
  border-radius: 0 0 ${theme.spacing.xs} ${theme.spacing.xs};
  background: transparent;
  cursor: pointer;
  transition: background-color 0.2s ease;

  :hover {
    background-color: ${theme.overlay.newMenuHover};
  }
`

export const StyledAccountMenuIcon = styled(Icon)`
  margin-right: ${theme.spacing.s};
`
