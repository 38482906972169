import styled from 'styled-components'
import theme from '../../theme_'

import { BluredBlock } from 'components/Layout'
import { COMMON_BUTTON_HEIGHT } from 'components/Button/styles'

export const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${theme.spacing.md};
`

export const CameraRecordingsHeader = styled.header`
  display: flex;
  justify-content: space-between;
  H1 {
    line-height: 18px;
    font-size: 18px;
    @media ${theme.devices.tablet} {
      line-height: 40px;
      font-size: 32px;
    }
  }
`

export const CameraRecordingsContentContainer = styled.div`
  position: relative;
  z-index: 1;
`

export const NoCamerasContainer = styled(BluredBlock)`
  height: auto;
  width: 100%;
  margin-top: ${theme.spacing.md};
  padding: ${theme.spacing.md};
  text-align: center;
`

export const LoadMoreClipsBtnWrap = styled.div`
  display: flex;
  justify-content: center;
  min-height: ${COMMON_BUTTON_HEIGHT.default};

  @media ${theme.devices.tablet} {
    margin-top: ${theme.spacing.lg};
  }
`
