import styled from 'styled-components'
import Icon from '@mdi/react'

import theme from 'theme_'

export const RoomsHeader = styled.header`
  border-bottom: 1px solid ${theme.colors.anotherDarkGrey};
  padding-bottom: ${theme.spacing.md};
  margin-top: ${theme.spacing.md};
  margin-bottom: ${theme.spacing.md};
`

export const RoomsHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const StyledCheckPermissionIcon = styled(Icon)`
  color: ${theme.colors.accentGold};
  cursor: pointer;
`
