import React, { useCallback, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { mdiChevronDown, mdiChevronUp } from '@mdi/js'

import IntlFormattedDate from 'components/i18n/IntlFormattedDate'
import {
  HomeInfoStatusWrapper,
  HomeInfoStatusCircle,
  HomeInfoContainer,
  HomeInfoTitle,
  HomeInfoName,
  HomeInfoHeader,
  HomeInfoToggler,
  HomeInfoTogglerIcon,
  HomeInfoDetails,
  HomeInfoDetail,
  HomeInfoDetailTitle,
  HomeInfoDetailContent,
  HomeInfoTitleIcon,
} from './styles'
import { homeInfoMessages } from './messages'
import {
  currentHomeInfoSelectors,
  currentHomeSelector,
} from 'features/homes/selectors'
import HomeUID from '../HomeUID'
import { hostTypes } from 'app/consts'
import { useAppSelector } from 'app/hooks'
import { activeSubscriptionSelector } from 'features/homes/Subscriptions/selectors'
import { commonMessages } from 'app/i18n'
import { WarningRounded } from 'components/Icons'

const HomeInfo: React.FC = () => {
  const { formatMessage } = useIntl()

  const [isOpen, setIsOpen] = useState(false)
  const toggleOpen = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen])

  const currentHome = useAppSelector(currentHomeSelector)
  const currentHomeInfo = useAppSelector(currentHomeInfoSelectors.data)
  const activeSubscription = useAppSelector(activeSubscriptionSelector)

  const hasMinimumSoftwareVersionForBackup = useMemo((): boolean => {
    const major = currentHomeInfo?.softwareInfo?.version.major
    const minor = currentHomeInfo?.softwareInfo?.version.minor

    if (major && (minor || minor === 0)) {
      return major > 10 || (major === 10 && minor >= 1)
    }

    return false
  }, [currentHomeInfo?.softwareInfo])

  const getHomeStatusMessage = useCallback(
    (homeStatus: boolean) => {
      return homeStatus
        ? formatMessage(homeInfoMessages.online)
        : formatMessage(homeInfoMessages.offline)
    },
    [formatMessage]
  )

  return currentHome ? (
    <HomeInfoContainer>
      <HomeInfoTitle>{formatMessage(homeInfoMessages.title)}</HomeInfoTitle>

      <HomeInfoHeader>
        <HomeInfoName>{currentHome.name}</HomeInfoName>
        <HomeInfoStatusWrapper status={currentHome.online}>
          <HomeInfoStatusCircle status={currentHome.online} />
          <span>{getHomeStatusMessage(currentHome.online)}</span>
        </HomeInfoStatusWrapper>
      </HomeInfoHeader>

      {isOpen && (
        <HomeInfoDetails>
          {currentHome?.uid && (
            <HomeInfoDetail>
              <HomeInfoDetailTitle>
                {formatMessage(homeInfoMessages.hostUid)}
              </HomeInfoDetailTitle>

              <HomeInfoDetailContent>
                <HomeUID uid={currentHome.uid} />
              </HomeInfoDetailContent>
            </HomeInfoDetail>
          )}

          {currentHome?.softwareInfo?.version?.name && (
            <HomeInfoDetail>
              <HomeInfoDetailTitle>
                {formatMessage(homeInfoMessages.systemVersion)}
              </HomeInfoDetailTitle>

              <HomeInfoDetailContent>
                {currentHome.softwareInfo.version.name}
              </HomeInfoDetailContent>
            </HomeInfoDetail>
          )}

          {currentHome?.hostType && (
            <HomeInfoDetail>
              <HomeInfoDetailTitle>
                {formatMessage(homeInfoMessages.hostType)}
              </HomeInfoDetailTitle>

              <HomeInfoDetailContent>
                {hostTypes[currentHome.hostType as keyof typeof hostTypes] ??
                  currentHome.hostType}
              </HomeInfoDetailContent>
            </HomeInfoDetail>
          )}

          {currentHome?.lastConfig && (
            <HomeInfoDetail>
              <HomeInfoDetailTitle>
                {formatMessage(homeInfoMessages.lastChange)}
              </HomeInfoDetailTitle>

              <HomeInfoDetailContent>
                <IntlFormattedDate date={currentHome.lastConfig} withTime />
              </HomeInfoDetailContent>
            </HomeInfoDetail>
          )}

          <HomeInfoDetail>
            <HomeInfoDetailTitle>
              {formatMessage(homeInfoMessages.lastUpdated)}
            </HomeInfoDetailTitle>

            {currentHome?.softwareInfo?.created && (
              <HomeInfoDetailContent>
                <IntlFormattedDate
                  date={currentHome.softwareInfo.created}
                  withTime
                />
              </HomeInfoDetailContent>
            )}
          </HomeInfoDetail>

          <HomeInfoDetail>
            <HomeInfoDetailTitle>
              {formatMessage(homeInfoMessages.lastBackup)}
            </HomeInfoDetailTitle>

            <HomeInfoDetailContent>
              {!hasMinimumSoftwareVersionForBackup && (
                <HomeInfoDetailTitle>
                  <HomeInfoTitleIcon>
                    <WarningRounded />
                  </HomeInfoTitleIcon>
                  <FormattedMessage
                    {...homeInfoMessages.requiresForBackup}
                    values={{
                      planName: formatMessage(commonMessages.savantEssentials),
                    }}
                  />
                </HomeInfoDetailTitle>
              )}
              {hasMinimumSoftwareVersionForBackup && !!activeSubscription && (
                <IntlFormattedDate
                  date={currentHomeInfo?.lastBackup}
                  withTime
                  defaultReturn="-"
                />
              )}
              {hasMinimumSoftwareVersionForBackup && !activeSubscription && (
                <HomeInfoDetailTitle>
                  <HomeInfoTitleIcon>
                    <WarningRounded />
                  </HomeInfoTitleIcon>
                  <FormattedMessage
                    {...homeInfoMessages.requiresPlan}
                    values={{
                      planName: formatMessage(commonMessages.savantEssentials),
                    }}
                  />
                </HomeInfoDetailTitle>
              )}
            </HomeInfoDetailContent>
          </HomeInfoDetail>
        </HomeInfoDetails>
      )}
      <HomeInfoToggler onClick={toggleOpen}>
        <HomeInfoTogglerIcon
          path={isOpen ? mdiChevronUp : mdiChevronDown}
          size={1}
        />
      </HomeInfoToggler>
    </HomeInfoContainer>
  ) : null
}
export default HomeInfo
