import { useHistory } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import ROUTES from 'app/routes'
import { useAppSelector } from 'app/hooks'

import { BackButton } from 'components/Button'
import { adminHomesListSelector } from 'features/homes/selectors'
import { accountMenuMessages } from 'components/AccountMenu/messages'

const AccountBackBtn: React.FC = () => {
  const history = useHistory()
  const adminHomesList = useAppSelector(adminHomesListSelector)

  const handleClick = () => {
    history.push(adminHomesList.length ? ROUTES.dashboard : ROUTES.noHomes)
  }
  return (
    <BackButton onClick={handleClick}>
      <FormattedMessage {...accountMenuMessages.backToHome} />
    </BackButton>
  )
}

export default AccountBackBtn
