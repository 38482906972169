import React from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import { FormattedMessage, useIntl } from 'react-intl'
import * as Yup from 'yup'
import { Prompt } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'app/hooks'
import theme from 'theme_'
import { commonMessages } from 'app/i18n'

import { passInitResetSelectors } from 'features/auth/components/ForgotPassword/selectors'
import { MyAccountFormValues } from 'features/auth/account/types'
import { currUserSelector } from 'features/auth/login/selectors'
import UserImgContainer from 'components/UserItems/UserImgContainer'
import InputControl from 'components/Input/InputControl'
import { UserContentContainer } from 'components/UserItems/styles'
import { ButtonsFooter } from 'components/UserItems/ButtonsFooter'
import { TextField, ToggleField } from 'components/Fields'
import { H4, Regular, SectionTitle } from 'components/Typography'
import { LoaderOverlay } from 'components/Loader'
import { CommonButton } from 'components/Button'

import { myAccountMessages } from '../../messages'
import { requestUserUpdateThunk } from '../../slice'
import { AccountInputWrapper, StyledAccountBlurredBlock } from '../styles'
import { AccountToggleContainer } from './styles'
import { getMyAccountFormValues } from './utils'
import { fieldsMessages } from 'components/Fields/messages'

const MyAccountForm: React.FC = () => {
  const dispatch = useAppDispatch()
  const { formatMessage } = useIntl()
  const currentUser = useAppSelector(currUserSelector)
  const isPassResetLoading = useAppSelector(passInitResetSelectors.isFetching)

  const initialMyAccountValues = getMyAccountFormValues(currentUser)

  const { email } = currentUser

  const handleSubmit = async (
    values: MyAccountFormValues,
    formikHelpers: FormikHelpers<MyAccountFormValues>
  ) => {
    try {
      await dispatch(requestUserUpdateThunk(values))
    } catch (err) {
      console.log('Error updating a user')
      formikHelpers.resetForm()
    }
    formikHelpers.resetForm({ values })
  }

  return (
    <>
      <Formik
        initialValues={initialMyAccountValues}
        onSubmit={handleSubmit}
        validationSchema={Yup.object().shape({
          firstName: Yup.string().required(
            formatMessage(fieldsMessages.requiredField)
          ),
          lastName: Yup.string().required(
            formatMessage(fieldsMessages.requiredField)
          ),
        })}
      >
        {(formikProps) => {
          return (
            <>
              <Prompt
                when={formikProps.dirty}
                message={formatMessage(commonMessages.promptUnsavedForm)}
              />
              <Form>
                {(formikProps.isSubmitting || isPassResetLoading) && (
                  <LoaderOverlay backgroundColor={theme.overlay.darkImage} />
                )}
                <StyledAccountBlurredBlock>
                  <SectionTitle>
                    <FormattedMessage {...myAccountMessages.profileInfo} />
                  </SectionTitle>
                  <UserContentContainer>
                    <UserImgContainer
                      profilePicUrl={currentUser.profilePicUrl}
                    />
                    <AccountInputWrapper>
                      <TextField
                        name="firstName"
                        placeholder="First Name"
                        maxLength={30}
                      />
                    </AccountInputWrapper>
                    <AccountInputWrapper>
                      <TextField
                        name="lastName"
                        placeholder="Last Name"
                        maxLength={30}
                      />
                    </AccountInputWrapper>
                    <AccountInputWrapper>
                      <InputControl value={email} disabled />
                    </AccountInputWrapper>
                  </UserContentContainer>
                </StyledAccountBlurredBlock>

                <StyledAccountBlurredBlock>
                  <H4 color={theme.colors.mutedGray}>
                    <FormattedMessage {...myAccountMessages.commManagement} />
                  </H4>
                  <UserContentContainer>
                    <AccountToggleContainer htmlFor="emailNewsletter">
                      <Regular>
                        <FormattedMessage
                          {...myAccountMessages.receiveEmails}
                        />
                      </Regular>
                      <ToggleField
                        name="emailNewsletter"
                        id="emailNewsletter"
                      />
                    </AccountToggleContainer>
                  </UserContentContainer>
                </StyledAccountBlurredBlock>

                {formikProps.dirty && (
                  <ButtonsFooter>
                    <CommonButton
                      type="button"
                      variant="goldenOutlined"
                      onClick={(ev) => {
                        ev.preventDefault()
                        ev.stopPropagation()
                        formikProps.resetForm()
                      }}
                    >
                      <FormattedMessage {...commonMessages.cancel} />
                    </CommonButton>
                    <CommonButton variant="goldenFilled" type="submit">
                      <FormattedMessage {...commonMessages.save} />
                    </CommonButton>
                  </ButtonsFooter>
                )}
              </Form>
            </>
          )
        }}
      </Formik>
    </>
  )
}

export default MyAccountForm
