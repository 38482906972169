import styled from 'styled-components'
import theme from 'theme_'

export const StyledMobileBackBtn = styled.button`
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: ${theme.spacing.large};
  padding: 0 ${theme.spacing.md} 0 ${theme.spacing.xs};
  color: ${theme.colors.white};
  border: none;
  border-bottom: 1px solid ${theme.colors.separatorColor};
  background: transparent;
`
