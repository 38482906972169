import { defineMessages } from 'react-intl'

export const resetErrorPageMessages = defineMessages({
  title: {
    id: 'resetError.title',
    description: 'Failed title',
    defaultMessage: 'Password Resetting Failed',
  },
  description: {
    id: 'resetError.description',
    description: 'Text describes an error processing reset password',
    defaultMessage:
      'We encountered and error processing your password resetting request.',
  },
  goToLogin: {
    id: 'resetError.goToLogin',
    description: 'Go to login button',
    defaultMessage: 'Go to Sign In',
  },
})
