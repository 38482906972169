import { compose, map, prop, propOr, reduce, pick } from 'lodash/fp'

import { UserPermissions } from 'app/types/user'
import { HomeConfig } from 'features/homes/types'

export const getPermissionsFormValues = (
  homeConfig: HomeConfig,
  permissions: UserPermissions
) => {
  const homeRooms = compose(
    reduce(
      (acc, name: string) => ({
        ...acc,
        [name]:
          (permissions.zoneBlacklist || []).indexOf(name) > -1 ? false : true,
      }),
      {}
    ),
    map(prop('name')),
    propOr([], 'rooms')
  )(homeConfig)
  const userRoles = pick(['admin', 'remote', 'notifications'], permissions)
  return { homeRooms, ...userRoles }
}
