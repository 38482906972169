import { makeFetchSelectors } from 'app/redux-fetch/selectors'
import { RootState } from 'app/store'
import { IBillingRow } from './components/BillingRow'

import {
  BILLING_CHARGES_LIST_REDUCER_NAME,
  BILLING_CHARGES_REDUCER_NAME,
  BILLING_REDUCER_NAME,
} from './consts'

export const chargesSelector = makeFetchSelectors(
  BILLING_REDUCER_NAME,
  BILLING_CHARGES_REDUCER_NAME
)

export const selectListOfCharges = (state: RootState): IBillingRow[] =>
  state[BILLING_REDUCER_NAME][BILLING_CHARGES_LIST_REDUCER_NAME].list
