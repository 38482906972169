import React from 'react'
import { useIntl } from 'react-intl'

import {
  MySubscriptionItemSubTitleTextLong,
  MySubscriptionItemSubTitleWithIcon,
  MySubscriptionItemTitle,
} from '../styles'
import { subscriptionMessages } from '../messages'
import { StyledSubscrManagedBy, SubscrManagedByImg } from './styles'
import { makePublicUrl } from 'app/utils/publicUrl'

const suitcaseIcon = makePublicUrl('images/subscriptions/suitcase.svg')

interface SubscrManagedByProps {
  managedBy: string | undefined
  managedByDealer?: boolean
}

const SubscrManagedBy: React.FC<SubscrManagedByProps> = (props) => {
  const intl = useIntl()
  const { formatMessage } = intl

  return (
    <StyledSubscrManagedBy>
      <MySubscriptionItemTitle>
        {formatMessage(subscriptionMessages.managedBy)}
      </MySubscriptionItemTitle>
      <MySubscriptionItemSubTitleTextLong>
        <SubscrManagedByImg src={suitcaseIcon} />
        <MySubscriptionItemSubTitleWithIcon>
          {props.managedBy}
        </MySubscriptionItemSubTitleWithIcon>
      </MySubscriptionItemSubTitleTextLong>
    </StyledSubscrManagedBy>
  )
}

export default SubscrManagedBy
