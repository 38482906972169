import React, { memo } from 'react'
import { ErrorMessage, Field } from 'formik'
import { CommonStyledInput, StyledInputError } from 'components/Input/styles'
import { IFieldProps } from '../types'
import { useIntl } from 'react-intl'
import { textFieldMessages } from './messages'

const TextField: React.FC<IFieldProps> = ({ label, ...props }) => {
  const { formatMessage } = useIntl()

  return (
    <>
      {label && <label htmlFor={props.name}>{label}</label>}
      <CommonStyledInput
        as={Field}
        type="text"
        id={props.name}
        placeholder={
          props.placeholder || `${formatMessage(textFieldMessages.placeholder)}`
        }
        {...props}
      />
      <StyledInputError>
        <ErrorMessage name={props.name} />
      </StyledInputError>
    </>
  )
}

export default memo(TextField)
