import styled from 'styled-components'

import { MySubscriptionItem } from '../styles'
import { Font14 } from 'components/Typography/Base'
import theme from 'theme_'

export const StyledSubsExpires = styled(MySubscriptionItem)<{
  $hasWarn: boolean
}>`
  ${Font14}
  text-align: right;
  align-items: flex-end;

  & > div {
    color: ${(p) => p.$hasWarn && theme.colors.accentOrange};
  }
`
