import styled from 'styled-components'

export const PermissionContainer = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
`

export const PermissionToggleContainer = styled.div`
  margin-left: 20px;
`
