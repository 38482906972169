import React from 'react'
import { useIntl } from 'react-intl'
import {
  mdiViewDashboardOutline,
  mdiAccountMultipleOutline,
  mdiCheckCircleOutline,
  mdiGoogleCirclesCommunities,
  mdiHistory,
  mdiBriefcaseVariantOutline,
  mdiForumOutline,
} from '@mdi/js'
import { ReactComponent as TutorialsIcon } from 'components/Icons/tutorials-icon.svg'
import { ReactComponent as DiscoverMoreIcon } from 'components/Icons/discover-more-icon.svg'
import { useSelector } from 'react-redux'
import { generatePath } from 'react-router-dom'

import { currentHomeIdSelector } from 'features/homes/selectors'
import routes from 'app/routes'

import { asideMenuMessages } from './messages'
import AsideMenuLink from './AsideMenuLink'
import { isSubscriptionHidden } from 'app/utils/isSubscriptionHidden'

const AsideMenuLinks: React.FC = () => {
  const { formatMessage } = useIntl()
  const homeId = useSelector(currentHomeIdSelector)

  return (
    <>
      <AsideMenuLink
        to="/"
        message={formatMessage(asideMenuMessages.homeStatus)}
        path={mdiViewDashboardOutline}
      />
      {!isSubscriptionHidden && (
        <>
          {/* <AsideMenuLink
            message={formatMessage(asideMenuMessages.getHelp)}
            path={mdiHelpCircleOutline}
            to={routes.getHelp}
          /> */}

          <AsideMenuLink
            message={formatMessage(asideMenuMessages.subscription)}
            path={mdiCheckCircleOutline}
            to={routes.subscription}
          />
        </>
      )}
      {homeId && (
        <AsideMenuLink
          message={formatMessage(asideMenuMessages.users)}
          path={mdiAccountMultipleOutline}
          to={generatePath(routes.users, { homeId })}
        />
      )}
      <AsideMenuLink
        message={formatMessage(asideMenuMessages.myDealer)}
        path={mdiBriefcaseVariantOutline}
        to="/my-dealer"
      />
      <AsideMenuLink
        message={formatMessage(asideMenuMessages.cameraRecordings)}
        path={mdiHistory}
        to="/camera-recordings"
      />
      <AsideMenuLink
        message={formatMessage(asideMenuMessages.connectedApps)}
        path={mdiGoogleCirclesCommunities}
        to={routes.connectedApps}
      />
      <AsideMenuLink
        message={formatMessage(asideMenuMessages.tutorials)}
        path={TutorialsIcon}
        to="/tutorials"
      />
      <AsideMenuLink
        message={formatMessage(asideMenuMessages.discoverMore)}
        path={DiscoverMoreIcon}
        to="/discover-more"
      />
      <AsideMenuLink
        message={formatMessage(asideMenuMessages.getHelp)}
        path={mdiForumOutline}
        to="/get-help"
      />
      {/* <AsideMenuLink
        message={formatMessage(asideMenuMessages.activityLog)}
        path={mdiViewListOutline}
        to="/activity-log"
      /> */}
      {/* <AsideMenuLink
        message={formatMessage(asideMenuMessages.energy)}
        path={mdiLightningBolt}
        to="/energy"
      /> */}
      {/* <AsideMenuLink
        message={formatMessage(asideMenuMessages.homePhotos)}
        path={mdiImageOutline}
        to="/home-photos"
      /> */}
    </>
  )
}

export default AsideMenuLinks
