import styled from 'styled-components'
import theme from 'theme_'

import { StyledScreenWrapper } from 'components/MobileMenu/styles'

export const OptionsContainer = styled.ul<{
  isOpen: boolean
  inMobileMenu?: boolean
}>`
  position: ${(p) => (p.inMobileMenu ? 'static' : 'absolute')};
  right: 20px;
  top: 100%;
  height: ${(p) => (p.isOpen ? 'auto' : 0)};
  margin-top: ${theme.spacing.xs};
  padding: 0;
  border-radius: ${theme.spacing.xs};
  background: ${theme.overlay.newDropdownBlack};
  backdrop-filter: ${theme.overlay.SBlur};
  transition: height 0.2s ease;
  overflow: hidden;

  ${(p) =>
    p.isOpen &&
    `
    box-shadow: 0px 16px 32px rgba(19, 27, 77, 0.15);
  `}

  ${StyledScreenWrapper} & {
    margin-top: 0;
  }

  @media ${theme.devices.desktop} {
    position: absolute;
    top: calc(100% + 12px);
    right: 0;
    width: 300px;
    margin: 0;
  }
`

export const Option = styled.li<{ isSelected: boolean }>`
  list-style: none;
  padding: ${theme.spacing.sm};
  cursor: pointer;
  user-select: none;
  transition: background-color 0.2s ease;

  label {
    cursor: pointer;
  }

  &:hover {
    background-color: ${theme.overlay.newMenuHover};
  }

  ${(p) => p.isSelected && `background-color: ${theme.overlay.newMenuHover}; `}
`
