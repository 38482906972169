import { useEffect, useState } from 'react'
import { useAppDispatch } from 'app/hooks'
import { useSelector } from 'react-redux'
import { RootState } from 'app/store'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useIntl } from 'react-intl'

import BillingRow, { IBillingRow } from './components/BillingRow'
import { chargesSelector, selectListOfCharges } from './selectors'
import { getChargesThunk, setChargesActions } from './slice'
import {
  BillingContainer,
  BillingHeaderTableContainer,
  BillingTable,
  BillingTableTh,
  NoPaymentContainer,
} from './styles'
import { Spinner } from 'components/Loader'
import { SpinnerContainer } from 'features/users/styles'
import { H1, H4 } from 'components/Typography'
import { billingHistory } from './messages'
import { getAuthorizedUser } from 'app/services/LocalStorage'

import ReactHelmet from 'features/analytics/RenderHelmet'

const BillingHistory: React.FC = () => {
  const hasLoaded = useSelector<RootState, boolean>(chargesSelector.isFetching)
  const [recentChargeId, setRecentChargeId] = useState('')
  const charges = useSelector<RootState, IBillingRow[]>(selectListOfCharges)
  const getCharges = useSelector<RootState, IBillingRow[]>(chargesSelector.data)

  const { formatMessage } = useIntl()

  const dispatch = useAppDispatch()

  const fetchData = () => {
    setRecentChargeId(charges[charges.length - 1]?.id)
  }
  useEffect(() => {
    dispatch(setChargesActions.initListCharges())
  }, [dispatch])

  useEffect(() => {
    dispatch(getChargesThunk(getAuthorizedUser()?.id ?? '', recentChargeId))
  }, [dispatch, recentChargeId])

  return (
    <>
      <ReactHelmet title={billingHistory.title.defaultMessage} />

      <H1>{formatMessage(billingHistory.title)}</H1>

      {!hasLoaded || charges.length !== 0 ? (
        <>
          {charges && charges.length === 0 && (
            <NoPaymentContainer>
              <H4>{formatMessage(billingHistory.noBilling)}</H4>
            </NoPaymentContainer>
          )}
          {charges && charges.length > 0 && (
            <>
              <BillingContainer>
                <BillingTable>
                  <InfiniteScroll
                    dataLength={charges.length}
                    next={fetchData}
                    hasMore={getCharges.length > 0}
                    loader={
                      hasLoaded &&
                      getCharges.length > 0 && (
                        <SpinnerContainer>
                          <Spinner />
                        </SpinnerContainer>
                      )
                    }
                  >
                    <BillingHeaderTableContainer>
                      <BillingTableTh>
                        {formatMessage(billingHistory.createAt)}
                      </BillingTableTh>
                      <BillingTableTh>
                        {formatMessage(billingHistory.home)}
                      </BillingTableTh>
                      <BillingTableTh>
                        {formatMessage(billingHistory.description)}
                      </BillingTableTh>
                      <BillingTableTh>
                        {formatMessage(billingHistory.paymentMethod)}
                      </BillingTableTh>
                      <BillingTableTh>
                        {formatMessage(billingHistory.amount)}
                      </BillingTableTh>
                      <BillingTableTh>
                        {formatMessage(billingHistory.status)}
                      </BillingTableTh>
                    </BillingHeaderTableContainer>
                    {charges.map((charge, i) => (
                      <BillingRow key={i} {...charge}></BillingRow>
                    ))}
                  </InfiniteScroll>
                </BillingTable>
              </BillingContainer>
            </>
          )}
        </>
      ) : (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      )}
    </>
  )
}

export default BillingHistory
