import styled from 'styled-components'
import theme from 'theme_'
import CommonLink from 'components/Button/CommonLink'
import { Regular } from 'components/Typography'

export const StyledInstructionsBlock = styled.div`
  max-width: 600px;
  margin: ${theme.spacing.md} auto;
  text-align: center;
`

export const ChangePassModalContainer = styled.div`
  padding: 64px 0;
  text-align: center;
  display: flex;
  flex-flow: column;
  align-items: center;
`

export const AccountToggleContainer = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .react-toggle {
    margin-left: ${theme.spacing.sm};
  }
`

export const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: ${(p) => p.theme.spacing.lg} 0 ${theme.spacing.md};

  & > button:first-child {
    margin-right: 32px;
  }

  @media only screen and (max-width: ${theme.breakpoint.desktop}) {
    & > button:first-child {
      margin-right: 16px;
    }
  }
`

export const StyledResendVerification = styled(CommonLink)`
  font-weight: 700;

  @media ${theme.devices.tablet} {
    margin-left: ${theme.spacing.tiny};
  }
`

export const StyledDescriptionTextWrapper = styled.div`
  margin-top: ${theme.spacing.s};
`

export const StyledResendText = styled.span`
  color: ${theme.colors.beachGrey};
`

export const StyledDescriptionText = styled(Regular)`
  text-align: center;
  margin-bottom: ${theme.spacing.md};
`

export const ResendCodeWrapper = styled.div`
  text-align: center;
`

export const ModalContent = styled.div`
  padding-left: 24px;
  padding-right: 24px;
`

export const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
`
