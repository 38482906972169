import Icon from '@mdi/react'
import { BluredBlock } from 'components/Layout'
import styled from 'styled-components'
import theme from 'theme_'

export const SubscriptionTableOuterContainer = styled.div`
  width: 100%;
  animation: ${theme.animations.fadeIn} 0.3s ease;
`

export const SubscriptionTableInnerContainer = styled(BluredBlock)`
  padding: 0;
  margin-bottom: 10px;
  color: ${theme.colors.beachGrey};

  @media ${theme.devices.tablet} {
    text-align: center;
    border-radius: 8px;
  }
`

export const SubscriptionInfoIcon = styled(Icon)<{
  $colorful: boolean
}>`
  height: 24px;
  color: ${({ $colorful }) =>
    $colorful ? theme.colors.accentGold : theme.colors.mutedGray};
`

export const SubscriptionUnlimitedText = styled.div<{
  colorful: boolean
}>`
  height: 24px;
  font-size: 16px;
  color: ${({ colorful }) =>
    colorful ? theme.colors.accentGold : theme.colors.mutedGray};
`

export const SubscriptionMinusIcon = styled(Icon)<{ $colorful?: boolean }>`
  height: 24px;
  color: ${theme.colors.dimmedGold};
  opacity: 0.2;
`

export const SubscriptionCheckIcon = styled(Icon)<{
  $colorful: boolean
}>`
  height: 24px;
  color: ${({ $colorful }) =>
    $colorful ? theme.colors.accentGold : theme.colors.mutedGray};
`
