import React from 'react'
import { mdiInformation } from '@mdi/js'

import {
  StyledAlertBanner,
  AlertBannerInfoIcon,
  AlertBannerText,
} from './styles'

interface AlertBannerProps {
  bannerText: string
  textColor?: string
  onBannerClick?: () => void
  onClose?: () => void
  onBannerClose?: () => void
}

const AlertBanner: React.FC<AlertBannerProps> = (props) => {
  return (
    <StyledAlertBanner onClick={props.onBannerClick}>
      <AlertBannerInfoIcon path={mdiInformation} size={1} />
      <AlertBannerText color={props.textColor}>
        {props.bannerText}
      </AlertBannerText>
      {props.onBannerClose && <button onClick={props.onBannerClose}>X</button>}
    </StyledAlertBanner>
  )
}

export default AlertBanner
