import React, { useMemo, useState } from 'react'
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js'
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
  StripeError,
} from '@stripe/stripe-js'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import * as Yup from 'yup'
import { useIntl } from 'react-intl'

import './styles.css'

import { DropdownField, TextField, ToggleField } from 'components/Fields'
import { CommonButton } from 'components/Button'
import {
  stripeElementsCommonInputStyle,
  StyledAddPaymentFormButtons,
  StyledAddPaymentFormError,
  StyledAddPaymentHeading,
  StyledAddPaymentInner,
  StyledAddPaymentMethodForm,
  StyledBrandIcon,
  StyledChargesInfo,
  StyledCountryPostal,
  StyledCountryWrap,
  StyledExpiryCvc,
  StyledExpiryWrap,
  StyledFailedScreen,
  StyledFailedScreenError,
  StyledMakeDefaultText,
  StyledMakeDefaultToggle,
  StyledMakeDefaultToggleWrapper,
  StyledPaymentIcons,
} from './styles'
import { fieldsMessages } from 'components/Fields/messages'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { setupIntentSelector } from 'features/payment/selectors'
import { paymentMethodsMessages } from './messages'
import postalCodes from 'app/utils/postalCodes'
import { LoaderOverlay } from 'components/Loader'
import theme from 'theme_'
import { makePublicUrl } from 'app/utils/publicUrl'
import {
  createSetupIntentThunk,
  setDefaultPaymentMethodThunk,
} from 'features/payment/slice'
import { AuthorizedUser } from 'app/types'
import { currUserSelector } from 'features/auth/login/selectors'
import { COUNTRY_CODES } from 'app/consts'

interface IBillingDetailsAddress {
  country: string
  postal_code: string
}

interface IAddPaymentMethodDetails {
  setDefault: boolean
  name: string
  address: IBillingDetailsAddress
}

const initialBillingDetails: IAddPaymentMethodDetails = {
  setDefault: true,
  name: '',
  address: {
    country: '',
    postal_code: '',
  },
}

type ElementsErrorType = StripeError | null | undefined

interface AddPaymentMethodFormProps {
  onCancel?: () => void
  onSuccess: () => void
  isFirstMethod?: boolean
}

const PaymentIcons: React.FC = () => (
  <StyledPaymentIcons>
    <StyledBrandIcon src={makePublicUrl(`images/paymentLogos/visa.svg`)} />
    <StyledBrandIcon
      src={makePublicUrl(`images/paymentLogos/mastercard.svg`)}
    />
    <StyledBrandIcon src={makePublicUrl(`images/paymentLogos/amex.svg`)} />
    <StyledBrandIcon src={makePublicUrl(`images/paymentLogos/discover.svg`)} />
  </StyledPaymentIcons>
)

const AddPaymentMethodForm: React.FC<AddPaymentMethodFormProps> = (props) => {
  const dispatch = useAppDispatch()
  const { formatMessage } = useIntl()
  const stripe = useStripe()
  const elements = useElements()
  const [error, setError] = useState<string | undefined>(undefined)
  const [cardNumberError, setCardNumberError] = useState<ElementsErrorType>(
    null
  )
  const [cardNumberComplete, setCardNumberComplete] = useState<boolean>(false)
  const [cardExpiryError, setCardExpiryError] = useState<ElementsErrorType>(
    null
  )
  const [cardExpiryComplete, setCardExpiryComplete] = useState<boolean>(false)
  const [cardCvcError, setCardCvcError] = useState<ElementsErrorType>(null)
  const [cardCvcComplete, setCardCvcComplete] = useState<boolean>(false)

  const currentSetupIntent = useAppSelector(setupIntentSelector)

  const user: AuthorizedUser = useAppSelector(currUserSelector)

  const handleCardNumberChange = (
    event: StripeCardNumberElementChangeEvent
  ) => {
    setCardNumberComplete(event.complete)
    setCardNumberError(event.error)
  }
  const handleCardExpiryChange = (
    event: StripeCardExpiryElementChangeEvent
  ) => {
    setCardExpiryComplete(event.complete)
    setCardExpiryError(event.error)
  }
  const handleCardCvcChange = (event: StripeCardCvcElementChangeEvent) => {
    setCardCvcComplete(event.complete)
    setCardCvcError(event.error)
  }

  const shouldDisableButtons =
    !!error || !stripe || !elements || !currentSetupIntent

  const shouldDisableSubmit = (
    formikProps: FormikProps<IAddPaymentMethodDetails>
  ) =>
    shouldDisableButtons ||
    !cardNumberComplete ||
    !cardExpiryComplete ||
    !cardCvcComplete ||
    !(formikProps.isValid && formikProps.dirty)

  const handleSubmit = async (
    values: IAddPaymentMethodDetails,
    formikHelpers: FormikHelpers<IAddPaymentMethodDetails>
  ) => {
    if (!stripe || !elements || !currentSetupIntent) {
      return
    }

    const { setDefault, ...paymentValues } = values

    const paymentMethodPayload = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement)!,
      billing_details: paymentValues,
    })

    if (paymentMethodPayload.error) {
      setError(paymentMethodPayload.error.message)
      return
    } else {
      const confirmPayload = await stripe.confirmCardSetup(
        currentSetupIntent.clientSecret,
        {
          payment_method: paymentMethodPayload.paymentMethod.id,
        }
      )
      if (confirmPayload.error) {
        setError(confirmPayload.error.message)
        return
      }
      await dispatch(createSetupIntentThunk(user.id))
      if (setDefault && !props.isFirstMethod) {
        await dispatch(
          setDefaultPaymentMethodThunk(
            paymentMethodPayload.paymentMethod.id,
            user.id
          )
        )
      }
      await props.onSuccess()
    }
  }

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required(
          formatMessage(fieldsMessages.requiredField)
        ),
        address: Yup.object({
          country: Yup.string().required(
            formatMessage(fieldsMessages.requiredField)
          ),
          postal_code: Yup.string()
            .required(formatMessage(fieldsMessages.requiredField))
            .when(['country'], (country: string | undefined, schema: any) => {
              return country === COUNTRY_CODES.USA
                ? schema.matches(
                    postalCodes.usa,
                    formatMessage(paymentMethodsMessages.notValidUSACode)
                  )
                : schema.matches(
                    postalCodes.canada,
                    formatMessage(paymentMethodsMessages.notValidCanadaCode)
                  )
            }),
        }),
      }),
    [formatMessage]
  )

  return (
    <>
      <StyledAddPaymentHeading>
        {formatMessage(paymentMethodsMessages.formTitle)}
      </StyledAddPaymentHeading>
      <Formik
        initialValues={initialBillingDetails}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {(formikProps) => (
          <StyledAddPaymentMethodForm>
            {!error ? (
              <>
                <StyledMakeDefaultToggleWrapper>
                  <StyledMakeDefaultToggle>
                    <StyledMakeDefaultText>
                      {formatMessage(paymentMethodsMessages.makeDefault)}{' '}
                    </StyledMakeDefaultText>
                    <ToggleField
                      disabled={props.isFirstMethod}
                      name="setDefault"
                      inline
                    />
                  </StyledMakeDefaultToggle>
                  <PaymentIcons />
                </StyledMakeDefaultToggleWrapper>
                <StyledAddPaymentInner>
                  {formikProps.isSubmitting && (
                    <LoaderOverlay backgroundColor={theme.colors.inputFill} />
                  )}
                  <div>
                    <CardNumberElement
                      options={{
                        style: stripeElementsCommonInputStyle,
                        showIcon: true,
                      }}
                      onChange={handleCardNumberChange}
                    />
                    {cardNumberError && (
                      <StyledAddPaymentFormError>
                        {cardNumberError?.message}
                      </StyledAddPaymentFormError>
                    )}
                    <StyledExpiryCvc>
                      <StyledExpiryWrap>
                        <CardExpiryElement
                          options={{
                            style: stripeElementsCommonInputStyle,
                          }}
                          onChange={handleCardExpiryChange}
                        />
                        {cardExpiryError && (
                          <StyledAddPaymentFormError>
                            {cardExpiryError?.message}
                          </StyledAddPaymentFormError>
                        )}
                      </StyledExpiryWrap>
                      <StyledExpiryWrap>
                        <CardCvcElement
                          options={{
                            style: stripeElementsCommonInputStyle,
                          }}
                          onChange={handleCardCvcChange}
                        />
                        {cardCvcError && (
                          <StyledAddPaymentFormError>
                            {cardCvcError?.message}
                          </StyledAddPaymentFormError>
                        )}
                      </StyledExpiryWrap>
                    </StyledExpiryCvc>
                  </div>
                  <TextField
                    name="name"
                    placeholder={formatMessage(
                      paymentMethodsMessages.cardNamePlaceholder
                    )}
                  />
                  <StyledCountryPostal>
                    <StyledCountryWrap>
                      <DropdownField
                        name="address.country"
                        placeholder={formatMessage(
                          paymentMethodsMessages.countryPlaceholder
                        )}
                        options={[
                          {
                            label: formatMessage(
                              paymentMethodsMessages.usaLabel
                            ),
                            value: COUNTRY_CODES.USA,
                          },
                          {
                            label: formatMessage(
                              paymentMethodsMessages.canadaLabel
                            ),
                            value: COUNTRY_CODES.Canada,
                          },
                        ]}
                      />
                    </StyledCountryWrap>
                    <div>
                      <TextField
                        name="address.postal_code"
                        disabled={!formikProps.values.address.country}
                        placeholder={formatMessage(
                          paymentMethodsMessages.postalPlaceholder
                        )}
                      />
                    </div>
                  </StyledCountryPostal>
                  <StyledChargesInfo>
                    {formatMessage(paymentMethodsMessages.chargesInfo)}
                  </StyledChargesInfo>
                </StyledAddPaymentInner>
                <StyledAddPaymentFormButtons>
                  <CommonButton
                    type="button"
                    variant="goldenOutlined"
                    onClick={props.onCancel}
                    disabled={shouldDisableButtons}
                  >
                    {formatMessage(paymentMethodsMessages.modalClose)}
                  </CommonButton>
                  <CommonButton
                    type="submit"
                    variant="goldenFilled"
                    disabled={
                      formikProps.isSubmitting ||
                      shouldDisableSubmit(formikProps)
                    }
                  >
                    {formatMessage(paymentMethodsMessages.saveMethod)}
                  </CommonButton>
                </StyledAddPaymentFormButtons>
              </>
            ) : (
              <StyledFailedScreen>
                <StyledFailedScreenError>{error}</StyledFailedScreenError>
                <CommonButton
                  type="button"
                  variant="goldenOutlined"
                  onClick={() => {
                    setError(undefined)
                    formikProps.resetForm()
                  }}
                >
                  {formatMessage(paymentMethodsMessages.backToForm)}
                </CommonButton>
              </StyledFailedScreen>
            )}
          </StyledAddPaymentMethodForm>
        )}
      </Formik>
    </>
  )
}

export default AddPaymentMethodForm
