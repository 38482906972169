import { AuthorizedUser } from '../types'

const USER_KEY = 'sav-user'
const CLIENT_KEY = 'sav-clientId'
const CREDS_KEY = 'sav-creds'
const SELECTED_HOME_KEY = 'sav-selectedHomeId'

export const storeAuthorizedUser = (user: AuthorizedUser) =>
  localStorage.setItem(USER_KEY, JSON.stringify(user))

export const getAuthorizedUser = (): AuthorizedUser | null => {
  const storedUser = localStorage.getItem(USER_KEY)
  if (storedUser) {
    try {
      return JSON.parse(storedUser) as AuthorizedUser
    } catch (err) {
      console.error('Error parsing stored authorized user', err)
    }
  }

  return null
}

export const storeClientId = (clientId: string) =>
  localStorage.setItem(CLIENT_KEY, clientId)

export const getClientId = (): string | null => localStorage.getItem(CLIENT_KEY)

export const clearAuthorizedUser = () => localStorage.removeItem(USER_KEY)
export const clearUserCreds = () => localStorage.removeItem(CREDS_KEY)

export const storeSelectedHomeId = (homeId: string) =>
  localStorage.setItem(SELECTED_HOME_KEY, homeId)
export const getSelectedHomeId = (): string | null =>
  localStorage.getItem(SELECTED_HOME_KEY)
