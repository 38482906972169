import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    id: 'revokeAppConfirmation.title',
    description: 'Connected app revoke confirmation dialog title',
    defaultMessage: 'Revoke access',
  },
  message: {
    id: 'revokeAppConfirmation.msg',
    description: 'Message for connected app revoke confirmation dialog',
    defaultMessage:
      'Are you sure you want to revoke access for {appName}? {br} These products or services will no longer have control or access to your Savant Home.',
  },
  ctaBtn: {
    id: 'revokeAppConfirmation.ctaBtn',
    description:
      'Confirm button text of connected app revoke confirmation dialog',
    defaultMessage: 'Revoke',
  },
})
