import { FC, useCallback, useState } from 'react'
import { mdiDelete, mdiEmailOutline } from '@mdi/js'
import { FormattedMessage } from 'react-intl'

import { MdiIcon } from 'components/Icons'
import { getUserRoleLabel } from 'features/auth/utils'
import theme from 'theme_'
import formatUserFullName from 'app/utils/formatUserFullName'
import { BodyPrimary, Secondary, SectionTitle } from 'components/Typography'
import { IconButton } from 'components/Button'
import { UserShape } from 'app/types/user'
import RemoveUserConfirmation from 'features/users/components/RemoveUserConfirmation'

import { messages } from '../../messages'
import DateRow from '../DateRow'
import DetailsBlock from '../DetailsBlock'

import {
  UserInfoHeader,
  UserName,
  UserRoleLabel,
  UserEmail,
  UserInfoDates,
} from './styles'
import { UserImgContainer } from 'components/UserItems'
import { UserContentContainer } from 'components/UserItems/styles'
import { useHistory } from 'react-router-dom'

const UserInfo: FC<{ userDetails: UserShape; homeId: string }> = ({
  userDetails,
  homeId,
}) => {
  const history = useHistory()
  const [isRemoveConfirmVisible, setIsRemoveConfirmVisible] = useState(false)

  const handleRemoveUserSuccess = useCallback(() => {
    setIsRemoveConfirmVisible(false)
    history.push(`/${homeId}/users`)
  }, [history, homeId])

  return (
    <>
      <RemoveUserConfirmation
        isOpen={isRemoveConfirmVisible}
        {...{ homeId }}
        userId={userDetails.id}
        onDiscard={() => setIsRemoveConfirmVisible(false)}
        onConfirm={handleRemoveUserSuccess}
      />

      <DetailsBlock
        header={
          <UserInfoHeader>
            <SectionTitle>
              <FormattedMessage {...messages.info} />
            </SectionTitle>
            <IconButton onClick={() => setIsRemoveConfirmVisible(true)}>
              <MdiIcon path={mdiDelete} />
            </IconButton>
          </UserInfoHeader>
        }
      >
        <UserContentContainer>
          <UserImgContainer profilePicUrl={userDetails.profilePicUrl} />

          <UserName>
            <BodyPrimary>{formatUserFullName(userDetails)}</BodyPrimary>
          </UserName>

          <UserRoleLabel>
            <FormattedMessage {...getUserRoleLabel(userDetails)} />
          </UserRoleLabel>

          <UserEmail href={`mailto:${userDetails.email}`}>
            <MdiIcon path={mdiEmailOutline} />
            <Secondary color={theme.colors.mutedGray}>
              {userDetails.email}
            </Secondary>
          </UserEmail>

          <UserInfoDates>
            <DateRow
              label={<FormattedMessage {...messages.joinHomeDate} />}
              date={userDetails.associated}
            />
          </UserInfoDates>
        </UserContentContainer>
      </DetailsBlock>
    </>
  )
}

export default UserInfo
