import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Formik, FormikProps, Form } from 'formik'
import { FormattedMessage, useIntl } from 'react-intl'
import { Prompt } from 'react-router-dom'

import { commonMessages } from 'app/i18n'
import { CommonButton } from 'components/Button'
import { LoaderOverlay, SpinnerCentered } from 'components/Loader'
import { ButtonsFooter } from 'components/UserItems/ButtonsFooter'
import { PermissionsForm } from './types'
import DealerPermissions, {
  AccessExpires,
} from './components/dealerPermissions'
import { getDealerAccessThunk, updateDealerAccessThunk } from '../slice'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { currentHomeIdSelector } from 'features/homes/selectors'
import {
  dealerAccessSelectors,
  dealerUpdateAccessSelectors,
} from '../selectors'

const makeConfigAccessDate = (
  currentExpiresDate: number,
  initialExpiresDate: number
) => {
  if (currentExpiresDate === initialExpiresDate) {
    return currentExpiresDate
  }
  return new Date().getTime() + currentExpiresDate
}

interface Dealer {
  homeId?: string
  id?: string
  requestedDomainId?: string
}

interface PermissionsProps {
  dealer: Dealer
}

function Permissions({ dealer }: PermissionsProps) {
  const dispatch = useAppDispatch()
  const { formatMessage } = useIntl()
  const currentHomeId = useSelector(currentHomeIdSelector)
  const dealerAccess = useSelector(dealerAccessSelectors.data)
  const isFetching = useSelector(dealerAccessSelectors.isFetching)
  const error = useSelector(dealerAccessSelectors.error)
  const initialValues = useMemo(
    () => ({
      monitoring: dealerAccess?.monitorAccess ?? false,
      systemAccess: dealerAccess?.configAccess > new Date().getTime() ?? false,
      systemAccessExpires: dealerAccess?.configAccess ?? -1,
    }),
    [dealerAccess]
  )

  const isFetchingUpdateAccess = useAppSelector(
    dealerUpdateAccessSelectors.isFetching
  )

  useEffect(() => {
    if (currentHomeId) {
      dispatch(getDealerAccessThunk(currentHomeId))
    }
  }, [currentHomeId, dispatch])

  return isFetching || isFetchingUpdateAccess || (!dealerAccess && !error) ? (
    <SpinnerCentered />
  ) : (
    <Formik
      {...{ initialValues }}
      onSubmit={async ({ ...values }: PermissionsForm) => {
        let newConfigAccess
        if (values.systemAccess) {
          if (!initialValues.systemAccess && !values.systemAccessExpires) {
            newConfigAccess = AccessExpires.Never
          } else {
            newConfigAccess = makeConfigAccessDate(
              values.systemAccessExpires,
              initialValues.systemAccessExpires
            )
          }
        } else {
          newConfigAccess = -1
        }
        await dispatch(
          updateDealerAccessThunk({
            homeId: currentHomeId ?? '',
            monitorAccess: values.monitoring,
            configAccess: newConfigAccess,
          })
        )
        await dispatch(getDealerAccessThunk(currentHomeId))
      }}
    >
      {(formikProps: FormikProps<PermissionsForm>) => (
        <>
          <Prompt
            when={formikProps.dirty}
            message={formatMessage(commonMessages.promptUnsavedForm)}
          />
          <Form>
            <DealerPermissions dealer={dealer} />
            {formikProps.dirty && (
              <ButtonsFooter>
                <CommonButton
                  variant="goldenOutlined"
                  onClick={(ev) => {
                    ev.preventDefault()
                    ev.stopPropagation()
                    formikProps.resetForm()
                  }}
                >
                  <FormattedMessage {...commonMessages.cancel} />
                </CommonButton>
                <CommonButton variant="goldenFilled" type="submit">
                  <FormattedMessage {...commonMessages.save} />
                </CommonButton>
              </ButtonsFooter>
            )}

            {formikProps.isSubmitting && <LoaderOverlay />}
          </Form>
        </>
      )}
    </Formik>
  )
}

export default Permissions
