import { FC, ReactElement, MouseEvent, ReactNode } from 'react'
import Modal from 'components/Modal'

import { H2 } from 'components/Typography'
import { CommonButton } from 'components/Button'

import { IModalProps } from '../types'

import {
  InnerContainer,
  ConfirmModalTitle,
  ConfirmModalBody,
  ConfirmModalFooter,
} from './styles'

interface ConfirmModalProps extends IModalProps {
  icon?: ReactElement
  title: ReactNode
  children?: ReactNode
  discardBtnTitle?: ReactNode
  confirmBtnTitle?: ReactNode
  onDiscard?: (ev: MouseEvent<HTMLElement>) => void
  onConfirm?: (ev: MouseEvent<HTMLElement>) => void
  isRedConfirmBtn?: boolean
}

const ConfirmModal: FC<ConfirmModalProps> = ({
  isOpen,
  icon,
  title,
  children,
  discardBtnTitle,
  confirmBtnTitle,
  onDiscard,
  onConfirm,
  onRequestClose = onDiscard,
  isRedConfirmBtn,
}) => {
  return (
    <Modal {...{ isOpen, onRequestClose }}>
      <InnerContainer>
        {icon}

        <ConfirmModalTitle>
          <H2>{title}</H2>
        </ConfirmModalTitle>

        <ConfirmModalBody>{children}</ConfirmModalBody>

        <ConfirmModalFooter>
          <CommonButton variant="goldenOutlined" onClick={onDiscard}>
            {discardBtnTitle}
          </CommonButton>
          <CommonButton
            variant={isRedConfirmBtn ? 'errorFilled' : 'goldenFilled'}
            onClick={onConfirm}
          >
            {confirmBtnTitle}
          </CommonButton>
        </ConfirmModalFooter>
      </InnerContainer>
    </Modal>
  )
}

export default ConfirmModal
