import styled from 'styled-components'
import theme from 'theme_'

export const StyledDealerRow = styled.div<{ fullWidth?: boolean }>`
  color: ${theme.colors.beachGrey};
  font-size: 16px;
  line-height: ${theme.spacing.md};
  text-align: ${(p) => (p.fullWidth ? 'center' : 'left')};
  margin-bottom: ${theme.spacing.md};
  &:last-child {
    margin-bottom: 0;
  }
  @media ${theme.devices.desktop} {
    max-width: ${(p) => (p.fullWidth ? '600px' : '460px')};
    margin-right: auto;
    margin-left: ${(p) => (p.fullWidth ? 'auto' : 0)};
    font-size: ${theme.spacing.base};
  }
  & > p:first-child {
    font-weight: bold;
    @media ${theme.devices.desktop} {
      margin: 0 0 ${theme.spacing.md} 0;
    }
  }
  & > p:last-child {
    margin: 0;
  }
  & > div:last-child {
    display: flex;
    justify-content: ${(p) => (p.fullWidth ? 'center' : 'flex-start')};
  }
`

export const StyledButtonWrapper = styled.div`
  & > a {
    font-weight: bold;
    -webkit-appearance: none;
  }
`

export const CodeBlockContainer = styled.div`
  margin-bottom: ${theme.spacing.md};
`
