import { FC, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'

import { H1 } from 'components/Typography'
import { connectedAppsMessages } from './messages'
import {
  ConnectedAppsHeader,
  ConnectedAppsContentContainer,
  SpinnerContainer,
} from './styles'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { currentHomeIdSelector } from 'features/homes/selectors'
import { LoaderOverlay } from 'components/Loader'
import {
  getPartnersInboundThunk,
  getPartnersThunk,
  partnersActions,
  partnersInboundActions,
} from './slice'
import {
  connectedAppsListSelector,
  connectedAppsListHasLoaded,
  connectedAppsListIsLoading,
} from './selectors'
import { PartnerShape } from 'app/types/partner'
import ConnectedAppsList from './components/ConnectedAppsList'
import Spinner from '../../components/Loader/Spinner'
import ReactHelmet from 'features/analytics/RenderHelmet'

const ConnectedAppsPage: FC = () => {
  const dispatch = useAppDispatch()
  const hasLoaded = useAppSelector<boolean>(connectedAppsListHasLoaded)
  const isLoading = useAppSelector<boolean>(connectedAppsListIsLoading)
  const connectedApps = useAppSelector<PartnerShape[]>(
    connectedAppsListSelector
  )
  const currentHomeId = useAppSelector(currentHomeIdSelector)

  useEffect(() => {
    if (currentHomeId) {
      dispatch(getPartnersThunk(currentHomeId))
      dispatch(getPartnersInboundThunk(currentHomeId))
    }

    return () => {
      dispatch(partnersActions.clear())
      dispatch(partnersInboundActions.clear())
    }
  }, [dispatch, currentHomeId])

  return (
    <>
      <ReactHelmet title={connectedAppsMessages.title.defaultMessage} />
      <ConnectedAppsHeader>
        <H1>
          <FormattedMessage {...connectedAppsMessages.title} />
        </H1>
      </ConnectedAppsHeader>

      <ConnectedAppsContentContainer>
        {isLoading && <LoaderOverlay backgroundColor="none" />}
        {hasLoaded ? (
          <ConnectedAppsList connectedApps={connectedApps} />
        ) : (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        )}
      </ConnectedAppsContentContainer>
    </>
  )
}

export default ConnectedAppsPage
