import { createSlice, combineReducers } from '@reduxjs/toolkit'
import path from 'lodash/fp/path'

import {
  HOMES_REDUCER_NAME,
  HOME_ADMIN_REDUCER_NAME,
  HOME_EDGE_ADMIN_REDUCER_NAME,
} from 'app/consts'
import { AppDispatch } from 'app/store'
import {
  getAuthorizedUser,
  getSelectedHomeId,
  storeSelectedHomeId,
} from 'app/services/LocalStorage'
import {
  makeFetchSlice,
  fetchEdgeDataThunk,
  fetchHomePortalDataThunk,
  fetchDataThunk,
} from 'app/redux-fetch'

import {
  getHomesByUserId,
  getHomeConfig,
  acceptDealerRequest,
  declineDealerRequest,
  getUber,
  ChangeDealerRequestShape,
  getHomeAdmin,
  getClaimCode,
  getHomeInfoById,
} from './api'
import {
  HOMES_LIST_REDUCER_NAME,
  CURR_HOME_ID_REDUCER_NAME,
  HOME_CONFIG_REDUCER_NAME,
  DEALER_REQUEST_ACCEPT_REDUCER_NAME,
  DEALER_REQUEST_DECLINE_REDUCER_NAME,
  REMOVE_DEALER_REQUEST_REDUCER_NAME,
  CLAIM_HOME_REQUEST_REDUCER_NAME,
} from './consts'
import { getUserRole, isAdmin } from '../auth/utils'
import { getUserInfo } from '../auth/account/api'
const uberListSlice = makeFetchSlice('getDealer')
export const uberListReducer = uberListSlice.reducer
const uberListActions = uberListSlice.actions

export const getUberThunk = (homeId: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      await fetchEdgeDataThunk({
        dispatch,
        actions: uberListActions,
        asyncFunc: () => getUber(homeId),
      })
    } catch (error) {
      console.log('Error fetching home requests', error)
    }
  }
}

const currHomeIdSlice = createSlice({
  initialState: '',
  name: HOMES_REDUCER_NAME,
  reducers: {
    setCurrentHomeId: (state, action) => action.payload,
  },
})
export const currHomeActions = currHomeIdSlice.actions

const homesListSlice = makeFetchSlice('homesList')
const homesListReducer = homesListSlice.reducer
const homesListActions = homesListSlice.actions

interface GetHomesActionParams {
  onSuccess?: (homes: any) => void
  onError: (err: any) => void
}

export const getHomesThunk = ({ onSuccess, onError }: GetHomesActionParams) => {
  return async (dispatch: AppDispatch) => {
    try {
      const storedUser = getAuthorizedUser()
      const storedSelectedHomeId = getSelectedHomeId()

      if (!storedUser) {
        throw new Error()
      }

      const homes = await fetchEdgeDataThunk({
        dispatch,
        actions: homesListActions,
        asyncFunc: () => getHomesByUserId(storedUser.id),
      })

      const adminHomes = homes.filter((home: any) =>
        isAdmin(getUserRole(home.permissions))
      )

      const selectedHomeExists =
        storedSelectedHomeId &&
        adminHomes.find((home: any) => home.id === storedSelectedHomeId)

      if (selectedHomeExists) {
        dispatch(currHomeActions.setCurrentHomeId(storedSelectedHomeId))
      } else if (adminHomes.length) {
        const firstHomeId = adminHomes[0].id
        storeSelectedHomeId(firstHomeId)
        dispatch(currHomeActions.setCurrentHomeId(firstHomeId))
      }

      onSuccess?.(adminHomes)
    } catch (err) {
      console.log(err)
      onError(err)
    }
  }
}

const homeConfigSlice = makeFetchSlice('getHomeConfig')
const homeConfigReducer = homeConfigSlice.reducer
const homeConfigActions = homeConfigSlice.actions

export const getHomeConfigThunk = (
  homeId: string,
  onError?: (err: any) => void
) => {
  return async (dispatch: AppDispatch) => {
    try {
      await fetchEdgeDataThunk({
        dispatch,
        actions: homeConfigActions,
        asyncFunc: () => getHomeConfig(homeId),
      })
    } catch (error) {
      console.log('Error fetching home config', error)
      if (onError) onError(error)
    }
  }
}

const acceptDealerRequestSlice = makeFetchSlice('acceptDealerRequest')
const acceptDealerRequestReducer = acceptDealerRequestSlice.reducer
const acceptDealerRequestActions = acceptDealerRequestSlice.actions

export const acceptDealerRequestThunk = (
  params: ChangeDealerRequestShape,
  onSuccess?: () => void
) => {
  return async (dispatch: AppDispatch) => {
    try {
      await fetchEdgeDataThunk({
        dispatch,
        actions: acceptDealerRequestActions,
        asyncFunc: () => acceptDealerRequest(params),
      })

      await dispatch(getUberThunk(params.homeId))
      console.log('Accepted a dealer request')
      onSuccess?.()
    } catch (error) {
      console.log('Error accepting dealer request', error)
    }
  }
}

const declineDealerRequestSlice = makeFetchSlice('declineDealerRequest')
const declineDealerRequestReducer = declineDealerRequestSlice.reducer
const declineDealerRequestActions = declineDealerRequestSlice.actions

export const declineDealerRequestThunk = (params: ChangeDealerRequestShape) => {
  return async (dispatch: AppDispatch) => {
    try {
      await fetchEdgeDataThunk({
        dispatch,
        actions: declineDealerRequestActions,
        asyncFunc: () => declineDealerRequest(params),
      })

      await dispatch(getUberThunk(params.homeId))
    } catch (error) {
      console.log('Error declining dealer request', error)
    }
  }
}

const removeDealerSlice = makeFetchSlice('removeDealer')
const removeDealerReducer = removeDealerSlice.reducer
const removeDealerActions = removeDealerSlice.actions

export const removeDealerThunk = (params: ChangeDealerRequestShape) => {
  return async (dispatch: AppDispatch) => {
    try {
      await fetchEdgeDataThunk({
        dispatch,
        actions: removeDealerActions,
        asyncFunc: () => declineDealerRequest(params),
      })
      await dispatch(getUberThunk(params.homeId))
    } catch (error) {
      console.log('Error removing dealer', error)
    }
  }
}

const getHomeAdminSlice = makeFetchSlice('getHomeAdmin')
const getHomeAdminReducer = getHomeAdminSlice.reducer
const getHomeAdminActions = getHomeAdminSlice.actions

export const getHomeAdminThunk = (homeId: string, userId: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const admin = await fetchHomePortalDataThunk({
        dispatch,
        actions: getHomeAdminActions,
        asyncFunc: () => getHomeAdmin(homeId, userId),
      })

      const edgeId = admin?.edgeId

      if (edgeId) {
        await fetchEdgeDataThunk({
          dispatch,
          actions: getHomeEdgeAdminActions,
          asyncFunc: () => getUserInfo(edgeId),
        })
      }
    } catch (error) {
      console.log('Error getting home admin', error)
    }
  }
}

const getHomeEdgeAdminSlice = makeFetchSlice('getHomeEdgeAdmin')
const getHomeEdgeAdminReducer = getHomeEdgeAdminSlice.reducer
const getHomeEdgeAdminActions = getHomeEdgeAdminSlice.actions

const claimHome = makeFetchSlice('claimHome')
export const claimHomeReducer = claimHome.reducer
export const claimHomeActions = claimHome.actions
export const getClaimCodeThunk = (homeId: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      await fetchDataThunk({
        dispatch,
        actions: claimHomeActions,
        asyncFunc: () => getClaimCode(homeId),
        transformResponse: path(['data', 'payload']),
      })
    } catch (error) {
      console.log('Error claiming home code', error)
    }
  }
}

const currentHomeInfoSlice = makeFetchSlice('currentHomeInfo')

export const currentHomeInfoReducer = currentHomeInfoSlice.reducer
export const currentHomeInfoActions = currentHomeInfoSlice.actions

export const currentHomeInfoThunk = (homeId: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const { response } = await fetchEdgeDataThunk({
        dispatch,
        actions: currentHomeInfoActions,
        asyncFunc: () => getHomeInfoById(homeId),
      })
      return response
    } catch (error) {
      console.log(error)
    }
  }
}

export const homesReducer = combineReducers({
  [CURR_HOME_ID_REDUCER_NAME]: currHomeIdSlice.reducer,
  [HOMES_LIST_REDUCER_NAME]: homesListReducer,
  [HOME_CONFIG_REDUCER_NAME]: homeConfigReducer,
  [DEALER_REQUEST_ACCEPT_REDUCER_NAME]: acceptDealerRequestReducer,
  [DEALER_REQUEST_DECLINE_REDUCER_NAME]: declineDealerRequestReducer,
  [REMOVE_DEALER_REQUEST_REDUCER_NAME]: removeDealerReducer,
  [HOME_ADMIN_REDUCER_NAME]: getHomeAdminReducer,
  [HOME_EDGE_ADMIN_REDUCER_NAME]: getHomeEdgeAdminReducer,
  [CLAIM_HOME_REQUEST_REDUCER_NAME]: claimHomeReducer,
})
