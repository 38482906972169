export const ENABLE_TWO_FA_REDUCER_NAME = 'enable2fa'
export const DISABLE_TWO_FA_REDUCER_NAME = 'disable2fa'
export const UBER_REDUCER_NAME = 'uberInfo'
export const HOMES_LIST_REDUCER_NAME = 'list'
export const HOME_CONFIG_REDUCER_NAME = 'config'
export const CURR_HOME_ID_REDUCER_NAME = 'currentHomeId'
export const CURR_HOME_INFO_REDUCER_NAME = 'currentHomeInfo'
export const DEALER_REQUEST_ACCEPT_REDUCER_NAME = 'acceptRequest'
export const DEALER_REQUEST_DECLINE_REDUCER_NAME = 'declineRequest'
export const REMOVE_DEALER_REQUEST_REDUCER_NAME = 'removeRequest'
export const CLAIM_HOME_REQUEST_REDUCER_NAME = 'claimHome'
