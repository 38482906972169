import { authorizedBillingFetch } from 'app/axios/billing'

export const getPaymentMethods = (userId: string) =>
  authorizedBillingFetch.get(`/user/${userId}/payment-methods`)

export const detachPaymentMethod = (id: string, userId: string) =>
  authorizedBillingFetch.delete(`/user/${userId}/payment-methods/${id}`)

export const setDefaultPaymentMethod = (id: string, userId: string) =>
  authorizedBillingFetch.post(
    `/user/${userId}/payment-methods/${id}/set-default`
  )

export const createSetupIntents = (userId: string) =>
  authorizedBillingFetch.get(`/user/${userId}/setupIntent?customerId=${userId}`)

export interface InvoiceParams {
  promocode: string
  priceId: string
}

export const getInvoice = (
  params: InvoiceParams,
  homeId: string,
  userId: string
) =>
  authorizedBillingFetch.post(
    `/home/${homeId}/user/${userId}/invoices/upcoming`,
    params
  )
