import styled from 'styled-components'

import { Link } from 'react-router-dom'

export const StyledIconLink = styled(Link)<{
  color: string
  $hoverColor: string
}>`
  display: inline-flex;
  vertical-align: top;
  color: ${(p) => p.color};

  :hover {
    color: ${(p) => p.$hoverColor};
  }
`

export const StyledIconLinkDiv = styled(StyledIconLink).attrs((p) => ({
  as: 'div',
  to: '#',
}))``
