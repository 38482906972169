import { FC, useCallback, useEffect, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory, generatePath } from 'react-router-dom'
import { RouteComponentProps } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'app/hooks'
import routes from 'app/routes'
import { H2 } from 'components/Typography'
import { BackButton } from 'components/Button'
import ReactHelmet from 'features/analytics/RenderHelmet'

import { currentHomeIdSelector } from 'features/homes/selectors'
import { getUserDetailsThunk } from '../slice'
import { userDetailsSelectors } from '../selectors'
import UserInfo from './components/UserInfo'
import UserPermissions from './components/Permissions'

import { BackBtnContainer, Container } from './styles'
import { messages } from './messages'
import { LoaderOverlay } from 'components/Loader'

interface MatchParams {
  homeId: string
  userId: string
}

interface UserDetailsProps extends RouteComponentProps<MatchParams> {}

const UserDetails: FC<UserDetailsProps> = ({
  match: {
    params: { homeId, userId },
  },
}) => {
  const dispatch = useAppDispatch()
  const history = useHistory()

  const currentHomeId = useAppSelector(currentHomeIdSelector)
  const isLoading = useAppSelector(userDetailsSelectors.isFetching)
  const userDetails = useAppSelector(userDetailsSelectors.data)

  const handleBackToUsersRoute = useCallback(() => {
    history.push(
      generatePath(routes.users, { homeId: currentHomeId || homeId, userId })
    )
  }, [currentHomeId, history, homeId, userId])

  const currHomeId = useRef<null | string>(null)

  useEffect(() => {
    if (currHomeId.current && currHomeId.current !== currentHomeId) {
      handleBackToUsersRoute()
    }
    currHomeId.current = currentHomeId
  }, [currentHomeId, handleBackToUsersRoute])

  useEffect(() => {
    dispatch(getUserDetailsThunk({ homeId, userId }))
    // TODO handle 404 error
  }, [dispatch, homeId, userId])

  return (
    <>
      <ReactHelmet title={messages.title.defaultMessage} />
      <div>
        <BackBtnContainer>
          <BackButton onClick={handleBackToUsersRoute}>
            <FormattedMessage {...messages.backBtn} />
          </BackButton>
        </BackBtnContainer>
        <H2>
          <FormattedMessage {...messages.title} />
        </H2>
      </div>

      {!isLoading && userDetails ? (
        <Container>
          <UserInfo {...{ userDetails, homeId }} />
          <UserPermissions
            {...{ homeId, userId }}
            permissions={userDetails.permissions}
          />
        </Container>
      ) : (
        <LoaderOverlay backgroundColor="transparent" />
      )}
    </>
  )
}

export default UserDetails
