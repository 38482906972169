import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Icon from '@mdi/react'
import { mdiMenu, mdiClose } from '@mdi/js'
import _throttle from 'lodash/throttle'

import { StyledMobileMenuBtn } from './styles'
import MobileMenuInner from './MobileMenuInner'

const MobileMenu: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)
  const prevWindowWidth = useRef(window.innerWidth)

  const toggleIsOpen = () => setIsOpen(!isOpen)

  const resizeEventListener: EventListener = useCallback(
    (e: Event) => {
      if (window.innerWidth !== prevWindowWidth.current && isOpen) {
        setIsOpen(false)
      }
      prevWindowWidth.current = window.innerWidth
    },
    [isOpen]
  )
  const throttledResizeListener = useMemo(
    () => _throttle(resizeEventListener, 200),
    [resizeEventListener]
  )

  useEffect(() => {
    window.addEventListener('resize', throttledResizeListener)
    return () => {
      window.removeEventListener('resize', throttledResizeListener)
    }
  }, [resizeEventListener, throttledResizeListener])

  return (
    <>
      <StyledMobileMenuBtn onClick={toggleIsOpen}>
        <Icon path={isOpen ? mdiClose : mdiMenu} size={1} color="#fff" />
      </StyledMobileMenuBtn>
      {isOpen && <MobileMenuInner handleClose={() => setIsOpen(false)} />}
    </>
  )
}

export default MobileMenu
