import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'

import { useAppDispatch } from 'app/hooks'
import Header from 'components/Header'
import { AuthorizedUser } from 'app/types'
import Footer from 'components/Footer'
import ROUTES from 'app/routes'

import {
  StyledHomesAside,
  StyledHomesContent,
  StyledHomesLayoutInner,
  StyledFullWidthContent,
} from './styles'
import AsideMenu from 'components/AsideMenu/AsideMenu'
import HomesBgImage from '../HomesBgImage/HomesBgImage'
import { currentHomeInfoThunk, getHomesThunk } from 'features/homes/homesSlice'
import { currUserSelector } from 'features/auth/login/selectors'
import { getUberThunk } from 'features/homes/homesSlice'
import { currentHomeIdSelector } from '../../selectors'
import { isAccountRoute } from 'app/utils/isAccountRoute'
import AsideAccountMenu from 'components/AccountMenu/AsideAccountMenu'
import AlertBannerDrawer from 'components/AlertBanner/AlertBannerDrawer'
import { isFullWidthPage } from 'app/utils/isFullWidthPage'
import { LoaderOverlay } from 'components/Loader'

const HomesCommonLayout: React.FC = (props) => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const user: AuthorizedUser = useSelector(currUserSelector)
  const currentHomeId = useSelector(currentHomeIdSelector)

  const { pathname } = useLocation()
  const isAccountUrl = isAccountRoute(pathname)
  const isFullWidthUrl = isFullWidthPage(pathname)

  const [isHomesLoading, setIsHomesLoading] = useState(true)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  const handleGetHomesSuccess = useCallback(
    (homes: any) => {
      if (!homes.length) {
        history.replace(ROUTES.noHomes)
      }
      setIsHomesLoading(false)
    },
    [history]
  )

  const handleGetHomesError = useCallback(() => {
    setIsHomesLoading(false)
    console.log("Failed to fetch user's homes")
  }, [])

  useEffect(() => {
    dispatch(
      getHomesThunk({
        onSuccess: handleGetHomesSuccess,
        onError: handleGetHomesError,
      })
    )
  }, [
    dispatch,
    user.id,
    currentHomeId,
    history,
    handleGetHomesSuccess,
    handleGetHomesError,
  ])

  // TODO update to get a single price, in scrope of plan table task
  // useEffect(() => {
  // currentHomeId && dispatch(subscriptionsPricesThunk(currentHomeId))
  // }, [dispatch, currentHomeId, user.id])

  useEffect(() => {
    currentHomeId && dispatch(getUberThunk(currentHomeId))
  }, [dispatch, currentHomeId])

  useEffect(() => {
    currentHomeId && dispatch(currentHomeInfoThunk(currentHomeId))
  }, [dispatch, currentHomeId, user.id])

  return (
    <>
      {isHomesLoading ? (
        <LoaderOverlay isFullScreen />
      ) : (
        <>
          <Header />
          <AlertBannerDrawer />
          <HomesBgImage noImage={isAccountUrl || isFullWidthUrl} />
          {isFullWidthUrl ? (
            <StyledHomesLayoutInner>
              <StyledFullWidthContent>{props.children}</StyledFullWidthContent>
            </StyledHomesLayoutInner>
          ) : (
            <StyledHomesLayoutInner>
              <StyledHomesAside>
                {isAccountUrl ? <AsideAccountMenu /> : <AsideMenu />}
              </StyledHomesAside>
              <StyledHomesContent>{props.children}</StyledHomesContent>
            </StyledHomesLayoutInner>
          )}
          <Footer />
        </>
      )}
    </>
  )
}

export default HomesCommonLayout
