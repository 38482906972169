import React from 'react'
import { mdiLoading } from '@mdi/js'
import Icon from '@mdi/react'
import { IconProps } from '@mdi/react/dist/IconProps'
import theme from 'theme_'

interface ISpinnerIconProps extends Omit<IconProps, 'path'> {
  path?: string
}

const Spinner: React.FC<ISpinnerIconProps> = (props) => {
  return (
    <Icon
      path={mdiLoading}
      color={theme.colors.dimmedGold}
      size={3}
      horizontal
      vertical
      spin={-1}
      {...props}
    />
  )
}

export default Spinner
