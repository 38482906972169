import { FC, useEffect, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Formik, Form, FormikProps } from 'formik'
import { compose, filter, keys, prop } from 'lodash/fp'
import { useSelector } from 'react-redux'
import { Prompt } from 'react-router-dom'

import { useAppDispatch } from 'app/hooks'
import { CommonButton } from 'components/Button'
import { commonMessages } from 'app/i18n/messages'
import { UpdatePermissionsParams } from 'features/users/api'
import { updateUserPermissionsThunk } from 'features/users/slice'
import { getHomeConfigThunk } from 'features/homes/homesSlice'
import { homeConfigSelectors } from 'features/homes/selectors'
import { LoaderOverlay, Spinner } from 'components/Loader'

import HomePermissions from './HomePermissions'
import RoomPermissions from './RoomPermissions'

import { PermissionsForm } from './types'
import { getPermissionsFormValues } from './utils'
import { ButtonsFooter } from 'components/UserItems/ButtonsFooter'
import { SpinnerContainer } from 'features/users/styles'

const Permissions: FC<UpdatePermissionsParams> = ({
  homeId,
  userId,
  permissions,
}) => {
  const dispatch = useAppDispatch()
  const { formatMessage } = useIntl()
  const hasHomeConfigLoaded = useSelector(homeConfigSelectors.isFinished)
  const homeConfig = useSelector(homeConfigSelectors.data)

  const initialValues = useMemo(() => {
    return getPermissionsFormValues(homeConfig, permissions)
  }, [homeConfig, permissions])

  useEffect(() => {
    dispatch(getHomeConfigThunk(homeId))
  }, [dispatch, homeId])

  if (!hasHomeConfigLoaded)
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    )

  return (
    <Formik
      {...{ initialValues }}
      enableReinitialize
      onSubmit={async (
        { homeRooms, ...values }: PermissionsForm,
        formikBag
      ) => {
        const zoneBlacklist = compose(
          filter((name) => !prop(name, homeRooms)),
          keys
        )(homeRooms)
        const updPermissions = await dispatch(
          updateUserPermissionsThunk({
            homeId,
            userId,
            permissions: {
              ...values,
              zoneBlacklist,
              serviceBlacklist: values.admin
                ? []
                : permissions.serviceBlacklist,
            },
          })
        )

        if (updPermissions) {
          formikBag.resetForm({
            values: getPermissionsFormValues(homeConfig, updPermissions),
          })
        }
      }}
    >
      {(formikProps: FormikProps<PermissionsForm>) => (
        <>
          <Prompt
            when={formikProps.dirty}
            message={formatMessage(commonMessages.promptUnsavedForm)}
          />
          <Form>
            <HomePermissions {...{ formikProps }} />
            <RoomPermissions {...{ formikProps }} />

            {formikProps.dirty && (
              <ButtonsFooter>
                <CommonButton
                  variant="goldenOutlined"
                  onClick={(ev) => {
                    ev.preventDefault()
                    ev.stopPropagation()
                    formikProps.resetForm()
                  }}
                >
                  <FormattedMessage {...commonMessages.cancel} />
                </CommonButton>
                <CommonButton variant="goldenFilled" type="submit">
                  <FormattedMessage {...commonMessages.save} />
                </CommonButton>
              </ButtonsFooter>
            )}

            {formikProps.isSubmitting && <LoaderOverlay />}
          </Form>
        </>
      )}
    </Formik>
  )
}

export default Permissions
