import { defineMessages } from 'react-intl'

export const asideMenuMessages = defineMessages({
  homeStatus: {
    id: 'asideMenu.homeStatus',
    description: 'Text on menu link to "My Savant Home" page',
    defaultMessage: 'My Savant Home',
  },
  users: {
    id: 'asideMenu.users',
    description: 'Text on menu link to "Users" page',
    defaultMessage: 'Users',
  },
  discoverMore: {
    id: 'asideMenu.discoverMore',
    description: 'Text on menu link to "Discover More" page',
    defaultMessage: 'Discover More',
  },
  tutorials: {
    id: 'asideMenu.tutorials',
    description: 'Text on menu link to "Tutorials" page',
    defaultMessage: 'Tutorials',
  },
  myDealer: {
    id: 'asideMenu.myDealer',
    description: 'Text on menu link to "My Savant Dealer" page',
    defaultMessage: 'My Savant Dealer',
  },
  subscription: {
    id: 'asideMenu.subscription',
    description: 'Text on menu link to "Subscription Plan" page',
    defaultMessage: 'Subscriptions',
  },
  connectedApps: {
    id: 'asideMenu.connectedApps',
    description: 'Text on menu link to "connected/3rd party apps" page',
    defaultMessage: 'Connected Apps',
  },
  payments: {
    id: 'asideMenu.payments',
    description: 'Text on menu link to "Payment methods" page',
    defaultMessage: 'Payment methods',
  },
  cameraRecordings: {
    id: 'asideMenu.cameraRecordings',
    description: 'Text on menu link to "Camera Recordings" page',
    defaultMessage: 'Camera Recordings',
  },
  energy: {
    id: 'asideMenu.energy',
    description: 'Text on menu link to "Energy" page',
    defaultMessage: 'Energy',
  },
  activityLog: {
    id: 'asideMenu.activityLog',
    description: 'Text on menu link to "Activity Log" page',
    defaultMessage: 'Activity Log',
  },
  homePhotos: {
    id: 'asideMenu.homePhotos',
    description: 'Text on menu link to "Home Photos" page',
    defaultMessage: 'Home Photos',
  },
  getHelp: {
    id: 'asideMenu.getHelp',
    description: 'Text on menu link to "GetHelp" page',
    defaultMessage: 'Help & Feedback',
  },
})
