import styled from 'styled-components'
import theme from 'theme_'

export const AccountToggleContainer = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .react-toggle {
    margin-left: ${theme.spacing.sm};
  }
`
