import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { H1 } from 'components/Typography'
import { Spinner } from 'components/Loader'
import { SpinnerContainer } from 'features/users/styles'
import ReactHelmet from '../analytics/RenderHelmet'
import { GetHelpMessages } from './messages'
import { Column, GetHelpHeader, HelpContent } from './styles'
import { dealerSelector, uberSelectors } from 'features/homes/selectors'
import ContactDealer from 'features/homes/components/MyDealer/ContactDealer'
import { useAppSelector } from 'app/hooks'
import FeedbackForm from './components/FeedbackForm'
import FindDealerHelp from 'features/homes/components/MyDealer/FindDealerHelp'
import { currentHomeIdSelector } from 'features/homes/selectors'
import HelpForm from './components/HelpForm'

const GetHelpPage: FC = () => {
  const uberIsFinished = useAppSelector(uberSelectors.isFinished)
  const dealer = useSelector(dealerSelector)
  const currentHomeId = useSelector(currentHomeIdSelector)

  return (
    <>
      <ReactHelmet title={GetHelpMessages.title.defaultMessage} />
      <GetHelpHeader>
        <H1>
          <FormattedMessage {...GetHelpMessages.title} />
        </H1>
      </GetHelpHeader>

      <HelpContent>
        {uberIsFinished ? (
          <>
            {dealer && <ContactDealer />}
            {!dealer && <FindDealerHelp homeId={currentHomeId} />}
          </>
        ) : (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        )}
        <Column>
          <FeedbackForm
            dealerId={dealer?.dealerInfo.id}
            homeId={currentHomeId}
          />
          <HelpForm dealerId={dealer?.dealerInfo.id} homeId={currentHomeId} />
        </Column>
      </HelpContent>
    </>
  )
}

export default GetHelpPage
