import React from 'react'
import { Helmet } from 'react-helmet-async'

import { DEFAULT_PAGE_TITLE } from 'app/consts'

interface ReactHelmetProps {
  title?: string
}

const ReactHelmet: React.FC<ReactHelmetProps> = (props) => {
  return (
    <Helmet>
      {props.title && (
        <title>
          {DEFAULT_PAGE_TITLE} {props.title}
        </title>
      )}
    </Helmet>
  )
}

export default ReactHelmet
