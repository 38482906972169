// this file should be in /auth/reducer.js

export const AUTH_REDUCER_NAME = 'auth'
export const LOGIN_REDUCER_NAME = 'login'
export const PASS_INIT_RESET_REDUCER_NAME = 'passInitReset'
export const USER_REDUCER_NAME = 'user'
export const ACTIVE_USER_INFO_REDUCER_NAME = 'activeUserInfo'
export const UPDATE_USER_REDUCER_NAME = 'updateUser'
export const LOGIN2FA_REDUCER_NAME = 'login2fa'
export const PASS_RESET_CONFIRM_REDUCER_NAME = 'passResetConfirm'
export const CLIENT_REDUCER_NAME = 'client'

export enum UserRole {
  ADMIN = 'ADMIN',
  HOUSEHOLD = 'HOUSEHOLD',
  GUEST = 'GUEST',
  CUSTOM = 'CUSTOM',
}
