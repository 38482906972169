import styled from 'styled-components'

import theme from 'theme_'
const { spacing, overlay, devices, colors } = theme

export const BluredBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spacing.sm} ${spacing.base} ${spacing.lg};
  color: ${colors.dolphinGrey};
  border-radius: ${spacing.xs};
  background-color: ${overlay.cardColor2};
  backdrop-filter: ${overlay.SBlur};
  animation: ${theme.animations.fadeIn} 0.3s ease-out;

  @media ${devices.tablet} {
    padding: ${spacing.md} ${spacing.md} ${spacing.lg};
  }
`
