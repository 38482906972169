import { useCallback, useState } from 'react'
import { mdiContentCopy } from '@mdi/js'
import { useIntl } from 'react-intl'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'

import { commonMessages } from 'app/i18n'
import { Container, CopyIconBtn, StyledCopyIcon, Label } from './styles'

interface CopyFieldProps {
  text: string
}

function CopyField({ text }: CopyFieldProps) {
  const { formatMessage } = useIntl()

  const [copied, setCopied] = useState<boolean>(false)

  const handleClick = useCallback(async () => {
    await navigator.clipboard.writeText(text)
    setCopied(true)
    setTimeout(() => setCopied(false), 1000)
  }, [text])

  return (
    <Container>
      <Label>{text}</Label>
      <Tippy content={formatMessage(commonMessages.copy)}>
        <CopyIconBtn onClick={handleClick}>
          <StyledCopyIcon path={mdiContentCopy} size={1} $copied={copied} />
        </CopyIconBtn>
      </Tippy>
    </Container>
  )
}

export default CopyField
