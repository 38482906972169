import ReactHelmet from '../analytics/RenderHelmet'
import { FormattedMessage, useIntl } from 'react-intl'

import { H1 } from 'components/Typography'
import {
  GuidesImg,
  GuidesImgWrap,
  GuidesItemTitle,
  GuidesList,
  GuidesListItem,
} from './styles'
import { discoverMoreMessages } from './messages'
import { getBrochuresAndGuides } from './brochuresAndGuides'
import { useMemo } from 'react'

function DiscoverMorePage() {
  const { formatMessage } = useIntl()

  const brochuresAndGuides = useMemo(
    () => getBrochuresAndGuides(formatMessage),
    [formatMessage]
  )

  return (
    <>
      <ReactHelmet title={discoverMoreMessages.title.defaultMessage} />
      <H1>
        <FormattedMessage {...discoverMoreMessages.title} />
      </H1>
      <GuidesList>
        {brochuresAndGuides.map((b) => (
          <GuidesListItem as="a" href={b.url} target="_blank" key={b.title}>
            <GuidesImgWrap>
              <GuidesImg src={b.imgSrc} alt={b.title} />
            </GuidesImgWrap>
            <GuidesItemTitle>{b.title}</GuidesItemTitle>
          </GuidesListItem>
        ))}
      </GuidesList>
    </>
  )
}

export default DiscoverMorePage
