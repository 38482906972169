import { defineMessages } from 'react-intl'

export const connectedAppsMessages = defineMessages({
  title: {
    id: 'connectedApps.title',
    description: 'Connected apps page title',
    defaultMessage: 'Connected apps',
  },
  active: {
    id: 'connectedApps.active',
    description: 'Label for connected apps active status',
    defaultMessage: 'Active',
  },

  inactive: {
    id: 'connectedApps.inactive',
    description: 'Label for connected apps inactive status',
    defaultMessage: 'Inactive',
  },
  buttonTextRevoke: {
    id: 'connectedApps.buttonTextRevoke',
    description: 'Button text for connected apps active status',
    defaultMessage: 'Revoke',
  },

  buttonTextActivate: {
    id: 'connectedApps.buttonTextActivate',
    description: 'Button text for connected apps inactive status',
    defaultMessage: 'Activate',
  },
})

export const homeSelectorMessages = defineMessages({
  selectHome: {
    id: 'homeSelector.selectHome',
    description: 'Header for admin home selector',
    defaultMessage: 'Select Home',
  },
  inputPlaceholder: {
    id: 'homeSelector.searchHome',
    description: 'Input placeholder for home search',
    defaultMessage: 'Search Home...',
  },
  openHome: {
    id: 'homeSelector.openHome',
    description: 'Text for button to select home',
    defaultMessage: 'Connect',
  },
})
