import { whatsNewMessages } from './messages'

export const getBlocks = (formatMessage: any) => [
  {
    imgSrc: 'https://cdn.savant.com/image/PowerSHM.png',
    title: formatMessage(whatsNewMessages.savantPower),
    subTitle: formatMessage(whatsNewMessages.savantPowerSubTitle),
    internalRoute: '',
    externalRoute: 'https://www.savant.com/power/pro-home/',
  },
  {
    imgSrc: 'https://cdn.savant.com/image/IntercomPhoneHome.png',
    title: formatMessage(whatsNewMessages.intercom),
    subTitle: formatMessage(whatsNewMessages.intercomSubTitle),
    externalRoute: 'https://savant.com/interfaces',
  },
]
