import styled from 'styled-components'
import Icon from '@mdi/react'

import { StyledHeaderWrapper } from 'components/Header/styles'
import theme from 'theme_'

export const StyledLoginHeader = styled(StyledHeaderWrapper)`
  justify-content: space-between;
  padding: 0 ${theme.spacing.md};
`

export const StyledLanguageWrapper = styled.div`
  width: 125px;
  flex: none;
  margin-left: ${theme.spacing.xs};
`

export const StyledHelpButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0;
  margin-left: ${theme.spacing.tiny};
  font-weight: 700;
  font-size: 14px;
  color: ${theme.colors.duneWarmGrey};
  border: none;
  background: transparent;
  cursor: pointer;

  :hover {
    color: ${theme.colors.horizonOrange};
  }

  @media ${theme.devices.tablet} {
    margin-left: ${theme.spacing.base};
  }
`
export const StyledHelpIcon = styled(Icon)`
  color: ${theme.colors.duneWarmGrey};
  margin-right: ${theme.spacing.tiny};

  @media ${theme.devices.tablet} {
    margin-right: ${theme.spacing.base};
  }
`
