import styled from 'styled-components'
import theme from '../../theme_'

export const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${theme.spacing.md};
`

export const ConnectedAppsHeader = styled.header`
  display: flex;
  justify-content: space-between;
  H1 {
    line-height: 18px;
    font-size: 18px;
    @media ${theme.devices.tablet} {
      line-height: 40px;
      font-size: 32px;
    }
  }
`

export const ConnectedAppsContentContainer = styled.div`
  position: relative;
  z-index: 1;
`
