import React from 'react'
import { StyledAsideMenu } from './styles'
import HomeSelector from './HomeSelector'

import AsideMenuLinks from './AsideMenuLinks'

const AsideMenu: React.FC = () => {
  return (
    <StyledAsideMenu>
      <HomeSelector />
      <AsideMenuLinks />
    </StyledAsideMenu>
  )
}

export default AsideMenu
