import { MyAccountFormValues } from '../../types'

const emptyMyAccountValues: MyAccountFormValues = {
  firstName: '',
  lastName: '',
  emailNewsletter: true,
}

export const getMyAccountFormValues = (userObj: any): MyAccountFormValues => ({
  ...emptyMyAccountValues,
  firstName: userObj.firstName,
  lastName: userObj.lastName,
  emailNewsletter: userObj.emailNewsletter,
})
