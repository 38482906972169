import { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { FormikProps } from 'formik'

import { messages } from '../../../messages'
import Permission from '../Permission'
import PermissionsBlock from '../PermissionsBlock'
import { PermissionsForm } from '../types'

interface HomePermissionsProps {
  formikProps: FormikProps<PermissionsForm>
}

const HomePermissions: FC<HomePermissionsProps> = ({ formikProps }) => {
  return (
    <PermissionsBlock
      title={<FormattedMessage {...messages.homePermissions} />}
    >
      <>
        <Permission
          title={<FormattedMessage {...messages.homeManagement} />}
          name="admin"
          type="toggle"
        />
        <Permission
          title={<FormattedMessage {...messages.accessRemotely} />}
          name="remote"
          // prevent changing permission if admin: true
          disabled={formikProps.values.admin}
          type="toggle"
        />
        <Permission
          title={<FormattedMessage {...messages.notifications} />}
          name="notifications"
          // prevent changing permission if admin: true
          disabled={formikProps.values.admin}
          type="toggle"
        />
      </>
    </PermissionsBlock>
  )
}

export default HomePermissions
