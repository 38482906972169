import { EDGE_API_URL } from '../consts'
import { makeEdgeHeaders, makeAuthorizedEdgeHeaders } from './headers'
import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios'

export const unauthorizedEdgeFetch = axios.create({
  baseURL: EDGE_API_URL,
  headers: makeEdgeHeaders(),
})

export interface EdgeAuthorizeParams {
  token: string
  secretKey: string
  onUnauthorized: () => void
}

export let authorizedEdgeFetch: AxiosInstance = unauthorizedEdgeFetch

export const authorizeEdgeAxios = ({
  token,
  secretKey,
  onUnauthorized,
}: EdgeAuthorizeParams): AxiosInstance => {
  authorizedEdgeFetch = axios.create({
    baseURL: EDGE_API_URL,
    headers: {
      ...makeAuthorizedEdgeHeaders(token, secretKey),
    },
  })

  authorizedEdgeFetch.interceptors.response.use(
    (response: AxiosResponse) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response
    },
    (error: AxiosError) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      if (error.response?.status === 401) {
        onUnauthorized()
      }

      return Promise.reject(error)
    }
  )

  return authorizedEdgeFetch
}

export const unauthorizeAxios = (): AxiosInstance => {
  authorizedEdgeFetch = unauthorizedEdgeFetch
  return authorizedEdgeFetch
}
