import { UserShape, UserPermissions } from 'app/types/user'

import { UserRole } from './consts'
import { authMessages } from './messages'

export const getUserRole = ({
  admin,
  remote,
  notifications,
}: UserPermissions): UserRole | null => {
  if (admin && remote && notifications) {
    return UserRole.ADMIN
  }

  if (remote && notifications) {
    return UserRole.HOUSEHOLD
  }

  if (!admin && !remote && !notifications) {
    return UserRole.GUEST
  }

  // admin=false, remote=true, notifications=false
  // and
  // admin=false, remote=false, notifications=true
  // don't have a named role, so they call it custom
  return null
}

export const getUserRoleSort = ({
  admin,
  remote,
  notifications,
}: UserPermissions): number => {
  if (admin && remote && notifications) {
    return 0
  }

  if (remote && notifications) {
    return 1
  }

  if (!admin && !remote && !notifications) {
    return 2
  }

  return 3
}

export const getDefaultPermissionsByRole = (
  role: UserRole
): UserPermissions => {
  if (isAdmin(role)) {
    return {
      admin: true,
      remote: true,
      notifications: true,
      zoneBlacklist: [],
      serviceBlacklist: [],
    }
  }

  if (isHousehold(role)) {
    return {
      admin: false,
      remote: true,
      notifications: true,
      zoneBlacklist: [],
      serviceBlacklist: [],
    }
  }

  return {
    admin: false,
    remote: false,
    notifications: false,
    zoneBlacklist: [],
    serviceBlacklist: [],
  }
}

const USER_ROLE_NAME = {
  [UserRole.ADMIN]: authMessages.userRoleAdmin,
  [UserRole.HOUSEHOLD]: authMessages.userRoleHousehold,
  [UserRole.GUEST]: authMessages.userRoleGuest,
  [UserRole.CUSTOM]: authMessages.userRoleCustom,
}

export const getUserRoleLabelByRole = (role: UserRole | null) => {
  if (!role) {
    return USER_ROLE_NAME[UserRole.CUSTOM]
  }

  if (USER_ROLE_NAME[role]) {
    return USER_ROLE_NAME[role]
  }

  return USER_ROLE_NAME[UserRole.CUSTOM]
}

export const getUserRoleLabel = (user: UserShape) => {
  const role = getUserRole(user.permissions)
  return getUserRoleLabelByRole(role)
}

export const isAdmin = (role: UserRole | null) => role === UserRole.ADMIN
export const isHousehold = (role: UserRole | null) =>
  role === UserRole.HOUSEHOLD
