import React from 'react'
import Icon from '@mdi/react'
import { mdiVideo, mdiClose, mdiCloudDownloadOutline } from '@mdi/js'
import { useIntl } from 'react-intl'

import {
  StyledClipCameraName,
  StyledClipDate,
  StyledClipHeader,
  StyledClipInfoWrapper,
  StyledDetectionIconImg,
  StyledDownloadLink,
  StyledHeaderCloseBtn,
  StyledHeaderCloseIcon,
  StyledVideoIcon,
} from './styles'
import { ClipShape } from 'app/types/clip'
import { clipHeaderMessages } from './messages'
import IntlFormattedDate from 'components/i18n/IntlFormattedDate'
import {
  CAM_DEFAULT_ICON,
  CAM_DOORBELL_ICON,
  CAM_LIVE_ICON,
  CAM_MOTION_ICON,
  CAM_NOISE_ICON,
  CAM_TAMPER_ICON,
} from 'features/cameraRecordings/consts'

const getDetectonIcon = (detectionMethod: string) => {
  switch (detectionMethod) {
    case 'EntryKeyEvent':
    case 'EntryRing':
      return CAM_DOORBELL_ICON
    case 'EntryMotionEvent':
      return CAM_MOTION_ICON
    case 'EntryNoiseEvent':
      return CAM_NOISE_ICON
    case 'EntryTamperEvent':
    case 'TamperSwitchActivated':
      return CAM_TAMPER_ICON
    case 'EntryLiveEvent':
      return CAM_LIVE_ICON
    default:
      return CAM_DEFAULT_ICON
  }
}

interface ClipHeaderProps {
  clipInfo: ClipShape | null
  handleClose?: () => void
  isCompactView?: boolean
}

const ClipHeader: React.FC<ClipHeaderProps> = ({
  clipInfo,
  handleClose,
  isCompactView,
}) => {
  const { formatMessage } = useIntl()

  return (
    <StyledClipHeader
      isCompactView={isCompactView}
      className={isCompactView ? 'isCompactView' : ''}
    >
      {clipInfo?.localEventId && (
        <StyledDetectionIconImg src={getDetectonIcon(clipInfo.localEventId)} />
      )}
      <StyledClipInfoWrapper>
        <StyledClipCameraName title={clipInfo?.cameraId}>
          <StyledVideoIcon path={mdiVideo} />
          {clipInfo?.cameraName}
        </StyledClipCameraName>
        <StyledClipDate>
          <IntlFormattedDate date={clipInfo?.startTime} withTime />
        </StyledClipDate>
      </StyledClipInfoWrapper>
      {isCompactView && (
        <StyledDownloadLink
          as="a"
          title={formatMessage(clipHeaderMessages.download)}
          href={clipInfo?.downloadUrl}
          download
        >
          <Icon path={mdiCloudDownloadOutline} size={1} />
        </StyledDownloadLink>
        // Uncomment below when there will be more than 1 action
        // <StyledExpandableWrapper>
        //   <OutsideClickHandler onOutsideClick={handleCloseExpandedMenu}>
        //     <StyledExpandableBlock>
        //       <StyledExpandableBtn
        //         onClick={() => setIsMenuExpanded(!isMenuExpanded)}
        //       >
        //         <Icon path={mdiDotsVertical} />
        //       </StyledExpandableBtn>
        //       {isMenuExpanded && (
        //         <StyledExpandedMenu>
        //           <StyledExpandedItem
        //             as="a"
        //             href={clipInfo?.downloadUrl}
        //             download
        //           >
        //             {formatMessage(clipHeaderMessages.download)}
        //           </StyledExpandedItem>
        //         </StyledExpandedMenu>
        //       )}
        //     </StyledExpandableBlock>
        //   </OutsideClickHandler>
        // </StyledExpandableWrapper>
      )}
      {handleClose && (
        <StyledHeaderCloseBtn onClick={handleClose}>
          <StyledHeaderCloseIcon path={mdiClose} size={1} />
        </StyledHeaderCloseBtn>
      )}
    </StyledClipHeader>
  )
}

export default ClipHeader
