import { defineMessages } from 'react-intl'

export const HelpFormMessages = defineMessages({
  helpTitle: {
    id: 'getHelp.helpTitle',
    description: 'Get Help page help block title',
    defaultMessage: 'Need More Help?',
  },
  helpText: {
    id: 'getHelp.helpText',
    description: 'Get Help page help block text',
    defaultMessage: 'Contact Savant for any additional help with your system.',
  },
  helpButton: {
    id: 'getHelp.helpButton',
    description: 'Get Help page help block button',
    defaultMessage: 'Contact Savant',
  },
  send: {
    id: 'getHelp.send',
    description: 'Get Help page send block button',
    defaultMessage: 'Send',
  },
  next: {
    id: 'getHelp.next',
    description: 'Get Help page next block button',
    defaultMessage: 'Next',
  },
  back: {
    id: 'getHelp.back',
    description: 'Get Help page back block button',
    defaultMessage: 'Back',
  },
  message: {
    id: 'getHelp.message',
    description: 'Get Help message form placeholder',
    defaultMessage: 'Message',
  },
  phone: {
    id: 'getHelp.phone',
    description: 'Phone number field',
    defaultMessage: 'Enter Phone Number',
  },
  helpWith: {
    id: 'getHelp.helpWith',
    description: 'Form selector',
    defaultMessage: 'I need help with',
  },
  selectCategory: {
    id: 'getHelp.selectCategory',
    description: 'Form selector',
    defaultMessage: 'Select a category',
  },
  contactMethod: {
    id: 'getHelp.contactMethod',
    description: 'Form selector',
    defaultMessage: 'Preferred contact method',
  },
  country: {
    id: 'getHelp.country',
    description: 'Form selector',
    defaultMessage: 'Country',
  },
  zipCode: {
    id: 'getHelp.zipCode',
    description: 'Form selector',
    defaultMessage: 'Zip Code',
  },
  modalTitle: {
    id: 'getHelp.modalTitle',
    description: 'Help form modal title',
    defaultMessage: 'Contact Savant',
  },
  modalSuccess: {
    id: 'getHelp.modalSuccess',
    description: 'Help form modal success title',
    defaultMessage: 'Success',
  },
  savantInTouch: {
    id: 'getHelp.savantInTouch',
    description: 'Help form modal content',
    defaultMessage: 'Savant will be in touch with you shortly.',
  },
  done: {
    id: 'getHelp.done',
    description: 'Help form modal done button',
    defaultMessage: 'Done',
  },
  helpUpgrade: {
    id: 'getHelp.helpUpgrade',
    description: 'Help select option',
    defaultMessage: 'Upgrading My Savant System',
  },
  helpIssue: {
    id: 'getHelp.helpIssue',
    description: 'Help select option',
    defaultMessage: 'An Issue with my Savant System',
  },
  helpAdding: {
    id: 'getHelp.helpAdding',
    description: 'Help select option',
    defaultMessage: 'Adding devices to my Savant System',
  },
  helpFinding: {
    id: 'getHelp.helpFinding',
    description: 'Help select option',
    defaultMessage: 'Finding an authorized Savant Dealer',
  },
  helpOther: {
    id: 'getHelp.helpOther',
    description: 'Help select option',
    defaultMessage: 'Other',
  },
  categoryPower: {
    id: 'getHelp.categoryPower',
    description: 'Category select option',
    defaultMessage: 'Smart Power',
  },
  categoryRemote: {
    id: 'getHelp.categoryRemote',
    description: 'Category select option',
    defaultMessage: 'Savant App & Remote',
  },
  categoryLighting: {
    id: 'getHelp.categoryLighting',
    description: 'Category select option',
    defaultMessage: 'Lighting & Shades',
  },
  categoryClimate: {
    id: 'getHelp.categoryClimate',
    description: 'Category select option',
    defaultMessage: 'HVAC & Climate',
  },
  categoryMusic: {
    id: 'getHelp.categoryMusic',
    description: 'Category select option',
    defaultMessage: 'Entertainment & Music',
  },
  categoryCameras: {
    id: 'getHelp.categoryCameras',
    description: 'Category select option',
    defaultMessage: 'Cameras & Security',
  },
  contactPhone: {
    id: 'getHelp.contactPhone',
    description: 'Contact select option',
    defaultMessage: 'Phone',
  },
  contactEmail: {
    id: 'getHelp.contactEmail',
    description: 'Contact select option',
    defaultMessage: 'Email',
  },
})
