import styled from 'styled-components'
import theme from 'theme_'

export const OldestFirstFilterButton = styled.button`
  width: ${theme.spacing.lg};
  height: ${theme.spacing.lg};
  margin-right: ${theme.spacing.s};
  border-radius: ${theme.spacing.xs};
  background-color: transparent;
  border: none;
  padding: ${theme.spacing.tiny};
  transition: all 0.2s ease;
  color: ${theme.colors.beachGrey};
  :hover {
    background-color: ${theme.colors.inputFillFocus};
  }
`
