import { useIntl } from 'react-intl'
import { HelpFormMessages } from './messages'

type FormatMessageType = ReturnType<typeof useIntl>['formatMessage']

export const HELP_OPTIONS = (format: FormatMessageType) => [
  {
    label: format(HelpFormMessages.helpUpgrade),
    value: 'Upgrading My Savant System',
  },
  {
    label: format(HelpFormMessages.helpIssue),
    value: 'An Issue with my Savant System',
  },
  {
    label: format(HelpFormMessages.helpAdding),
    value: 'Adding devices to my Savant System',
  },
  {
    label: format(HelpFormMessages.helpFinding),
    value: 'Finding an authorized Savant Dealer',
  },
  {
    label: format(HelpFormMessages.helpOther),
    value: 'Other',
  },
]

export const CATEGORY_OPTIONS = (format: FormatMessageType) => [
  {
    label: format(HelpFormMessages.categoryPower),
    value: 'Smart Power',
  },
  {
    label: format(HelpFormMessages.categoryRemote),
    value: 'Savant App & Remote',
  },
  {
    label: format(HelpFormMessages.categoryLighting),
    value: 'Lighting & Shades',
  },
  {
    label: format(HelpFormMessages.categoryClimate),
    value: 'HVAC & Climate',
  },
  {
    label: format(HelpFormMessages.categoryMusic),
    value: 'Entertainment & Music',
  },
  {
    label: format(HelpFormMessages.categoryCameras),
    value: 'Cameras & Security',
  },
  {
    label: format(HelpFormMessages.helpOther),
    value: 'Other',
  },
]

export const CONTACT_OPTIONS = (format: FormatMessageType) => [
  {
    label: format(HelpFormMessages.contactEmail),
    value: 'Email',
  },
  {
    label: format(HelpFormMessages.contactPhone),
    value: 'Phone',
  },
]

export const COUNTRY_OPTIONS = [
  'Albania',
  'Algeria',
  'Andorra',
  'Angola',
  'Antigua & Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Austraila',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Barbados',
  'Belarus',
  'Belgium',
  'Bermuda',
  'Bolivia',
  'Bosnia & Herzegovina',
  'Botswana',
  'Brazil',
  'British Virgin Islands',
  'Cameroon',
  'Canada',
  'Cayman Islands',
  'Central African Republic',
  'Chile',
  'China',
  'Colombia',
  'Costa Rica',
  'Croatia',
  'Curacao',
  'Cyprus',
  'Czech Republic',
  'Democratic Republic of the Congo',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Finland',
  'France',
  'Gabon',
  'Georgia',
  'Germany',
  'Ghana',
  'Greece',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Ireland',
  'Israel',
  'Italy',
  'Jamaica',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kuwait',
  'Lebanon',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macao',
  'Macedonia',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Mali',
  'Malta',
  'Mauritius',
  'Mexico',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Morocco',
  'Netherlands',
  'New Zealand',
  'Nigeria',
  'Norway',
  'Pakistan',
  'Panama',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Romania',
  'Russia',
  'Saint Lucia',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Singapore',
  'Sint Maarten (Dutch part)',
  'Slovakia',
  'Slovenia',
  'South Africa',
  'Spain',
  'Sri Lanka',
  'Suriname',
  'Swaziland',
  'Sweden',
  'Switzerland',
  'Taiwan',
  'Thailand',
  'Tunisia',
  'Turkey',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States',
  'Uruguay',
  'Uzbekistan',
  'Vatican City',
  'Venezuela',
  'Viet Nam',
  'Virgin Islands',
  'Yemen',
  'Zambia',
  'Zimbabwe',
]
