import { defineMessages } from 'react-intl'

export const shareCodeMessages = defineMessages({
  msg: {
    id: 'shareCode.msg',
    description: 'Primary message of "Share Code" block',
    defaultMessage:
      'Share this code to allow your Savant Dealer to remotely support and configure your home. You can manage and revoke this access at any time.',
  },
  shareCodeBtn: {
    id: 'shareCode.shareCodeBtn',
    description: 'CTA button title in "Share Code" block',
    defaultMessage: 'Share code',
  },
  shareCodeTxt: {
    id: 'shareCode.shareCodeTxt',
    description: 'Text sent when sharing share code',
    defaultMessage:
      'Hello, I would like your assistance with remotely supporting my Savant Home. Please use this code to connect from Savant Central Management: {claimCode}',
  },
})
