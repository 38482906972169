import styled from 'styled-components'

import theme from 'theme_'

export const ToggleContainer = styled.div`
  .react-toggle-track {
    height: 22px;
    width: 36px;
    background-color: ${theme.colors.dimmedGold};
  }

  .react-toggle-thumb {
    width: 20px;
    height: 20px;
    border: 0;
    background-color: rgba(0, 0, 0, 0.7);
    left: 2px;
  }

  .react-toggle--checked .react-toggle-thumb {
    left: 14px;
  }

  .react-toggle--checked:hover:not(.react-toggle--disabled)
    .react-toggle-track {
    background-color: #c9a778;
  }

  .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #c9a778;
  }

  .react-toggle--checked .react-toggle-track {
    background-color: #c9a778;
  }

  .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    box-shadow: 0px 0px 2px 2px #c9a778;
  }

  .react-toggle--focus .react-toggle-thumb {
    box-shadow: 0px 0px 2px 1px #c9a778;
  }
`
