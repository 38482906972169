import { FC, InputHTMLAttributes, useRef, useCallback, useMemo } from 'react'
import { mdiCircleOutline, mdiCheckCircle } from '@mdi/js'

import theme from 'theme_'
import { MdiIcon } from 'components/Icons'

import { HiddenCheckbox, CheckboxContainer } from './styles'
import DisabledCheckIcon from './DisabledCheck'

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {}

const Checkbox: FC<CheckboxProps> = ({ checked, disabled, ...rest }) => {
  const underlyingInput = useRef<HTMLInputElement>(null)
  const handleClick = useCallback(() => {
    if (underlyingInput.current) {
      underlyingInput.current.click()
    }
  }, [])

  const icon = useMemo(() => {
    if (disabled) return <DisabledCheckIcon />
    return checked ? (
      <MdiIcon path={mdiCheckCircle} color={theme.colors.accentGold} />
    ) : (
      <MdiIcon path={mdiCircleOutline} color={theme.colors.dimmedGold} />
    )
  }, [disabled, checked])

  return (
    <CheckboxContainer onClick={handleClick} disabled={disabled}>
      <HiddenCheckbox
        {...{ checked, disabled }}
        {...rest}
        type="checkbox"
        ref={underlyingInput}
      />
      {icon}
    </CheckboxContainer>
  )
}

export default Checkbox
