import { FC } from 'react'
import { Field, FieldProps } from 'formik'

import Checkbox from 'components/Input/Checkbox'

import { IFieldProps } from '../types'

interface CheckboxProps extends FieldProps {
  disabled?: boolean
}

const CheckboxDrawer: FC<CheckboxProps> = ({ field, disabled }) => {
  return <Checkbox {...field} {...{ disabled }} checked={field.value} />
}

const CheckboxField: FC<IFieldProps> = (props) => (
  <Field component={CheckboxDrawer} {...props} />
)

export default CheckboxField
