import styled from 'styled-components'

import theme from 'theme_'
import { IButtonVariants, ICommonButtonProps } from './CommonButton'

export const COMMON_BUTTON_HEIGHT = {
  default: '48px',
  small: '32px',
}

interface IGeneratedColors {
  color: string
  colorHover: string
  border: string
  borderHover: string
  background: string
  backgroundHover: string
  fontWeight?: string
}

const generateColors = (
  disabled: boolean | undefined,
  variant?: IButtonVariants
): IGeneratedColors => {
  const filledDisabled = {
    color: theme.colors.newBackgroundGrey,
    colorHover: 'none',
    border: `1px solid ${theme.colors.disabledGrey}`,
    borderHover: `1px solid ${theme.colors.disabledGrey}`,
    background: theme.colors.disabledGrey,
    backgroundHover: theme.colors.disabledGrey,
  }

  const outlinedDisabled = {
    color: theme.colors.disabledGrey,
    colorHover: theme.colors.disabledGrey,
    border: `1px solid ${theme.colors.disabledGrey}`,
    borderHover: `1px solid ${theme.colors.disabledGrey}`,
    background: 'transparent',
    backgroundHover: 'transparent',
  }

  switch (variant) {
    case 'whiteOutlined':
      return disabled
        ? outlinedDisabled
        : {
            color: theme.colors.white,
            colorHover: theme.colors.horizonOrange,
            border: `1px solid ${theme.colors.white}`,
            borderHover: `1px solid ${theme.colors.horizonOrange}`,
            background: 'transparent',
            backgroundHover: 'transparent',
          }
    case 'goldenFilled':
      return disabled
        ? filledDisabled
        : {
            color: theme.colors.otterWarmBlack,
            colorHover: 'none',
            border: `1px solid ${theme.colors.accentGold}`,
            borderHover: `1px solid ${theme.colors.white}`,
            background: theme.colors.accentGold,
            backgroundHover: theme.colors.white,
            fontWeight: '700',
          }
    case 'goldenOutlined':
      return disabled
        ? outlinedDisabled
        : {
            color: theme.colors.dimmedGold,
            colorHover: theme.colors.white,
            border: `1px solid ${theme.colors.dimmedGold}`,
            borderHover: `1px solid ${theme.colors.white}`,
            background: 'transparent',
            backgroundHover: 'transparent',
          }
    case 'errorFilled':
      return {
        color: theme.colors.white,
        colorHover: theme.colors.white,
        border: 'none',
        borderHover: 'none',
        background: theme.colors.error,
        backgroundHover: theme.colors.duneWarmGrey,
      }
    // default is darkOutlined
    default:
      return disabled
        ? outlinedDisabled
        : {
            color: theme.colors.duneWarmGrey,
            colorHover: theme.colors.white,
            border: `1px solid ${theme.colors.duneWarmGrey}`,
            borderHover: `1px solid ${theme.colors.white}`,
            background: 'transparent',
            backgroundHover: 'transparent',
          }
  }
}

export const ButtonStyles = styled.button`
  -webkit-appearance: none;
  appearance: none;
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  text-decoration: none;
  outline: none;
  transition: all 0.2s ease;
`

export const LinkStyles = styled.a`
  -webkit-appearance: none;
  appearance: none;
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  text-decoration: none;
  outline: none;
  transition: all 0.2s ease;
`

export const StyledCommonButton = styled(ButtonStyles)<ICommonButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(p) =>
    p.size === 'small'
      ? COMMON_BUTTON_HEIGHT.small
      : COMMON_BUTTON_HEIGHT.default};
  width: ${(p) => (p.size === 'very-small' ? '80px' : 'auto')};
  max-width: 400px;
  padding: 0 ${(p) => (p.size === 'small' ? '12px' : '24px')};
  line-height: ${(p) => (p.size === 'small' ? '11px' : '20px')};
  font-weight: 700;
  font-size: ${(p) => (p.size === 'small' ? '12px' : '16px')};
  font-family: ${(p) => p.theme.fonts.base};
  color: ${(p) => generateColors(p.disabled, p.variant).color};
  border: ${(p) => generateColors(p.disabled, p.variant).border};
  background-color: ${(p) => generateColors(p.disabled, p.variant).background};
  border-radius: 8px;
  text-transform: capitalize;
  user-select: none;

  :hover {
    color: ${(p) => generateColors(p.disabled, p.variant).colorHover};
    border: ${(p) => generateColors(p.disabled, p.variant).borderHover};
    background-color: ${(p) =>
      generateColors(p.disabled, p.variant).backgroundHover};
  }

  :focus-visible {
    outline: 1px solid ${(p) => p.theme.colors.horizonOrange};
  }

  :disabled {
    pointer-events: none;
  }

  @media ${theme.devices.tablet} {
    padding: 0 ${(p) => (p.size === 'small' ? '12px' : '36px')};
  }
`

export const StyledCommonLink = styled(LinkStyles)<ICommonButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(p) =>
    p.size === 'small'
      ? COMMON_BUTTON_HEIGHT.small
      : COMMON_BUTTON_HEIGHT.default};
  width: ${(p) => (p.size === 'very-small' ? '80px' : 'auto')};
  max-width: 400px;
  padding: 0 ${(p) => (p.size === 'small' ? '12px' : '24px')};
  line-height: ${(p) => (p.size === 'small' ? '11px' : '20px')};
  font-weight: 700;
  font-size: ${(p) => (p.size === 'small' ? '12px' : '16px')};
  font-family: ${(p) => p.theme.fonts.base};
  color: ${(p) => generateColors(p.disabled, p.variant).color};
  border: ${(p) => generateColors(p.disabled, p.variant).border};
  background-color: ${(p) => generateColors(p.disabled, p.variant).background};
  border-radius: 8px;
  text-transform: capitalize;
  user-select: none;

  :hover {
    color: ${(p) => generateColors(p.disabled, p.variant).colorHover};
    border: ${(p) => generateColors(p.disabled, p.variant).borderHover};
    background-color: ${(p) =>
      generateColors(p.disabled, p.variant).backgroundHover};
  }

  :focus-visible {
    outline: 1px solid ${(p) => p.theme.colors.horizonOrange};
  }

  :disabled {
    pointer-events: none;
  }

  @media ${theme.devices.tablet} {
    padding: 0 ${(p) => (p.size === 'small' ? '12px' : '36px')};
  }
`

export const StyledCommonButtonDiv = styled.div<ICommonButtonProps>`
  -webkit-appearance: none;
  appearance: none;
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  text-decoration: none;
  outline: none;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(p) =>
    p.size === 'small'
      ? COMMON_BUTTON_HEIGHT.small
      : COMMON_BUTTON_HEIGHT.default};
  width: ${(p) => (p.size === 'very-small' ? '80px' : 'auto')};
  max-width: 400px;
  padding: 0 ${(p) => (p.size === 'small' ? '12px' : '24px')};
  line-height: ${(p) => (p.size === 'small' ? '11px' : '20px')};
  font-weight: 700;
  font-size: ${(p) => (p.size === 'small' ? '12px' : '16px')};
  font-family: ${(p) => p.theme.fonts.base};
  color: ${(p) => generateColors(p.disabled, p.variant).color};
  border: ${(p) => generateColors(p.disabled, p.variant).border};
  background-color: ${(p) => generateColors(p.disabled, p.variant).background};
  border-radius: 8px;
  text-transform: capitalize;
  user-select: none;

  :hover {
    color: ${(p) => generateColors(p.disabled, p.variant).colorHover};
    border: ${(p) => generateColors(p.disabled, p.variant).borderHover};
    background-color: ${(p) =>
      generateColors(p.disabled, p.variant).backgroundHover};
  }

  :focus-visible {
    outline: 1px solid ${(p) => p.theme.colors.horizonOrange};
  }

  :disabled {
    pointer-events: none;
  }

  @media ${theme.devices.tablet} {
    padding: 0 ${(p) => (p.size === 'small' ? '12px' : '36px')};
  }
`
