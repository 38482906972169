import { discoverMoreMessages } from './messages'

interface BrochureOrGuideType {
  title: string
  imgSrc: string
  url: string
}

// https://www.savant.com/brochures-and-guides
export const getBrochuresAndGuides = (
  formatMessage: any
): BrochureOrGuideType[] => [
  {
    title: formatMessage(discoverMoreMessages.audio),
    imgSrc: 'https://cdn.savant.com/products/Music_Hero_1%20%281%29.png',
    url: 'https://www.savant.com/resources-entertainment',
  },
  {
    title: formatMessage(discoverMoreMessages.smartAutomatiom),
    imgSrc:
      'https://cdn.savant.com/products/Hallway_Woman_Tstat_Overshoulder.png',
    url: 'https://www.savant.com/resources-remote',
  },
  {
    title: formatMessage(discoverMoreMessages.lighting),
    imgSrc: 'https://cdn.savant.com/products/Bridgehouse_night%20%281%29.png',
    url: 'https://www.savant.com/resources-lighting',
  },
  {
    title: formatMessage(discoverMoreMessages.theater),
    imgSrc: 'https://cdn.savant.com/products/Lighting%20Colored%201.png',
    url: 'https://www.savant.com/resources-theater',
  },
  {
    title: formatMessage(discoverMoreMessages.shades),
    imgSrc: 'https://cdn.savant.com/products/Shades.png',
    url: 'https://www.savant.com/resources-shades',
  },
  {
    title: formatMessage(discoverMoreMessages.cameras),
    imgSrc: 'https://cdn.savant.com/products/Mobile_Security.png',
    url: 'https://www.savant.com/resources-camera',
  },
  {
    title: formatMessage(discoverMoreMessages.energy),
    imgSrc: 'https://cdn.savant.com/products/Energy-product-cover_2.png',
    url: 'https://www.savant.com/resources-energy',
  },
  {
    title: formatMessage(discoverMoreMessages.partners),
    imgSrc:
      'https://cdn.savant.com/products/Bedroom_Couple_Shades_Remote_02%20Copy.png',
    url: 'https://www.savant.com/resources-partners',
  },
]
