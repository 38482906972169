import React from 'react'
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { mdiAlertCircleOutline } from '@mdi/js'
import { useHistory } from 'react-router-dom'

import ROUTES from 'app/routes'
import { adminHomesListSelector } from 'features/homes/selectors'
import { notFoundPageMessages } from './messages'
import {
  StyledNotFoundWarn,
  StyledNotFoundTitle,
  StyledNotFoundText,
  StyledNotFoundBackBtn,
} from './styles'
import { StubPageWrapper } from '../Layout'
import ReactHelmet from 'features/analytics/RenderHelmet'

const NotFoundPage: React.FC = () => {
  const history = useHistory()
  const adminHomesList = useSelector(adminHomesListSelector)

  const handleClick = () => {
    history.push(adminHomesList.length ? ROUTES.dashboard : ROUTES.noHomes)
  }

  return (
    <>
      <ReactHelmet title="404" />

      <StubPageWrapper>
        <StyledNotFoundWarn
          path={mdiAlertCircleOutline}
          size={2.5}
          color="#A39689"
        />
        <StyledNotFoundTitle>
          <FormattedMessage {...notFoundPageMessages.title} />
        </StyledNotFoundTitle>
        <StyledNotFoundText>
          <FormattedMessage {...notFoundPageMessages.description} />
        </StyledNotFoundText>
        <StyledNotFoundBackBtn variant="goldenFilled" onClick={handleClick}>
          <FormattedMessage {...notFoundPageMessages.backToHome} />
        </StyledNotFoundBackBtn>
      </StubPageWrapper>
    </>
  )
}

export default NotFoundPage
