import {
  AUTH_REDUCER_NAME,
  LOGIN_REDUCER_NAME,
  USER_REDUCER_NAME,
} from '../consts'
import { createSelector } from '@reduxjs/toolkit'
import { makeFetchSelectors } from 'app/redux-fetch/selectors'
import { prop, path } from 'lodash/fp'

export const loginSelectors = makeFetchSelectors(
  AUTH_REDUCER_NAME,
  LOGIN_REDUCER_NAME
)

const selectUserState = path([AUTH_REDUCER_NAME, USER_REDUCER_NAME])

export const currUserSelector = createSelector(selectUserState, prop('user'))

export const failedLoginAttemptsSelector = createSelector(
  selectUserState,
  prop('failedLoginAttempts')
)

export const hasUserStateLoadedSelector = createSelector(
  selectUserState,
  prop('hasLoaded')
)

export const isAuthenticatedSelector = createSelector(
  selectUserState,
  ({ user, hasLoaded }) => hasLoaded && !!user
)
