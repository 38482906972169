import { createSlice } from '@reduxjs/toolkit'

import { fetchEdgeDataThunk, makeFetchSlice } from 'app/redux-fetch'
import { AppDispatch } from 'app/store'
import {
  ClipsListRequestParams,
  getHomeCameraClip,
  GetHomeCameraClipParams,
  getHomeCameraClips,
  GetHomeCameraClipsData,
  getHomeCameras,
  getHomeClips,
} from './api'
import {
  CLIPS_FILTERS_REDUCER_NAME,
  CLIPS_TO_RENDER_LIST_REDUCER_NAME,
} from './consts'
import { currentHomeIdSelector } from 'features/homes/selectors'
import { EventFilterOption } from './components/ClipsFilters/EventFilter'
import { CameraFilterOption } from './components/ClipsFilters/CameraFilter'

const camerasListSlice = makeFetchSlice('camerasList')
export const camerasListReducer = camerasListSlice.reducer
export const camerasListActions = camerasListSlice.actions

export const getCamerasListThunk = (homeId: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      await fetchEdgeDataThunk({
        dispatch,
        actions: camerasListActions,
        asyncFunc: () => getHomeCameras(homeId),
      })
    } catch (error) {
      console.log('Error fetching cameras list for home', homeId, error)
    }
  }
}

const clipsListSlice = makeFetchSlice('clipsList')
export const clipsListReducer = clipsListSlice.reducer
export const clipsListActions = clipsListSlice.actions

export const getClipsListThunk = (params: ClipsListRequestParams) => async (
  dispatch: AppDispatch,
  getState: any
) => {
  const homeId = currentHomeIdSelector(getState())
  const getClipsList = () =>
    params.cameraId
      ? getHomeCameraClips(
          { homeId: homeId, cameraId: params.cameraId },
          params
        )
      : getHomeClips(homeId, params)

  try {
    const payload = await fetchEdgeDataThunk({
      dispatch,
      actions: clipsListActions,
      asyncFunc: getClipsList,
    })

    if (params.next) {
      dispatch(clipsToRenderActions.add(payload.clips))
    } else {
      dispatch(clipsToRenderActions.set(payload.clips))
    }
  } catch (error) {
    console.log('Error fetching clips list for home', homeId, error)
  }
}

const clipsToRenderSlice = createSlice({
  initialState: [],
  name: CLIPS_TO_RENDER_LIST_REDUCER_NAME,
  reducers: {
    set: (_, action) => action.payload,
    add: (state, action) => state.concat(action.payload),
    clear: () => [],
  },
  extraReducers: {
    'homesList/init': () => {
      // Clear clips list when switching home
      return []
    },
  },
})
export const clipsToRenderActions = clipsToRenderSlice.actions
export const clipsToRenderReducer = clipsToRenderSlice.reducer

const cameraClipsListSlice = makeFetchSlice('cameraClipsList')
export const cameraClipsListReducer = cameraClipsListSlice.reducer
export const cameraClipsListActions = cameraClipsListSlice.actions

export const getCameraClipsListThunk = (
  data: GetHomeCameraClipsData,
  params: ClipsListRequestParams
) => {
  return async (dispatch: AppDispatch): Promise<void> => {
    try {
      await fetchEdgeDataThunk({
        dispatch,
        actions: cameraClipsListActions,
        asyncFunc: () => getHomeCameraClips(data, params),
      })
    } catch (error) {
      console.log('Error fetching camera clips list for home', params, error)
    }
  }
}

const cameraClipSlice = makeFetchSlice('cameraClip')
export const cameraClipReducer = cameraClipSlice.reducer
export const cameraClipActions = cameraClipSlice.actions

export const getCameraClipThunk = (params: GetHomeCameraClipParams) => {
  return async (dispatch: AppDispatch): Promise<void> => {
    try {
      await fetchEdgeDataThunk({
        dispatch,
        actions: cameraClipActions,
        asyncFunc: () => getHomeCameraClip(params),
      })
    } catch (error) {
      console.log('Error fetching camera clip for home', params, error)
    }
  }
}

interface ClipsFiltersShape {
  selectedCamera: CameraFilterOption | null
  oldestFirst: boolean
  startDate: string | null
  endDate: string | null
  eventId?: EventFilterOption
}

export const clipsFiltersInitState: ClipsFiltersShape = {
  selectedCamera: null,
  oldestFirst: false,
  startDate: null,
  endDate: null,
}

const clipsFiltersSlice = createSlice({
  initialState: clipsFiltersInitState,
  name: CLIPS_FILTERS_REDUCER_NAME,
  reducers: {
    setEndDate: (state, action) => ({ ...state, endDate: action.payload }),
    setStartDate: (state, action) => ({ ...state, startDate: action.payload }),
    setOldestFirstFilter: (state, action) => ({
      ...state,
      oldestFirst: action.payload,
    }),
    setSelectedCamera: (state, action) => ({
      ...state,
      selectedCamera: action.payload,
    }),
    clearSelectedCamera: (state) => ({ ...state, selectedCamera: null }),
    setEventOption: (state, action) => ({
      ...state,
      eventId: action.payload,
    }),
    clearEventId: (state) => {
      const newState = { ...state }
      delete newState.eventId
      return newState
    },
  },
})
export const clipsFiltersActions = clipsFiltersSlice.actions
export const clipsFiltersReducer = clipsFiltersSlice.reducer
