import React from 'react'
import Icon from '@mdi/react'
import { mdiSkipNext, mdiSkipPrevious } from '@mdi/js'

import type { CurrentClipState } from 'features/cameraRecordings/types'
import type { ClipShape } from 'app/types/clip'
import { customModalStyles } from 'components/Modal'
import ClipHeader from '../ClipHeader'
import {
  StyledVideoModal,
  StyledVideoModalFooter,
  StyledVideoModalInner,
  StyledVideoNavigationBtn,
  StyledVideoNavigationWrap,
} from './styles'

interface VideoModalProps {
  isOpen: boolean
  onClose: () => void
  clipInfo: CurrentClipState
  handleClipChange: (clip: ClipShape) => void
}

const VideoModal: React.FC<VideoModalProps> = ({
  isOpen,
  onClose,
  clipInfo,
  handleClipChange,
}) => {
  const { currentClip, nextClip, prevClip } = clipInfo

  return (
    <StyledVideoModal
      style={customModalStyles}
      isOpen={isOpen}
      shouldCloseOnEsc
      onRequestClose={onClose}
      ariaHideApp={false}
    >
      <StyledVideoModalInner>
        <ClipHeader handleClose={onClose} clipInfo={currentClip} />
        <video
          width="100%"
          controls
          autoPlay
          muted
          disablePictureInPicture
          poster={currentClip?.thumbUrl}
          key={currentClip?.clipId}
        >
          <source src={currentClip?.viewUrl} type="video/mp4" />
        </video>
        <StyledVideoModalFooter>
          <StyledVideoNavigationWrap>
            {prevClip ? (
              <StyledVideoNavigationBtn
                onClick={() => handleClipChange(prevClip)}
              >
                <Icon path={mdiSkipPrevious} size={1} />
              </StyledVideoNavigationBtn>
            ) : (
              <div />
            )}
            {nextClip ? (
              <StyledVideoNavigationBtn
                onClick={() => handleClipChange(nextClip)}
              >
                <Icon path={mdiSkipNext} size={1} />
              </StyledVideoNavigationBtn>
            ) : (
              <div />
            )}
          </StyledVideoNavigationWrap>
        </StyledVideoModalFooter>
      </StyledVideoModalInner>
    </StyledVideoModal>
  )
}

export default VideoModal
